import LazyLoaded from "@/components/LazyLoaded";
import NamingPopup from "@/components/Popup/NamingPopup";
import { updateFolderInFolderList } from "@knowt/syncing/hooks/folders/utils";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import CircularFilledIcon from "@/components/CircularButton/styled/CircularFilledIcon";
import { FolderPlus } from "lucide-react";
import React, { useState } from "react";
import { iconSizes } from "@/utils/iconProps";
import { Folder } from "@knowt/syncing/graphql/schema";

const CreateFolderButton = ({ onCreate }: { onCreate: ({ name }: { name: string }) => Promise<Folder> }) => {
    const { userId } = useCurrentUser();

    const [isCreateNewFolderOpen, setIsCreateNewFolderOpen] = useState(false);

    return (
        <React.Fragment>
            <CircularFilledIcon
                onClick={() => setIsCreateNewFolderOpen(true)}
                Icon={FolderPlus}
                size={iconSizes.MD}
                isActive={isCreateNewFolderOpen}
                radius="5rem"
                tooltip="Create a folder"
            />

            {/* CREATE NEW FOLDER POPUP */}
            <LazyLoaded load={isCreateNewFolderOpen}>
                <NamingPopup
                    title={"New Folder"}
                    isOpen={isCreateNewFolderOpen}
                    onClose={() => setIsCreateNewFolderOpen(false)}
                    placeholder={"Untitled folder"}
                    onConfirm={async name => {
                        if (!name?.trim()?.length) {
                            throw new Error("Sorry please set a name for your folder");
                        }
                        const newFolder = await onCreate({ name });
                        await updateFolderInFolderList({
                            userId,
                            folderId: newFolder.folderId,
                            updatedFields: newFolder,
                        });
                    }}
                />
            </LazyLoaded>
        </React.Fragment>
    );
};

export default CreateFolderButton;
