import React from "react";
import { timeDeltaFromNow } from "@knowt/syncing/utils/dateTimeUtils";
import clsx from "clsx";
import br from "@/styles/breakpoints.module.css";

type LastSavedTimeProps = {
    timestamp: number;
    style?: React.CSSProperties;
    renderText?: (time: string) => string;
    className?: string;
};

export const LastSavedTime = ({ timestamp, style, renderText, className }: LastSavedTimeProps) => {
    const time = timeDeltaFromNow(timestamp);

    const text = typeof renderText === "function" ? renderText(time) : `Last saved ${time} ago`;

    return (
        <div
            className={clsx(className, br.smDownDisplayNone, "secondaryText1")}
            title={text}
            style={{ color: "#CECECE", ...style }}>
            {text}
        </div>
    );
};
