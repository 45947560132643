export const maybeOpenChromeExtensionInstallPage = router => {
    try {
        const chromeExtensionId = process.env.NEXT_PUBLIC_CHROME_EXTENSION_ID;
        chrome.runtime.sendMessage(chromeExtensionId, { installCheck: true }, function (reply) {
            if (reply?.confirmation === "knowt-chrome-bananas") {
                localStorage.setItem("knowt-chrome-extension-installed", "true");
                router.push("/chrome-help");
            } else {
                localStorage.removeItem("knowt-chrome-extension-installed");
                window.open(
                    "https://chrome.google.com/webstore/detail/knowt-quizlet-import-ai-n/akegecpdcdbkjioddaingaedacjgfjhm?hl=en",
                    "_blank"
                );
            }
        });
    } catch {
        // ignore error thrown when chrome extension is not installed, or they're not on chrome
        window.open(
            "https://chrome.google.com/webstore/detail/knowt-quizlet-import-ai-n/akegecpdcdbkjioddaingaedacjgfjhm?hl=en",
            "_blank"
        );
    }
};
