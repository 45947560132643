import { useFolder } from "@knowt/syncing/hooks/folders/useFolders";
import {
    deleteFolder,
    duplicateFolder,
    moveFolder,
    renameFolder,
    restoreFolder,
    trashFolder,
    updateFolderPublicState,
} from "@knowt/syncing/hooks/folders/utils";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { type Folder, ItemType, UpgradeEvent } from "@knowt/syncing/graphql/schema";
import Portal from "@mui/base/Portal";
import { Copy, ExternalLink, FolderInput, Link, Palette, PencilLine, SmilePlus } from "lucide-react";
import dynamic from "next/dynamic";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { ThreeDotsBtn } from "../../UserContentCard/components/UserContentCardHandlerButtons";
import { FlexRowAlignCenter } from "@/components/Flex";
import LazyLoaded from "@/components/LazyLoaded";
import { useUpgradePopupContextSelector } from "@/components/Popup/Subscription/UpgradePopupContext";
import WithOptionsMenu, { WithMenuOption } from "@/components/WithOptionsMenu";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";
import useConfirmDialog from "@/hooks/useConfirmDialog";
import { copyToClipboard } from "@/utils/clipboard";
import { iconSizes, strokeWidth } from "@/utils/iconProps";
import { isOnFolder, isOnHome } from "@/utils/pathUtils";
import { usePathname } from "next/navigation";
import { useRouter } from "next13-progressbar";
import { themeColors } from "@/utils/themeColors";
import { useMoveToPopupContextSelector } from "@/features/MoveToPopup";

const NamingPopup = dynamic(() => import("@/components/Popup/NamingPopup"), { ssr: false });

type FolderOptionsMenuProps = {
    folder: Folder;
    parentRef: React.MutableRefObject<HTMLDivElement>;
    btnRef: React.MutableRefObject<HTMLButtonElement>;
    openIconPicker: (type: "emoji" | "color") => void;
    renderBtn?: (openMenu: () => void) => React.ReactNode;
};

const FolderOptionsMenu = ({
    folder: initialFolder,
    parentRef,
    openIconPicker,
    btnRef,
    renderBtn,
}: FolderOptionsMenuProps) => {
    const pathname = usePathname();
    const router = useRouter();

    const { smDown } = useBreakPoints();
    const { isSubscriptionActive, user } = useCurrentUser();
    const openUpgradePopup = useUpgradePopupContextSelector(state => state.openUpgradePopup);

    const [shouldHookLoad, setShouldHookLoad] = useState(false);

    // folder related states
    const { folder, readOnly } = useFolder({
        folderId: initialFolder.folderId,
        fallbackData: initialFolder,
        isEnabled: shouldHookLoad,
    });

    const { name, folderId, userId, parentId, public: isPublic, classId } = folder as Folder;
    const folderRoute = `/folder/${folderId}`;

    // menu popup related states
    const { openConfirmDialog } = useConfirmDialog();
    const [isFolderPopupOpen, setIsFolderPopupOpen] = useState(false);
    const openMoveToPopup = useMoveToPopupContextSelector(state => state?.openMoveToPopup);

    const setPublicAndGetLink = async (newIsPublic: boolean) => {
        const toastId = toast.loading(newIsPublic ? "Sharing folder..." : "Updating folder sharing settings...");
        try {
            await updateFolderPublicState({ folderId, userId, isPublic: newIsPublic, parentId });

            toast.success(newIsPublic ? "Folder link successfully copied!" : "Folder sharing settings updated", {
                id: toastId,
            });

            if (newIsPublic) {
                copyToClipboard(encodeURI(window.location.origin) + folderRoute);
            }
        } catch (error) {
            toast.error("Failed to update the folder.", { id: toastId });
        }
    };

    const changeColorOption: WithMenuOption = {
        label: "Change Folder Color",
        onClick: () => {
            if (!isSubscriptionActive) {
                return openUpgradePopup({
                    event: UpgradeEvent.FOLDER_COLOR,
                    context: { itemId: folderId, itemType: ItemType.FOLDER },
                });
            }
            openIconPicker("color");
        },
        node: (
            <FlexRowAlignCenter style={{ gap: "0.9rem" }}>
                <Palette strokeWidth={strokeWidth.normal} size={iconSizes.SM} /> Change Folder Color
            </FlexRowAlignCenter>
        ),
        hide: readOnly,
    };

    const changeIconOption: WithMenuOption = {
        label: "Change Folder Icon",
        onClick: () => {
            if (!isSubscriptionActive) {
                return openUpgradePopup({
                    event: UpgradeEvent.FOLDER_EMOJI,
                    context: { itemId: folderId, itemType: ItemType.FOLDER },
                });
            }
            openIconPicker("emoji");
        },
        node: (
            <FlexRowAlignCenter style={{ gap: "0.9rem" }}>
                <SmilePlus strokeWidth={strokeWidth.normal} size={iconSizes.SM} /> Change Folder Emoji
            </FlexRowAlignCenter>
        ),
        hide: readOnly,
    };

    const renameOption: WithMenuOption = {
        label: "Rename",
        onClick: () => {
            setIsFolderPopupOpen(true);
        },
        hide: readOnly,
        node: (
            <FlexRowAlignCenter style={{ gap: "0.9rem" }}>
                <PencilLine strokeWidth={strokeWidth.normal} size={iconSizes.SM} /> Rename
            </FlexRowAlignCenter>
        ),
    };

    const duplicateOption: WithMenuOption = {
        label: "Duplicate",
        onClick: async () => {
            const newFolder = await toast.promise(duplicateFolder({ folderId, user }), {
                loading: "Duplicating folder...",
                success: "Folder duplicated",
                error: "Something went wrong! Please try again later",
            });

            if (!isOnHome(pathname) && !isOnFolder(pathname)) {
                router.push(`/folder/${newFolder.folderId}`);
            }
        },
        node: (
            <FlexRowAlignCenter style={{ gap: "0.9rem" }}>
                <Copy strokeWidth={strokeWidth.normal} size={iconSizes.SM} /> Duplicate
            </FlexRowAlignCenter>
        ),
    };

    const getLinkOption: WithMenuOption = {
        label: isPublic ? "Unshare" : "Get link",
        onClick: () => {
            if (!readOnly) {
                setPublicAndGetLink(!isPublic);
            } else {
                copyToClipboard(encodeURI(window.location.origin) + folderRoute);
            }
        },
        node: (
            <FlexRowAlignCenter style={{ gap: "0.9rem" }}>
                <Link strokeWidth={strokeWidth.normal} size={iconSizes.SM} />{" "}
                {isPublic && !readOnly ? "Unshare" : "Get link"}
            </FlexRowAlignCenter>
        ),
    };

    const moveToOption: WithMenuOption = {
        label: "Move to",
        onClick: async () => {
            if (!folder) return;

            openMoveToPopup({
                onMove: async ({ classId: destinationClassId, folderId: destinationFolderId }) => {
                    moveFolder({
                        folderId,
                        userId,
                        sourceFolderId: parentId ?? null,
                        parentId: destinationFolderId,
                        sourceClassId: folder.classId ?? null,
                        destinationClassId,
                    });
                },
                selectedFolders: {
                    [folderId]: folder,
                },
            });
        },
        node: (
            <FlexRowAlignCenter style={{ gap: "0.9rem" }}>
                <FolderInput strokeWidth={strokeWidth.normal} size={iconSizes.SM} /> Move to
            </FlexRowAlignCenter>
        ),
        hide: readOnly || typeof openMoveToPopup !== "function",
    };

    const openInNewTabOption: WithMenuOption = {
        label: "Open in new tab",
        onClick: () => window.open(folderRoute, "_blank"),
        node: (
            <FlexRowAlignCenter style={{ gap: "0.9rem" }}>
                <ExternalLink strokeWidth={strokeWidth.normal} size={iconSizes.SM} /> Open in new tab
            </FlexRowAlignCenter>
        ),
    };

    const trashOption: WithMenuOption = {
        label: folder?.classId ? "Remove" : "Trash",
        onClick: async () => {
            if (folder?.classId) {
                toast.loading("Removing folder from class...", { id: "DELETE_FOLDER" });
                await moveFolder({
                    folderId,
                    userId,
                    sourceFolderId: parentId ?? null,
                    parentId: null,
                    sourceClassId: folder.classId,
                    destinationClassId: null,
                });
                toast.success("Folder removed from class", { id: "DELETE_FOLDER" });
            } else {
                toast.loading("Trashing folder...", { id: "DELETE_FOLDER" });
                await trashFolder({ folderId, userId, parentId: parentId ?? null, removeFromClass: !!classId });
                toast.success("Folder trashed", { id: "DELETE_FOLDER" });
            }
        },
        hide: readOnly,
        menuItemProps: {
            sx: {
                borderTop: `2px solid ${themeColors.neutral1}`,
                height: "4.7rem",
                color: themeColors.errorPrimary,
                fontWeight: "600",
                fontSize: "1.5rem",
                marginBottom: "-0.8rem",
            },
        },
    };

    const restoreOption: WithMenuOption = {
        label: "Restore",
        onClick: async () => {
            const toastId = toast.loading("Restoring folder...");
            await restoreFolder({ folderId, userId });
            toast.success("Folder restored", { id: toastId });
        },
        hide: readOnly,
    };

    const deletePermanentOption: WithMenuOption = {
        label: "Delete",
        onClick: async () => {
            openConfirmDialog({
                title: "Are you sure you want to delete?",
                subtitle: "This folder will not be able to be recovered if you continue to delete it.",
                style: { borderRadius: "4rem" },
                rounded: true,
                cardSize: !smDown ? "58rem" : "31rem",
                spacing: 2.2,
                subtitleFontSize: "1.9rem",
                subtitleFontWeight: "500",
                buttonColor: "black",
                simpleCancelBtn: true,
                onConfirm: async () => {
                    toast.promise(deleteFolder({ folderId, userId }), {
                        loading: "Deleting folder permanently...",
                        success: "Folder deleted permanently",
                        error: "Something went wrong! Please try again later",
                    });
                },
            });
        },
        hide: readOnly,
    };

    const options: WithMenuOption[] = folder?.trash
        ? [restoreOption, deletePermanentOption]
        : [
              changeColorOption,
              changeIconOption,
              duplicateOption,
              moveToOption,
              renameOption,
              getLinkOption,
              openInNewTabOption,
              trashOption,
          ];

    return (
        <>
            {/* THREE DOTS */}
            <WithOptionsMenu
                options={options}
                menuItemProps={{
                    sx: { padding: "1rem 1.6rem" },
                }}
                menuProps={{
                    PaperProps: {
                        sx: {
                            borderRadius: "2rem",
                            width: "21.6rem",
                            fontSize: "1.4rem",
                            margin: "-0.5rem 0 0 -1.25rem",
                        },
                    },
                    transformOrigin: { vertical: "top", horizontal: "left" },
                }}>
                {({ openMenu }) => {
                    if (renderBtn) return renderBtn(openMenu);

                    return (
                        <ThreeDotsBtn
                            ref={btnRef}
                            onPointerEnter={() => setShouldHookLoad(true)}
                            onRightClick={e => {
                                setShouldHookLoad(true);
                                openMenu(e);
                            }}
                            onClick={openMenu}
                            cardContainerRef={parentRef}
                        />
                    );
                }}
            </WithOptionsMenu>

            {/* MENU POPUP */}
            <Portal>
                {/* disable propogation since we don't want to trigger note anchor href when inside the popup */}
                <LazyLoaded load={isFolderPopupOpen}>
                    <div onClick={e => e.stopPropagation()}>
                        <NamingPopup
                            title={"Rename folder"}
                            placeholder="Untitled folder"
                            defaultName={name ? name : undefined}
                            isOpen={isFolderPopupOpen}
                            onClose={() => setIsFolderPopupOpen(false)}
                            onConfirm={async (newFolderName: string) => {
                                await renameFolder({
                                    userId: userId as string,
                                    folderId: folder?.folderId as string,
                                    newName: newFolderName,
                                });
                                toast.success("Folder renamed");
                            }}
                        />
                    </div>
                </LazyLoaded>
            </Portal>
        </>
    );
};

export default FolderOptionsMenu;
