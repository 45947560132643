"use client";

import { FlexRowAlignCenter } from "@/components/Flex";
import { useClassManagementContextSelector } from "../../hooks/useClassManagementContext";
import { ALL_ITEM_TYPES } from "../../../types";
import {
    CreateFolderButton,
    FilterButton,
    SelectMultipleButton,
    SortButton,
} from "../../../components/ControlSections";
import { spacing } from "@/utils/spacing";
import ClickableText from "@/components/styled/button/ClickableText";
import { CreateNewContentButtonWithHandler } from "@/components/CreateNewContent/CreateNewContentButtonWithHandler";
import { callCreateFolder } from "@knowt/syncing/hooks/folders/graphqlUtils";
import { v4 as uuidv4 } from "uuid";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import ClassContentRemoveButton from "./ClassContentRemoveButton";
import { resolveFoldersSWRKey } from "@knowt/syncing/hooks/folders/utils";
import { mutate } from "swr";
import br from "@/styles/breakpoints.module.css";
import { ItemType } from "@knowt/syncing/graphql/schema";
import { useClassSharingPopupContextSelector } from "@/features/ShareSettings";
import { useUserContentManagementContextSelector } from "@/features/UserContentManagement";

const ClassControlSection = () => {
    const { userId } = useCurrentUser();

    const classId = useClassManagementContextSelector(state => state.classId);
    const course = useClassManagementContextSelector(state => state.course);
    const isTeacher = useClassManagementContextSelector(state => state.isTeacher);

    const sort = useUserContentManagementContextSelector(state => state.sort);
    const setSort = useUserContentManagementContextSelector(state => state.setSort);
    const isSelectMode = useUserContentManagementContextSelector(state => state.isSelectMode);
    const setIsSelectMode = useUserContentManagementContextSelector(state => state.setIsSelectMode);
    const itemTypesToShow = useUserContentManagementContextSelector(state => state.itemTypesToShow);
    const setItemTypesToShow = useUserContentManagementContextSelector(state => state.setItemTypesToShow);

    const openClassSharingPopup = useClassSharingPopupContextSelector(state => state.openClassSharingPopup);

    // TODO: should separate between student and teacher with `isTeacher` or something

    if (isSelectMode) {
        return (
            <FlexRowAlignCenter style={{ gap: spacing.SM }}>
                <ClickableText onClick={() => setIsSelectMode(false)}>cancel</ClickableText>
                <ClassContentRemoveButton />
            </FlexRowAlignCenter>
        );
    }

    return (
        <FlexRowAlignCenter className={br.smDownColumn}>
            {isTeacher && (
                <FlexRowAlignCenter
                    className={br.smDownWidth100}
                    style={{
                        gap: spacing.SM,
                    }}>
                    <CreateNewContentButtonWithHandler color={course?.color} classId={course?.classId} />
                    <CreateFolderButton
                        onCreate={async ({ name }) => {
                            const newFolder = await callCreateFolder({
                                userId,
                                folderId: uuidv4(),
                                name,
                                trash: false,
                                classId: course?.classId as string,
                            });

                            const mutateFolder = async () => {
                                await mutate(resolveFoldersSWRKey({ userId: userId as string, classId }));
                            };

                            await mutateFolder();

                            openClassSharingPopup({
                                fileId: newFolder.folderId,
                                fileType: ItemType.FOLDER,
                            });

                            return newFolder;
                        }}
                    />
                    <SelectMultipleButton />
                </FlexRowAlignCenter>
            )}

            {isTeacher && <div style={{ paddingTop: spacing.SM }} className={br.smUpDisplayNone} />}

            <FlexRowAlignCenter style={{ gap: spacing.SM, marginLeft: "auto" }}>
                <FilterButton
                    allItemTypes={ALL_ITEM_TYPES}
                    selectedItemTypes={itemTypesToShow}
                    onChange={({ selectedItemTypes: newSelected }) => setItemTypesToShow(newSelected)}
                />
                <SortButton
                    onChange={({ by, direction }) => setSort({ by, direction })}
                    by={sort.by}
                    direction={sort.direction}
                />
            </FlexRowAlignCenter>
        </FlexRowAlignCenter>
    );
};

export default ClassControlSection;
