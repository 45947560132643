import Box, { BoxProps } from "@mui/material/Box";
import { Check, LucideProps } from "lucide-react";
import { themeColors } from "@/utils/themeColors";
import { iconSizes } from "@/utils/iconProps";

const CheckBox = ({
    isChecked,
    onCheck,
    boxProps,
    iconProps,
}: {
    isChecked: boolean;
    onCheck: () => void;
    boxProps?: BoxProps;
    iconProps?: LucideProps;
}) => {
    return (
        <Box
            className="strippedBtn"
            onClick={onCheck}
            {...boxProps}
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "2.4rem",
                height: "2.4rem",
                borderRadius: "0.5rem",
                border: "2px solid",
                borderColor: isChecked ? themeColors.primary : themeColors.neutral2,
                backgroundColor: isChecked ? themeColors.primaryLight : "transparent",
                transition: "border-color 0.2s ease-in-out, background-color 0.2s ease-in-out",

                ":hover": {
                    borderColor: themeColors.primary,
                },
                ...boxProps?.sx,
            }}>
            <Check
                size={iconSizes.SM}
                color={themeColors.primary}
                {...iconProps}
                style={{
                    opacity: isChecked ? 1 : 0,
                    transition: "opacity 0.2s ease-in-out",
                    ...iconProps?.style,
                }}
            />
        </Box>
    );
};

export default CheckBox;
