"use client";

import { FlexColumnAlignJustifyCenter } from "@/components/Flex";
import LoadingAnimation from "@/components/LoadingAnimation";
import { MoveToPopupProvider } from "features/MoveToPopup";
import FolderCard from "@/components/cards/FolderCard";
import UserContentCard from "@/components/cards/UserContentCard";
import { useAllMedias } from "@/hooks/media/useMedia";
import { useAllBookmarks } from "@knowt/syncing/hooks/bookmarks/useBookmarks";
import { useTopLevelStandaloneFlashcardSets } from "@knowt/syncing/hooks/flashcards/useStandaloneFlashcards";
import { useFolders } from "@knowt/syncing/hooks/folders/useFolders";
import { useNotebook } from "@knowt/syncing/hooks/notes/useNotes";
import { FlashcardSet, Media, Note } from "@knowt/syncing/graphql/schema";
import React, { useMemo } from "react";
import { SavedTabEmptyState, TrashTabEmptyState } from "../../components/EmptyStateComponents";
import {
    DraggableCardHandler,
    DroppableCardHandler,
    UserContentManagementMoveToHandler,
} from "../../components/ContentDndHandlers";
import { useUserContentManagementContextSelector } from "../../hooks/useUserContentManagementContext";
import { ContentsGrid, FolderList, ItemList } from "../../components/UserContentManagementGrid";
import { filterUserContentItems, sortHomeFolder, sortPinnedItemsToTop, sortUserContentItems } from "../../utils";
import { useSavedUserContents } from "@knowt/syncing/hooks/bookmarks/useSavedUserContents";
import { UserContentItem } from "../../types";
import { MyFilesTabEmptyState, ClassesTabEmptyState } from "./HomeEmptyStates";
import { useClasses } from "@/hooks/classes/useClass";
import { assertTruthy } from "@/utils/assertions";
import ClassInfoCard from "../../ClassPage/components/ClassInfoCard";
import { useHomeManagementContextSelector } from "../hooks/useHomeManagementContext";
import { isFlashcardSet, isNote } from "@knowt/syncing/utils/dataCleaning";
import { HomeTab } from "../types";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import {
    CreateClassGuidedWalkthrough,
    CreateNoteGuidedWalkthrough,
    CreatePDFGuidedWalkthrough,
    SwitchFilesTabGuidedWalkthrough,
} from "./Walkthroughs";

export const HomeHomeTabContent = () => {
    const serverNotes = useUserContentManagementContextSelector(state => state.serverNotes);
    const serverFlashcardSets = useUserContentManagementContextSelector(state => state.serverFlashcardSets);
    const serverMedias = useUserContentManagementContextSelector(state => state.serverMedias);
    const sort = useUserContentManagementContextSelector(state => state.sort);
    const itemTypesToShow = useUserContentManagementContextSelector(state => state.itemTypesToShow);
    const serverFolders = useUserContentManagementContextSelector(state => state.serverFolders);
    const isSelectMode = useUserContentManagementContextSelector(state => state.isSelectMode);
    const selectedItems = useUserContentManagementContextSelector(state => state.selectedItems);
    const toggleSelectedItem = useUserContentManagementContextSelector(state => state.toggleSelectedItem);

    const { notes: notesObj } = useNotebook({
        inTrash: false,
        showFoldersNotes: false,
        fallbackData: serverNotes,
    });

    const { flashcardSetsObj } = useTopLevelStandaloneFlashcardSets({
        inTrash: false,
        fallbackData: serverFlashcardSets,
    });

    const { medias: mediasObj } = useAllMedias({ inTrash: false, fallbackData: serverMedias });

    const isLoading = !notesObj || !flashcardSetsObj || !mediasObj;

    const { bookmarks } = useAllBookmarks();

    const sortedItems = useMemo(() => {
        if (isLoading) return [];

        const notes = Object.values(notesObj).filter(({ folderId, mediaId }) => !folderId && !mediaId);

        const flashcardSets = Object.values(flashcardSetsObj).filter(
            ({ folderId, mediaId, noteId }) => !folderId && !mediaId && !noteId
        );

        const medias = Object.values(mediasObj).filter(({ folderId }) => !folderId);

        const filteredHomeItems = filterUserContentItems(
            [...notes, ...flashcardSets, ...medias].map(item => {
                const id = isNote(item) ? item.noteId : isFlashcardSet(item) ? item.flashcardSetId : item.mediaId;
                return { ...item, isBookmarked: !!bookmarks?.[id] };
            }),
            itemTypesToShow
        );

        const sortedHomeItems = sortUserContentItems(filteredHomeItems, sort) as UserContentItem[];

        return sortPinnedItemsToTop(sortedHomeItems, Object.keys(bookmarks || {}));
    }, [isLoading, notesObj, flashcardSetsObj, mediasObj, itemTypesToShow, sort, bookmarks]);

    const { folders: foldersObj } = useFolders({ inTrash: false, fallbackData: serverFolders });

    const sortedFolders = useMemo(() => {
        const foldersArr = Object.values(foldersObj || {});
        return sortHomeFolder(foldersArr, sort);
    }, [foldersObj, sort]);

    const userHasNoContent = !sortedItems.length && !sortedFolders.length;

    if (isLoading) {
        return <LoadingAnimation style={{ width: "100%", margin: "auto", flex: 1 }} />;
    }

    if (userHasNoContent) {
        return (
            <div style={{ flex: 1 }}>
                <MyFilesTabEmptyState />
                <CreateNoteGuidedWalkthrough userHasNoContent={userHasNoContent} />
            </div>
        );
    }

    // Home folders with drag and drop handler
    const renderFolderGrid = () => {
        return (
            <ContentsGrid label={"Folders"} itemMinWidth={"28rem"} style={{ marginBottom: "3.2rem" }}>
                {sortedFolders.map(folder => {
                    const folderId = folder.folderId;

                    return (
                        <DroppableCardHandler key={folderId} id={folderId}>
                            {({ isOver }) => (
                                <DraggableCardHandler id={folderId} item={folder}>
                                    <FolderCard
                                        folder={folder}
                                        modeProps={{
                                            mode: isSelectMode ? "SELECT" : null,
                                            isSelected: folderId in selectedItems,
                                            toggleSelect: () => toggleSelectedItem(folderId, folder),
                                        }}
                                        style={{ ...(isOver ? { backgroundColor: "#50d2c2" } : {}) }}
                                    />
                                </DraggableCardHandler>
                            )}
                        </DroppableCardHandler>
                    );
                })}
            </ContentsGrid>
        );
    };

    // Home notes, flashcards and medias with drag and drop handler
    // UserContents means notes, flashcards and media
    const renderUserContentGrid = () => {
        return (
            <ContentsGrid label={"Your Files"} itemMinWidth={"28rem"} style={{ paddingBottom: "13rem" }}>
                {sortedItems.map((item, i) => {
                    const itemId = isNote(item)
                        ? item.noteId
                        : isFlashcardSet(item)
                        ? item.flashcardSetId
                        : item.mediaId;

                    return (
                        <DraggableCardHandler key={"homeItem" + itemId + i} id={itemId} item={item}>
                            <UserContentCard
                                {...(isNote(item)
                                    ? { note: item }
                                    : isFlashcardSet(item)
                                    ? { flashcardSet: item }
                                    : { media: item })}
                                modeProps={{
                                    mode: isSelectMode ? "SELECT" : null,
                                    isSelected: itemId in selectedItems,
                                    toggleSelect: toggleSelectedItem,
                                }}
                            />
                        </DraggableCardHandler>
                    );
                })}
            </ContentsGrid>
        );
    };

    return (
        <UserContentManagementMoveToHandler disabled={isSelectMode}>
            <MoveToPopupProvider>
                {!!sortedFolders.length && renderFolderGrid()}
                {!!sortedItems.length && renderUserContentGrid()}
            </MoveToPopupProvider>
            <CreatePDFGuidedWalkthrough />
        </UserContentManagementMoveToHandler>
    );
};

export const HomeSavedTabContent = () => {
    const sort = useUserContentManagementContextSelector(state => state.sort);
    const itemTypesToShow = useUserContentManagementContextSelector(state => state.itemTypesToShow);

    const { savedNotes, savedFlashcardSets, savedMedias } = useSavedUserContents({ delay: 0 });

    const isLoading = !savedNotes || !savedFlashcardSets || !savedMedias;

    const sortedItems = useMemo(() => {
        if (isLoading) return [];

        const filteredItems = filterUserContentItems(
            [...savedNotes, ...savedFlashcardSets, ...savedMedias],
            itemTypesToShow
        );

        return sortUserContentItems(filteredItems, sort) as UserContentItem[];
    }, [isLoading, itemTypesToShow, sort, savedNotes, savedFlashcardSets, savedMedias]);

    if (isLoading) {
        return <LoadingAnimation style={{ width: "100%", margin: "auto", flex: 1 }} />;
    }

    if (!sortedItems.length) {
        return (
            <FlexColumnAlignJustifyCenter style={{ flex: 1 }}>
                <SavedTabEmptyState />
            </FlexColumnAlignJustifyCenter>
        );
    }

    return (
        <ContentsGrid label={"Your Files"} itemMinWidth={"28rem"} style={{ paddingBottom: "15rem" }}>
            <ItemList items={sortedItems} />
        </ContentsGrid>
    );
};

export const HomeTrashTabContent = () => {
    const serverNotes = useUserContentManagementContextSelector(state => state.serverNotes);
    const serverFlashcardSets = useUserContentManagementContextSelector(state => state.serverFlashcardSets);
    const sort = useUserContentManagementContextSelector(state => state.sort);
    const itemTypesToShow = useUserContentManagementContextSelector(state => state.itemTypesToShow);

    const { notes: notesObj } = useNotebook({ inTrash: true, fallbackData: serverNotes });

    const { flashcardSetsObj } = useTopLevelStandaloneFlashcardSets({
        inTrash: true,
        fallbackData: serverFlashcardSets,
    });

    const { medias: mediasObj } = useAllMedias({ inTrash: true });

    const isLoading = !notesObj || !flashcardSetsObj;

    const sortedItems = useMemo(() => {
        if (isLoading) return [];

        const notes = Object.values(notesObj).filter(({ trash }: Note) => trash) as Note[];

        const flashcardSets = Object.values(flashcardSetsObj).filter(
            ({ trash }: FlashcardSet) => trash
        ) as FlashcardSet[];

        const medias = mediasObj && (Object.values(mediasObj).filter(({ folderId }: Media) => !folderId) as Media[]);

        const filteredItems = filterUserContentItems(
            [...notes, ...flashcardSets, ...medias] as UserContentItem[],
            itemTypesToShow
        );

        return sortUserContentItems(filteredItems, sort);
    }, [isLoading, notesObj, flashcardSetsObj, mediasObj, sort, itemTypesToShow]);

    const { folders: foldersObj } = useFolders({ parentId: null, inTrash: true });

    const sortedFolders = useMemo(() => {
        const foldersArr = Object.values(foldersObj || {}).filter(({ trash }) => trash);
        return sortHomeFolder(foldersArr, sort);
    }, [foldersObj, sort]);

    if (isLoading) {
        return <LoadingAnimation style={{ width: "100%", margin: "auto", flex: 1 }} />;
    }

    if (!sortedItems.length && !sortedFolders.length) {
        return (
            <FlexColumnAlignJustifyCenter style={{ flex: 1 }}>
                <TrashTabEmptyState />
            </FlexColumnAlignJustifyCenter>
        );
    }

    return (
        <React.Fragment>
            {!!sortedFolders.length && (
                <ContentsGrid label={"Folders"} itemMinWidth={"28rem"} style={{ marginBottom: "3.2rem" }}>
                    <FolderList folders={sortedFolders} />
                </ContentsGrid>
            )}
            {!!sortedItems.length && (
                <ContentsGrid label={"Your Files"} itemMinWidth={"28rem"} style={{ paddingBottom: "15rem" }}>
                    <ItemList items={sortedItems} />
                </ContentsGrid>
            )}
        </React.Fragment>
    );
};

export const HomeClassesTabContent = () => {
    const { isTeacher } = useCurrentUser();
    const sort = useUserContentManagementContextSelector(state => state.sort);
    const serverClasses = useUserContentManagementContextSelector(state => state.serverClasses);

    const currentTab = useHomeManagementContextSelector(state => state.currentTab);

    const { classes: classesObj, isLoading } = useClasses({
        fallbackData: serverClasses,
        isEnabled: currentTab === HomeTab.CLASSES,
    });

    const sortedClasses = useMemo(() => {
        if (isLoading) return [];

        const classes = Object.values(classesObj || {});

        return sortUserContentItems(classes, sort);
    }, [classesObj, isLoading, sort]);

    if (isLoading) return <LoadingAnimation style={{ width: "100%", margin: "auto", flex: 1 }} />;

    const isError = !classesObj;
    if (isError) return <div>error fetching</div>;

    assertTruthy(classesObj);

    if (!sortedClasses.length)
        return (
            <>
                <ClassesTabEmptyState isTeacher={isTeacher} />
                {isTeacher && <CreateClassGuidedWalkthrough />}
                {isTeacher && <SwitchFilesTabGuidedWalkthrough />}
            </>
        );

    return (
        <ContentsGrid label={"Classes"} itemMinWidth={"30rem"}>
            {sortedClasses.map(course => (
                <ClassInfoCard key={course.classId} course={course} />
            ))}
        </ContentsGrid>
    );
};
