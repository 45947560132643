/**
 * https://github.com/gcoro/react-qrcode-logo
 * Fork of react-qrcode-logo with removal of some unused props and types
 * Swapped to functional component, with stronger typing.
 * Thanks to @gcoro for the original work!
 */

import * as qrGenerator from "qrcode-generator";
import { createElement, useEffect, useRef } from "react";

type EyeColor = string;
type InnerOuterEyeColor = {
    inner: string;
    outer: string;
};

type CornerRadii = [number, number, number, number];
type InnerOuterRadii = {
    inner: [number, number, number, number];
    outer: [number, number, number, number];
};

type QRCodeProps = {
    value: string;
    ecLevel?: "L" | "M" | "Q" | "H";
    enableCORS?: boolean;
    size?: number;
    quietZone?: number;
    bgColor?: `#${string}`;
    fgColor?: `#${string}`;
    logoImage?: string;
    logoWidth?: number;
    logoHeight?: number;
    logoOpacity?: number;
    logoOnLoad?: () => void;
    logoPadding?: number;
    logoPaddingStyle?: "square" | "circle";
    eyeRadius?: CornerRadii | InnerOuterRadii;
    eyeColor?: EyeColor | InnerOuterEyeColor;
    qrStyle?: "squares" | "dots";
    style?: object;
    id?: string;
};

interface ICoordinates {
    row: number;
    col: number;
}

const utf16to8 = (str: string): string => {
    let out = "",
        i: number,
        c: number;
    const len: number = str.length;
    for (i = 0; i < len; i++) {
        c = str.charCodeAt(i);
        if (c >= 0x0001 && c <= 0x007f) {
            out += str.charAt(i);
        } else if (c > 0x07ff) {
            out += String.fromCharCode(0xe0 | ((c >> 12) & 0x0f));
            out += String.fromCharCode(0x80 | ((c >> 6) & 0x3f));
            out += String.fromCharCode(0x80 | ((c >> 0) & 0x3f));
        } else {
            out += String.fromCharCode(0xc0 | ((c >> 6) & 0x1f));
            out += String.fromCharCode(0x80 | ((c >> 0) & 0x3f));
        }
    }
    return out;
};

/**
 *
 * @param value - The value to encode in the QR code
 * @param ecLevel - The error correction level to use
 * @param enableCORS - Enable CORS for the logo image
 * @param size - The size of the QR code
 * @param quietZone - The size of the quiet zone
 * @param bgColor - The background color
 * @param fgColor - The foreground color
 * @param logoImage - The logo image
 * @param logoWidth - The width of the logo
 * @param logoHeight - The height of the logo
 * @param logoOpacity - The opacity of the logo
 * @param logoOnLoad - The logo onload callback
 * @param logoPadding - The padding around the logo
 * @param logoPaddingStyle - The padding style around the logo
 * @param eyeRadius - The radius of the eyes
 * @param eyeColor - The color of the eyes
 * @param qrStyle - The style of the QR code: "squares" or "dots"

 */
export const QRCode = ({
    value,
    ecLevel = "M",
    enableCORS = false,
    size = 150,
    quietZone = 10,
    bgColor = "#FFFFFF",
    fgColor = "#000000",
    logoOpacity = 1,
    qrStyle = "squares",
    eyeRadius = [0, 0, 0, 0],
    eyeColor = fgColor,
    logoPadding,
    logoPaddingStyle = "square",
    logoImage,
    logoWidth,
    logoHeight,
    logoOnLoad,
    id = `qrcode-${value}`,
}: QRCodeProps) => {
    const canvas = useRef<HTMLCanvasElement | null>(null);

    useEffect(() => {
        /**
         * Draw a single positional pattern eye.
         */
        const drawPositioningPattern = (
            ctx: CanvasRenderingContext2D,
            cellSize: number,
            offset: number,
            row: number,
            col: number,
            color: EyeColor | InnerOuterEyeColor,
            radii: CornerRadii | InnerOuterRadii = [0, 0, 0, 0]
        ) => {
            const lineWidth = Math.ceil(cellSize);

            let radiiOuter: CornerRadii;
            let radiiInner: CornerRadii;
            if (!Array.isArray(radii)) {
                radiiOuter = radii.outer || 0;
                radiiInner = radii.inner || 0;
            } else {
                radiiOuter = radii as CornerRadii;
                radiiInner = radii as CornerRadii;
            }

            let colorOuter: string;
            let colorInner: string;
            if (typeof color !== "string") {
                colorOuter = color.outer;
                colorInner = color.inner;
            } else {
                colorOuter = color;
                colorInner = color;
            }

            let y = row * cellSize + offset;
            let x = col * cellSize + offset;
            let size = cellSize * 7;

            // Outer box
            drawRoundedSquare(lineWidth, x, y, size, colorOuter, radiiOuter, false, ctx);

            // Inner box
            size = cellSize * 3;
            y += cellSize * 2;
            x += cellSize * 2;
            drawRoundedSquare(lineWidth, x, y, size, colorInner, radiiInner, true, ctx);
        };

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - qrcode-generator types are incorrect
        const qrCode = qrGenerator(0, ecLevel);
        qrCode.addData(utf16to8(value));
        qrCode.make();

        if (!canvas.current) {
            return;
        }

        const ctx: CanvasRenderingContext2D | null = canvas.current.getContext("2d");

        if (!ctx) {
            throw new Error("Could not get the canvas context");
        }

        const canvasSize = size + 2 * quietZone;
        const length = qrCode.getModuleCount();
        const cellSize = size / length;
        const scale = window.devicePixelRatio || 1;
        canvas.current.height = canvas.current.width = canvasSize * scale;
        ctx.scale(scale, scale);

        ctx.fillStyle = bgColor;
        ctx.fillRect(0, 0, canvasSize, canvasSize);

        const offset = quietZone;

        const positioningZones: ICoordinates[] = [
            { row: 0, col: 0 },
            { row: 0, col: length - 7 },
            { row: length - 7, col: 0 },
        ];

        ctx.strokeStyle = fgColor;
        if (qrStyle === "dots") {
            ctx.fillStyle = fgColor;
            const radius = cellSize / 2;
            for (let row = 0; row < length; row++) {
                for (let col = 0; col < length; col++) {
                    if (qrCode.isDark(row, col) && !isInPositioninZone(row, col, positioningZones)) {
                        ctx.beginPath();
                        ctx.arc(
                            Math.round(col * cellSize) + radius + offset,
                            Math.round(row * cellSize) + radius + offset,
                            (radius / 100) * 75,
                            0,
                            2 * Math.PI,
                            false
                        );
                        ctx.closePath();
                        ctx.fill();
                    }
                }
            }
        } else {
            for (let row = 0; row < length; row++) {
                for (let col = 0; col < length; col++) {
                    if (qrCode.isDark(row, col) && !isInPositioninZone(row, col, positioningZones)) {
                        ctx.fillStyle = fgColor;
                        const w = Math.ceil((col + 1) * cellSize) - Math.floor(col * cellSize);
                        const h = Math.ceil((row + 1) * cellSize) - Math.floor(row * cellSize);
                        ctx.fillRect(Math.round(col * cellSize) + offset, Math.round(row * cellSize) + offset, w, h);
                    }
                }
            }
        }

        // Draw positioning patterns
        for (let i = 0; i < 3; i++) {
            const { row, col } = positioningZones[i];
            drawPositioningPattern(ctx, cellSize, offset, row, col, eyeColor, eyeRadius);
        }

        if (logoImage) {
            const image = new Image();
            if (enableCORS) {
                image.crossOrigin = "Anonymous";
            }
            image.onload = () => {
                ctx.save();

                const dWidthLogo = logoWidth || size * 0.2;
                const dHeightLogo = logoHeight || dWidthLogo;
                const dxLogo = (size - dWidthLogo) / 2;
                const dyLogo = (size - dHeightLogo) / 2;

                if (logoPadding) {
                    ctx.beginPath();

                    ctx.strokeStyle = bgColor;
                    ctx.fillStyle = bgColor;

                    const dWidthLogoPadding = dWidthLogo + 2 * logoPadding;
                    const dHeightLogoPadding = dHeightLogo + 2 * logoPadding;
                    const dxLogoPadding = dxLogo + offset - logoPadding;
                    const dyLogoPadding = dyLogo + offset - logoPadding;

                    if (logoPaddingStyle === "circle") {
                        const dxCenterLogoPadding = dxLogoPadding + dWidthLogoPadding / 2;
                        const dyCenterLogoPadding = dyLogoPadding + dHeightLogoPadding / 2;
                        ctx.ellipse(
                            dxCenterLogoPadding,
                            dyCenterLogoPadding,
                            dWidthLogoPadding / 2,
                            dHeightLogoPadding / 2,
                            0,
                            0,
                            2 * Math.PI
                        );
                        ctx.stroke();
                        ctx.fill();
                    } else {
                        ctx.fillRect(dxLogoPadding, dyLogoPadding, dWidthLogoPadding, dHeightLogoPadding);
                    }
                }

                ctx.globalAlpha = logoOpacity;
                ctx.drawImage(image, dxLogo + offset, dyLogo + offset, dWidthLogo, dHeightLogo);
                ctx.restore();
                if (logoOnLoad) {
                    logoOnLoad();
                }
            };
            image.src = logoImage;
        }
    }, [
        value,
        ecLevel,
        enableCORS,
        size,
        quietZone,
        bgColor,
        fgColor,
        logoOpacity,
        qrStyle,
        eyeRadius,
        logoPadding,
        logoPaddingStyle,
        logoImage,
        logoWidth,
        logoHeight,
        logoOnLoad,
        eyeColor,
    ]);

    /**
     * Draw a rounded square in the canvas
     */
    const drawRoundedSquare = (
        lineWidth: number,
        x: number,
        y: number,
        size: number,
        color: string,
        radii: CornerRadii,
        fill: boolean,
        ctx: CanvasRenderingContext2D
    ) => {
        ctx.lineWidth = lineWidth;
        ctx.fillStyle = color;
        ctx.strokeStyle = color;

        // Adjust coordinates so that the outside of the stroke is aligned to the edges
        y += lineWidth / 2;
        x += lineWidth / 2;
        size -= lineWidth;

        // Radius should not be greater than half the size or less than zero
        radii = radii.map(r => {
            r = Math.min(r, size / 2);
            return r < 0 ? 0 : r;
        }) as CornerRadii;

        const rTopLeft = radii[0] || 0;
        const rTopRight = radii[1] || 0;
        const rBottomRight = radii[2] || 0;
        const rBottomLeft = radii[3] || 0;

        ctx.beginPath();

        ctx.moveTo(x + rTopLeft, y);

        ctx.lineTo(x + size - rTopRight, y);
        if (rTopRight) ctx.quadraticCurveTo(x + size, y, x + size, y + rTopRight);

        ctx.lineTo(x + size, y + size - rBottomRight);
        if (rBottomRight) ctx.quadraticCurveTo(x + size, y + size, x + size - rBottomRight, y + size);

        ctx.lineTo(x + rBottomLeft, y + size);
        if (rBottomLeft) ctx.quadraticCurveTo(x, y + size, x, y + size - rBottomLeft);

        ctx.lineTo(x, y + rTopLeft);
        if (rTopLeft) ctx.quadraticCurveTo(x, y, x + rTopLeft, y);

        ctx.closePath();

        ctx.stroke();
        if (fill) {
            ctx.fill();
        }
    };

    /**
     * Is this dot inside a positional pattern zone.
     */
    const isInPositioninZone = (col: number, row: number, zones: ICoordinates[]) => {
        return zones.some(zone => row >= zone.row && row <= zone.row + 7 && col >= zone.col && col <= zone.col + 7);
    };

    const qrSize = size + 2 * quietZone;

    return createElement("canvas", {
        id,
        height: qrSize,
        width: qrSize,
        style: { height: qrSize + "px", width: qrSize + "px" },
        ref: canvas,
    });
};
