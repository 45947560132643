"use client";

import React, { MutableRefObject, useRef, useState } from "react";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { Folder } from "@knowt/syncing/graphql/schema";
import dynamic from "next/dynamic";
import { useRouter } from "next13-progressbar";
import CardSkeleton, { CardSkeletonProps } from "../CardSkeleton";
import styles from "./folderCard.module.css";
import { FlexRowAlignCenter } from "@/components/Flex";
import FolderIcon from "./components/FolderIcon";
import { UNTITLED } from "@knowt/syncing/utils/dataCleaning";

const SelectableButton = dynamic(() =>
    import("../UserContentCard/components/UserContentCardHandlerButtons").then(mod => mod.SelectableButton)
);

const FolderOptionsMenu = dynamic(() => import("./components/FolderOptionsMenu"));

type ModeProps = {
    mode: "SELECT" | null;
    isSelected: boolean;
    toggleSelect: (event: React.MouseEvent<HTMLDivElement>, { isSelected }: { isSelected: boolean }) => void;
};

type FolderProps = { folder: Folder; href?: string; modeProps?: ModeProps } & Partial<Pick<CardSkeletonProps, "style">>;

const FolderCard = ({ folder, style, modeProps }: FolderProps) => {
    const router = useRouter();
    const { userId } = useCurrentUser();

    const containerRef = useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>;

    const menuBtnRef = useRef<HTMLButtonElement>(null);

    const [showColorPicker, setShowColorPicker] = useState(false);
    const [loadColorPicker, setLoadColorPicker] = useState(false);

    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [loadEmojiPicker, setLoadEmojiPicker] = useState(false);

    const renderTitleWithEmoji = () => {
        if (!folder) return null;

        return (
            <FlexRowAlignCenter className="bold" style={{ fontSize: "1.3rem", gap: "0.3rem", width: "90%" }}>
                <span style={{ width: "3.5rem" }}>
                    {modeProps?.mode === "SELECT" ? (
                        <SelectableButton isSelected={modeProps.isSelected} />
                    ) : (
                        <FolderIcon
                            {...{
                                folder,
                                menuBtnRef,
                                showColorPicker,
                                setShowColorPicker,
                                loadColorPicker,
                                showEmojiPicker,
                                setShowEmojiPicker,
                                loadEmojiPicker,
                            }}
                        />
                    )}
                </span>
                <span
                    title={folder.name || UNTITLED}
                    style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                    {folder.name || UNTITLED}
                </span>
            </FlexRowAlignCenter>
        );
    };

    return (
        <CardSkeleton
            role="link"
            data-testid={"notebook-card"}
            data-href={`/folder/${folder.folderId}`}
            className={styles.folderCard}
            tabIndex={0}
            onMouseEnter={() => {
                setLoadColorPicker(true);
                setLoadEmojiPicker(true);
            }}
            onClick={
                modeProps?.mode === "SELECT"
                    ? e => modeProps?.toggleSelect(e, { isSelected: modeProps.isSelected })
                    : e => {
                          if (e.metaKey || e.ctrlKey) {
                              return window.open(`/folder/${folder.folderId}`, "_blank");
                          }
                          router.push(`/folder/${folder.folderId}`);
                      }
            }
            ref={containerRef}
            style={{ backgroundColor: undefined, height: "5.2rem", cursor: "pointer", ...style }}
            renderTopSection={() => null}
            renderMiddleSection={() => (
                <FlexRowAlignCenter style={{ justifyContent: "space-between" }}>
                    {renderTitleWithEmoji()}
                    {modeProps?.mode !== "SELECT" && folder.userId === userId && (
                        <FolderOptionsMenu
                            folder={folder}
                            parentRef={containerRef}
                            btnRef={menuBtnRef}
                            openIconPicker={type => {
                                if (type === "color") {
                                    setShowColorPicker(true);
                                } else {
                                    setShowEmojiPicker(true);
                                }
                            }}
                        />
                    )}
                </FlexRowAlignCenter>
            )}
            renderBottomSection={() => null}
        />
    );
};

export default FolderCard;
