import { FlexRowAlignCenter } from "@/components/Flex";
import { iconSizes, strokeWidth } from "@/utils/iconProps";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { ClassSection } from "@knowt/syncing/graphql/schema";
import { isDarkColor } from "@knowt/syncing/utils/genericUtils";
import { Eye } from "lucide-react";
import { useClassManagementContextSelector } from "../hooks/useClassManagementContext";
import SectionPill from "./styled/SectionPill";
import { WALKTHROUGH_SECTION_PILL } from "./Walkthroughs";

const SectionPills = () => {
    const course = useClassManagementContextSelector(state => state.course);
    const selectedSection = useClassManagementContextSelector(state => state.selectedSection);
    const setSelectedSection = useClassManagementContextSelector(state => state.setSelectedSection);

    const sections = course?.sections;

    const isViewAll = selectedSection === null;

    if (sections.length === 1) {
        return null;
    }

    const getColors = (section: ClassSection) => {
        const backgroundColor = selectedSection === section.id ? section.color : themeColors.neutralWhite;
        return {
            backgroundColor: backgroundColor,
            color:
                selectedSection !== section.id
                    ? themeColors.neutralBlack
                    : isDarkColor(backgroundColor)
                    ? themeColors.pureWhite
                    : themeColors.pureBlack,
        };
    };

    return (
        <FlexRowAlignCenter
            style={{ gap: spacing.SM, margin: "auto", marginTop: "4rem", flexWrap: "wrap", justifyContent: "center" }}>
            <SectionPill
                onClick={() => setSelectedSection(null)}
                sx={{
                    backgroundColor: isViewAll ? course?.color || themeColors.neutralBlack : themeColors.neutralWhite,
                    color: isViewAll
                        ? course?.color
                            ? isDarkColor(course?.color)
                                ? themeColors.pureWhite
                                : themeColors.pureBlack
                            : themeColors.neutralWhite
                        : themeColors.neutralBlack,
                }}>
                <Eye strokeWidth={strokeWidth.normal} size={iconSizes.SM} style={{ marginRight: "0.5rem" }} />
                View All
            </SectionPill>
            {sections.map((section, i) => (
                <SectionPill
                    className={i === 0 ? WALKTHROUGH_SECTION_PILL : undefined}
                    key={section.name + i}
                    onClick={() => setSelectedSection(section.id)}
                    sx={getColors(section)}>
                    {section.name}
                </SectionPill>
            ))}
        </FlexRowAlignCenter>
    );
};

export default SectionPills;
