"use client";
import { Flex, FlexColumn, FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { ArrowDownIcon, InfoIcon } from "lucide-react";
import { iconSizes } from "@/utils/iconProps";
import React, { useMemo, useRef, useState } from "react";
import { useRouter } from "next13-progressbar";
import { ClassSection, FlashcardSet } from "@knowt/syncing/graphql/schema";
import { useClassFlashcardStudyStates, useClassStudySessions } from "@/hooks/study/useStudySession";
import { getUsersStudyAnalytics } from "@/hooks/study/statsUtils";
import { useClassManagementContextSelector } from "../../../hooks/useClassManagementContext";
import { borderRadius } from "@/utils/borderRadius";
import ProgressTable from "./ProgressTable";
import FlashcardSetPicker from "./FlashcardSetPicker";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { TextToSpeechContextProvider } from "@/contexts/TextToSpeechContext";
import { Virtuoso } from "react-virtuoso";
import MasteryPerFlashcard from "../../../components/MasteryPerFlashcard/MasteryPerFlashcard";
import { AD_SLOT_WIDTH, FlashcardItemCard, createFlashcardItemCard } from "@/features/FlashcardSetOverview";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";
import { dialogClasses } from "@mui/material/Dialog";
import dynamic from "next/dynamic";
import LazyLoaded from "@/components/LazyLoaded";
import { useFlashcardSetStudyAnalytics } from "@knowt/syncing/hooks/study/useStudySessionStats";
import InputWithSearchbarIcon from "@/components/styled/input/InputWithSearchbarIcon";
import { useDebouncedCallback } from "@knowt/syncing/utils/hooks/useDebouncedCallback";
import Image from "next/image";
import { makeStandaloneFlashcardSet } from "@knowt/syncing/hooks/flashcards/utils";
import { UNTITLED } from "@knowt/syncing/utils/dataCleaning";
import { useFlashcardSet } from "@knowt/syncing/hooks/flashcards/useFlashcards";
import ProgressNumBoxes from "../../../components/studyProgress/ProgressNumBoxes";
import ProgressPieChart from "../../../components/studyProgress/ProgressPieChart";
import br from "@/styles/breakpoints.module.css";
import clsx from "clsx";
import { isDarkColor } from "@knowt/syncing/utils/genericUtils";
import { useUserContentManagementContextSelector } from "@/features/UserContentManagement";
import { useFlashcardSetViewer } from "@knowt/syncing/hooks/flashcardSetViewer/useFlashcardSetViewer";

const EditableFlashcardPopup = dynamic(() => import("@/components/Popup/EditableFlashcardPopup"));

const MasteryPerFlashcardDetailsPopup = dynamic(() =>
    import("@/features/UserContentManagement").then(p => p.MasteryPerFlashcardDetailsPopup)
);

const MASTERY_CARD_TITLE_ID = "mastery-card-title";

const EmptyState = ({ classId, draftSets }: { classId: string; draftSets?: FlashcardSet[] }) => {
    const router = useRouter();
    const { userId } = useCurrentUser();

    const course = useClassManagementContextSelector(state => state.course);

    const handleCreateFlashcardSet = async () => {
        if (draftSets?.length) {
            router.push(`/flashcards/${draftSets[0].flashcardSetId}/edit`);
            return;
        }

        const { flashcardSetId } = await makeStandaloneFlashcardSet({
            userId,
            classId: classId,
        });

        router.push(`/flashcards/${flashcardSetId}/edit`);
    };

    return (
        <FlexColumnAlignJustifyCenter
            style={{
                textAlign: "center",
                margin: "0 auto 5rem",
                padding: "4rem",
                gap: spacing.MD,
                backgroundColor: themeColors.neutralWhite,
                borderRadius: borderRadius.card,
                width: "100%",
                flex: 1,
            }}>
            <Image src="/images/kai-sherlock.svg" width={250} height={250} alt="no content" />
            <FlexColumn>
                <p className="heading5" style={{ marginBottom: spacing.XXS }}>
                    {draftSets?.length
                        ? "All of your flashcard sets are in draft mode!"
                        : "You have no flashcard sets in your class"}
                </p>
                <p className="body2" style={{ maxWidth: "61rem" }}>
                    {draftSets?.length
                        ? "Once you save your files, your class can start to study it."
                        : "Create one to start viewing student progress."}
                </p>
            </FlexColumn>
            <CircularRectTextButton
                onClick={handleCreateFlashcardSet}
                className="bodyBold1"
                style={{
                    backgroundColor: course?.color || themeColors.neutralBlack,
                    color: course?.color
                        ? isDarkColor(course.color)
                            ? themeColors.pureWhite
                            : themeColors.pureBlack
                        : themeColors.neutralWhite,
                    padding: "0.8rem 1.6rem ",
                }}>
                {draftSets?.length ? "finish your set" : " add flashcard set"}
            </CircularRectTextButton>
        </FlexColumnAlignJustifyCenter>
    );
};

const CompletionProgressCard = ({ selectedSection }: { selectedSection: ClassSection | null }) => {
    const selectedFlashcardSet = useClassManagementContextSelector(state => state.selectedFlashcardSet);
    const studyProgressFilter = useClassManagementContextSelector(state => state.studyProgressFilter);
    const setStudyProgressFilter = useClassManagementContextSelector(state => state.setStudyProgressFilter);

    const { completionStats } = useFlashcardSetStudyAnalytics({ flashcardSet: selectedFlashcardSet, selectedSection });

    return (
        <FlexColumn
            style={{
                height: "63rem",
                padding: spacing.MD,
                width: "32%",
                minWidth: "32rem",
                backgroundColor: themeColors.neutralWhite,
                borderRadius: borderRadius.card,
                justifyContent: "space-between",
            }}
            className={br.smDownWidth100}>
            <FlexColumn>
                <FlexRowAlignCenter style={{ justifyContent: "space-between" }}>
                    <div className="bodyBold2">Completion Progress</div>
                    <InfoIcon size={iconSizes.SM} color={themeColors.neutralBlack} />
                </FlexRowAlignCenter>
                <div style={{ marginBottom: spacing.MD }} />

                <ProgressPieChart completionStats={completionStats} radius={100} thickness={100 * 0.4} />
                <div style={{ marginBottom: spacing.MD }} />

                <p className="bodyBold2">{selectedFlashcardSet?.title || UNTITLED}</p>
                <div style={{ marginBottom: spacing.MD }} />

                <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: spacing.MD }}>
                    <ProgressNumBoxes
                        studyProgressFilter={studyProgressFilter}
                        completionStats={completionStats}
                        onProgressClick={progress => {
                            if (progress !== studyProgressFilter) {
                                setStudyProgressFilter(progress);
                            } else {
                                setStudyProgressFilter(null);
                            }
                        }}
                    />
                </div>
                <div style={{ marginBottom: spacing.MD }} />
            </FlexColumn>
            <CircularRectTextButton
                onClick={() => {
                    const masteryTitle = document.getElementById(MASTERY_CARD_TITLE_ID);
                    masteryTitle?.scrollIntoView({ behavior: "smooth", block: "start" });
                }}
                sx={{
                    paddingInline: spacing.SM,
                    paddingBlock: spacing.XS_2,
                    fontSize: "1.5rem",
                    columnGap: spacing.XS,
                    opacity: selectedFlashcardSet ? 1 : 0.8,
                    borderRadius: "10px",
                    backgroundColor: "transparent",
                    color: themeColors.neutralBlack,
                    border: `1px solid ${themeColors.neutral1}`,
                    whiteSpace: "nowrap",
                }}>
                frequently missed flashcards
                <ArrowDownIcon size={iconSizes.SM} color={themeColors.neutralBlack} />
            </CircularRectTextButton>
        </FlexColumn>
    );
};

const ClassDashboardProgressTab = () => {
    const { lgUp } = useBreakPoints();

    const { canShowAds } = useCurrentUser();

    const router = useRouter();

    const classFlashcardSets = useUserContentManagementContextSelector(state => state.serverFlashcardSets);

    const course = useClassManagementContextSelector(state => state.course);
    const selectedFlashcardSet = useClassManagementContextSelector(state => state.selectedFlashcardSet);
    const setSelectedFlashcardSetId = useClassManagementContextSelector(state => state.setSelectedFlashcardSetId);
    const studyProgressFilter = useClassManagementContextSelector(state => state.studyProgressFilter);
    const setStudyProgressFilter = useClassManagementContextSelector(state => state.setStudyProgressFilter);
    const members = course?.members;

    const flashcardSetId = selectedFlashcardSet?.flashcardSetId;

    const { flashcards } = useFlashcardSet({ flashcardSetId });

    const [selectedSection, setSelectedSection] = useState<ClassSection | null>(null);
    const [query, setQuery] = useState<string>("");
    const [masteryDetailsPopupIndex, setMasteryDetailsPopupIndex] = useState<number>(-1);

    const debounceUpdateQuery = useDebouncedCallback((text: string) => setQuery(text), 300);

    const inputRef = useRef<HTMLInputElement>(null);

    const {
        editFlashcardPopupOpen,
        setEditFlashcardPopupOpen,
        flashcardToEdit,
        readOnly,
        showEditFlashcard,
        studentIds,
        flashcardsMastery,
        flashcardsStudyAnalytics,
    } = useFlashcardSetStudyAnalytics({
        flashcardSet: selectedFlashcardSet,
        selectedSection: selectedSection,
    });

    const { flashcardSetViewerData, toggleFlashcardStarByIds } = useFlashcardSetViewer({
        flashcardSetId,
    });

    const { studySessions } = useClassStudySessions({
        classId: course.classId,
        itemId: flashcardSetId,
    });

    const { flashcardStudyStates } = useClassFlashcardStudyStates({
        classId: course.classId,
        flashcardSetId: flashcardSetId,
    });

    // TODO: add a `useMemo` if this turned out to be slow
    const studentsStudyAnalytics = getUsersStudyAnalytics({
        studySessions,
        flashcardSetId: flashcardSetId,
        flashcardStudyStates,
        userIds: studentIds,
    });

    const items = useMemo(() => {
        if (!flashcards) return [];

        const items: FlashcardItemCard[] = flashcards.map(flashcard =>
            createFlashcardItemCard({
                flashcard,
                flashcardStudyStates: undefined,
                starredFlashcardIds: [], // TODO: pass the selectedFlashcardIds here
                toggleFlashcardStarByIds,
            })
        );

        return (
            items.filter(({ flashcard }) => {
                return (
                    !query ||
                    flashcard?.term?.toLowerCase().includes(query.toLowerCase()) ||
                    flashcard?.definition?.toLowerCase().includes(query.toLowerCase())
                );
            }) || []
        );
    }, [flashcards, query, toggleFlashcardStarByIds]);

    const isEmpty = !classFlashcardSets || !Object.keys(classFlashcardSets).length;

    const draftSets = Object.values(classFlashcardSets).every(set => set.draft)
        ? Object.values(classFlashcardSets).filter(set => set.draft)
        : undefined;

    if (isEmpty || draftSets) return <EmptyState classId={course.classId} draftSets={draftSets} />;

    const renderHeader = () => (
        <FlexColumn>
            <FlashcardSetPicker
                course={course}
                classFlashcardSets={classFlashcardSets}
                selectedFlashcardSet={selectedFlashcardSet}
                onChange={(_, newValue) => {
                    if (!newValue && classFlashcardSets)
                        return setSelectedFlashcardSetId(Object.values(classFlashcardSets)[0].flashcardSetId);

                    setSelectedFlashcardSetId(newValue?.value ?? null);
                }}
                selectedSection={selectedSection}
                onSectionChange={setSelectedSection}
                onViewFlashcardSetClick={() => router.push(`/flashcards/${flashcardSetId}`)}
            />
            <Flex
                style={{
                    height: "auto",
                    marginBottom: "4rem",
                    gap: spacing.MD,
                    columnGap: spacing.MD,
                }}
                className={br.smDownColumn}>
                <CompletionProgressCard selectedSection={selectedSection} />
                <ProgressTable
                    studentsStudyAnalytics={studentsStudyAnalytics}
                    studentDetails={course.members}
                    studyProgressFilter={studyProgressFilter}
                    onRemoveStudyProgressFilter={() => setStudyProgressFilter(null)}
                />
            </Flex>
            <FlexRowAlignCenter
                className={br.smDownColumn}
                style={{ width: "100%", justifyContent: "space-between", gap: spacing.XS, alignItems: "flex-end" }}>
                <p
                    id={MASTERY_CARD_TITLE_ID}
                    className={clsx("bodyBold2", br.smDownWidth100)}
                    style={{ margin: "auto 0" }}>
                    Mastery per flashcard
                </p>
                <InputWithSearchbarIcon
                    className={clsx("bodyBold2", br.smDownWidth100)}
                    ref={inputRef}
                    onChange={e => debounceUpdateQuery(e.target.value.trim())}
                    value={query}
                    placeholder={"Search terms/definitions"}
                    sx={{
                        width: "38rem",
                        transition: "width 0.3s",
                        color: "var(--color-neutral-black)",
                        "&:focus": { width: "50rem" },
                    }}
                />
            </FlexRowAlignCenter>
        </FlexColumn>
    );

    return (
        <FlexColumn>
            <TextToSpeechContextProvider flashcardSetId={flashcardSetId}>
                <FlexColumn style={{ gap: "4rem" }}>
                    {renderHeader()}
                    <Flex style={{ position: "relative", maxWidth: "173rem", margin: "0 auto", width: "100%" }}>
                        <Virtuoso
                            useWindowScroll
                            initialItemCount={Math.min(items.length, 100)}
                            data={items}
                            increaseViewportBy={500}
                            style={{ flex: 1, margin: "0 auto", maxWidth: "124rem", height: "100%" }}
                            context={{
                                readOnly,
                                showEditFlashcard,
                                flashcardsMastery,
                                setMasteryDetailsPopupIndex,
                                toggleFlashcardStarByIds,
                                starredFlashcards: flashcardSetViewerData?.starred || [],
                            }}
                            itemContent={(i, item, context) => {
                                return <MasteryPerFlashcard index={i} item={item} context={context} />;
                            }}
                        />
                        <LazyLoaded load={masteryDetailsPopupIndex > -1}>
                            <MasteryPerFlashcardDetailsPopup
                                isOpen={masteryDetailsPopupIndex > -1}
                                onClose={() => setMasteryDetailsPopupIndex(-1)}
                                currentFlashcardIndex={
                                    masteryDetailsPopupIndex > -1 ? masteryDetailsPopupIndex : undefined
                                }
                                allFlashcards={flashcards}
                                members={members}
                                flashcardsStudyAnalytics={flashcardsStudyAnalytics}
                            />
                        </LazyLoaded>
                    </Flex>
                </FlexColumn>
            </TextToSpeechContextProvider>
            <LazyLoaded load={editFlashcardPopupOpen}>
                <EditableFlashcardPopup
                    isOpen={editFlashcardPopupOpen}
                    onClose={() => setEditFlashcardPopupOpen(false)}
                    flashcard={flashcardToEdit || flashcards?.[0]}
                    dialogProps={{
                        sx: {
                            [`.${dialogClasses.container}`]: {
                                ...(canShowAds &&
                                    lgUp && {
                                        width: `calc(100% - ${AD_SLOT_WIDTH}px)`,
                                    }),
                            },
                        },
                        slotProps: {
                            backdrop: {
                                style: {
                                    backdropFilter: "brightness(0.8)",
                                    WebkitBackdropFilter: "brightness(0.8)",
                                },
                            },
                        },
                    }}
                />
            </LazyLoaded>
        </FlexColumn>
    );
};

export default ClassDashboardProgressTab;
