"use client";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { Flex, FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import Select from "@/components/Select";
import ResponsiveDialog, { DialogCloseBtn } from "@/features/ResponsiveDialog";
import { iconSizes } from "@/utils/iconProps";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { Class } from "@knowt/syncing/graphql/schema";
import { useClasses } from "@knowt/syncing/hooks/classes/useClass";
import { ChevronDown } from "lucide-react";
import { useEffect, useState } from "react";
import clsx from "clsx";
import { toggleArrayElements } from "@knowt/syncing/utils/arrayUtils";
import CheckBox from "@/components/styled/input/CheckBox";

type DuplicateAndAddToClassPopupProps = {
    isOpen: boolean;
    onClose: () => void;
    onSave: (props: { classId?: string; sharedWithSections: string[] | null }) => void;
    isDuplicate: boolean;
    itemTitles: string[];
};

const DuplicateAndAddToClassPopup = ({
    isOpen,
    onClose,
    onSave,
    isDuplicate,
    itemTitles,
}: DuplicateAndAddToClassPopupProps) => {
    const { classes } = useClasses();

    const [selectedClass, setSelectedClass] = useState<Class | null>(null);
    const [sharedWithSections, setSharedWithSections] = useState<string[]>([]);

    useEffect(() => {
        if (selectedClass) setSharedWithSections(selectedClass.sections.map(section => section.id));
    }, [selectedClass]);

    return (
        <ResponsiveDialog
            isOpen={isOpen}
            onClose={onClose}
            DialogStyle={{
                width: "62rem",
                padding: "7rem 3rem 3rem 3rem",
                borderRadius: "4rem",
                transition: "height 0.35s ease",
            }}
            SlideMenuStyle={{
                padding: "4rem 2rem 4rem 2rem",
            }}>
            <FlexColumn
                style={{
                    gap: spacing.MD,
                }}>
                <DialogCloseBtn
                    onClick={onClose}
                    forDialogStyle={{
                        position: "absolute",
                        right: 30,
                        top: 30,
                    }}
                    forSlideMenuStyle={{ marginLeft: "auto" }}
                />
                <FlexColumn style={{ gap: spacing.XS }}>
                    <p className="heading4">
                        {isDuplicate ? "Duplicate and add to class" : "Move this file into one of your classes"}
                    </p>
                    <p className="body1">Share this file with one or more of your classes.</p>
                </FlexColumn>
                <FlexRowAlignCenter
                    style={{
                        gap: spacing.XS,
                    }}>
                    <p className="bodyBold1">File:</p>
                    <Flex
                        style={{
                            gap: spacing.XS_2,
                            flexWrap: "wrap",
                        }}>
                        {itemTitles.map((title, index) => (
                            <CircularRectTextButton
                                key={index}
                                className="body2"
                                style={{
                                    padding: "0.8rem 1.2rem",
                                    backgroundColor: themeColors.background,
                                }}>
                                {title}
                            </CircularRectTextButton>
                        ))}
                    </Flex>
                </FlexRowAlignCenter>
                <FlexColumn style={{ gap: spacing.XS }}>
                    <p className="bodyBold1">Class:</p>
                    <Select
                        options={classes ? Object.values(classes)?.map(c => ({ label: c.name, value: c })) : []}
                        onChange={({ value }) => setSelectedClass(value)}
                        selected={
                            selectedClass
                                ? { label: selectedClass?.name, value: selectedClass }
                                : { label: "Pick a class to add this file to", value: null }
                        }
                        menuSx={{ width: "100%", maxWidth: "56rem", padding: "1rem" }}
                        btnSx={{
                            width: "100%",
                            gap: "1rem",
                            height: "4.8rem",
                            borderRadius: "2rem",
                            backgroundColor: themeColors.neutralWhite,
                            color: selectedClass ? themeColors.neutralBlack : themeColors.neutral2,
                            justifyContent: "space-between",
                            border: `1px solid ${themeColors.neutralBlack}`,
                            padding: "1.2rem",
                            paddingLeft: "2.4rem",
                        }}
                        transitionOrigin={{
                            anchorOrigin: { vertical: "bottom", horizontal: "left" },
                            transformOrigin: { vertical: "top", horizontal: "left" },
                        }}
                        adornment={<ChevronDown size={iconSizes.MD} />}
                    />
                </FlexColumn>
                <FlexRowAlignCenter
                    style={{
                        margin: `${spacing.MD} 0`,
                        flexWrap: "wrap",
                        rowGap: spacing.MD,
                        columnGap: spacing.SM,
                    }}>
                    {selectedClass &&
                        selectedClass.sections.map((section, index) => (
                            <FlexRowAlignCenter key={index} style={{ gap: spacing.SM, width: "48%" }}>
                                <CheckBox
                                    boxProps={{
                                        sx: {
                                            width: "3.2rem",
                                            height: "3.2rem",
                                        },
                                    }}
                                    iconProps={{
                                        size: 28,
                                    }}
                                    isChecked={sharedWithSections.includes(section.id)}
                                    onCheck={() =>
                                        setSharedWithSections(toggleArrayElements(sharedWithSections, [section.id]))
                                    }
                                />
                                <span className={clsx("body2", "ellipsisText")}>{section.name}</span>
                            </FlexRowAlignCenter>
                        ))}
                </FlexRowAlignCenter>
                <CircularRectTextButton
                    onClick={() =>
                        onSave({
                            classId: selectedClass?.classId,
                            sharedWithSections:
                                sharedWithSections.length === selectedClass?.sections.length
                                    ? null
                                    : sharedWithSections,
                        })
                    }
                    className="bodyBold2"
                    style={{
                        padding: "1.2rem 2.4rem",
                        backgroundColor: themeColors.primary,
                        color: themeColors.primaryDark,
                        width: "fit-content",
                        margin: "auto",
                    }}>
                    next
                </CircularRectTextButton>
            </FlexColumn>
        </ResponsiveDialog>
    );
};

export default DuplicateAndAddToClassPopup;
