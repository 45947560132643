"use client";

import { InnerContainer, MainContainer } from "@/components/Containers";
import { spacing } from "@/utils/spacing";
import { LocalAuthUser } from "@knowt/syncing/hooks/user/types";
import { Class, FlashcardSet, Folder, Media, Note } from "@knowt/syncing/graphql/schema";
import ClassControlSection from "../TeacherView/components/ClassControlSection";
import { ClassManagementContextProvider, useClassManagementContextSelector } from "../hooks/useClassManagementContext";
import { Flex } from "@/components/Flex";
import React from "react";
import ClassCard from "../components/ClassCard";
import ClassActivityCard from "./components/ClassActivityCard";
import { ClassHomeTabContents, ClassSavedTabContents } from "../components/ClassTabContents";
import { UserContentSortSettings } from "../../types";
import { ClassDashboardTab, ClassFilesTab } from "../types";
import ClassBreadcrumb from "../TeacherView/components/ClassBreadcrumb";
import { SWRConfig } from "swr";

const DashboardTab = () => {
    const currentFilesTab = useClassManagementContextSelector(state => state.currentFilesTab);
    const isSavedTab = currentFilesTab === ClassFilesTab.SAVED;

    return (
        <React.Fragment>
            <div style={{ paddingBottom: spacing.MD }} />
            {/* TOP CARDS */}
            <Flex style={{ gap: spacing.SM, height: "37.7rem" }}>
                <ClassCard />
                <ClassActivityCard />
            </Flex>
            <div style={{ paddingBottom: spacing.MD }} />
            <ClassControlSection />
            <div style={{ paddingBottom: spacing.LG }} />
            {isSavedTab ? <ClassSavedTabContents /> : <ClassHomeTabContents />}
        </React.Fragment>
    );
};

const DashboardClassBreadcrumb = () => {
    const course = useClassManagementContextSelector(state => state.course);
    return <ClassBreadcrumb course={course} />;
};

type StudentDashboardProps = {
    serverUser: LocalAuthUser;
    serverClass: Class;
    serverFolders: Record<string, Folder>;
    serverNotes: Record<string, Note>;
    serverFlashcardSets: Record<string, FlashcardSet>;
    serverMedias: Record<string, Media>;
    sortValue: UserContentSortSettings;
};

const StudentDashboard = ({
    serverUser,
    serverClass,
    serverNotes,
    serverFlashcardSets,
    serverMedias,
    serverFolders,
    sortValue,
}: StudentDashboardProps) => {
    return (
        <SWRConfig value={{ fallback: { getCurrentUser: serverUser } }}>
            <ClassManagementContextProvider
                serverUser={serverUser.user}
                serverClass={serverClass}
                serverNotes={serverNotes}
                serverFolders={serverFolders}
                serverFlashcardSets={serverFlashcardSets}
                serverMedias={serverMedias}
                sortValue={sortValue}
                // tab is unused for student view
                classTab={ClassDashboardTab.DASHBOARD}>
                <MainContainer style={{ paddingBlock: "0rem 0rem", minHeight: "calc(99vh - var(--navbar-height))" }}>
                    <InnerContainer data-testid="class-dashboard-page">
                        <div style={{ paddingBottom: spacing.XL }} />
                        <DashboardClassBreadcrumb />
                        <DashboardTab />
                    </InnerContainer>
                </MainContainer>
            </ClassManagementContextProvider>
        </SWRConfig>
    );
};

export default StudentDashboard;
