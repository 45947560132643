import React, { ReactElement } from "react";
import { FlexColumn } from "@/components/Flex";
import PopupSlide from "@/components/Popup/PopupCards/PopupSlide";
import SlideMenu from "@/components/SlideMenu";
import CircularButton from "@/components/CircularButton";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";
import { themeColors } from "@/utils/themeColors";
import Image from "next/image";
import { ASSETS_URL } from "@/config/deployConstants";
import Dialog from "@/components/Dialog";

export type SimplePopupCardContent = {
    title?: string;
    description?: string | ReactElement;
    image?: string;
    video?: string;
    videoProps?: {
        start: number;
        end: number;
    };
    imageStyle?: React.CSSProperties;
    mobileImageStyle?: React.CSSProperties;
    backgroundColor?: string;
    learnMoreArticle?: string;
    customComponent?: () => ReactElement;
    hideNextBtn?: boolean;
    containerStyle?: React.CSSProperties;
    customButton?: ReactElement;
    closeButton?: boolean;
};

const SimplePopupCard = ({
    isOpen,
    forceOpen = false,
    onClose,
    closeOnOverlayClick = false,
    onNext = onClose,
    content,
}: {
    isOpen: boolean;
    forceOpen?: boolean;
    onClose: () => void;
    closeOnOverlayClick?: boolean;
    onNext?: () => void;
    content: SimplePopupCardContent;
}) => {
    const { smDown } = useBreakPoints();

    const {
        title,
        description,
        image,
        video,
        videoProps,
        imageStyle,
        mobileImageStyle,
        backgroundColor,
        learnMoreArticle,
        customComponent,
        hideNextBtn,
        containerStyle,
        customButton,
        closeButton,
    } = content || "";

    const mobileContent = (
        <SlideMenu isOpen={isOpen || forceOpen} onClose={onClose}>
            {closeButton && (
                <CircularButton
                    onClick={onClose}
                    sx={{ backgroundColor: "white", position: "absolute", top: "2.47rem", left: "2rem", zIndex: 21 }}
                    radius="4.5rem">
                    <Image
                        src={`${ASSETS_URL}/images/X-bold-black.png`}
                        width={22}
                        height={22}
                        style={{ objectFit: "contain" }}
                        alt="close"
                    />
                </CircularButton>
            )}
            <PopupSlide
                title={title}
                description={description}
                image={image}
                video={video}
                videoProps={videoProps}
                imageStyle={imageStyle}
                mobileImageStyle={mobileImageStyle}
                backgroundColor={backgroundColor}
                article={learnMoreArticle}
                onNext={hideNextBtn ? null : onClose}
                customComponent={customComponent}
                customButton={customButton}
            />
        </SlideMenu>
    );

    const desktopContent = (
        <Dialog
            TransitionProps={{ unmountOnExit: true }}
            open={isOpen || forceOpen}
            onClose={(_, reason) => {
                if (reason === "backdropClick" && !closeOnOverlayClick) {
                    return;
                }
                onClose();
            }}
            maxWidth={false}
            PaperProps={{
                style: {
                    backgroundColor: themeColors.neutralWhite,
                    color: themeColors.neutralBlack,
                    borderRadius: "4rem",
                    width: "87rem",
                    ...containerStyle,
                },
            }}>
            {closeButton && (
                <CircularButton
                    onClick={onClose}
                    sx={{ backgroundColor: "white", position: "absolute", top: "4rem", left: "5rem" }}
                    radius="4.5rem">
                    <Image
                        src={`${ASSETS_URL}/images/X-bold-black.png`}
                        width={22}
                        height={22}
                        style={{ objectFit: "contain" }}
                        alt="close"
                    />
                </CircularButton>
            )}
            <FlexColumn style={{ height: "90vh", maxHeight: "70rem" }}>
                <PopupSlide
                    title={title}
                    description={description}
                    image={image}
                    video={video}
                    videoProps={videoProps}
                    imageStyle={imageStyle}
                    mobileImageStyle={mobileImageStyle}
                    backgroundColor={backgroundColor}
                    article={learnMoreArticle}
                    onNext={hideNextBtn ? null : onNext}
                    customComponent={customComponent}
                    customButton={customButton}
                />
            </FlexColumn>
        </Dialog>
    );

    return !smDown ? desktopContent : mobileContent;
};

export default SimplePopupCard;
