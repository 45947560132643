"use client";

import { useEffect, useRef, useState } from "react";
import Image from "next/image";
import { Flex, FlexColumn } from "@/components/Flex";
import { useTheme } from "@/utils/theme";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";
import br from "@/styles/breakpoints.module.css";
import { ASSETS_URL } from "@/config/deployConstants";
import { WALKTRHOUGH_BEGIN_CREATE_BTN } from "../HomePage/components/Walkthroughs";
import Portal from "@mui/material/Portal";

const swirlyArrowBlack = `${ASSETS_URL}/images/swirly-arrow.png`;
const swirlyArrowWhite = `${ASSETS_URL}/images/swirly-arrow-white.png`;
const bookmarkImg = `${ASSETS_URL}/images/save-icon.png`;
const recentImg = `${ASSETS_URL}/images/Recent.png`;
const trashImg = `${ASSETS_URL}/images/Trash_Bin_Illustration.png`;

export const HomeOrFolderTabEmptyState = () => {
    const { isDarkMode } = useTheme();

    const createContentBtnRef = useRef<HTMLElement>();
    const [pos, setPos] = useState({ top: 0, left: 0 });

    useEffect(() => {
        createContentBtnRef.current = document.getElementsByClassName(WALKTRHOUGH_BEGIN_CREATE_BTN)[0];
    }, []);

    const getEmptyStatePos = () => {
        if (!createContentBtnRef.current) {
            return {};
        }
        setPos({ top: 46, left: 25 });
    };

    useEffect(() => {
        setTimeout(() => {
            getEmptyStatePos();
        }, 500);
    }, []);

    if (!createContentBtnRef.current) {
        return null;
    }

    if (!pos.top && !pos.left) {
        return null;
    }

    return (
        <Portal container={createContentBtnRef.current}>
            <div className={br.mdDownDisplayNone} style={{ position: "absolute", top: pos.top, left: pos.left }}>
                <Image
                    src={isDarkMode ? swirlyArrowWhite : swirlyArrowBlack}
                    alt={"swirlyArrow"}
                    width={150}
                    height={150}
                    style={{ rotate: "170deg" }}
                />
                <FlexColumn style={{ marginTop: "5rem", position: "absolute", top: 30, left: 185, width: "80rem" }}>
                    <span className="bold" style={{ fontSize: "2.5rem", paddingBottom: "1rem" }}>
                        Create a note, flashcard, or <br /> upload a lecture video!
                    </span>
                    <span style={{ fontSize: "1.7rem" }}>Press create new, and choose what you want to create.</span>
                </FlexColumn>
            </div>
        </Portal>
    );
};

const EmptyState = ({
    renderImage,
    title,
    desc,
}: {
    renderImage: () => React.ReactNode;
    title: string;
    desc: string;
}) => {
    const { smDown } = useBreakPoints();

    return (
        <Flex
            style={{ gap: "3rem", maxWidth: "80rem", flexDirection: smDown ? "column" : "row", alignItems: "center" }}>
            {renderImage()}
            <FlexColumn as="section" style={{ textAlign: smDown ? "center" : "left" }}>
                <p style={{ fontSize: "2.3rem", fontWeight: "600", marginBottom: "0.5rem" }}>{title}</p>
                <p style={{ fontWeight: "1.5rem" }}>{desc}</p>
            </FlexColumn>
        </Flex>
    );
};

export const SavedTabEmptyState = () => {
    return (
        <EmptyState
            title={"You haven't saved anything yet"}
            desc={
                "If you like someone else’s note or flashcard set click the save button. You won’t be able to save your own files, but you can pin them."
            }
            renderImage={() => (
                <Image
                    src={bookmarkImg}
                    alt={"no saved items"}
                    width={100}
                    height={100}
                    style={{ objectFit: "contain" }}
                />
            )}
        />
    );
};

export const RecentTabEmptyState = () => {
    return (
        <EmptyState
            title={"You haven't viewed anything yet"}
            desc={
                "Go to Explore in the nav-bar to browse 1,000,000 notes and flashcards on different subjects, exams, and books"
            }
            renderImage={() => (
                <Image
                    src={recentImg}
                    alt={"no recent items"}
                    width={150}
                    height={150}
                    style={{ objectFit: "contain" }}
                />
            )}
        />
    );
};

export const TrashTabEmptyState = () => {
    return (
        <EmptyState
            title={"Your trash is empty"}
            desc={
                "Here is where you can see the notes you've trashed. We'll keep them for you here in case you need to recover them!"
            }
            renderImage={() => (
                <Image
                    src={trashImg}
                    alt={"no trash items"}
                    width={110}
                    height={110}
                    style={{ objectFit: "contain" }}
                />
            )}
        />
    );
};
