import { Class, FlashcardSet, Folder, ItemType, Media, Note } from "@knowt/syncing/graphql/schema";
import { UNTITLED, isFlashcardSet, isMedia, isNote } from "@knowt/syncing/utils/dataCleaning";
import { UserContentItem, UserContentItemType, UserContentSortSettings } from "./types";
import { isExcel, isPPT, isVideo, isPDF } from "@knowt/syncing/hooks/media/checkers";
import { ASSETS_URL } from "@/config/deployConstants";

export const sortUserContentItems = (items: UserContentItem[] | Class[], sort: UserContentSortSettings) => {
    const isAscending = sort.direction === "ASCENDING" ? true : false;

    return items.sort((card1, card2) => {
        const {
            created: created1,
            updated: updated1,
            addedAt: addedAt1,
        } = card1 as { created: number | string; updated: number | string; addedAt: string };

        const {
            created: created2,
            updated: updated2,
            addedAt: addedAt2,
        } = card2 as { created: number | string; updated: number | string; addedAt: string };

        const title1 = (card1 as FlashcardSet | Note | Media)?.title || (card1 as Class)?.name || UNTITLED;
        const title2 = (card2 as FlashcardSet | Note | Media)?.title || (card2 as Class)?.name || UNTITLED;

        switch (sort.by) {
            case "CREATED":
                return (Number(created1) - Number(created2)) * (isAscending ? 1 : -1);
            case "NAME":
                return title1.localeCompare(title2) * (isAscending ? 1 : -1);
            case "ADDED":
                return (Number(addedAt1) - Number(addedAt2)) * (isAscending ? 1 : -1);
            case "LAST_UPDATED":
            default:
                return (Number(updated1) - Number(updated2)) * (isAscending ? 1 : -1);
        }
    });
};

/** _pinned_ is bookmarked item by owner */
export const sortPinnedItemsToTop = (items: UserContentItem[], pinnedIds: string[]): UserContentItem[] => {
    const pinnedItems: UserContentItem[] = [];
    const unPinnedItems: UserContentItem[] = [];

    items.forEach(item => {
        const id = isNote(item as Note)
            ? item?.noteId
            : isFlashcardSet(item as FlashcardSet)
            ? item?.flashcardSetId
            : item?.mediaId;

        if (pinnedIds.includes(id as string)) {
            pinnedItems.push(item);
        } else {
            unPinnedItems.push(item);
        }
    });

    return pinnedItems.concat(unPinnedItems);
};

export const filterUserContentItems = (items: UserContentItem[], itemTypesToShow: UserContentItemType[]) => {
    const savedOnly = itemTypesToShow.includes("PINNED_ONLY");

    return items
        .filter(
            item =>
                (isNote(item) && itemTypesToShow.includes("NOTE")) ||
                (isFlashcardSet(item) && itemTypesToShow.includes("FLASHCARD_SET")) ||
                (isMedia(item) &&
                    ((isVideo(item) && itemTypesToShow.includes("VIDEO")) ||
                        (isPDF(item) && itemTypesToShow.includes("PDF")) ||
                        (isPPT(item) && itemTypesToShow.includes("POWERPOINT")) ||
                        (isExcel(item) && itemTypesToShow.includes("EXCEL"))))
        )
        .filter(item => (savedOnly ? !!item.isBookmarked : true));
};

export const sortHomeFolder = (folders: Folder[], sort: UserContentSortSettings) => {
    const isAscending = sort.direction === "ASCENDING" ? true : false;

    return folders.sort((folder1, folder2) => {
        const { created: created1, updated: updated1 } = folder1;
        const { created: created2, updated: updated2 } = folder2;
        const title1 = folder1.name ?? UNTITLED;
        const title2 = folder2.name ?? UNTITLED;

        switch (sort.by) {
            case "CREATED":
                return (Number(created1) - Number(created2)) * (isAscending ? 1 : -1);
            case "NAME":
                return title1.localeCompare(title2) * (isAscending ? 1 : -1);
            case "LAST_UPDATED":
            default:
                return (Number(updated1) - Number(updated2)) * (isAscending ? 1 : -1);
        }
    });
};

// TODO: move to syncing
export const getImgFromItemType = (type: ItemType) => {
    if (type === ItemType.NOTE) {
        return `${ASSETS_URL}/images/note-icon.svg`;
    } else if (type === ItemType.FLASHCARDSET) {
        return `${ASSETS_URL}/images/flashcard-icon.svg`;
    } else if (type === ItemType.MEDIA) {
        return `${ASSETS_URL}/images/video-icon.svg`;
    } else if (type === ItemType.FOLDER) {
        return `/images/folder-icon.svg`;
    } else if (type === ItemType.CLASS) {
        return `/images/class-icon.svg`;
    }
    throw new Error("type is out of what our conditional check");
};

export const getNameFromItemType = (type: ItemType) => {
    if (type === ItemType.NOTE) {
        return "Note";
    } else if (type === ItemType.FLASHCARDSET) {
        return "Flashcard Set";
    } else if (type === ItemType.MEDIA) {
        return "Media";
    } else if (type === ItemType.FOLDER) {
        return "Folder";
    } else if (type === ItemType.CLASS) {
        return "Class";
    }
    throw new Error("type is out of what our conditional check");
};
