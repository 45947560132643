import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import Image from "next/image";
import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import { SimplePopupCardContent } from "@/components/Popup/PopupCards/SimplePopupCard";
import VideoLooper from "@/components/VideoLooper";
import CircularButton from "@/components/CircularButton";
import ClickableText from "@/components/styled/button/ClickableText";
import LinkWrapper from "@/components/wrappers/Link";
import { useTheme } from "@/utils/theme";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";
import Mixpanel from "@/utils/analytics/Mixpanel";
import { themeColors } from "@/utils/themeColors";
import { ASSETS_URL } from "@/config/deployConstants";

const PopupSlide = ({
    backgroundColor = "#daecfd",
    image,
    video,
    videoProps,
    darkImage = image,
    imageStyle,
    mobileImageStyle,
    title,
    description,
    article,
    onNext,
    customComponent,
    customButton,
}: SimplePopupCardContent & {
    darkImage?: string;
    article?: string;
    onNext?: () => void;
}) => {
    const { smDown } = useBreakPoints();
    const { userId } = useCurrentUser();
    const { isDarkMode } = useTheme();

    return (
        <>
            <FlexColumn
                style={{
                    backgroundColor: backgroundColor,
                    height: !smDown ? "55%" : "30%",
                    overflow: "hidden",
                }}>
                {image && (
                    <Image
                        alt={"slide popup image"}
                        width={290}
                        height={290}
                        src={isDarkMode ? darkImage : image}
                        style={{ objectFit: "contain", margin: "auto", ...imageStyle, ...(smDown && mobileImageStyle) }}
                    />
                )}
                {video && (
                    <VideoLooper
                        key={video}
                        source={video}
                        loopCount={15}
                        borderRadius={"0"}
                        autoPlay={true}
                        objectFit={"cover"}
                        {...videoProps}
                    />
                )}
            </FlexColumn>
            <div
                style={{
                    margin: !smDown ? "5rem 5rem auto" : "5rem 3rem auto",
                    position: "relative",
                    flex: 1,
                }}>
                <div
                    className={"knowtFontBold"}
                    style={{ fontSize: "2.4rem", fontWeight: "600", marginBottom: "2rem" }}>
                    {title}
                </div>
                <p style={{ fontSize: "2rem", fontWeight: "400", minHeight: "12rem", marginBottom: "0.4rem" }}>
                    {description}
                </p>
                <FlexRowAlignCenter
                    style={{
                        justifyContent: "space-between",
                        position: "absolute",
                        inset: "auto 0 20px 0",
                    }}>
                    {article ? (
                        <LinkWrapper openInNewTab href={`/article/${article}`}>
                            <ClickableText
                                style={{ color: "#50d2c2" }}
                                className="knowtFontBold"
                                onClick={() => Mixpanel.track("Learn More Clicked", { userId, article })}>
                                Learn more
                            </ClickableText>
                        </LinkWrapper>
                    ) : customComponent ? (
                        customComponent()
                    ) : (
                        <span />
                    )}

                    <FlexRowAlignCenter style={{ gap: "2rem" }}>
                        {customButton ??
                            (onNext && (
                                <CircularButton
                                    radius="4.5rem"
                                    sx={{ backgroundColor: themeColors.primary }}
                                    onClick={onNext}>
                                    <Image
                                        src={`${ASSETS_URL}/images/arrow-right.png`}
                                        width="25"
                                        height="25"
                                        alt="next"
                                    />
                                </CircularButton>
                            ))}
                    </FlexRowAlignCenter>
                </FlexRowAlignCenter>
            </div>
        </>
    );
};

export default PopupSlide;
