import { ASSETS_URL } from "@/config/deployConstants";
import { FlexRowAlignCenter } from "@/components/Flex";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import Image from "next/image";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import Link from "@/components/wrappers/Link";
import { OrganizationStage } from "@knowt/syncing/graphql/schema";
import br from "@/styles/breakpoints.module.css";

const moneyIcon = `${ASSETS_URL}/images/money-icon.svg`;
const giftboxIcon = `${ASSETS_URL}/images/giftbox-icon.svg`;

const ReminderBadge = ({ classId }) => {
    const { userId, organization, international } = useCurrentUser();
    const isSubscribed = organization?.stage === OrganizationStage.SUBSCRIBED;
    const isReferralFinished = organization?.referrals?.find(referral => referral.userId === userId)?.sent === true;

    if (isSubscribed || international) return null;

    return (
        <Link href={`/class/${classId}/school-referral`} className={br.mdDownDisplayNone}>
            <CircularRectTextButton
                sx={{
                    backgroundColor: themeColors.neutralBlack,
                }}>
                <FlexRowAlignCenter
                    style={{
                        padding: `${spacing.XS} ${spacing.XS_2}`,
                        gap: spacing.XS_2,
                        color: themeColors.neutralWhite,
                    }}>
                    <Image
                        src={!isSubscribed ? moneyIcon : giftboxIcon}
                        alt="Click to earn reward"
                        width={30}
                        height={30}
                    />
                    <span>
                        {isReferralFinished ? "Track your $200 bonus" : "Earn $200 by getting this in your school"}
                    </span>
                </FlexRowAlignCenter>
            </CircularRectTextButton>
        </Link>
    );
};

export default ReminderBadge;
