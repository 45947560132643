import { FlexColumn, FlexColumnAlignJustifyCenter } from "@/components/Flex";
import { iconSizes } from "@/utils/iconProps";
import { themeColors } from "@/utils/themeColors";
import { Table, TableBody, TableContainer } from "@mui/material";
import { ChevronDown } from "lucide-react";
import { useMemo, useState } from "react";
import { TableHeader, TableOptions, TablePaginationFooter } from "./StudentTableSections";
import { TableEntry } from "./StudentTableEntry";
import { useClassManagementContextSelector } from "../../../hooks/useClassManagementContext";
import { ClassMemberWithDetails, ClassSection } from "@knowt/syncing/graphql/schema";
import { chunkArray } from "@/utils/arrayUtils";
import Image from "next/image";
import { ASSETS_URL } from "@/config/deployConstants";
import { ClassCodeTextWithPopup } from "../../../components/ClassCodeLinkCard";
import dynamic from "next/dynamic";
import LazyLoaded from "@/components/LazyLoaded";
import SectionPicker from "../../../components/SectionPicker";
import { updateClass } from "@knowt/syncing/hooks/classes/utils";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { report } from "@/utils/analytics/logging";
import toast from "react-hot-toast";
import { UNTITLED } from "@knowt/syncing/utils/dataCleaning";
import { spacing } from "@/utils/spacing";
import { borderRadius } from "@/utils/borderRadius";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import LinkWrapper from "@/components/wrappers/Link/Link";
import { isDarkColor } from "@knowt/syncing/utils/genericUtils";

const StudentActionPopup = dynamic(() => import("./StudentActionPopup"));

const EmptyState = ({
    isDPASigned,
    classId,
    classColor,
}: {
    isDPASigned: boolean;
    classId: string;
    classColor: string;
}) => {
    return (
        <FlexColumnAlignJustifyCenter
            style={{
                textAlign: "center",
                margin: "0 auto 5rem",
                padding: "4rem",
                gap: spacing.MD,
                backgroundColor: themeColors.neutralWhite,
                borderRadius: borderRadius.card,
                width: "100%",
                flex: 1,
            }}>
            <Image src="/images/kai-sherlock.svg" width={250} height={250} alt="no content" />
            <FlexColumn>
                <p className="heading5" style={{ marginBottom: spacing.XXS }}>
                    Ask your students to join with a class code, or send them a link
                </p>
                <p className="body2" style={{ maxWidth: "61rem" }}>
                    {isDPASigned
                        ? "Students need to press the + button in the top right, and select “Join Class” to enter the code"
                        : "Click on “invite students” to invite your class. For the first time you’ll need to go through the DPA process. "}
                </p>
            </FlexColumn>
            {isDPASigned ? (
                <ClassCodeTextWithPopup />
            ) : (
                <LinkWrapper href={`/class/${classId}/school-referral`}>
                    <CircularRectTextButton
                        style={{
                            padding: "1.6rem 2.4rem",
                            backgroundColor: classColor,
                            color: isDarkColor(classColor) ? themeColors.pureWhite : themeColors.neutralBlack,
                        }}>
                        invite your students
                    </CircularRectTextButton>
                </LinkWrapper>
            )}
        </FlexColumnAlignJustifyCenter>
    );
};

const SectionEmptyState = () => {
    return (
        <FlexColumnAlignJustifyCenter
            style={{
                height: "auto",
                width: "100%",
                padding: "13rem 2.4rem",
                backgroundColor: themeColors.neutralWhite,
                borderRadius: "2rem",
                gap: "1.6rem",
            }}>
            <Image
                src={`${ASSETS_URL}/images/sherlock.svg`}
                alt="kai-sherlock"
                width={250}
                height={250}
                style={{
                    width: "18.7rem",
                    height: "20rem",
                }}
            />
            <FlexColumnAlignJustifyCenter
                style={{
                    textAlign: "center",
                }}>
                <p className="heading5">There’s no students in this section yet.</p>
                <p className="body2" style={{ maxWidth: "80%" }}>
                    If you believe a student joined the wrong section, you can press actions on their row and move them
                    to this section.
                </p>
            </FlexColumnAlignJustifyCenter>
        </FlexColumnAlignJustifyCenter>
    );
};

export enum ClassMemberSortByKeys {
    NAME,
    SECTION,
    DATE,
}

export enum ClassMemberSortOrder {
    ASC,
    DESC,
}

export type MemberSortSettings = {
    sortBy: ClassMemberSortByKeys;
    order: ClassMemberSortOrder;
};

const ClassDashboardStudentsTab = () => {
    const { user, organization } = useCurrentUser();

    const course = useClassManagementContextSelector(state => state.course);

    const [selectedSection, setSelectedSection] = useState<ClassSection>();
    const [selectedStudents, setSelectedStudents] = useState<ClassMemberWithDetails[]>([]);

    const [popupOpen, setPopupOpen] = useState<"move" | "remove" | null>(null);
    const [selectedSectionForMove, setSelectedSectionForMove] = useState<ClassSection | null>();

    const [tablePage, setTablePage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [sortSettings, setSortSettings] = useState<MemberSortSettings>();

    const classMembers = useMemo(() => {
        if (!course.members) return [];

        const membersBySelectedSections = selectedSection
            ? course.members.filter(classMember => classMember.sections.includes(selectedSection.id))
            : course.members;

        if (!sortSettings) return membersBySelectedSections;

        return membersBySelectedSections.sort((a, b) => {
            switch (sortSettings.sortBy) {
                case ClassMemberSortByKeys.NAME:
                    return (
                        (a.Name ?? UNTITLED)?.localeCompare(b.Name ?? UNTITLED) *
                        (sortSettings.order === ClassMemberSortOrder.ASC ? 1 : -1)
                    );
                case ClassMemberSortByKeys.SECTION:
                    return (
                        a.sections[0].localeCompare(b.sections[0]) *
                        (sortSettings.order === ClassMemberSortOrder.ASC ? 1 : -1)
                    );
                case ClassMemberSortByKeys.DATE:
                    return (
                        ((a.lastLogIn ?? 0) - (b.lastLogIn ?? 0)) *
                        (sortSettings.order === ClassMemberSortOrder.ASC ? 1 : -1)
                    );
            }
        });
    }, [course.members, sortSettings, selectedSection]);

    const handleRemoveStudentsPress = () => {
        // Remove selected students
        try {
            if (!course) throw new Error("Class not found");

            const members = course.members.filter(
                member => !selectedStudents.find(({ userId }) => userId === member.userId)
            );

            const newClass = { classId: course.classId, name: course.name, members };
            updateClass(newClass, user);
        } catch (error) {
            report(error, "handleRemoveStudentsPress");
            toast.error("Failed to remove students");
        } finally {
            setSelectedStudents([]);
        }
    };

    const handleMovePress = async () => {
        // Move selected students to another section
        setPopupOpen(null);
        try {
            if (!course) throw new Error("Class not found");
            if (!selectedSectionForMove) throw new Error("Section not found");

            const members = course.members.map(member => {
                if (selectedStudents.find(({ userId }) => userId === member.userId)) {
                    return {
                        ...member,
                        sections: [selectedSectionForMove.id], // Currently, only one section can be selected
                    };
                } else {
                    return member;
                }
            });

            const newClass = { classId: course.classId, name: course.name, members };
            await updateClass(newClass, user);
        } catch (error) {
            report(error, "handleMovePress");
            toast.error("Failed to move students");
        } finally {
            setSelectedStudents([]);
            setSelectedSectionForMove(null);
        }
    };

    const isEmpty = !course?.members.length;
    const isSectionEmpty = selectedSection && !classMembers.length;
    const isLoading = !isEmpty && !classMembers.length && !isSectionEmpty;

    const classMembersPerPage = chunkArray(classMembers, rowsPerPage);

    if (isEmpty)
        return (
            <EmptyState
                classId={course?.classId}
                isDPASigned={!organization || !!organization.dpa}
                classColor={course.color}
            />
        );

    const renderTable = () => (
        <TableContainer
            sx={{
                ".MuiTableCell-root": {
                    fontFamily: "var(--knowt-font-name)",
                    fontSize: "1.4rem",
                    padding: "1.6rem 2.4rem",
                    height: "7.8rem",
                    color: themeColors.neutralBlack,
                },
            }}>
            <Table sx={{ borderColor: themeColors.background }}>
                <TableHeader
                    isChecked={!!classMembers.length && selectedStudents.length === classMembers.length}
                    onCheck={() => {
                        if (selectedStudents.length === classMembers.length) {
                            setSelectedStudents([]);
                        } else {
                            setSelectedStudents(classMembers);
                        }
                    }}
                    onSortChange={({ sortBy, order }) => setSortSettings({ sortBy, order })}
                    currentSortSettings={sortSettings}
                />
                <TableBody>
                    {(isLoading ? new Array(rowsPerPage).fill(null) : classMembersPerPage[tablePage]).map(
                        (classMember, index) => (
                            <TableEntry
                                key={classMember?.userId ?? index}
                                loading={isLoading}
                                classMember={classMember}
                                isChecked={selectedStudents.includes(classMember)}
                                onCheck={() => {
                                    if (selectedStudents.includes(classMember)) {
                                        setSelectedStudents(prev =>
                                            prev.filter(selectedClassMember => selectedClassMember !== classMember)
                                        );
                                    } else {
                                        setSelectedStudents([...(selectedStudents ?? []), classMember]);
                                    }
                                }}
                                onRemoveStudent={() => {
                                    setSelectedStudents([classMember]);
                                    setPopupOpen("remove");
                                }}
                                onChangeSection={() => {
                                    setSelectedStudents([classMember]);
                                    setPopupOpen("move");
                                }}
                            />
                        )
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );

    return (
        <FlexColumn style={{ backgroundColor: themeColors.neutralWhite, borderRadius: "2rem", margin: "2.4rem 0" }}>
            <TableOptions
                numOfMembers={course?.members.length}
                hasSelection={!!selectedStudents?.length}
                selectedSection={selectedSection}
                onSectionChange={setSelectedSection}
                onMoveStudents={() => setPopupOpen("move")}
                onRemoveStudents={() => setPopupOpen("remove")}
            />
            {renderTable()}
            {isSectionEmpty && <SectionEmptyState />}
            {/* keep this outside renderTable, it's messing with column width otherwise */}
            {classMembers.length > rowsPerPage && (
                <TablePaginationFooter
                    onRowPerPageChange={rows => setRowsPerPage(rows)}
                    rowPerPage={rowsPerPage}
                    page={tablePage}
                    totalRows={classMembers.length}
                    totalPages={classMembersPerPage.length}
                    onPageChange={page => setTablePage(page)}
                />
            )}
            <LazyLoaded load={!!selectedStudents.length}>
                <StudentActionPopup
                    isOpen={course.sections.length > 1 && popupOpen === "move"}
                    onClose={() => {
                        setPopupOpen(null);
                        setSelectedStudents([]);
                        setSelectedSectionForMove(null);
                    }}
                    onConfirm={handleMovePress}
                    title={
                        selectedStudents.length === 1
                            ? `Move ${selectedStudents[0].Name ?? selectedStudents[0].username} to another section?`
                            : `Move ${selectedStudents.length} students to another section?`
                    }
                    description="Students will appear in the section you select below."
                    actionLabel="move"
                    additionalContent={
                        <SectionPicker
                            selectedSection={
                                selectedSectionForMove ??
                                course.sections.find(section => section.id === selectedStudents[0]?.sections?.[0])
                            }
                            hideAllSections
                            onSectionChange={section => setSelectedSectionForMove(section)}
                            btnSx={{
                                padding: "1.2rem 2.4rem",
                                border: `2px solid ${themeColors.neutral1}`,
                                borderRadius: "2rem",
                                width: "24rem",
                                justifyContent: "space-between",
                            }}
                            adornment={<ChevronDown size={iconSizes.SM} />}
                        />
                    }
                />
            </LazyLoaded>
            <LazyLoaded load={!!selectedStudents.length}>
                <StudentActionPopup
                    isOpen={popupOpen === "remove"}
                    onClose={() => {
                        setPopupOpen(null);
                        setSelectedStudents([]);
                    }}
                    title={
                        selectedStudents.length === 1
                            ? `Remove ${selectedStudents[0].Name ?? selectedStudents[0].username} from this class?`
                            : `Remove ${selectedStudents.length} students from this class?`
                    }
                    description="Students can only rejoin the class if you provide them with an invite link or class code."
                    actionLabel="remove"
                    onConfirm={handleRemoveStudentsPress}
                />
            </LazyLoaded>
        </FlexColumn>
    );
};

export default ClassDashboardStudentsTab;
