import { iconSizes, strokeWidth } from "@/utils/iconProps";
import { Folder } from "@knowt/syncing/graphql/schema";
import { FolderInput } from "lucide-react";
import React, { useMemo } from "react";
import { toast } from "react-hot-toast";
import { useUserContentManagementContextSelector } from "../../hooks/useUserContentManagementContext";
import BadgedButton from "../styled/BadgedButton";
import { isFlashcardSet, isFolder, isMedia, isNote } from "@knowt/syncing/utils/dataCleaning";
import { useMoveToPopupContextSelector } from "@/features/MoveToPopup";

const MoveToFolderButton = ({
    onMove,
}: {
    onMove: (
        { classId, folderId }: { classId: string; folderId: string },
        {
            noteIds,
            folderIds,
            flashcardSetIds,
            mediaIds,
        }: { noteIds: string[]; folderIds: string[]; flashcardSetIds: string[]; mediaIds: string[] }
    ) => Promise<void>;
}) => {
    const openMoveToPopup = useMoveToPopupContextSelector(state => state?.openMoveToPopup);

    const selectedItems = useUserContentManagementContextSelector(state => state.selectedItems);
    const setSelectedItems = useUserContentManagementContextSelector(state => state.setSelectedItems);
    const setIsSelectMode = useUserContentManagementContextSelector(state => state.setIsSelectMode);

    const selectedItemArr = useMemo(() => Object.values(selectedItems), [selectedItems]);
    const selectedCount = selectedItemArr.length;

    const selectedFolders: { [folderId: string]: Folder } = useMemo(() => {
        const folders = {};
        Object.keys(selectedItems).forEach(itemId => {
            const item = selectedItems[itemId];
            if (isFolder(item)) {
                folders[itemId] = item;
            }
        });
        return folders;
    }, [selectedItems]);

    const moveSelected = async ({ classId, folderId }: { classId: string; folderId: string }) => {
        const noteIds: string[] = [];
        const folderIds: string[] = [];
        const flashcardSetIds: string[] = [];
        const mediaIds: string[] = [];

        selectedItemArr.forEach(item => {
            if (isNote(item)) {
                return noteIds.push(item.noteId);
            } else if (isFolder(item)) {
                return folderIds.push(item.folderId);
            } else if (isFlashcardSet(item)) {
                return flashcardSetIds.push(item.flashcardSetId);
            } else if (isMedia(item)) {
                return mediaIds.push(item.mediaId);
            }

            throw new Error("Unknown item type");
        });

        // toast vocab
        const tv = {
            verb: "moved",
            flashcardSet: (() => {
                const len = flashcardSetIds.length;
                const flashcardSet = len === 1 ? "flashcard set" : "flashcard sets";
                return len > 0 ? len + " " + flashcardSet : null;
            })(),
            note: (() => {
                const len = noteIds.length;
                const note = len === 1 ? "note" : "notes";
                return len > 0 ? len + " " + note : null;
            })(),
            folder: (() => {
                const len = folderIds.length;
                const folder = len === 1 ? "folder" : "folders";
                return len > 0 ? len + " " + folder : null;
            })(),
            media: (() => {
                const len = mediaIds.length;
                const media = len === 1 ? "media" : "medias";
                return len > 0 ? len + " " + media : null;
            })(),
        };

        try {
            await onMove(
                {
                    classId,
                    folderId,
                },
                { noteIds, folderIds, flashcardSetIds, mediaIds }
            );

            setTimeout(() => {
                toast.success(
                    [tv.note, tv.flashcardSet, tv.folder, tv.media].filter(Boolean).join(", ") + " " + tv.verb
                );
            }, 1000);

            setSelectedItems({});
            setIsSelectMode(false);
        } catch (e) {
            toast.error([tv.note, tv.flashcardSet, tv.folder, tv.media].filter(Boolean).join(", ") + " failed to move");
        }
    };

    return (
        <BadgedButton
            badgeCount={selectedCount}
            onClick={() => {
                if (!selectedItemArr.length) {
                    toast.error("No items selected, please select some items to move.");
                    return;
                }

                openMoveToPopup({ onMove: moveSelected, selectedFolders });
            }}
            sx={{ width: "16rem" }}>
            <FolderInput size={iconSizes.MD} strokeWidth={strokeWidth.normal} />
            move to
        </BadgedButton>
    );
};

export default MoveToFolderButton;
