import { FolderIcon } from "lucide-react";
import { FlexColumnAlignJustifyCenter } from "@/components/Flex";
import CircularButton from "@/components/CircularButton";
import { iconSizes, strokeWidth } from "@/utils/iconProps";
import { Folder } from "@knowt/syncing/graphql/schema";
import Image from "next/image";

type IconWithHandlerProps = {
    selectedColor?: string | null | undefined;
    folder: Pick<Folder, "color" | "icon">;
    onClick?: () => void;
};

const FolderIconBtn = ({ folder, selectedColor = folder.color, onClick }: IconWithHandlerProps) => {
    const shouldShowEmoji = !!folder.icon?.emoji;

    return (
        <CircularButton
            radius="2rem"
            sx={{
                backgroundColor: "transparent",
                "&:hover": { boxShadow: onClick ? "inset 0 0 0 50em rgba(200, 200, 200, 0.35)" : "none" },
                "&:active": { boxShadow: "none" },
            }}
            onClick={e => {
                if (onClick) {
                    e.stopPropagation();
                    onClick?.();
                }
            }}>
            <FlexColumnAlignJustifyCenter>
                {shouldShowEmoji ? (
                    <span style={{ fontSize: iconSizes.SM }}>{folder.icon?.emoji}</span>
                ) : selectedColor ? (
                    <FolderIcon
                        strokeWidth={strokeWidth.normal}
                        size={iconSizes.SM}
                        fill={selectedColor}
                        color={selectedColor}
                    />
                ) : (
                    <Image
                        src={`/images/folder-icon.svg`}
                        width={iconSizes.SM}
                        height={iconSizes.SM}
                        alt="folder icon"
                    />
                )}
            </FlexColumnAlignJustifyCenter>
        </CircularButton>
    );
};

export default FolderIconBtn;
