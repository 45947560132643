"use client";

import { themeColors } from "@/utils/themeColors";
import ResponsiveDialog, { DialogCloseBtn } from "@/features/ResponsiveDialog";
import { FlexColumn, FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import { spacing } from "@/utils/spacing";
import br from "@/styles/breakpoints.module.css";
import SelectableCardWithCenteredBottomText from "@/components/SelectableCard/styled/SelectableCardWithCenteredBottomText";
import { ASSETS_URL } from "@/config/deployConstants";
import Image from "next/image";
import { ItemType } from "@knowt/syncing/graphql/schema";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { useRouter } from "next13-progressbar";
import { createAndOpenNewNote } from "@/utils/navigation/notes";
import { makeStandaloneFlashcardSet } from "@/hooks/flashcards/utils";
import { now } from "@knowt/syncing/utils/SyncUtils";
import { updateClass } from "@/hooks/classes/utils";
import { useClass } from "@/hooks/classes/useClass";

const MoveFileToClassIcon = `${ASSETS_URL}/images/move-to-class-icon.svg`;
const blackBoardIcon = `/images/blackboard.svg`; //TODO: UPLOAD TO S3

type AddClassFilePopupProps = {
    isOpen: boolean;
    onClose: () => void;
    classId: string | undefined;
    openMoveFileToClassPopup: () => void;
    itemType: ItemType;
    folderId?: string;
};

const mapItemTypeToText = (itemType: ItemType): string => {
    if (itemType === ItemType.FLASHCARDSET) {
        return "flashcards";
    } else {
        return itemType.toLowerCase();
    }
};

const AddClassFilePopup = ({
    isOpen,
    onClose,
    classId,
    folderId,
    openMoveFileToClassPopup,
    itemType,
}: AddClassFilePopupProps) => {
    const { user, userId } = useCurrentUser();
    const router = useRouter();

    const { course } = useClass({ classId, isEnabled: !!classId });

    const createNewFile = async () => {
        const props = { classId: classId, folderId, addedAt: now().toString() };

        if (itemType === ItemType.NOTE) {
            const extraAction = (noteId: string) => {
                localStorage.setItem("addedFileId", noteId);
            };

            await createAndOpenNewNote(props, user, router, null, extraAction);
        } else if (itemType === ItemType.FLASHCARDSET) {
            const { flashcardSetId } = await makeStandaloneFlashcardSet({
                userId,
                ...props,
            });

            localStorage.setItem("addedFileId", flashcardSetId);
            router.push(`/flashcards/${flashcardSetId}/edit`);
        }

        await updateClass({ classId, fileCount: (course?.fileCount ?? 0) + 1 }, user);

        onClose();
    };

    const cards = [
        {
            title: "Move existing file here",
            desc: "This will move your file and only be visible to this class.",
            topCardColor: themeColors.subject1,
            onClick: () => openMoveFileToClassPopup(),
            loading: false,
            topComponent: (
                <Image
                    src={MoveFileToClassIcon}
                    width={96}
                    height={95}
                    alt="Click to an existing file to this class"
                    objectFit="contain"
                    style={{ width: "66%", height: "66%" }}
                />
            ),
        },
        {
            title: `Create new ${mapItemTypeToText(itemType)} in class`,
            desc: "Your file will be created here and only be visible here.",
            topCardColor: themeColors.subject2,
            onClick: () => createNewFile(),
            loading: false,
            topComponent: (
                <Image
                    src={blackBoardIcon}
                    width={102}
                    height={102}
                    alt="Create a new file in this class"
                    style={{ width: "66%", height: "66%" }}
                />
            ),
        },
    ];

    const renderContent = () => {
        return (
            <FlexColumnAlignJustifyCenter>
                <DialogCloseBtn onClick={onClose} style={{ marginLeft: "auto", marginBottom: "2rem" }} />
                <FlexColumn style={{ width: "100%" }}>
                    <span className="heading4" style={{ marginBottom: spacing.XS }}>
                        Choose how you want to create
                    </span>
                    <span className="body1">
                        Move a file from outside your class into your class, or create a new one.
                    </span>

                    <FlexRowAlignCenter className={br.smDownColumn} style={{ marginTop: spacing.XL, gap: "4rem" }}>
                        {cards.map((card, index) => (
                            <SelectableCardWithCenteredBottomText
                                key={index}
                                top={card.topComponent}
                                topStyle={{ backgroundColor: card.topCardColor }}
                                title={card.title}
                                desc={card.desc}
                                onClick={card.onClick}
                                isLoading={card.loading}
                                style={{ backgroundColor: "transparent" }}
                            />
                        ))}
                    </FlexRowAlignCenter>
                </FlexColumn>
            </FlexColumnAlignJustifyCenter>
        );
    };

    return (
        <ResponsiveDialog
            isOpen={isOpen}
            onClose={onClose}
            style={{ backgroundColor: themeColors.neutralWhite, overflow: "hidden" }}
            DialogStyle={{
                padding: "4rem 4rem 8rem",
                width: "66.4rem",
                height: "auto",
                borderRadius: "4rem",
            }}
            SlideMenuStyle={{
                padding: "2.5rem 2rem 1rem",
            }}>
            {renderContent()}
        </ResponsiveDialog>
    );
};

export default AddClassFilePopup;
