import { AnimatedTrashButton } from "@/components/TrashButton/TrashButton";
import { useUserContentManagementContextSelector } from "@/features/UserContentManagement";
import { trashFlashcardSets } from "@knowt/syncing/hooks/flashcards/utils";
import { trashFolders } from "@knowt/syncing/hooks/folders/utils";
import { trashMedias } from "@knowt/syncing/hooks/media/utils";
import { trashNotes } from "@knowt/syncing/hooks/notes/utils";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { isFlashcardSet, isFolder, isMedia, isNote } from "@knowt/syncing/utils/dataCleaning";
import React from "react";
import { toast } from "react-hot-toast";
import { useClassManagementContextSelector } from "../../hooks/useClassManagementContext";

const ClassContentRemoveButton = () => {
    const { userId } = useCurrentUser() as { userId: string };

    const selectedItems = useUserContentManagementContextSelector(state => state.selectedItems);
    const setSelectedItems = useUserContentManagementContextSelector(state => state.setSelectedItems);
    const setIsSelectMode = useUserContentManagementContextSelector(state => state.setIsSelectMode);

    const classId = useClassManagementContextSelector(state => state.classId);

    const selectedItemCount = Object.keys(selectedItems).length;

    const deleteSelectedItems = async () => {
        const selectedItemArr = Object.values(selectedItems);

        const noteIds: string[] = [];
        const folderIds: string[] = [];
        const flashcardSetIds: string[] = [];
        const mediaIds: string[] = [];

        selectedItemArr.forEach(item => {
            if (isNote(item)) {
                return noteIds.push(item.noteId);
            } else if (isFolder(item)) {
                return folderIds.push(item.folderId);
            } else if (isFlashcardSet(item)) {
                return flashcardSetIds.push(item.flashcardSetId);
            } else if (isMedia(item)) {
                return mediaIds.push(item.mediaId);
            }

            throw new Error("Unknown item type");
        });

        try {
            // move selected to trash
            await Promise.all([
                trashNotes({ noteIds, userId, sourceFolderId: null, sourceClassId: classId }),
                trashFlashcardSets({ flashcardSetIds, userId, sourceFolderId: null, sourceClassId: classId }),
                trashMedias({ mediaIds, userId, sourceFolderId: null, sourceClassId: classId }),
                trashFolders({ folderIds, userId, sourceClassId: classId }),
            ]);

            // we have animation during the deletion, so wait for it to finish
            setTimeout(() => {
                setSelectedItems({});
                setIsSelectMode(false);
            }, 1000);
        } catch (e) {
            const verb = "remove";
            const noun = selectedItemCount > 1 ? "items" : "item";
            toast.error(`Failed to ${verb} ${selectedItemCount} ${noun}`);
            toast.error(e);
        }
    };

    return (
        <AnimatedTrashButton
            width={"22.4rem"}
            text={"Remove from class"}
            confirmDialogProps={{
                title: `Are you sure you want to remove ${selectedItemCount > 1 ? "these" : "this"} from your class?`,
                subtitle: "Your files will now be located in your personal files outside of this class.",
                buttonText: "remove",
            }}
            selectedCount={selectedItemCount}
            onDelete={selectedItemCount >= 1 ? deleteSelectedItems : null}
            onAnimationFinished={() => null}
        />
    );
};

export default ClassContentRemoveButton;
