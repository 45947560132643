"use client";
import { moveMedias } from "@/hooks/media/utils";
import {
    closestCenter,
    DndContext,
    DragOverlay,
    MouseSensor,
    rectIntersection,
    useDraggable,
    useDroppable,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import { snapCenterToCursor } from "@dnd-kit/modifiers";
import { moveFlashcardSets } from "@knowt/syncing/hooks/flashcards/utils";
import { moveFolder } from "@knowt/syncing/hooks/folders/utils";
import { moveNotes } from "@knowt/syncing/hooks/notes/utils";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import dynamic from "next/dynamic";
import React, { useState } from "react";
import { UserContent } from "../types";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";
import FolderCard from "@/components/cards/FolderCard";
import toast from "react-hot-toast";
import { isFlashcardSet, isFolder, isMedia, isNote } from "@knowt/syncing/utils/dataCleaning";

const UserContentCard = dynamic(() => import("@/components/cards/UserContentCard"));

export const UserContentManagementMoveToHandler = ({
    children,
    disabled,
}: {
    children: React.ReactNode;
    disabled?: boolean;
}) => {
    const { userId } = useCurrentUser();

    const pointerSensor = useSensor(MouseSensor, { activationConstraint: { distance: 35 } });
    const sensors = useSensors(pointerSensor);

    const [currDraggedItem, setCurrDraggedItem] = useState<UserContent | null>(null);

    if (disabled) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <React.Fragment>{children}</React.Fragment>;
    }

    return (
        <DndContext
            sensors={sensors}
            // UI wise, folder dragging intersection can be hard in most of the time since list of folders contain very small spaces each
            // that's why we use `closestCenter` for folder dragging
            collisionDetection={currDraggedItem && isFolder(currDraggedItem) ? closestCenter : rectIntersection}
            onDragStart={event => {
                const draggedItem = event.active.data.current?.item;
                setCurrDraggedItem(draggedItem);
            }}
            // on drop action
            onDragEnd={async event => {
                setCurrDraggedItem(null);

                if (!event.over || !event.active) return;

                const draggedItem = event.active.data.current?.item as UserContent;
                const _droppableItemId = event.over.id as DroppableId;
                const droppableItemId = _droppableItemId === HOME_DIRECTORY_ID ? null : _droppableItemId;

                if (draggedItem.folderId === droppableItemId) return;

                const fileType = isFolder(draggedItem)
                    ? "folder"
                    : isFlashcardSet(draggedItem)
                    ? "flashcard set"
                    : isNote(draggedItem)
                    ? "note"
                    : "media";

                toast.loading(`Moving your ${fileType}...`, { id: "MOVING_FILE" });
                if (isFolder(draggedItem)) {
                    await moveFolder({
                        userId,
                        folderId: draggedItem.folderId,
                        sourceFolderId: draggedItem.parentId || null,
                        parentId: droppableItemId || null,
                    });
                } else if (isFlashcardSet(draggedItem)) {
                    await moveFlashcardSets({
                        userId: userId as string,
                        flashcardSetIds: [draggedItem.flashcardSetId],
                        sourceFolderId: draggedItem.folderId,
                        destinationFolderId: droppableItemId,
                        sourceClassId: draggedItem.classId,
                    });
                } else if (isNote(draggedItem)) {
                    await moveNotes({
                        userId,
                        noteIds: [draggedItem.noteId],
                        sourceFolderId: draggedItem.folderId,
                        destinationFolderId: droppableItemId,
                        sourceClassId: draggedItem.classId,
                    });
                } else if (isMedia(draggedItem)) {
                    await moveMedias({
                        userId,
                        mediaIds: [draggedItem.mediaId],
                        sourceFolderId: draggedItem.folderId,
                        destinationFolderId: droppableItemId,
                        sourceClassId: draggedItem.classId,
                    });
                } else {
                    toast.error(`An error occured while moving your ${fileType}.`, { id: "MOVING_FILE" });
                    throw new Error("Unexpected dragged item");
                }
                toast.success(`${fileType} moved successfully!`, { id: "MOVING_FILE" });
            }}>
            {children}

            {/* PLACEHOLDER PREVIEW-CARD DURING DRAGGING A CARD */}
            <DragOverlay dropAnimation={null} modifiers={[snapCenterToCursor]}>
                {(() => {
                    if (!currDraggedItem) return null;

                    if (isNote(currDraggedItem) || isFlashcardSet(currDraggedItem) || isMedia(currDraggedItem)) {
                        return (
                            <UserContentCard
                                style={{ transform: "scale(0.75)", opacity: "0.80" }}
                                {...(isNote(currDraggedItem)
                                    ? { note: currDraggedItem }
                                    : isFlashcardSet(currDraggedItem)
                                    ? { flashcardSet: currDraggedItem }
                                    : { media: currDraggedItem })}
                            />
                        );
                    } else if (isFolder(currDraggedItem)) {
                        return <FolderCard folder={currDraggedItem} style={{ opacity: "0.80" }} />;
                    } else {
                        throw new Error(
                            "Unexpected dragged item. Make sure it's either a Note, FlashcardSet or Folder"
                        );
                    }
                })()}
            </DragOverlay>
        </DndContext>
    );
};

type DraggableCardHandlerProps = React.PropsWithChildren<{ id: string; item: UserContent }>;
export const DraggableCardHandler = ({ id, item, children }: DraggableCardHandlerProps) => {
    const { smDown } = useBreakPoints();

    const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
        id,
        data: { item },
        disabled: smDown,
    });

    return (
        <div
            ref={setNodeRef}
            style={{ ...(isDragging && { opacity: 0.5 }), userSelect: "none" }}
            onMouseUpCapture={event => {
                // disable default behavior of "anchor tag" from children while the card is dragged
                if (isDragging) {
                    event.stopPropagation();
                }
            }}
            {...listeners}
            {...attributes}>
            {children}
        </div>
    );
};

export const HOME_DIRECTORY_ID = "home-directory";
type DroppableId = string;

type DroppableCardHandlerProps = {
    id: DroppableId;
    children: ({ isOver }: { isOver: boolean }) => React.ReactNode;
};
export const DroppableCardHandler = ({ id, children }: DroppableCardHandlerProps) => {
    const { smDown } = useBreakPoints();
    const { isOver: _isOver, setNodeRef, active } = useDroppable({ id, disabled: smDown });

    const isOver = active?.id !== id && _isOver;

    return <div ref={setNodeRef}>{children({ isOver })}</div>;
};
