"use client";

import { FlexRowAlignCenter } from "@/components/Flex";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { formatUnixSecondsTimestamp } from "@knowt/syncing/utils/dateTimeUtils";

const ComplianceBanner = () => {
    const { userId, organization } = useCurrentUser();
    const referral = organization?.referrals.find(referral => referral.userId === userId);

    const shouldShowBanner = !organization?.dpa && referral;

    if (!shouldShowBanner) return null;

    const { day, month, year } = formatUnixSecondsTimestamp(referral.created);

    return (
        <FlexRowAlignCenter
            style={{
                padding: spacing.SM,
                backgroundColor: themeColors.primaryLight,
                justifyContent: "center",
                gap: spacing.XS,
            }}>
            <span className="bodyBold1" style={{ color: themeColors.primaryDark }}>
                Your compliance review is pending!
            </span>
            <span style={{ color: themeColors.primaryDark }}>
                We reached out to your {referral.type} on {month}-{day}-{year}
            </span>
        </FlexRowAlignCenter>
    );
};

export default ComplianceBanner;
