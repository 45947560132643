import { useEffect, useState, useMemo } from "react";
import { useFolderNotes, useNotebook } from "@/hooks/notes/useNotes";
import { useAllStandaloneFlashcardSets } from "@/hooks/flashcards/useStandaloneFlashcards";
import { FlashcardSet, ItemType, Media, Note } from "@knowt/syncing/graphql/schema";
import { useAllFolders, useNestedFolders } from "@/hooks/folders/useFolders";
import { platform } from "@/platform";
import { useAllMedias, useFolderMedias } from "./media/useMedia";
import { useClasses } from "./classes/useClass";
import { useFolderFlashcardSets } from "./flashcards/useFlashcards";

export const useSearchItems = ({
    isEnabled = true,
    disabledItemTypes = [],
}: {
    isEnabled?: boolean;
    disabledItemTypes?: ItemType[];
}) => {
    const { notes: notesObj } = useNotebook({
        inTrash: false,
        showFoldersNotes: true,
        isEnabled: isEnabled && !disabledItemTypes.includes(ItemType.NOTE),
        showClassNotes: true,
    });

    const { standaloneFlashcards: flashcardSetsObj } = useAllStandaloneFlashcardSets({
        showClassSets: true,
        showFolderSets: true,
        isEnabled: isEnabled && !disabledItemTypes.includes(ItemType.FLASHCARDSET),
    });

    const { medias: mediasObj } = useAllMedias({
        inTrash: false,
        isEnabled: isEnabled && !disabledItemTypes.includes(ItemType.MEDIA),
    });

    const { allFolders: foldersObj } = useAllFolders({
        isEnabled: isEnabled && !disabledItemTypes.includes(ItemType.FOLDER),
    });

    const { classes: classesObj } = useClasses({ isEnabled: isEnabled && !disabledItemTypes.includes(ItemType.CLASS) });

    const notes = Object.values(notesObj || {}).filter(({ mediaId }: Note) => !mediaId) as Note[];

    const flashcardSets = Object.values(flashcardSetsObj || {}).filter(
        ({ mediaId, noteId }: FlashcardSet) => !mediaId && !noteId
    ) as FlashcardSet[];

    const medias = Object.values(mediasObj || {}) as Media[];

    const folders = useMemo(() => {
        return Object.values(foldersObj || {});
    }, [foldersObj]);

    const classes = useMemo(() => {
        return Object.values(classesObj || {});
    }, [classesObj]);

    return {
        notes,
        flashcardSets,
        medias,
        folders,
        classes,
    };
};

export const useSearchFolderItems = ({ folderId }: { folderId: string }) => {
    const { folders: foldersObj } = useNestedFolders({ parentId: folderId });
    const { folderNotes: notesObj } = useFolderNotes({ folderId: folderId, isEnabled: true });
    const { folderFlashcardSets: flashcardSetsObj } = useFolderFlashcardSets({ folderId: folderId, isEnabled: true });
    const { folderMedias: mediasObj } = useFolderMedias({ folderId: folderId });

    const notes = Object.values(notesObj || {}).filter(({ mediaId }: Note) => !mediaId) as Note[];

    const flashcardSets = Object.values(flashcardSetsObj || {}).filter(
        ({ mediaId, noteId }: FlashcardSet) => !mediaId && !noteId
    ) as FlashcardSet[];

    const medias = Object.values(mediasObj || {}) as Media[];

    const folders = useMemo(() => {
        return Object.values(foldersObj || {});
    }, [foldersObj]);

    return {
        notes,
        flashcardSets,
        medias,
        folders,
    };
};

export const useNotesSearchItems = ({
    notes,
    notesContent,
}: {
    notes: Record<string, Note> | null | undefined;
    notesContent: Record<string, string> | null | undefined;
}) => {
    const [searchItems, setSearchItems] = useState<Note[]>(undefined);

    useEffect(() => {
        if (!notes) return;

        Promise.all(
            Object.values(notes).map(async note => {
                const rawContent = notesContent?.[note.noteId];
                if (!rawContent) return note;

                const { getPlainTextFromContent } = await platform.dataCleaning();
                return { ...note, content: getPlainTextFromContent({ content: rawContent, type: "note" }) };
            })
        ).then(setSearchItems);
    }, [notes, notesContent]);

    return searchItems;
};

export default useSearchItems;
