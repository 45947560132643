import { useEffect, useMemo, useState } from "react";
import { UNTITLED } from "@/utils/dataCleaning";
import { Class, FlashcardSet, Folder, ItemType, Media, Note } from "@knowt/syncing/graphql/schema";
import useSearchItems from "../useSearchItems";
import { timeDeltaFromNow } from "@/utils/dateTimeUtils";
import { wait } from "@/utils/genericUtils";

export type SuggestedHomeSearchItems = (Note | FlashcardSet | Folder | Media) & {
    title: string;
    id: string;
    type: ItemType;
    description: string;
    parentId: string | null;
    classId?: string;
    color?: string;
    icon?: Folder["icon"];
};

export const getLinkPerItemType = (type: ItemType, id: string) => {
    switch (type) {
        case ItemType.FLASHCARDSET:
            return `/flashcards/${id}`;
        case ItemType.NOTE:
            return `/note/${id}`;
        case ItemType.FOLDER:
            return `/folder/${id}`;
        case ItemType.MEDIA:
            return `/media/${id}`;
        case ItemType.CLASS:
            return `/class/${id}/dashboard`;
        default:
            return "#";
    }
};

const getItemInfo = (item: Note | FlashcardSet | Media | Folder | Class) => {
    switch (item.__typename) {
        case "Note":
            return {
                ...(item as Note),
                title: (item as Note).title || UNTITLED,
                id: (item as Note).noteId,
                type: ItemType.NOTE,
                parentId: (item as Note).folderId,
                classId: (item as Note).classId,
            };
        case "FlashcardSet":
            return {
                ...(item as FlashcardSet),
                title: (item as FlashcardSet).title || UNTITLED,
                id: (item as FlashcardSet).flashcardSetId,
                type: ItemType.FLASHCARDSET,
                parentId: (item as FlashcardSet).folderId,
                classId: (item as FlashcardSet).classId,
            };
        case "Media":
            return {
                ...(item as Media),
                title: (item as Media).title || UNTITLED,
                id: (item as Media).mediaId,
                type: ItemType.MEDIA,
                parentId: (item as Media).folderId,
                classId: (item as Media).classId,
            };
        case "Folder":
            return {
                ...(item as Folder),
                title: (item as Folder).name || UNTITLED,
                id: (item as Folder).folderId,
                type: ItemType.FOLDER,
                parentId: (item as Folder).parentId,
                classId: (item as Folder).classId,
                color: (item as Folder).color,
                icon: (item as Folder).icon,
            };
        case "Class":
            return {
                ...(item as Class),
                title: (item as Class).name || UNTITLED,
                id: (item as Class).classId,
                type: ItemType.CLASS,
                parentId: null,
                classId: (item as Class).classId,
            };
        // TODO: add classes when the time comes.
        default:
            // should never happen
            return {
                title: UNTITLED,
                id: "",
                type: ItemType.NOTE,
                parentId: null,
            };
    }
};

export const mapItemToSuggestedItem = (
    item: Note | FlashcardSet | Media | Folder | Class
): SuggestedHomeSearchItems => ({
    ...getItemInfo(item),
    description: `${timeDeltaFromNow(Number((item?.updated || 0).toString()))} ago`,
    updated: (item?.updated || 0).toString(),
});

export const useLocalSearchSuggestions = ({
    isEnabled = true,
    disabledItemTypes = [],
}: {
    isEnabled?: boolean;
    disabledItemTypes?: ItemType[];
}) => {
    const { notes, flashcardSets, medias, folders, classes } = useSearchItems({ isEnabled, disabledItemTypes });

    const [searchText, setSearchText] = useState("");
    const [suggestedItems, setSuggestedItems] = useState<SuggestedHomeSearchItems[]>([]);

    const getDescription = (item: Note | FlashcardSet | Media | Folder | Class) => {
        let description = `${timeDeltaFromNow(Number((item?.updated || 0).toString()))} ago`;

        if (item.__typename === "Folder") {
            // we know it's a folder
            if ((item as Folder).parentId) {
                const folder = folders.find(folder => folder.folderId === (item as Folder).parentId);
                description = `Inside: ${folder?.name}`;
            }
        } else if (item.__typename === "Class") {
            // we know it's a class
            description = `${(item as Class).fileCount ?? 0} files`;
        } else if ((item as Note | FlashcardSet | Media).folderId) {
            // we know it's a note, flashcard set, or media
            const folder = folders.find(folder => folder.folderId === (item as Note | FlashcardSet | Media).folderId);
            if (folder) {
                description = `Folder: ${folder.name}`;
            }
        }

        // TODO: add classes when the time comes.
        // if (item.className) {
        //     data.className = item.className;
        // }

        return description;
    };

    const _mapItemToSuggestedItem = (item: Note | FlashcardSet | Media | Folder | Class) => ({
        ...mapItemToSuggestedItem(item),
        description: getDescription(item),
    });

    const isLoading = !notes || !flashcardSets || !medias || !folders || !classes;

    const query = useMemo(() => searchText?.toLowerCase()?.trim(), [searchText]);
    const isMatch = (str: string) => (str ?? UNTITLED).toLowerCase().indexOf(query) !== -1;

    useEffect(() => {
        let ignore = false;

        const handleSuggestedItems = async () => {
            await wait(100);
            if (isLoading || !query || ignore) return;

            const customFilteredItems = [
                ...notes.filter(note => isMatch(note.title)).map(_mapItemToSuggestedItem),
                ...flashcardSets.filter(flashcardSet => isMatch(flashcardSet.title)).map(_mapItemToSuggestedItem),
                ...medias.filter(media => isMatch(media.title)).map(_mapItemToSuggestedItem),
                ...folders.filter(folder => isMatch(folder.name)).map(_mapItemToSuggestedItem),
                ...classes.filter(course => isMatch(course.name)).map(_mapItemToSuggestedItem),
            ].sort((a, b) => (a.updated > b.updated ? -1 : 1));

            setSuggestedItems(customFilteredItems);
        };

        handleSuggestedItems();

        return () => {
            ignore = true;
        };
    }, [notes, flashcardSets, folders, medias, classes, query]);

    return {
        notes: notes.map(mapItemToSuggestedItem),
        flashcardSets: flashcardSets.map(mapItemToSuggestedItem),
        folders: folders.map(mapItemToSuggestedItem),
        medias: medias.map(mapItemToSuggestedItem),
        classes: classes.map(mapItemToSuggestedItem),
        suggestedItems,
        isLoading,
        searchText,
        setSearchText,
    };
};
