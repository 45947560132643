import { Class, ClassRole } from "@knowt/syncing/graphql/schema";
import { themeColors } from "@/utils/themeColors";
import CardSkeleton from "@/components/cards/CardSkeleton";
import Image from "next/image";
import React, { useRef, RefObject, useState } from "react";
import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import { spacing } from "@/utils/spacing";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import WithOptionsMenu, { WithMenuOption } from "@/components/WithOptionsMenu";
import { ThreeDotsBtn } from "@/components/cards/UserContentCard/components/UserContentCardHandlerButtons";
import { useCurrentUser } from "@/hooks/user/useCurrentUser";
import { useRouter } from "next13-progressbar";
import { borderRadius } from "@/utils/borderRadius";
import { timeDeltaFromNow } from "@knowt/syncing/utils/dateTimeUtils";
import styles from "./classInfoCard.module.css";
import LazyLoaded from "@/components/LazyLoaded";
import dynamic from "next/dynamic";
import { uploadPictureToS3 } from "@knowt/syncing/utils/s3";
import { deleteClass, leaveClass, updateClass } from "@knowt/syncing/hooks/classes/utils";
import { ExternalLink } from "lucide-react";
import { iconSizes, strokeWidth } from "@/utils/iconProps";
import useConfirmDialog from "@/hooks/useConfirmDialog";
import toast from "react-hot-toast";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";
import { preventStopPropogation } from "@/utils/domUtils";
import { isDarkColor } from "@knowt/syncing/utils/genericUtils";

const CustomizeAppearancePopup = dynamic(() => import("@/features/CustomizeAppearancePopup"));

const ThreeDotsMenu = ({
    userId,
    course,
    isTeacher,
    cardRef,
}: {
    userId: string | null | undefined;
    course: Class;
    isTeacher: boolean;
    cardRef: RefObject<HTMLDivElement>;
}) => {
    const router = useRouter();
    const { smDown } = useBreakPoints();
    const [isCustomizePopupOpen, setIsCustomizePopupOpen] = useState(false);

    const { openConfirmDialog } = useConfirmDialog();

    const options: WithMenuOption[] = isTeacher
        ? [
              // Teacher Options
              {
                  label: "Settings",
                  onClick: () => router.push(`/class/${course.classId}/settings`),
                  hide: !isTeacher,
              },
              {
                  label: "Customize",
                  onClick: () => setIsCustomizePopupOpen(true),
                  hide: !isTeacher,
              },
              {
                  label: "Delete",
                  onClick: () =>
                      openConfirmDialog({
                          title: "Are you sure you want to delete this class?",
                          subtitle: "This class will not be able to be recovered if you continue to delete it.",
                          style: { borderRadius: "4rem" },
                          rounded: true,
                          cardSize: !smDown ? "58rem" : "31rem",
                          spacing: 2.2,
                          subtitleFontSize: "1.9rem",
                          subtitleFontWeight: "500",
                          buttonColor: "black",
                          simpleCancelBtn: true,
                          onConfirm: async () => {
                              toast.promise(deleteClass({ classId: course?.classId }), {
                                  loading: "Deleting class...",
                                  success: "Class deleted!",
                                  error: "Something went wrong! Please try again later",
                              });
                          },
                      }),
                  hide: !isTeacher,
                  menuItemProps: {
                      sx: {
                          borderTop: `2px solid ${themeColors.neutral1}`,
                          height: "4.7rem",
                          color: themeColors.errorPrimary,
                          fontWeight: "600",
                          fontSize: "1.5rem",
                          marginBottom: "-0.8rem",
                      },
                  },
              },
          ]
        : [
              // Teacher Options
              {
                  label: "Open in new tab",
                  onClick: () => window.open(`/class/${course.classId}`, "_blank"),
                  hide: !isTeacher,
                  node: (
                      <FlexRowAlignCenter style={{ gap: "0.9rem" }}>
                          <ExternalLink strokeWidth={strokeWidth.normal} size={iconSizes.SM} /> Open in new tab
                      </FlexRowAlignCenter>
                  ),
              },
              // Student Options
              {
                  label: "Leave class",
                  onClick: async () =>
                      openConfirmDialog({
                          title: "Are you sure you want to leave this class?",
                          subtitle: "You will have to join this class again if you want to view any of the material.",
                          style: { borderRadius: "4rem" },
                          rounded: true,
                          cardSize: !smDown ? "58rem" : "31rem",
                          spacing: 2.2,
                          subtitleFontSize: "1.9rem",
                          subtitleFontWeight: "500",
                          buttonColor: "black",
                          simpleCancelBtn: true,
                          onConfirm: async () => {
                              toast.promise(leaveClass({ classId: course?.classId, userId }), {
                                  loading: "Leaving class...",
                                  success: "You have left the class!",
                                  error: "Something went wrong! Please try again later",
                              });
                          },
                      }),
                  hide: isTeacher,
                  menuItemProps: {
                      sx: {
                          height: "4.7rem",
                          color: themeColors.errorPrimary,
                          fontWeight: "600",
                          fontSize: "1.5rem",
                      },
                  },
              },
          ];

    return (
        <>
            <WithOptionsMenu
                options={options}
                menuItemProps={{
                    sx: { padding: "1rem 1.6rem" },
                }}
                menuProps={{
                    PaperProps: {
                        sx: {
                            borderRadius: "2rem",
                            width: "20.6rem",
                            fontSize: "1.4rem",
                            margin: "-0.5rem 0 0 -1.25rem",
                        },
                    },
                    transformOrigin: { vertical: "top", horizontal: "left" },
                }}>
                {({ openMenu }) => (
                    <FlexRowAlignCenter
                        style={{
                            marginTop: spacing.XS_2,
                            paddingRight: spacing.XS_2,
                            width: "100%",
                            justifyContent: "flex-end",
                        }}>
                        <ThreeDotsBtn
                            onClick={openMenu}
                            onRightClick={e => openMenu(e)}
                            cardContainerRef={cardRef}
                            color={isDarkColor(course?.color) ? themeColors.pureWhite : themeColors.pureBlack}
                        />
                    </FlexRowAlignCenter>
                )}
            </WithOptionsMenu>
            <LazyLoaded load={isCustomizePopupOpen}>
                <div onClick={preventStopPropogation}>
                    <CustomizeAppearancePopup
                        onSave={async ({ newCoverImg, newSelectedColor }) => {
                            let imgURL: string | null = null;
                            if (newCoverImg) {
                                imgURL = await uploadPictureToS3(
                                    newCoverImg,
                                    "knowt-profile-pictures",
                                    newCoverImg.type
                                );
                            }

                            await updateClass(
                                {
                                    color: newSelectedColor,
                                    cover: imgURL,
                                    classId: course?.classId,
                                    name: course?.name,
                                },
                                { ID: userId }
                            );
                        }}
                        isOpen={isCustomizePopupOpen}
                        onClose={() => setIsCustomizePopupOpen(false)}
                        coverImg={course?.cover || undefined}
                        selectedColor={course?.color || undefined}
                    />
                </div>
            </LazyLoaded>
        </>
    );
};

const ClassInfoCard = ({ course }: { course: Class }) => {
    const { user, userId } = useCurrentUser();
    const isTeacher = !!user?.classes?.teacher?.includes(course.classId);

    const cardRef = useRef<HTMLDivElement>(null);
    const router = useRouter();

    const renderTopSection = () => {
        return (
            <div
                style={{
                    height: "18rem",
                    backgroundColor: course.color ?? undefined,
                    overflow: "hidden",
                    position: "relative",
                    margin: `${spacing.XS_2} ${spacing.XS_2} 0`,
                    borderRadius: `${borderRadius.card} ${borderRadius.card} 0 0 `,
                }}>
                {course.cover && <Image src={course.cover} alt={course.name} fill style={{ objectFit: "cover" }} />}
                <ThreeDotsMenu userId={userId} course={course} isTeacher={isTeacher} cardRef={cardRef} />
            </div>
        );
    };

    const renderMiddleSection = () => {
        return (
            <div className={"bodyBold1 clampText"} style={{ paddingInline: spacing.XS_2, marginTop: spacing.SM }}>
                {course.name}
            </div>
        );
    };

    const renderBottomSection = () => {
        const renderPill = (text: string) => {
            return (
                <FlexRowAlignCenter
                    className={"secondaryText2"}
                    style={{
                        justifyContent: "center",
                        backgroundColor: themeColors.background,
                        borderRadius: "1rem",
                        padding: `${spacing.XS} ${spacing.XS_2}`,
                    }}>
                    {text}
                </FlexRowAlignCenter>
            );
        };

        const studentsCount = course.members.filter(
            ({ pending, role }) => !pending && role === ClassRole.STUDENT
        ).length;

        const sectionsCount = course.sections.length;

        const getLastUpdated = () => {
            return timeDeltaFromNow(course.updated ? +course.updated * 1000 : 0);
        };

        return (
            <FlexColumn
                style={{
                    paddingInline: spacing.XS_2,
                    marginTop: spacing.SM,
                    rowGap: spacing.XS,
                }}>
                <FlexColumn style={{ rowGap: spacing.XS_2, alignItems: "flex-start" }}>
                    {isTeacher ? (
                        <FlexRowAlignCenter style={{ gap: spacing.XS }}>
                            {renderPill(`${studentsCount} student${studentsCount === 1 ? "" : "s"}`)}
                            {renderPill(
                                sectionsCount ? `${sectionsCount} section${sectionsCount === 1 ? "" : "s"}` : ""
                            )}
                        </FlexRowAlignCenter>
                    ) : (
                        renderPill(`${course.fileCount ?? 0} file${(course.fileCount ?? 0) === 1 ? "" : "s"}`)
                    )}
                    {renderPill(`Last updated ${getLastUpdated()} ago`)}
                </FlexColumn>
                <FlexRowAlignCenter style={{ height: "3.5rem", justifyContent: "flex-end" }}>
                    {isTeacher && (
                        <CircularRectTextButton
                            sx={{
                                columnGap: spacing.XS,
                                alignSelf: "flex-end",
                                background: themeColors.neutralWhite,
                                color: themeColors.neutralBlack,
                                height: "3.5rem",
                                padding: "0 12px",
                                "&:hover": {
                                    background: themeColors.neutralBlack,
                                    color: themeColors.neutralWhite,
                                },
                                "&:active": { transform: "scale(0.97)" },
                                transition: "all 0.2s ease",
                                border: `1px solid ${themeColors.neutralBlack}`,
                                borderRadius: "1rem",
                            }}
                            onClick={() => router.push(`/class/${course.classId}/progress`)}
                            tooltip={"View study progress"}>
                            <Image src={"/images/progress.svg"} alt={"progress"} width={16} height={16} />
                            progress
                        </CircularRectTextButton>
                    )}
                </FlexRowAlignCenter>
            </FlexColumn>
        );
    };

    return (
        <CardSkeleton
            ref={cardRef}
            role="link"
            tabIndex={0}
            className={styles.classInfoCard}
            renderTopSection={renderTopSection}
            renderMiddleSection={renderMiddleSection}
            renderBottomSection={renderBottomSection}
            style={{ padding: "0 0 24px 0", overflow: "hidden", cursor: "pointer", backgroundColor: undefined }}
            onClick={event => {
                const href = `/class/${course.classId}/dashboard`;
                const opensInNewTab = event.metaKey || event.ctrlKey;

                if (opensInNewTab) window.open(href, "_blank");
                else router.push(href);
            }}
        />
    );
};

export default ClassInfoCard;
