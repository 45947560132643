import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { FlexRowAlignCenter } from "@/components/Flex";
import { iconSizes } from "@/utils/iconProps";
import { ClassMemberSortByKeys, ClassMemberSortOrder, MemberSortSettings } from "./ClassDashboardStudentsTab";
import { themeColors } from "@/utils/themeColors";
import { TableCell, TableHead, TableRow } from "@mui/material";
import { ChevronDown, ChevronLeft, ChevronRight, LucideIcon, UserPlus } from "lucide-react";
import SectionPicker from "../../../components/SectionPicker";
import Select from "@/components/Select";
import br from "@/styles/breakpoints.module.css";
import { spacing } from "@/utils/spacing";
import { Dispatch, SetStateAction, useState } from "react";
import LazyLoaded from "@/components/LazyLoaded";
import { useClassManagementContextSelector } from "../../../hooks/useClassManagementContext";
import dynamic from "next/dynamic";
import { useRouter } from "next13-progressbar";
import { ClassSection } from "@knowt/syncing/graphql/schema";
import { copyToClipboard } from "@/utils/clipboard";
import { BASE_URL } from "@/config/deployConstants";
import clsx from "clsx";
import { isDarkColor } from "@knowt/syncing/utils/genericUtils";
import CheckBox from "@/components/styled/input/CheckBox";

const ClassCodePopup = dynamic(() => import("../../../components/ClassCodePopup"), { ssr: false });

type OptionProps = {
    label: string;
    Icon?: LucideIcon;
    onClick?: () => void;
    className?: string;
};

export const TableOptions = ({
    numOfMembers,
    hasSelection,
    selectedSection,
    onSectionChange,
    onMoveStudents,
    onRemoveStudents,
}: {
    numOfMembers: number;
    hasSelection: boolean;
    selectedSection: ClassSection | null | undefined;
    onSectionChange: Dispatch<SetStateAction<ClassSection | null | undefined>>;
    onMoveStudents: () => void;
    onRemoveStudents: () => void;
}) => {
    const router = useRouter();
    const course = useClassManagementContextSelector(state => state.course);

    const [isInvitePopupOpen, setIsInvitePopupOpen] = useState(false);

    const optionsWhenNotSelected: OptionProps[] = [
        {
            label: "Invite Students",
            Icon: UserPlus,
            onClick: () => setIsInvitePopupOpen(true),
        },
        {
            label: `Edit Sections`,
            onClick: () => router.push(`/class/${course?.classId}/settings`),
            className: br.smDownDisplayNone,
        },
    ];

    const optionsWhenSelected: OptionProps[] = [
        {
            label: "Remove Student",
            onClick: onRemoveStudents,
        },
        {
            label: "Change Section",
            onClick: onMoveStudents,
        },
    ];

    const options = hasSelection ? optionsWhenSelected : optionsWhenNotSelected;

    return (
        <FlexRowAlignCenter
            style={{
                justifyContent: "space-between",
                padding: "2.4rem 3.5rem 1.6rem 3.5rem",
                width: "100%",
                gap: "1.6rem",
            }}>
            <FlexRowAlignCenter
                style={{
                    gap: "1.6rem",
                    justifyContent: "flex-start",
                    width: "100%",
                }}>
                <SectionPicker
                    selectedSection={selectedSection}
                    onSectionChange={onSectionChange}
                    btnSx={{
                        padding: "1.2rem 2.4rem",
                        border: `2px solid ${themeColors.neutral1}`,
                        borderRadius: "2rem",
                        width: "20%",
                        minWidth: "18rem",
                        justifyContent: "space-between",
                    }}
                    adornment={<ChevronDown size={iconSizes.SM} />}
                />
                <CircularRectTextButton
                    className={clsx(br.mdDownDisplayNone, "secondaryText1")}
                    style={{
                        color: isDarkColor(course?.color) ? themeColors.pureWhite : themeColors.pureBlack,
                        backgroundColor: course?.color,
                        padding: "0.8rem 1.6rem",
                        gap: spacing.XXS,
                    }}>
                    {numOfMembers}
                    <span>student{numOfMembers === 1 ? "" : "s"}</span>
                </CircularRectTextButton>
            </FlexRowAlignCenter>
            <FlexRowAlignCenter
                style={{
                    width: "100%",
                    gap: "1.6rem",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                }}>
                {options.map(({ label, Icon, onClick, className }) => (
                    <CircularRectTextButton
                        className={className}
                        onClick={onClick}
                        tooltip={label}
                        key={label}
                        style={{
                            border: `2px solid ${themeColors.neutral1}`,
                            padding: "1.2rem 2.4rem",
                            gap: "0.8rem",
                        }}>
                        {Icon && <Icon size={iconSizes.SM} />}
                        <p className={Icon ? br.smDownDisplayNone : ""}>{label}</p>
                    </CircularRectTextButton>
                ))}

                <LazyLoaded load={isInvitePopupOpen}>
                    <ClassCodePopup
                        course={course}
                        isOpen={isInvitePopupOpen}
                        onClose={() => setIsInvitePopupOpen(false)}
                        onCopy={() => copyToClipboard(`${BASE_URL}/class/join?classId=${course.classId}`)}
                    />
                </LazyLoaded>
            </FlexRowAlignCenter>
        </FlexRowAlignCenter>
    );
};

export const TableHeader = ({
    isChecked,
    onCheck,
    onSortChange,
    currentSortSettings,
}: {
    isChecked: boolean;
    onCheck: () => void;
    onSortChange: (params: MemberSortSettings) => void;
    currentSortSettings?: MemberSortSettings;
}) => {
    const getToggleSortHandler = (sortBy: ClassMemberSortByKeys) => {
        return () =>
            onSortChange({
                sortBy,
                order:
                    currentSortSettings?.sortBy === sortBy && currentSortSettings.order === ClassMemberSortOrder.ASC
                        ? ClassMemberSortOrder.DESC
                        : ClassMemberSortOrder.ASC,
            });
    };

    const columnHeaders = [
        {
            id: "name",
            label: "Name",
            sortKey: ClassMemberSortByKeys.NAME,
            onToggleSort: getToggleSortHandler(ClassMemberSortByKeys.NAME),
        },
        {
            id: "section",
            label: "Section",
            sortKey: ClassMemberSortByKeys.SECTION,
            onToggleSort: getToggleSortHandler(ClassMemberSortByKeys.SECTION),
        },
        {
            id: "active",
            label: "Last Active",
            sortKey: ClassMemberSortByKeys.DATE,
            onToggleSort: getToggleSortHandler(ClassMemberSortByKeys.DATE),
            className: br.mdDownDisplayNone,
        },
        {
            id: "progress",
            label: "Progress",
            className: br.mdDownDisplayNone,
        },
        {
            id: "actions",
            label: "Actions",
            className: br.smDownDisplayNone,
        },
    ];

    return (
        <TableHead>
            <TableRow style={{ backgroundColor: themeColors.background }}>
                <TableCell className={br.smDownDisplayNone}>
                    <CheckBox isChecked={isChecked} onCheck={onCheck} />
                </TableCell>
                {columnHeaders.map(({ id, label, sortKey, onToggleSort, className }) => (
                    <TableCell key={id} sx={{ fontWeight: "600" }} onClick={onToggleSort} className={className}>
                        <FlexRowAlignCenter style={{ gap: "0.8rem", cursor: onToggleSort ? "pointer" : "default" }}>
                            {label}
                            {onToggleSort && (
                                <ChevronDown
                                    size={iconSizes.SM}
                                    style={{
                                        cursor: "pointer",
                                        transform: `rotate(${
                                            currentSortSettings?.sortBy === sortKey &&
                                            currentSortSettings.order === ClassMemberSortOrder.ASC
                                                ? "0"
                                                : "180deg"
                                        })`,
                                    }}
                                />
                            )}
                        </FlexRowAlignCenter>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export const TablePaginationFooter = ({
    onRowPerPageChange,
    rowPerPage,
    page,
    onPageChange,
    totalRows,
    totalPages,
}: {
    onRowPerPageChange: (row: number) => void;
    rowPerPage: number;
    page: number;
    totalRows: number;
    totalPages: number;
    onPageChange: (page: number) => void;
}) => {
    return (
        <FlexRowAlignCenter
            style={{
                padding: "2rem  3.5rem",
                width: "100%",
                justifyContent: "flex-end",
                gap: "1.6rem",
                userSelect: "none",
            }}>
            <FlexRowAlignCenter
                style={{
                    gap: "1.6rem",
                }}>
                <p className="bodyBold2">Rows per page:</p>
                <Select
                    options={[5, 10, 25, 50].map(value => ({ label: value.toString(), value }))}
                    onChange={({ value }) => onRowPerPageChange(value)}
                    menuSx={{ width: "25rem", padding: "1rem" }}
                    btnSx={{
                        gap: "1rem",
                        height: "4.8rem",
                        backgroundColor: themeColors.neutralWhite,
                        padding: "1.2rem 2.4rem",
                        border: `2px solid ${themeColors.neutral1}`,
                        borderRadius: "2rem",
                        width: "fit-content",
                        justifyContent: "space-between",
                    }}
                    selected={
                        rowPerPage ? { label: rowPerPage.toString(), value: rowPerPage } : { label: "5", value: 5 }
                    }
                    transitionOrigin={{
                        anchorOrigin: { vertical: "bottom", horizontal: "left" },
                        transformOrigin: { vertical: "top", horizontal: "left" },
                    }}
                    adornment={<ChevronDown size={iconSizes.SM} />}
                />
                <p className="bodyBold2">{`${page * rowPerPage + 1}-${rowPerPage * (page + 1)} of ${totalRows}`}</p>
                <ChevronLeft
                    size={iconSizes.SM}
                    style={{ cursor: "pointer", opacity: page === 0 ? 0.5 : 1 }}
                    onClick={page === 0 ? undefined : () => onPageChange(page - 1)}
                />
                <ChevronRight
                    size={iconSizes.SM}
                    style={{
                        cursor: "pointer",
                        opacity: page === totalPages - 1 ? 0.5 : 1,
                    }}
                    onClick={page === totalPages - 1 ? undefined : () => onPageChange(page + 1)}
                />
            </FlexRowAlignCenter>
        </FlexRowAlignCenter>
    );
};
