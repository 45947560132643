import { FlexColumn, FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import Image, { ImageProps } from "next/image";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";
import noop from "@/utils/noop";
import SlideMenu from "@/components/SlideMenu";
import { iconSizes } from "@/utils/iconProps";
import { ArrowRight, X } from "lucide-react";
import React, { Dispatch, useCallback, useRef, useState, MutableRefObject, SetStateAction, useEffect } from "react";
import { themeColors } from "@/utils/themeColors";
import { spacing } from "@/utils/spacing";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import VimeoVideo from "@/components/VimeoVideo";
import { CircularButtonProps } from "@/components/CircularButton";
import CircularOutlineIcon from "@/components/CircularButton/styled/CircularOutlineIcon";
import br from "@/styles/breakpoints.module.css";
import Dialog from "@/components/Dialog";
import clsx from "clsx";

export type SimpleSlideCardSlide =
    | {
          imageProps: ImageProps;
          vimeoId?: never;
          mediaContainerStyle?: React.CSSProperties;
          title: string;
          description: string;
          bottomSection?: React.ReactNode;
          onNextButtonProps?: CircularButtonProps & { text: string };
          preventDefaultNext?: boolean;
          disableNext?: boolean;
          onSelected?: () => void;
      }
    | {
          vimeoId: string;
          mediaContainerStyle?: React.CSSProperties;
          imageProps?: never;
          title: string;
          description: string;
          onNextButtonProps?: CircularButtonProps & { text: string };
          preventDefaultNext?: boolean;
          bottomSection?: React.ReactNode;
          disableNext?: boolean;
          onSelected?: () => void;
      };

export type SimpleSlideCardProps = {
    isOpen: boolean;
    onClose: () => void;
    slides: SimpleSlideCardSlide[];
    slideIndex?: number;
    setSlideIndex?: Dispatch<SetStateAction<number>>;
    showCloseIcon?: boolean;
};

const SlideIndicator = ({
    currentIndex,
    totalSlides,
    changeSlide,
}: {
    currentIndex: number;
    totalSlides: number;
    changeSlide: (index: number) => void;
}) => {
    return (
        <FlexRowAlignCenter
            style={{
                gap: spacing.XXS,
                justifyContent: "center",
            }}>
            {[...Array(totalSlides).keys()].map(index => (
                <div
                    key={"slide-indicator-" + index}
                    onClick={() => changeSlide(index)}
                    style={{
                        width: currentIndex === index ? "5rem" : "2rem",
                        height: "0.4rem",
                        borderRadius: "3rem",
                        backgroundColor: currentIndex === index ? themeColors.neutralBlack : themeColors.neutral2,
                        cursor: "pointer",
                        transition: "all 0.3s ease-in-out",
                    }}
                />
            ))}
        </FlexRowAlignCenter>
    );
};

const SlidingSection = ({
    slides,
    currentSlideIndex,
    swiperRef,
    setCurrentSlideIndex,
}: {
    slides: SimpleSlideCardSlide[];
    currentSlideIndex: number;
    swiperRef: MutableRefObject<null>;
    setCurrentSlideIndex: Dispatch<SetStateAction<number>>;
}) => (
    <Swiper
        ref={swiperRef}
        spaceBetween={0}
        slidesPerView={1}
        initialSlide={currentSlideIndex}
        onSlideChange={swiper => {
            if (currentSlideIndex !== swiper.activeIndex) {
                setCurrentSlideIndex(swiper.activeIndex);
            }
        }}
        style={{
            width: "100%",
        }}>
        {slides.map((slide, index) => (
            <SwiperSlide key={"slide-section-" + index}>
                <FlexColumn
                    style={{
                        padding: spacing.XS,
                        gap: "3.3rem",
                        height: "100%",
                    }}>
                    <FlexColumnAlignJustifyCenter
                        style={{
                            width: "100%",
                            margin: "auto",
                            padding: spacing.XS,
                            borderRadius: "3rem",
                            overflow: "hidden",
                            aspectRatio: "16/9",
                            maxHeight: "50vh",
                            ...slide.mediaContainerStyle,
                        }}>
                        {slide?.imageProps?.src ? (
                            <Image
                                {...slide.imageProps}
                                src={slide.imageProps.src}
                                alt={slide.imageProps.alt}
                                style={{
                                    width: "100%",
                                    height: "70%",
                                    ...slide.imageProps.style,
                                }}
                            />
                        ) : (
                            <VimeoVideo
                                id={slide.vimeoId ?? ""}
                                style={{
                                    maxHeight: "50vh",
                                    maxWidth: "100%",
                                    height: "100%",
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                                iframeStyle={{
                                    borderRadius: "3rem",
                                    width: "unset",
                                    aspectRatio: "16/9",
                                    // center since it has absolute positioning
                                    left: "50%",
                                    transform: "translateX(-50%)",
                                }}
                            />
                        )}
                    </FlexColumnAlignJustifyCenter>
                    <FlexColumn
                        style={{
                            gap: spacing.XXS,
                            textAlign: "center",
                            alignItems: "center",
                        }}>
                        <p className="heading5">{slide.title}</p>
                        <p className={clsx(br.smDownDisplayNone, "body2")} style={{ maxWidth: "70%" }}>
                            {slide.description}
                        </p>
                        <p className={clsx(br.smUpDisplayNone, "body2")} style={{ maxWidth: "98%", minHeight: "20vh" }}>
                            {slide.description}
                        </p>
                    </FlexColumn>
                    {slide.bottomSection}
                </FlexColumn>
            </SwiperSlide>
        ))}
    </Swiper>
);

const SimpleSlideCard = ({
    isOpen,
    onClose,
    slides,
    slideIndex = 0,
    setSlideIndex,
    showCloseIcon,
}: SimpleSlideCardProps) => {
    const { smDown } = useBreakPoints();

    const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(slideIndex);

    const swiperRef = useRef(null);

    const currentSlide = slides[currentSlideIndex];
    const showNextButton = (!currentSlide?.disableNext && currentSlide?.onNextButtonProps) || slides.length > 1;

    const changeSlide = useCallback((index: number) => {
        if (slides?.[index]?.onSelected) {
            slides?.[index]?.onSelected?.();
        }
        setCurrentSlideIndex(index);
        setSlideIndex?.(index);
        if (swiperRef.current) {
            return (swiperRef.current as { swiper: SwiperClass }).swiper.slideTo(index, 250, true);
        }
    }, []);

    useEffect(() => {
        changeSlide(slideIndex);
    }, [changeSlide, slideIndex]);

    const renderNextButton = () => (
        <CircularRectTextButton
            onClick={() => {
                if (!currentSlide?.preventDefaultNext) {
                    if (currentSlideIndex === slides.length - 1) {
                        onClose();
                    } else {
                        changeSlide(currentSlideIndex + 1);
                    }
                }
            }}
            sx={{
                width: "fill-content",
                minWidth: "4.8rem",
                height: "4.8rem",
                backgroundColor: "transparent",
                color: themeColors.neutralBlack,
                border: "1.5px solid",
                borderColor: themeColors.neutralBlack,
                fontSize: "1.5rem",
                fontWeight: 700,
                gap: spacing.XS,
                ...currentSlide?.onNextButtonProps?.sx,
            }}>
            {currentSlide?.onNextButtonProps && currentSlide?.onNextButtonProps?.text}
            <ArrowRight size={iconSizes.MD} />
        </CircularRectTextButton>
    );

    const renderBottomSection = () => (
        <FlexRowAlignCenter
            style={{
                width: "100%",
                paddingLeft: "3rem",
                position: "relative",
                justifyContent: "space-between",
                minHeight: "5.2rem",
            }}>
            {slides.length > 1 && (
                <SlideIndicator
                    currentIndex={currentSlideIndex}
                    totalSlides={slides.length}
                    changeSlide={changeSlide}
                />
            )}
            <FlexRowAlignCenter
                className={br.smDownDisplayNone}
                style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    gap: spacing.MD,
                }}>
                {currentSlide?.disableNext ? null : renderNextButton()}
            </FlexRowAlignCenter>
            <FlexRowAlignCenter
                style={{
                    gap: spacing.MD,
                }}
                className={br.smUpDisplayNone}>
                {currentSlide?.disableNext ? null : renderNextButton()}
            </FlexRowAlignCenter>
        </FlexRowAlignCenter>
    );

    const renderContent = () => (
        <FlexColumn
            style={{
                padding: "2rem 1rem",
                height: "100%",
                overflowX: "hidden",
                position: "relative",
                justifyContent: showNextButton ? "space-between" : "flex-start",
            }}>
            {showCloseIcon && (
                <React.Fragment>
                    <CircularOutlineIcon
                        className={br.smDownDisplayNone}
                        style={{
                            position: "absolute",
                            top: "2rem",
                            right: "1rem",
                        }}
                        onClick={onClose}
                        size={iconSizes.MD}
                        Icon={X}
                    />
                    <FlexRowAlignCenter
                        className={br.smUpDisplayNone}
                        style={{
                            width: "100%",
                            justifyContent: "flex-end",
                            paddingRight: "2rem",
                            marginBottom: "-4rem",
                            zIndex: 99,
                        }}>
                        <CircularOutlineIcon onClick={onClose} size={iconSizes.MD} Icon={X} />
                    </FlexRowAlignCenter>
                </React.Fragment>
            )}
            <FlexColumn
                style={{
                    paddingTop: showCloseIcon ? "4rem" : "0rem",
                }}>
                <SlidingSection
                    slides={slides}
                    currentSlideIndex={currentSlideIndex}
                    setCurrentSlideIndex={index => {
                        setCurrentSlideIndex(index);
                        setSlideIndex?.(index);
                    }}
                    swiperRef={swiperRef}
                />
            </FlexColumn>
            {showNextButton && renderBottomSection()}
        </FlexColumn>
    );

    const renderDesktopContent = () => (
        <Dialog
            TransitionProps={{ unmountOnExit: true }}
            open={isOpen}
            onClose={(_e, reason) => {
                if (reason && reason === "backdropClick") {
                    return;
                }
                onClose();
            }}
            disableEscapeKeyDown={true}
            maxWidth={false}
            PaperProps={{
                style: {
                    backgroundColor: themeColors.neutralWhite,
                    color: themeColors.neutralBlack,
                    width: "85%",
                    maxWidth: "80rem",
                    //account for the 2rem 1rem padding in renderContent
                    padding: "1.2rem 2.2rem",
                    borderRadius: "3rem",
                },
            }}>
            {renderContent()}
        </Dialog>
    );

    const renderMobileContent = () => (
        <SlideMenu
            isOpen={isOpen}
            onClose={onClose}
            onClickAway={noop}
            style={{
                backgroundColor: themeColors.neutralWhite,
            }}>
            {renderContent()}
        </SlideMenu>
    );

    return smDown ? renderMobileContent() : renderDesktopContent();
};

export default SimpleSlideCard;
