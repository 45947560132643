import Image from "next/image";
import { UserContentItem } from "../../../types";
import { spacing } from "@/utils/spacing";
import { sortUserContentItems } from "../../../utils";
import { themeColors } from "@/utils/themeColors";
import { borderRadius } from "@/utils/borderRadius";
import { ASSETS_URL } from "@/config/deployConstants";
import { FlashcardSet, Media, Note } from "@knowt/syncing/graphql/schema";
import { FlexColumn, FlexColumnAlignJustifyCenter } from "@/components/Flex";
import { MyFilesSearchCard } from "@/components/Popup/SearchPopup";
import { mapItemToSuggestedItem } from "@knowt/syncing/hooks/localSearchSuggestions/useLocalSearchSuggestions";
import { timeDeltaFromNow } from "@knowt/syncing/utils/dateTimeUtils";
import { useUserContentManagementContextSelector } from "@/features/UserContentManagement";

const kaiClock = `${ASSETS_URL}/images/kai-clock.svg`;

const ClassActivityCard = () => {
    const classNotes = useUserContentManagementContextSelector(state => state.classNotes);
    const classFlashcardSets = useUserContentManagementContextSelector(state => state.classFlashcardSets);
    const classMedias = useUserContentManagementContextSelector(state => state.classMedias);

    const notes = Object.values(classNotes || {}) as Note[];
    const flashcardSets = Object.values(classFlashcardSets || {}) as FlashcardSet[];
    const medias = Object.values(classMedias || {}) as Media[];

    const items = [
        ...notes.filter(note => !note.mediaId),
        ...flashcardSets.filter(set => !set.noteId && !set.mediaId),
        ...medias,
    ] as UserContentItem[];

    const sortedItems = sortUserContentItems(items, { by: "ADDED", direction: "DESCENDING" });

    const renderActivityEmptyState = () => {
        return (
            <FlexColumnAlignJustifyCenter style={{ flex: 1 }}>
                <Image
                    src={kaiClock}
                    width={162}
                    height={134}
                    alt={"Activity feed is empty"}
                    style={{ marginBottom: spacing.XS }}
                />
                <FlexColumnAlignJustifyCenter style={{ gap: spacing.XXS, maxWidth: "50%", textAlign: "center" }}>
                    <span className="secondaryTextBold1">There’s no activity in this class yet</span>
                    <span className="secondaryText1">
                        Once your teacher adds something to the class you’ll see some activity here.
                    </span>
                </FlexColumnAlignJustifyCenter>
            </FlexColumnAlignJustifyCenter>
        );
    };

    const isEmpty = !sortedItems.length;

    return (
        <FlexColumn
            style={{
                flex: 1,
                backgroundColor: themeColors.neutralWhite,
                padding: isEmpty ? spacing.SM : `${spacing.SM} ${spacing.SM} 0`,
                borderRadius: borderRadius.card,
            }}>
            <span className="bodyBold2" style={{ marginBottom: spacing.SM }}>
                Activity Feed
            </span>
            {isEmpty ? (
                renderActivityEmptyState()
            ) : (
                <FlexColumn
                    className="scrollbar-thin"
                    style={{
                        gap: spacing.XS,
                        overflowY: "scroll",
                        paddingBottom: "2rem",
                        marginRight: "-1.6rem",
                        paddingRight: spacing.SM,
                    }}>
                    {sortedItems.map((item, i: number) => (
                        <MyFilesSearchCard
                            key={i + item.title}
                            item={{
                                ...mapItemToSuggestedItem(item),
                                description: `Added ${timeDeltaFromNow(Number((item?.updated || 0).toString()))} ago`,
                            }}
                        />
                    ))}
                </FlexColumn>
            )}
        </FlexColumn>
    );
};

export default ClassActivityCard;
