import Circular from "@/components/Circular";
import { FlexColumn, FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import UserAvatar from "@/components/userCardComponents/UserAvatar";
import { themeColors } from "@/utils/themeColors";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { isDarkColor } from "@knowt/syncing/utils/genericUtils";
import Image from "next/image";
import Link from "@/components/wrappers/Link";
import { useRouter } from "next13-progressbar";
import { useEffect } from "react";

const MiscPill = ({
    children,
    ...btnProps
}: { children: React.ReactNode; disabled?: boolean } & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
    return (
        <Circular
            {...btnProps}
            className="bold"
            radius="auto"
            style={{
                height: "4rem",
                flexDirection: "row",
                backgroundColor: themeColors.background,
                color: themeColors.neutralBlack,
                fontSize: "1.1rem",
                padding: "0 1rem",
            }}>
            {children}
        </Circular>
    );
};

const HomeProfileCard = () => {
    const { user, organization } = useCurrentUser();
    const router = useRouter();

    useEffect(() => {
        // This is needed to refresh the server component (navbar) when using social sign in,
        // as the home page loads in before the user is fully finished signing in
        if (user) {
            router.refresh();
        }
    }, [user?.ID]);

    return (
        <Link
            href={organization?.dpa ? "/settings" : `/u/${user?.username}`}
            style={{
                textDecoration: "none",
                color: themeColors.neutralBlack,
                overflow: "hidden",
                backgroundColor: user?.profileColor ?? themeColors.neutralWhite,
                borderRadius: "2rem",
                width: "100%",
                position: "relative",
            }}>
            {/* USER's HEADER */}
            <FlexColumn style={{ height: "50%" }}>
                {user?.cover ? (
                    <Image
                        src={user.cover}
                        style={{ width: "100%", height: "100%", objectFit: "cover" }}
                        alt="cover image"
                        width={500}
                        height={500}
                        quality={100}
                    />
                ) : (
                    <div style={{ backgroundColor: "#EBF2DF", height: "100%" }} />
                )}
            </FlexColumn>
            <UserAvatar
                src={user?.pictureUrl as string}
                alt={user?.Name || "U"}
                radius={"10rem"}
                sx={{
                    border: `2px solid ${themeColors.card}`,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                }}
            />
            <FlexColumn
                style={{
                    height: "50%",
                    textAlign: "center",
                    color: user?.profileColor
                        ? isDarkColor(user?.profileColor)
                            ? themeColors.pureWhite
                            : "#000000"
                        : themeColors.neutralBlack,
                }}>
                <div style={{ paddingBottom: "5.7rem" }} />
                <FlexColumnAlignJustifyCenter style={{ marginBottom: "1.7rem" }}>
                    <span className="bold" style={{ fontSize: "1.5rem", marginBottom: "0.3rem" }}>
                        {user?.Name || "..."}
                    </span>
                    <span style={{ fontSize: "1rem" }}>@{user?.username || "..."}</span>
                </FlexColumnAlignJustifyCenter>
                {!organization?.dpa && (
                    <FlexRowAlignCenter
                        style={{
                            gap: "1rem",
                            margin: "0 auto",
                            alignItems: "center",
                            flexWrap: "wrap",
                            justifyContent: "center",
                            overflowY: "auto",
                            width: "100%",
                        }}>
                        <MiscPill>{user?.numFollowers || 0} Followers</MiscPill>
                        <MiscPill>{user?.numFollowing || 0} Following</MiscPill>
                    </FlexRowAlignCenter>
                )}
            </FlexColumn>
        </Link>
    );
};

export default HomeProfileCard;
