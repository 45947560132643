import StudentInformationCell from "../StudentInformationCell";
import { assertTruthy } from "@/utils/assertions";
import { formatTimeStudying, timeDeltaFromNow } from "@/utils/dateTimeUtils";
import { ASSETS_URL } from "@/config/deployConstants";
import Image from "next/image";
import { getBackgroundColor, getColor } from "@/utils/progressPill";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { borderRadius } from "@/utils/borderRadius";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { ClassMemberWithDetails, StudySessionType } from "@knowt/syncing/graphql/schema";
import { UserStudyAnalytics, getProgressBucket } from "@knowt/syncing/hooks/study/statsUtils";
import { Skeleton } from "@mui/material";
import { FlexColumn, FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import Circular from "@/components/Circular";
import br from "@/styles/breakpoints.module.css";
import HoverPopper from "@/components/HoverPopper";
import noop from "@knowt/syncing/utils/noop";
import { iconSizes } from "@/utils/iconProps";

const LoadingState = ({
    studyModesData,
}: {
    studyModesData: Record<StudySessionType, { label: string; image: string }>;
}) => (
    <TableRow>
        <TableCell
            className={br.mdDownWidth100}
            style={{
                width: "28%",
            }}>
            <FlexRowAlignCenter
                style={{
                    gap: spacing.XS_2,
                    width: "100%",
                }}>
                <Skeleton variant="circular" width={44} height={44} />
                <FlexColumn
                    style={{
                        flex: 1,
                        gap: spacing.XXS,
                    }}>
                    <Skeleton
                        style={{
                            width: "70%",
                            height: "20px",
                        }}
                    />
                    <Skeleton
                        style={{
                            width: "50%",
                            height: "20px",
                        }}
                    />
                </FlexColumn>
            </FlexRowAlignCenter>
        </TableCell>
        <TableCell
            style={{
                padding: "1.6rem 2.4rem",
            }}>
            <FlexRowAlignCenter
                style={{
                    flexWrap: "wrap",
                }}>
                {Object.entries(studyModesData).map(([mode, { label, image }]) => (
                    <FlexColumnAlignJustifyCenter
                        as="span"
                        key={mode}
                        style={{
                            display: "inline-block",
                            textAlign: "center",
                            marginRight: spacing.MD,
                        }}>
                        <Circular
                            radius="4rem"
                            style={{
                                border: `1px solid ${themeColors.neutralBlack}`,
                                position: "relative",
                                margin: "auto",
                            }}>
                            <Image
                                src={image}
                                alt={label}
                                width={iconSizes.SM}
                                height={iconSizes.SM}
                                style={{
                                    opacity: 0.5,
                                    zIndex: 0,
                                }}
                            />
                        </Circular>
                        <p
                            className="secondaryText2"
                            style={{
                                fontWeight: "400",
                            }}>
                            {label}
                        </p>
                    </FlexColumnAlignJustifyCenter>
                ))}
            </FlexRowAlignCenter>
        </TableCell>
        <TableCell
            style={{
                width: "8.9rem",
            }}>
            <Skeleton style={{ padding: "0.6rem 1rem", width: "4rem", height: "5.2rem", borderRadius: 35 }} />
        </TableCell>
    </TableRow>
);

const DEFAULT_STATS = {
    lastActive: 0,
    timeSpent: 0,
    cardsSeen: 0,
};

type TableEntryProps =
    | {
          isLoading?: never;
          studentData: ClassMemberWithDetails | undefined;
          analytics: UserStudyAnalytics | undefined;
          studyMode: StudySessionType | null;
      }
    | {
          isLoading: boolean;
          studentData?: never;
          analytics?: never;
          studyMode?: never;
      };

const studyModesData: Record<StudySessionType, { label: string; image: string }> = {
    [StudySessionType.LEARN]: {
        label: "Learn",
        image: `${ASSETS_URL}/images/brain.png`,
    },
    [StudySessionType.REVIEW]: {
        label: "Flashcards",
        image: `${ASSETS_URL}/images/flashcards-black.png`,
    },
    [StudySessionType.TEST]: {
        label: "Test",
        image: `${ASSETS_URL}/images/ExamIcon.png`,
    },
    [StudySessionType.MATCH]: {
        label: "Match",
        image: `${ASSETS_URL}/images/heart_puzzle.png`,
    },
    [StudySessionType.SPACED]: {
        label: "Spaced",
        image: `${ASSETS_URL}/images/Spaced_Repetition.png`,
    },
};

const TableEntry = ({ isLoading, studentData, analytics, studyMode }: TableEntryProps) => {
    if (isLoading) return <LoadingState studyModesData={studyModesData} />;

    assertTruthy(studentData, "studentDetails is undefined and isLoading is false");
    assertTruthy(analytics, "analytics is undefined and isLoading is false");

    const currentModeStats = (studyMode && analytics.statsPerMode?.[studyMode]) || { ...DEFAULT_STATS };
    const progressPercentage = analytics.progressPercentage ?? 0;

    return (
        <TableRow>
            <TableCell
                className={br.mdDownWidth100}
                style={{
                    padding: "1.6rem 0rem",
                    width: "30%",
                }}>
                <StudentInformationCell member={studentData} />
            </TableCell>

            {studyMode === null ? (
                <TableCell style={{ width: "100%" }} className={br.mdDownDisplayNone}>
                    {Object.entries(studyModesData).map(([mode, { label, image }]) => {
                        const { lastActive, timeSpent, cardsSeen } = analytics.statsPerMode?.[
                            mode as StudySessionType
                        ] ?? { ...DEFAULT_STATS };

                        return (
                            <HoverPopper
                                key={mode}
                                PopperChildren={
                                    <FlexColumn
                                        style={{
                                            borderRadius: borderRadius.smallModal,
                                            backgroundColor: themeColors.background,
                                            gap: spacing.XS,
                                            fontSize: "1.5rem",
                                            fontWeight: "600",
                                            padding: "16px 20px",
                                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.12)",
                                        }}>
                                        <div>Last Active: {timeDeltaFromNow(lastActive)} ago</div>
                                        <div>Time Spent: {formatTimeStudying(timeSpent)}</div>
                                        <div>Cards Seen: {cardsSeen}</div>
                                    </FlexColumn>
                                }>
                                {({ ref, onPointerEnter, onPointerLeave }) => (
                                    <FlexColumnAlignJustifyCenter
                                        as="span"
                                        ref={ref}
                                        onPointerEnter={cardsSeen > 0 ? onPointerEnter : noop}
                                        onPointerLeave={cardsSeen > 0 ? onPointerLeave : noop}
                                        style={{
                                            display: "inline-block",
                                            textAlign: "center",
                                            marginRight: spacing.MD,
                                        }}>
                                        <Circular
                                            key={mode}
                                            radius="4rem"
                                            style={{
                                                border: `1px solid ${themeColors.neutralBlack}`,
                                                backgroundColor: cardsSeen > 0 ? themeColors.neutralBlack : undefined,
                                                opacity: cardsSeen > 0 ? 1 : 0.3,
                                                position: "relative",
                                                margin: "auto",
                                            }}>
                                            <Image
                                                src={image}
                                                alt={label}
                                                width={iconSizes.SM}
                                                height={iconSizes.SM}
                                                style={{ zIndex: 0 }}
                                            />
                                        </Circular>
                                        <span
                                            className={"secondaryText2"}
                                            style={{
                                                fontWeight: cardsSeen > 0 ? "600" : "400",
                                            }}>
                                            {studyModesData[mode].label}
                                        </span>
                                    </FlexColumnAlignJustifyCenter>
                                )}
                            </HoverPopper>
                        );
                    })}
                </TableCell>
            ) : (
                <>
                    <TableCell className={br.mdDownDisplayNone}>
                        <p className="secondaryText1">{formatTimeStudying(currentModeStats.timeSpent)}</p>
                    </TableCell>
                    <TableCell style={{ width: "11rem" }} className={br.lgDownDisplayNone}>
                        {currentModeStats.lastActive
                            ? `${timeDeltaFromNow(currentModeStats.lastActive)} ago`
                            : "Not Started"}
                    </TableCell>
                    <TableCell className={br.mdDownDisplayNone}>{currentModeStats.cardsSeen}</TableCell>
                </>
            )}
            <TableCell
                style={{
                    padding: "1.6rem 0rem",
                }}>
                <div
                    className={"secondaryText1"}
                    style={{
                        color: getColor(getProgressBucket(progressPercentage)),
                        backgroundColor: getBackgroundColor(getProgressBucket(progressPercentage)),
                        padding: "0.6rem 1rem",
                        width: "fit-content",
                        borderRadius: 20,
                    }}>
                    {progressPercentage}%
                </div>
            </TableCell>
        </TableRow>
    );
};

export default TableEntry;
