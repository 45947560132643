import CircularButton from "@/components/CircularButton";
import { themeColors } from "@/utils/themeColors";
import { ArrowDown, ArrowUp } from "lucide-react";
import { UserContentSortSettings } from "../../types";
import Select from "@/components/Select";
import { iconSizes } from "@/utils/iconProps";
import { FlexRowAlignCenter } from "@/components/Flex";

const sortOptions: { label: string; value: UserContentSortSettings["by"] }[] = [
    { label: "Created", value: "CREATED" },
    { label: "Name", value: "NAME" },
    { label: "Last Updated", value: "LAST_UPDATED" },
];

const SortButton = ({
    onChange,
    by,
    direction,
}: {
    onChange: (value: { by: UserContentSortSettings["by"]; direction: UserContentSortSettings["direction"] }) => void;
    by: UserContentSortSettings["by"];
    direction: UserContentSortSettings["direction"];
}) => {
    return (
        <FlexRowAlignCenter style={{ gap: "0.2rem" }}>
            <Select
                options={sortOptions}
                onChange={option => onChange({ by: option.value, direction })}
                menuSx={{ width: "15rem" }}
                btnSx={{
                    padding: 0,
                    width: "fit-content",
                    height: "4.4rem",
                    borderRadius: "0.5rem",
                    "&:hover, &:active": { boxShadow: "inset 0 0 0 10em rgba(0, 0, 0, 0.05)" },
                }}
                selected={sortOptions.find(option => option.value === by) ?? sortOptions[2]}
                adornment={null}
            />
            <CircularButton
                radius="3.6rem"
                sx={{
                    backgroundColor: themeColors.background,
                    "&:hover, &:active": { boxShadow: "inset 0 0 0 10em rgba(0, 0, 0, 0.05)" },
                }}
                onClick={() => onChange({ by, direction: direction === "DESCENDING" ? "ASCENDING" : "DESCENDING" })}>
                {direction === "DESCENDING" ? <ArrowDown size={iconSizes.MD} /> : <ArrowUp size={iconSizes.MD} />}
            </CircularButton>
        </FlexRowAlignCenter>
    );
};

export default SortButton;
