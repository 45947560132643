import { AnimatedTrashButton } from "@/components/TrashButton/TrashButton";
import { deleteFlashcardSets, trashFlashcardSets } from "@knowt/syncing/hooks/flashcards/utils";
import { deleteFolders, trashFolders } from "@knowt/syncing/hooks/folders/utils";
import { deleteMedias, trashMedias } from "@knowt/syncing/hooks/media/utils";
import { deleteNotes, trashNotes } from "@knowt/syncing/hooks/notes/utils";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import React from "react";
import { toast } from "react-hot-toast";
import { useUserContentManagementContextSelector } from "../../hooks/useUserContentManagementContext";
import { isFlashcardSet, isFolder, isMedia, isNote } from "@knowt/syncing/utils/dataCleaning";
import { pluralize } from "@/utils/stringUtils";

const UserContentTrashButton = ({ inTrashTab: inTrash }: { inTrashTab: boolean }) => {
    const { userId } = useCurrentUser() as { userId: string };

    const selectedItems = useUserContentManagementContextSelector(state => state.selectedItems);
    const setSelectedItems = useUserContentManagementContextSelector(state => state.setSelectedItems);
    const setIsSelectMode = useUserContentManagementContextSelector(state => state.setIsSelectMode);

    const selectedItemCount = Object.keys(selectedItems).length;

    const deleteSelectedItems = async () => {
        const selectedItemArr = Object.values(selectedItems);

        const noteIds: string[] = [];
        const folderIds: string[] = [];
        const flashcardSetIds: string[] = [];
        const mediaIds: string[] = [];

        selectedItemArr.forEach(item => {
            if (isNote(item)) {
                return noteIds.push(item.noteId);
            } else if (isFolder(item)) {
                return folderIds.push(item.folderId);
            } else if (isFlashcardSet(item)) {
                return flashcardSetIds.push(item.flashcardSetId);
            } else if (isMedia(item)) {
                return mediaIds.push(item.mediaId);
            }

            throw new Error("Unknown item type");
        });

        try {
            if (inTrash) {
                await Promise.all([
                    deleteNotes({ noteIds, userId }),
                    deleteFlashcardSets({ flashcardSetIds, userId }),
                    deleteFolders({ folderIds, userId }),
                    deleteMedias({ mediaIds, userId }),
                ]);
            } else {
                await Promise.all([
                    trashNotes({ noteIds, userId, sourceFolderId: null }),
                    trashFlashcardSets({ flashcardSetIds, userId, sourceFolderId: null }),
                    trashMedias({ mediaIds, userId, sourceFolderId: null }),
                    trashFolders({ folderIds, userId }),
                ]);
            }

            // we have animation during the deletion, so wait for it to finish
            setTimeout(() => {
                setSelectedItems({});
                setIsSelectMode(false);
            }, 1000);
        } catch (e) {
            const verb = inTrash ? "delete" : "trash";
            toast.error(`Failed to ${verb} ${selectedItemCount} ${pluralize("item", selectedItemCount)}`);
            toast.error(e);
        }
    };

    return (
        <AnimatedTrashButton
            width={"18.4rem"}
            text={inTrash ? "delete" : "move to trash"}
            confirmDialogProps={{
                title: `Are you sure you want to ${inTrash ? "delete" : "trash"} ${
                    selectedItemCount > 1 ? "these" : "this"
                }?`,
                subtitle: inTrash
                    ? `${
                          selectedItemCount > 1 ? "These items" : "This item"
                      } will not be able to be recovered if you continue to delete them.`
                    : "You'll need to visit your trash to recover these items, open them, or if you want to delete them forever.",
                buttonText: inTrash ? "confirm" : "delete",
            }}
            selectedCount={selectedItemCount}
            onDelete={selectedItemCount >= 1 ? deleteSelectedItems : null}
            onAnimationFinished={() => null}
        />
    );
};

export default UserContentTrashButton;
