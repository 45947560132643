import { themeColors } from "@/utils/themeColors";
import { UserContentItemType } from "../../types";
import { FlexColumn, FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import { iconSizes } from "@/utils/iconProps";
import WithOptionsMenu from "@/components/WithOptionsMenu";
import CircularFilledIcon from "@/components/CircularButton/styled/CircularFilledIcon";
import Checkbox from "@mui/material/Checkbox";
import { toggleArrayElements } from "@knowt/syncing/utils/arrayUtils";
import Radio from "@mui/material/Radio";
import { Filter } from "lucide-react";
import { objectWithout } from "@knowt/syncing/utils/dataCleaning";

type FilterButtonProps = {
    allItemTypes: UserContentItemType[];
    inSavedTab?: boolean;
    selectedItemTypes: UserContentItemType[];
    onChange: ({ selectedItemTypes }: { selectedItemTypes: UserContentItemType[] }) => void;
};

const ITEM_LABEL: Record<UserContentItemType, string> = {
    FLASHCARD_SET: "Flashcards",
    NOTE: "Notes",
    VIDEO: "Videos",
    PDF: "PDFs",
    POWERPOINT: "PowerPoint",
    EXCEL: "Excel",
    PINNED_ONLY: "Pinned Only",
};

const ITEM_LABEL_WITHOUT_SAVED = objectWithout(ITEM_LABEL, "PINNED_ONLY");

const isAllSelected = (currSelected: UserContentItemType[]) => {
    return Object.keys(ITEM_LABEL_WITHOUT_SAVED).every(key => currSelected.includes(key as UserContentItemType));
};

const FilterButton = ({ allItemTypes: _allItemTypes, selectedItemTypes, onChange, inSavedTab }: FilterButtonProps) => {
    const allItemTypes = _allItemTypes.slice(0, -1);

    const allSelected = isAllSelected(selectedItemTypes);

    const options = allItemTypes
        .map(type => ({ value: type, label: ITEM_LABEL[type] }))
        .map(({ value, label }, index) => {
            const isLastOption = index === allItemTypes.length - 1;

            return {
                label: "Flashcards",
                node: (
                    <FlexRowAlignCenter style={{ columnGap: "1rem" }}>
                        <Checkbox
                            disableRipple
                            checked={selectedItemTypes.includes(value)}
                            inputProps={{ "aria-label": label }}
                            sx={{
                                color: themeColors.neutralBlack,
                                padding: 0,
                                "&.Mui-checked": {
                                    color: themeColors.neutralBlack,
                                },
                                "& .MuiSvgIcon-root": {
                                    fontSize: iconSizes.MD,
                                },
                            }}
                        />
                        {label}
                    </FlexRowAlignCenter>
                ),
                onClick: () => onChange({ selectedItemTypes: toggleArrayElements(selectedItemTypes, [value]) }),
                menuItemProps: {
                    sx: {
                        ...(isLastOption && {
                            marginBottom: "-8px", // menu's padding bottom
                        }),
                    },
                },
            };
        });

    // TODO: this is hacky, find a way to make a multi option menu
    const renderFilterByPinned = () => {
        return (
            <div style={{ padding: "1rem", width: "100%" }}>
                <p
                    style={{
                        paddingBlock: "0rem 1.2rem",
                        paddingInline: "0 1rem",
                        fontWeight: "600",
                        color: themeColors.neutralBlack,
                    }}>
                    Filter by pinned
                </p>
                <FlexColumn style={{ color: themeColors.neutralBlack, gap: "1rem", cursor: "pointer" }}>
                    <FlexRowAlignCenter
                        style={{ columnGap: "1rem" }}
                        onClick={() => {
                            const newSelected = selectedItemTypes.filter(type => type !== "PINNED_ONLY");
                            onChange({ selectedItemTypes: newSelected });
                        }}>
                        <Radio
                            disableRipple
                            checked={!selectedItemTypes.includes("PINNED_ONLY")}
                            inputProps={{ "aria-label": "All items" }}
                            sx={{
                                color: themeColors.neutralBlack,
                                padding: 0,
                                "&.Mui-checked": {
                                    color: themeColors.neutralBlack,
                                },
                                "& .MuiSvgIcon-root": {
                                    fontSize: iconSizes.MD,
                                },
                            }}
                        />
                        All items
                    </FlexRowAlignCenter>
                    <FlexRowAlignCenter
                        style={{ columnGap: "1rem" }}
                        onClick={() => {
                            const newSelected = [
                                ...new Set([...selectedItemTypes, "PINNED_ONLY"]),
                            ] as UserContentItemType[];

                            onChange({ selectedItemTypes: newSelected });
                        }}>
                        <Radio
                            disableRipple
                            checked={selectedItemTypes.includes("PINNED_ONLY")}
                            inputProps={{ "aria-label": "Filter by pinned" }}
                            sx={{
                                color: themeColors.neutralBlack,
                                padding: 0,
                                "&.Mui-checked": { color: themeColors.neutralBlack },
                                "& .MuiSvgIcon-root": { fontSize: iconSizes.MD },
                            }}
                        />
                        Filter by pinned
                    </FlexRowAlignCenter>
                </FlexColumn>
            </div>
        );
    };

    return (
        <WithOptionsMenu
            menuItemProps={{ sx: { padding: "0.8rem" } }}
            menuProps={{
                PaperProps: {
                    sx: {
                        marginTop: "1rem",
                        borderRadius: "1rem",
                        backgroundColor: themeColors.background,
                        fontSize: "1.4rem",
                        width: "21rem",
                    },
                },
            }}
            header={
                <FlexColumnAlignJustifyCenter>
                    {!inSavedTab && renderFilterByPinned()}
                    <FlexRowAlignCenter style={{ justifyContent: "space-between", width: "100%" }}>
                        <p
                            style={{
                                paddingBlock: "1rem 1.2rem",
                                paddingInline: "1rem",
                                fontWeight: "600",
                                color: themeColors.neutralBlack,
                            }}>
                            Filter by file type
                        </p>
                        <p
                            style={{
                                paddingRight: "1rem",
                                paddingInline: "1rem",
                                fontWeight: "600",
                                color: allSelected ? themeColors.errorPrimary : themeColors.primary,
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                onChange({ selectedItemTypes: allSelected ? [] : allItemTypes });
                            }}>
                            {allSelected ? "none" : "all"}
                        </p>
                    </FlexRowAlignCenter>
                </FlexColumnAlignJustifyCenter>
            }
            options={options}
            preventClosingMenuOnOptionClick={true}>
            {({ openMenu }) => (
                <CircularFilledIcon
                    onClick={openMenu}
                    Icon={Filter}
                    size={iconSizes.MD}
                    radius="5rem"
                    tooltip="Filter by types"
                />
            )}
        </WithOptionsMenu>
    );
};

export default FilterButton;
