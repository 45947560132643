"use client";

import { useRecentlyStudied } from "@knowt/syncing/hooks/study/useStudySession";
import { getFirstActiveStudyMode } from "@knowt/syncing/hooks/study/utils";
import { getProgressValue } from "@knowt/syncing/hooks/study/statsUtils";
import { useRecentlyViewed } from "@knowt/syncing/hooks/recentlyViewed/useRecentlyViewed";
import { StudySession } from "@knowt/syncing/graphql/schema";
import { UNTITLED } from "@knowt/syncing/utils/dataCleaning";
import { timeDeltaFromNow } from "@knowt/syncing/utils/dateTimeUtils";
import { Jelly } from "@uiball/loaders";
import clsx from "clsx";
import { useRouter } from "next13-progressbar";
import Image from "next/image";
import { useEffect, useState } from "react";
import styles from "../homepage.module.css";
import { themeColors } from "@/utils/themeColors";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import CircularButton from "@/components/CircularButton";
import TabSwitcher from "@/components/TabSwitcher";
import { FlexColumn, FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import CircularProgress from "@/components/CircularProgress";
import { ASSETS_URL } from "@/config/deployConstants";
import Mixpanel from "@/utils/analytics/Mixpanel";
import { getImgFromItemType } from "../../utils";
import { MyFilesSearchCard } from "@/components/Popup/SearchPopup";

export const ResumeStudyCard = ({
    item,
    onButtonClick,
    button,
}: {
    item: StudySession;
    onButtonClick: () => void;
    button: JSX.Element;
}) => {
    const router = useRouter();
    const type = item.type;

    return (
        <CircularRectTextButton
            component={"div"}
            role="button"
            onClick={e => {
                e.stopPropagation();
                router.push(`/flashcards/${item.itemId}`);
            }}
            style={{
                padding: "1rem 1.5rem",
                backgroundColor: themeColors.background,
                justifyContent: "space-between",
                borderRadius: "2rem",
                color: "#000000",
                gap: "0.8rem",
            }}>
            <FlexRowAlignCenter style={{ gap: "1.5rem", textAlign: "start" }}>
                <Image
                    src={getImgFromItemType(type)}
                    width="24"
                    height="24"
                    style={{ objectFit: "contain" }}
                    alt={"resume study"}
                />
                <FlexColumn>
                    <span
                        className="bodyBold2"
                        style={{
                            overflowWrap: "anywhere",
                            display: "-webkit-box",
                            WebkitLineClamp: "1",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}>
                        {item.title || UNTITLED}
                    </span>
                    {/* recently viewed data contains the time  */}
                    <span className="secondaryText2" style={{ overflowWrap: "anywhere" }}>
                        {timeDeltaFromNow(Number(item.updated))} ago
                    </span>
                </FlexColumn>
            </FlexRowAlignCenter>
            <FlexRowAlignCenter style={{ gap: "0.8rem", minWidth: "fit-content" }}>
                <CircularRectTextButton
                    onClick={e => {
                        e.stopPropagation();
                        onButtonClick();
                    }}
                    sx={{
                        backgroundColor: themeColors.neutralWhite,
                        color: themeColors.neutralBlack,
                        height: "3.6rem",
                        padding: "0 1.2rem",
                        gap: "0.5rem",
                        width: "auto",
                        justifyContent: "space-between",
                        ":hover": { backgroundColor: themeColors.neutralBlack, color: themeColors.neutralWhite },
                        transition: "all 0.2s ease-in-out",
                    }}>
                    {button}
                </CircularRectTextButton>
            </FlexRowAlignCenter>
        </CircularRectTextButton>
    );
};

const EmptyState = () => {
    return (
        <FlexColumnAlignJustifyCenter
            style={{ gap: "1rem", flex: 1, margin: "4rem auto 0" }}
            onClick={() => {
                // no point in making a new instance of the search bar popup for this
                const searchBar = document.getElementById("main-search-bar");
                if (searchBar) searchBar.click();
            }}>
            <div style={{ position: "relative" }}>
                <CircularButton style={{ backgroundColor: "#F6EEE2" }} radius={"10.3rem"}>
                    <Image src={`${ASSETS_URL}/images/search-icon.png`} alt={"search"} width={"45"} height={"45"} />
                </CircularButton>
            </div>
            <span className="bold" style={{ fontSize: "1.3" }}>
                Browse over 1.5 million notes & flashcards
            </span>
            <span className={styles.centerOnMobile} style={{ fontSize: "1.2rem" }}>
                Search an exam, or keyword and find what you’re looking for.
            </span>
        </FlexColumnAlignJustifyCenter>
    );
};

const StudyEncouragementCard = () => {
    const router = useRouter();
    const [tab, setTab] = useState(0);

    const [fetchRecentlyViewed, setFetchRecentlyViewed] = useState(false);

    useEffect(() => {
        // if we are on the recently viewed tab, we want to fetch the data, and keep it fetched
        if (tab === 1) {
            setFetchRecentlyViewed(true);
        }
    }, [tab]);

    const { data: recentlyViewed } = useRecentlyViewed({ isEnabled: fetchRecentlyViewed });
    const { recentlyStudied, isLoading } = useRecentlyStudied();

    const getStudyButton = (item: StudySession) => {
        const progressValue = getProgressValue(item.progress);
        const progressStyle = { progressBgColor: "#f6cb8a", progressColor: "#ed9c1d" };

        return (
            <>
                <CircularProgress
                    value={progressValue}
                    size={"2rem"}
                    thickness={8}
                    bgColor={progressStyle.progressBgColor}
                    color={progressStyle.progressColor}
                    disableAnimation
                />
                <div
                    style={{
                        lineHeight: "1.2rem",
                    }}>
                    <span>resume</span>
                </div>
            </>
        );
    };

    const renderContent = () => {
        const maybeGetItemList = () => {
            if (tab === 0 && recentlyStudied?.length) {
                return recentlyStudied?.slice(0, 10).map((item: StudySession, i) => (
                    <ResumeStudyCard
                        key={i}
                        item={item}
                        onButtonClick={() => {
                            Mixpanel.track("Recent Card - Clicked", {
                                tab: "Recently Studied",
                                type: item.type,
                                to: "Study Mode",
                            });

                            router.push(
                                `/study/flashcards/${item.itemId}/${getFirstActiveStudyMode(item)?.toLowerCase()}`
                            );
                        }}
                        button={getStudyButton(item)}
                    />
                ));
            }

            if (tab === 1 && recentlyViewed?.length) {
                return recentlyViewed?.slice(0, 10).map((item, i) => <MyFilesSearchCard key={i} item={item} />);
            }

            return <EmptyState />;
        };

        return (
            <FlexColumn
                className={clsx("scrollbar-thin", styles.recentlyStudied)}
                style={{
                    gap: "0.8rem",
                    overflowY: "auto",
                    overflowX: "hidden",
                    marginBottom: "-1.5rem",
                    marginRight: "-2.1rem",
                    paddingRight: "2.1rem",
                    paddingBottom: "1rem",
                    minHeight: "calc(100% - 10rem)",
                }}>
                {isLoading ? (
                    <div style={{ margin: "auto" }}>
                        <Jelly size={50} color={themeColors.neutralBlack} />
                    </div>
                ) : (
                    maybeGetItemList()
                )}
            </FlexColumn>
        );
    };

    return (
        <FlexColumn
            style={{
                padding: "1.5rem 2.1rem",
                backgroundColor: themeColors.card,
                borderRadius: "2rem",
                width: "100%",
            }}>
            <FlexRowAlignCenter style={{ justifyContent: "space-between", marginBottom: "1.6rem" }}>
                <TabSwitcher
                    tabs={[{ label: "Resume Studying" }, { label: "Recently Viewed" }]}
                    selectedTab={tab}
                    setSelectedTab={i => setTab(i)}
                    width={"13.5rem"}
                    height="4.2rem"
                    fontSize={"1.2rem"}
                    alternate
                />
            </FlexRowAlignCenter>

            {/* CONTENT */}
            {renderContent()}
        </FlexColumn>
    );
};

export default StudyEncouragementCard;
