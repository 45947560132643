import { SimplePopupCardContent } from "@/components/Popup/PopupCards/SimplePopupCard";
import { SimpleSlideCardSlide } from "@/components/Popup/PopupCards/SimpleSlideCard";
import { ASSETS_URL } from "@/config/deployConstants";

export const useWalkThroughContent = (eventName: string | boolean) => {
    if (!eventName) {
        return null;
    }

    const content: {
        [key: string]: SimplePopupCardContent;
    } = {};

    return content[typeof eventName === "string" ? eventName : ""];
};

export const getUpdateContent = (eventName: string | boolean) => {
    if (!eventName) {
        return null;
    }

    const updates: { [key: string]: { content: SimpleSlideCardSlide[] } } = {
        sept2023: {
            content: [
                {
                    vimeoId: "889354324",
                    title: "The fastest way to watch your lecture videos is here!",
                    description:
                        "You can now upload any video on Knowt & get flashcards,  notes and practice tests made from it in one click!",
                },
                {
                    imageProps: {
                        src: `${ASSETS_URL}/images/kai-waving-upgrade-popup.svg`,
                        alt: "kai-waving",
                        height: "100",
                        width: "100",
                        style: {
                            objectFit: "contain",
                            width: "auto",
                            height: "100%",
                            maxHeight: "50vh",
                            borderRadius: "3rem",
                        },
                    },
                    mediaContainerStyle: {
                        padding: "0.8rem 0",
                        borderRadius: "3rem",
                    },
                    title: "Say hi to Kai - our new spirit animal! ",
                    description:
                        "It's time for you to meet the newest & cutest member of the Knowt family! He’ll help you study faster & ace your classes.",
                },
                {
                    vimeoId: "889354311",
                    title: "Create Practice Tests directly from your class material",
                    description:
                        "We'll create  practice questions from your notes, videos and files to make sure you know the important stuff for test day.",
                },
                {
                    vimeoId: "889354245",
                    title: "Knowt just got a MAJOR makeover",
                    description:
                        "We've redesigned it from top to bottom. Things on Knowt are more intuitive, easy to see, and a whole lot more fun!",
                },
                // copy of chx event
                {
                    imageProps: {
                        src: `${ASSETS_URL}/images/chx-upgrade-popup.svg.svg`,
                        alt: "kai-waving",
                        height: "839",
                        width: "472",
                        style: {
                            objectFit: "contain",
                            width: "auto",
                            height: "100%",
                            maxHeight: "50vh",
                            borderRadius: "3rem",
                        },
                    },
                    mediaContainerStyle: {
                        padding: "0.8rem 0",
                        borderRadius: "3rem",
                    },
                    title: "Use our chrome extension to import flashcards in seconds",
                    description:
                        "You can now go to that “other flashcard site” and bring all your sets over to Knowt. It only takes seconds.",
                },
            ],
        },
        chx: {
            content: [
                {
                    imageProps: {
                        src: `${ASSETS_URL}/images/chx-upgrade-popup.svg.svg`,
                        alt: "kai-waving",
                        height: "839",
                        width: "472",
                        style: {
                            objectFit: "contain",
                            width: "auto",
                            height: "100%",
                            maxHeight: "50vh",
                            borderRadius: "3rem",
                        },
                    },
                    mediaContainerStyle: {
                        padding: "0.8rem 0",
                        borderRadius: "3rem",
                    },
                    title: "Use our chrome extension to import flashcards in seconds",
                    description:
                        "You can now go to that “other flashcard site” and bring all your sets over to Knowt. It only takes seconds.",
                },
            ],
        },
    };

    return updates[typeof eventName === "string" ? eventName : ""];
};
