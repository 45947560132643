import Image from "next/image";
import noop from "@knowt/syncing/utils/noop";
import { FlexRowAlignCenter } from "@/components/Flex";
import { Class, Folder } from "@knowt/syncing/graphql/schema";
import { useClass } from "@knowt/syncing/hooks/classes/useClass";
import { useFolderAncestors } from "@knowt/syncing/hooks/folders/useFolders";
import FolderIconBtn from "@/components/cards/FolderCard/components/FolderIconBtn";
import { iconSizes } from "@/utils/iconProps";
import { SlugPillBordered } from "./styled/SlugPills";
import { SlugSeparatorChevron } from "./styled/SlugSeparator";

type Props = {
    folderId: string | null | undefined;
    classId: string | null | undefined;
    onHomeClick: () => void;
    onFolderClick?: (folder: Folder) => void;
    onClassClick?: (course: Class) => void;
};

const MoveToPopupBreadCrumb = ({ folderId, classId, onHomeClick, onFolderClick, onClassClick }: Props) => {
    const { ancestors } = useFolderAncestors({ folderId });
    const { course } = useClass({ classId });

    return (
        <FlexRowAlignCenter style={{ maxWidth: "100%", overflowX: "auto" }}>
            <HomeTitleItem onHomeClick={onHomeClick} />
            {course && (
                <>
                    <SlugSeparatorChevron />
                    <ClassTitleItem key={classId} course={course} onClassClick={onClassClick} />
                </>
            )}
            {ancestors?.map(folder => (
                <>
                    <SlugSeparatorChevron />
                    <FolderTitleItem key={folder.folderId} folder={folder} onFolderClick={onFolderClick} />
                </>
            ))}
        </FlexRowAlignCenter>
    );
};

export const HomeTitleItem = ({ onHomeClick = noop }: { onHomeClick?: () => void }) => {
    return (
        <SlugPillBordered onClick={onHomeClick}>
            {/* TODO: find good icon */}
            Home
        </SlugPillBordered>
    );
};

export const FolderTitleItem = ({
    folder,
    onFolderClick = noop,
}: {
    folder: Folder;
    onFolderClick?: (folder: Folder) => void;
}) => {
    if (!folder) return null;

    return (
        <SlugPillBordered onClick={() => onFolderClick(folder)}>
            <FolderIconBtn folder={folder} />
            {folder.name}
        </SlugPillBordered>
    );
};

export const ClassTitleItem = ({
    course,
    onClassClick = noop,
}: {
    course: Class;
    onClassClick?: (course: Class) => void;
}) => {
    if (!course) return null;

    return (
        <SlugPillBordered onClick={() => onClassClick(course)}>
            <Image src={`/images/class-icon.svg`} width={iconSizes.SM} height={iconSizes.SM} alt="class icon" />
            {course.name}
        </SlugPillBordered>
    );
};

export default MoveToPopupBreadCrumb;
