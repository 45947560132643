"use client";

import { useFolderMedias } from "@/hooks/media/useMedia";
import { useFolderFlashcardSets } from "@knowt/syncing/hooks/flashcards/useFlashcards";
import { useNestedFolders } from "@knowt/syncing/hooks/folders/useFolders";
import { useFolderNotes } from "@knowt/syncing/hooks/notes/useNotes";
import { LocalUser } from "@knowt/syncing/hooks/user/types";
import { FlashcardSet, Folder, Media, Note, UserDetails, Bookmark } from "@knowt/syncing/graphql/schema";
import { useMemo } from "react";
import { InnerContainer, MainContainer } from "@/components/Containers";
import DroppableFolderTitle from "./components/DroppableFolderTitle";
import { MainContainerAdSlot } from "@/features/Ads";
import { FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import FolderControlSection from "./components/FolderControlSection";
import {
    FolderManagementContextProvider,
    useFolderManagementContextSelector,
} from "./hooks/useFolderManagementContext";
import { HomeOrFolderTabEmptyState } from "../components/EmptyStateComponents";
import {
    DraggableCardHandler,
    DroppableCardHandler,
    UserContentManagementMoveToHandler,
} from "../components/ContentDndHandlers";
import UserContentManagementPlusButton from "../components/UserContentManagementPlusButton";
import { ContentsGrid } from "../components/UserContentManagementGrid";
import { filterUserContentItems, sortHomeFolder, sortPinnedItemsToTop, sortUserContentItems } from "../utils";
import LoadingAnimation from "@/components/LoadingAnimation";
import { MoveToPopupProvider } from "features/MoveToPopup";
import FolderCard from "@/components/cards/FolderCard";
import UserContentCard from "@/components/cards/UserContentCard";
import { spacing } from "@/utils/spacing";
import { useAllBookmarks } from "@knowt/syncing/hooks/bookmarks/useBookmarks";
import br from "@/styles/breakpoints.module.css";
import { UserContentItem, UserContentSortSettings } from "../types";
import { isFlashcardSet, isNote } from "@knowt/syncing/utils/dataCleaning";
import { SWRConfig, unstable_serialize } from "swr";
import { useUserContentManagementContextSelector } from "@/features/UserContentManagement";

const FolderContent = () => {
    const sort = useUserContentManagementContextSelector(state => state.sort);
    const itemTypesToShow = useUserContentManagementContextSelector(state => state.itemTypesToShow);
    const isSelectMode = useUserContentManagementContextSelector(state => state.isSelectMode);
    const toggleSelectedItem = useUserContentManagementContextSelector(state => state.toggleSelectedItem);
    const selectedItems = useUserContentManagementContextSelector(state => state.selectedItems);
    const serverFolders = useUserContentManagementContextSelector(state => state.serverFolders);
    const serverFlashcardSets = useUserContentManagementContextSelector(state => state.serverFlashcardSets);
    const serverNotes = useUserContentManagementContextSelector(state => state.serverNotes);
    const serverMedias = useUserContentManagementContextSelector(state => state.serverMedias);

    const folderId = useFolderManagementContextSelector(state => state.folderId);
    const folder = useFolderManagementContextSelector(state => state.folder);

    const { folders: nestedFolders } = useNestedFolders({
        parentId: folderId,
        inTrash: folder.trash || undefined,
        fallbackData: serverFolders,
    });

    const folders = useMemo(() => {
        const foldersArr = Object.values(nestedFolders || {});
        return sortHomeFolder(foldersArr, sort);
    }, [nestedFolders, sort]);

    const { bookmarks } = useAllBookmarks();

    const { folderNotes, isLoading: isLoadingNote } = useFolderNotes({
        folderId,
        inTrash: folder.trash || undefined,
        fallbackData: serverNotes,
    });

    const { folderFlashcardSets, isLoading: isLoadingFlashcardSet } = useFolderFlashcardSets({
        folderId,
        inTrash: folder.trash || undefined,
        fallbackData: serverFlashcardSets,
    });

    const { folderMedias: folderMediasObj } = useFolderMedias({
        folderId,
        inTrash: folder.trash || undefined,
        fallbackData: serverMedias,
    });

    const isLoading = isLoadingNote || isLoadingFlashcardSet || !folderMediasObj;

    const sortedAndFilteredItems = useMemo(() => {
        if (isLoading) return [];

        const notes = Object.values(folderNotes || {}) as Note[];
        const flashcardSets = Object.values(folderFlashcardSets || {}) as FlashcardSet[];
        const medias = Object.values(folderMediasObj || {}) as Media[];

        const filteredItems = filterUserContentItems(
            [...notes, ...flashcardSets, ...medias].map(item => {
                const id = isNote(item) ? item.noteId : isFlashcardSet(item) ? item.flashcardSetId : item.mediaId;
                return { ...item, isBookmarked: !!bookmarks?.[id] };
            }),
            itemTypesToShow
        );

        const sortedItems = sortUserContentItems(filteredItems, sort) as UserContentItem[];

        return sortPinnedItemsToTop(sortedItems, Object.keys(bookmarks || {}));
    }, [folderMediasObj, folderNotes, folderFlashcardSets, isLoading, sort, itemTypesToShow, bookmarks]);

    if (isLoading) {
        return <LoadingAnimation style={{ width: "100%", margin: "auto", flex: 1 }} />;
    }

    const renderFolderGrid = () => {
        return (
            <ContentsGrid label={"Folders"} itemMinWidth={"30.8rem"} style={{ marginBottom: "3.2rem" }}>
                {folders.map(folder => {
                    const folderId = folder.folderId;
                    return (
                        <DroppableCardHandler key={folder.folderId} id={folder.folderId}>
                            {({ isOver }) => (
                                <DraggableCardHandler id={folder.folderId} item={folder}>
                                    <FolderCard
                                        folder={folder}
                                        modeProps={{
                                            mode: isSelectMode ? "SELECT" : null,
                                            isSelected: folderId in selectedItems,
                                            toggleSelect: () => toggleSelectedItem(folderId, folder),
                                        }}
                                        style={{ ...(isOver ? { backgroundColor: "#50d2c2" } : {}) }}
                                    />
                                </DraggableCardHandler>
                            )}
                        </DroppableCardHandler>
                    );
                })}
            </ContentsGrid>
        );
    };

    const renderNoteAndFlashcardGrid = () => {
        return (
            <ContentsGrid label={"Your Files"} itemMinWidth={"28rem"} style={{ paddingBottom: "13rem" }}>
                {sortedAndFilteredItems.map((item, i) => {
                    const itemId = isNote(item)
                        ? item.noteId
                        : isFlashcardSet(item)
                        ? item.flashcardSetId
                        : item.mediaId;

                    return (
                        <DraggableCardHandler key={"homeItem" + itemId + i} id={itemId} item={item}>
                            <UserContentCard
                                {...(isNote(item)
                                    ? { note: item }
                                    : isFlashcardSet(item)
                                    ? { flashcardSet: item }
                                    : { media: item })}
                                modeProps={{
                                    mode: isSelectMode ? "SELECT" : null,
                                    isSelected: itemId in selectedItems,
                                    toggleSelect: toggleSelectedItem,
                                }}
                            />
                        </DraggableCardHandler>
                    );
                })}
            </ContentsGrid>
        );
    };

    if (!isLoading && !folders.length && !sortedAndFilteredItems.length) {
        return (
            <FlexColumnAlignJustifyCenter style={{ flex: 1 }}>
                <HomeOrFolderTabEmptyState />
            </FlexColumnAlignJustifyCenter>
        );
    }

    return (
        <MoveToPopupProvider>
            {folders.length ? renderFolderGrid() : null}
            {sortedAndFilteredItems.length ? renderNoteAndFlashcardGrid() : null}
        </MoveToPopupProvider>
    );
};

const FolderPageWithDndWrapper = () => {
    const readOnly = useFolderManagementContextSelector(state => state.readOnly);
    const folder = useFolderManagementContextSelector(state => state.folder);
    const update = useFolderManagementContextSelector(state => state.update);

    const isSelectMode = useUserContentManagementContextSelector(state => state.isSelectMode);

    const disableDnd = readOnly || isSelectMode;

    return (
        <UserContentManagementMoveToHandler disabled={disableDnd}>
            <FlexRowAlignCenter className={br.smDownFullWidthScrollableX} style={{ overflowX: "auto" }}>
                <DroppableFolderTitle folder={folder} update={update} />
            </FlexRowAlignCenter>
            <div style={{ paddingBottom: "4.3rem" }} />
            <FolderControlSection />
            <div style={{ paddingBottom: spacing.LG }} />
            <FolderContent />
            <span className={br.smUpDisplayNone}>
                <UserContentManagementPlusButton />
            </span>
        </UserContentManagementMoveToHandler>
    );
};

const FolderPage = ({
    serverUser,
    serverFolder,
    serverFolders,
    serverNotes,
    serverFlashcardSets,
    serverMedias,
    serverBookmarks,
    sortValue,
    readOnly,
}: {
    serverUser: LocalUser;
    serverFolder: Folder;
    serverFolders: Record<string, Folder>;
    serverNotes: Record<string, Note>;
    serverFlashcardSets: Record<string, FlashcardSet>;
    serverMedias: Record<string, Media>;
    serverBookmarks?: Bookmark[];
    sortValue: UserContentSortSettings;
    readOnly: boolean;
}) => {
    return (
        <SWRConfig
            value={{
                fallback: {
                    getCurrentUser: serverUser,
                    [unstable_serialize(["bookmarks", serverUser?.user?.ID])]: serverBookmarks,
                },
            }}>
            <MainContainer style={{ paddingBlock: "0rem 0rem", minHeight: "calc(99vh - var(--navbar-height))" }}>
                <InnerContainer data-testid="home-page">
                    <div style={{ paddingBottom: spacing.XL }} />
                    <FolderManagementContextProvider
                        serverUser={serverUser.user as UserDetails | null}
                        serverFolder={serverFolder}
                        serverFolders={serverFolders}
                        serverNotes={serverNotes}
                        serverFlashcardSets={serverFlashcardSets}
                        serverMedias={serverMedias}
                        sortValue={sortValue}
                        readOnly={readOnly}>
                        <FolderPageWithDndWrapper />
                    </FolderManagementContextProvider>
                </InnerContainer>
                <MainContainerAdSlot user={serverUser.user} organization={serverUser.organization} />
            </MainContainer>
        </SWRConfig>
    );
};

export default FolderPage;
