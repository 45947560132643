import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import SimplePopupCard, { SimplePopupCardContent } from "@/components/Popup/PopupCards/SimplePopupCard";
import { themeColors } from "@/utils/themeColors";
import { ASSETS_URL } from "@/config/deployConstants";

const CustomButton = () => {
    return (
        <CircularRectTextButton
            onClick={() => {
                window.open("https://checkout.knowt.io/p/login/6oE8y6cFbgF29JSaEE", "_blank");
            }}
            sx={{
                backgroundColor: themeColors.primary,
                color: themeColors.pureWhite,
                height: "5.4rem",
                width: "17rem",
                borderRadius: "2rem",
                fontSize: "1.6rem",
            }}>
            {"Fix Card"}
        </CircularRectTextButton>
    );
};

const content: SimplePopupCardContent = {
    title: "Oh no, did your card expire?",
    description:
        "We tried to renew your subscription but your card was declined? Please enter a new payment method ASAP to avoid your subscription getting disabled!",
    backgroundColor: "#d9ecfd",
    customButton: <CustomButton />,
    image: `${ASSETS_URL}/images/atm-card.png`,
    imageStyle: {
        width: "35%",
    },
    closeButton: true,
};

const PaymentExpiredCard = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
    return <SimplePopupCard isOpen={isOpen} onClose={onClose} content={content} />;
};

export default PaymentExpiredCard;
