import { themeColors } from "@/utils/themeColors";
import { DotWave } from "@uiball/loaders";
import { iconSizes } from "@/utils/iconProps";
import SelectableCard, { SelectableCardProps } from "../SelectableCard";

const SelectableCardWithCenteredBottomText = ({
    title,
    desc,
    isLoading,
    ...genericSelectableCardProps
}: SelectableCardProps & { title: string; desc: string; isLoading: boolean }) => {
    return (
        <SelectableCard
            {...genericSelectableCardProps}
            bottom={
                <>
                    <span style={{ fontSize: "1.8rem", fontWeight: "600" }}>{title}</span>
                    <span style={{ fontSize: "1.3rem" }}>{desc}</span>
                    {isLoading && (
                        <div style={{ margin: "auto" }}>
                            <DotWave color={themeColors.neutralBlack} size={iconSizes.MD} />
                        </div>
                    )}
                </>
            }
        />
    );
};

export default SelectableCardWithCenteredBottomText;
