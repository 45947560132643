import { useState } from "react";
import clsx from "clsx";
import { themeColors } from "@/utils/themeColors";
import { spacing } from "@/utils/spacing";
import { borderRadius } from "@/utils/borderRadius";
import { FlexColumn, FlexColumnAlignJustifyCenter } from "@/components/Flex";
import br from "@/styles/breakpoints.module.css";
import styles from "./selectableCard.module.css";

export type SelectableCardProps = {
    id?: string;
    className?: string;
    /** style for the main container */
    style?: React.CSSProperties;
    /** a `FlexColumnAlignJustifyCenter` container for top part of card. on `smDown` this will be the left part */
    top: React.ReactNode;
    topStyle?: React.CSSProperties;
    /** a `FlexColumn` container for bottom part of card. on `smDown` this will be the right part */
    bottom?: React.ReactNode;
    bottomStyle?: React.CSSProperties;
    isSelected?: boolean;
    onClick?: () => void;
};

const SelectableCard = ({
    id,
    onClick,
    className,
    style,
    top,
    topStyle,
    bottom,
    bottomStyle,
    isSelected,
}: SelectableCardProps) => {
    const [isHover, setIsHover] = useState(false);

    const borderStyle = `solid ${isSelected ? "gray" : isHover ? themeColors.neutralBlack : "lightgray"} 2px`;

    return (
        <FlexColumn
            id={id}
            // should add height 15rem on smDown
            className={clsx([className, br.smDownRow, br.smDownWidth100, styles.cardHeight])}
            onClick={onClick}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            style={{
                cursor: typeof onClick === "function" ? "pointer" : "default",
                border: borderStyle,
                borderRadius: borderRadius.card,
                overflow: "hidden",
                padding: "0.8rem",
                gap: "1.5rem 2rem",
                width: "25.5rem",
                ...style,
            }}>
            {/* TOP CARD */}
            <FlexColumnAlignJustifyCenter
                className={styles.topBasis}
                style={{ borderRadius: borderRadius.card, position: "relative", ...topStyle }}>
                {top}
            </FlexColumnAlignJustifyCenter>

            {/* BOTTOM CARD */}
            <FlexColumn
                className={clsx(br.smDownTextAlignLeft, br.smDownJustifyCenter)}
                style={{ flex: 1, gap: spacing.XS, textAlign: "center", ...bottomStyle }}>
                {bottom}
            </FlexColumn>
        </FlexColumn>
    );
};

export default SelectableCard;
