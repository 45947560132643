import TableHead from "@mui/material/TableHead";
import Select, { SelectProps } from "@/components/Select";
import { ChevronDown, XIcon } from "lucide-react";
import { iconSizes, strokeWidth } from "@/utils/iconProps";
import InputWithSearchbarIcon from "@/components/styled/input/InputWithSearchbarIcon";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { FlexRowAlignCenter } from "@/components/Flex";
import { getColor, getBackgroundColor } from "@/utils/progressPill";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { StudySessionType, StudySessionProgressEnum } from "@knowt/syncing/graphql/schema";
import { TableRow, TableCell } from "@mui/material";
import br from "@/styles/breakpoints.module.css";
import { getProgressLabel } from "@knowt/syncing/hooks/study/statsUtils";

type ControlsSectionProps = {
    studentNameQuery: string;
    setStudentNameQuery: (query: string) => void;
    selectedStudyMode: StudySessionType | null;
    onStudyModeChange: (section: StudySessionType | null) => void;
    studyProgressFilter: StudySessionProgressEnum | null;
    onClearStudyProgressCategoryFilter: () => void;
};

export const ControlsSection = ({
    studentNameQuery,
    setStudentNameQuery,
    selectedStudyMode,
    onStudyModeChange,
    studyProgressFilter,
    onClearStudyProgressCategoryFilter,
}: ControlsSectionProps) => {
    const studyModesOptions: SelectProps<{
        label: string;
        value: StudySessionType | null;
    }>["options"] = [
        { label: "All study modes", value: null },
        { label: "Learn", value: StudySessionType.LEARN },
        { label: "Flashcards", value: StudySessionType.REVIEW },
        { label: "Test", value: StudySessionType.TEST },
        { label: "Match", value: StudySessionType.MATCH },
        { label: "Spaced", value: StudySessionType.SPACED },
    ];

    const renderContent = () => {
        if (studyProgressFilter) {
            return (
                <FlexRowAlignCenter style={{ columnGap: spacing.XS_2 }}>
                    <CircularRectTextButton
                        key={studyProgressFilter}
                        onClick={() => onClearStudyProgressCategoryFilter()}
                        className={"secondaryTextBold2"}
                        sx={{
                            color: getColor(studyProgressFilter),
                            backgroundColor: getBackgroundColor(studyProgressFilter),
                            fontSize: "1.5rem",
                            columnGap: spacing.XXS,
                            border: `2px solid ${getColor(studyProgressFilter)}`,
                            paddingInline: spacing.XS_2,
                            paddingBlock: spacing.XS,
                            borderRadius: "10px",
                            "&:hover": { boxShadow: "inset 0 0 0 10em rgba(0, 0, 0, 0.1)" },
                            "&:active": { boxShadow: "inset 0 0 0 10em rgba(0, 0, 0, 0.15)" },
                        }}>
                        {getProgressLabel(studyProgressFilter)}
                        <XIcon
                            size={iconSizes.SM}
                            color={getColor(studyProgressFilter)}
                            strokeWidth={strokeWidth.bold}
                        />
                    </CircularRectTextButton>
                </FlexRowAlignCenter>
            );
        }

        return (
            <FlexRowAlignCenter style={{ flex: 1, justifyContent: "space-between" }}>
                <div className={"bodyBold2"}>Study Analytics</div>
                <FlexRowAlignCenter style={{ columnGap: spacing.XS_2, alignSelf: "stretch" }}>
                    <InputWithSearchbarIcon
                        value={studentNameQuery}
                        onChange={e => setStudentNameQuery(e.target.value)}
                        placeholder="Search students"
                        containerStyle={{ alignSelf: "stretch" }}
                        sx={{
                            backgroundColor: themeColors.background,
                            borderRadius: 40,
                            paddingLeft: "4.5rem",
                            paddingRight: spacing.SM,
                            paddingBlock: spacing.XS,
                            fontSize: "1.6rem",
                            width: "100%",
                            height: "100%",
                            border: `1px solid ${themeColors.neutral1}`,
                            "&:focus": {
                                border: `1px solid ${themeColors.neutral2}`,
                            },
                        }}
                        adornmentProps={{
                            style: { left: spacing.SM, width: "15px", height: "15px" },
                            strokeWidth: strokeWidth.bold,
                        }}
                    />
                    {/*TODO: test Select after replacing the div tag wrapper with a `React.Fragment` in the `WithOptionsMenu.tsx`*/}
                    <div className={br.mdDownDisplayNone}>
                        <Select
                            options={studyModesOptions}
                            onChange={({ value }) => onStudyModeChange(value)}
                            menuSx={{ width: "25rem", padding: "1rem" }}
                            btnSx={{
                                width: "fit-content",
                                gap: "1rem",
                                alignSelf: "stretch",
                                borderRadius: 999,
                                backgroundColor: themeColors.neutralWhite,
                                border: `1px solid ${themeColors.neutral1}`,
                            }}
                            selected={
                                studyModesOptions.find(({ value }) => value === selectedStudyMode) as {
                                    label: string;
                                    value: StudySessionType | null;
                                }
                            }
                            transitionOrigin={{
                                anchorOrigin: { vertical: "bottom", horizontal: "left" },
                                transformOrigin: { vertical: "top", horizontal: "left" },
                            }}
                            adornment={<ChevronDown size={iconSizes.SM} />}
                        />
                    </div>
                </FlexRowAlignCenter>
            </FlexRowAlignCenter>
        );
    };

    return <FlexRowAlignCenter style={{ height: "4.5rem" }}>{renderContent()}</FlexRowAlignCenter>;
};

export enum ClassMemberSortByKeys {
    NAME,
    TIME_STUDYING,
    LAST_ACTIVE,
    PROGRESS,
    CARDS_SEEN,
}

export enum ClassMemberSortOrder {
    ASC,
    DESC,
}

export type MemberSortSettings = {
    sortBy: ClassMemberSortByKeys;
    order: ClassMemberSortOrder;
};

type TableHeaderProps = {
    studyMode: StudySessionType | null;
    onSortSettingsChange: (params: MemberSortSettings) => void;
    sortSettings: MemberSortSettings | null;
};

export const TableHeader = ({ studyMode, onSortSettingsChange, sortSettings }: TableHeaderProps) => {
    const getToggleSortHandler = (sortBy: ClassMemberSortByKeys) => () =>
        onSortSettingsChange({
            sortBy,
            order:
                sortSettings?.sortBy === sortBy && sortSettings.order === ClassMemberSortOrder.ASC
                    ? ClassMemberSortOrder.DESC
                    : ClassMemberSortOrder.ASC,
        });

    const columnHeaders = [
        {
            id: "name",
            label: "Name",
            sortKey: ClassMemberSortByKeys.NAME,
            onToggleSort: getToggleSortHandler(ClassMemberSortByKeys.NAME),
        },
        ...(studyMode === null
            ? [
                  {
                      id: "activity",
                      label: "Activity",
                      className: br.mdDownDisplayNone,
                  },
              ]
            : [
                  {
                      id: "time_studying",
                      label: "Time Studying",
                      sortKey: ClassMemberSortByKeys.TIME_STUDYING,
                      onToggleSort: getToggleSortHandler(ClassMemberSortByKeys.TIME_STUDYING),
                      className: br.mdDownDisplayNone,
                  },
                  {
                      id: "last_active",
                      label: "Last Active",
                      sortKey: ClassMemberSortByKeys.LAST_ACTIVE,
                      onToggleSort: getToggleSortHandler(ClassMemberSortByKeys.LAST_ACTIVE),
                      className: br.lgDownDisplayNone,
                  },
                  {
                      id: "cards_seen",
                      label: "Cards Seen",
                      sort: ClassMemberSortByKeys.CARDS_SEEN,
                      onToggleSort: getToggleSortHandler(ClassMemberSortByKeys.CARDS_SEEN),
                      className: br.mdDownDisplayNone,
                  },
              ]),
        {
            id: "progress",
            label: "Progress",
            sortKey: ClassMemberSortByKeys.PROGRESS,
            onToggleSort: getToggleSortHandler(ClassMemberSortByKeys.PROGRESS),
        },
    ];

    return (
        <TableHead>
            <TableRow>
                {columnHeaders.map(({ id, label, sortKey, onToggleSort, className = "" }, index) => (
                    <TableCell
                        className={className}
                        key={id}
                        sx={{ fontWeight: "600" }}
                        style={{
                            padding:
                                index !== 0 && index !== columnHeaders.length - 1 ? "1.6rem 2.4rem" : "1.6rem 0rem",
                        }}
                        onClick={onToggleSort}>
                        <FlexRowAlignCenter style={{ gap: "0.8rem", cursor: "pointer", whiteSpace: "nowrap" }}>
                            {label}
                            {onToggleSort && (
                                <ChevronDown
                                    size={iconSizes.SM}
                                    style={{
                                        cursor: "pointer",
                                        transform: `rotate(${
                                            sortSettings?.sortBy === sortKey &&
                                            sortSettings?.order === ClassMemberSortOrder.ASC
                                                ? "0"
                                                : "180deg"
                                        })`,
                                    }}
                                />
                            )}
                        </FlexRowAlignCenter>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};
