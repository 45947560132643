import { CircularProps } from "@/components/Circular";
import CircularButton, { CircularButtonProps } from "@/components/CircularButton";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { themeColors } from "@/utils/themeColors";

const BadgedButton = ({
    onClick,
    badgeProps,
    badgeCount,
    children,
    ...btnProps
}: CircularButtonProps & { badgeProps?: CircularProps; badgeCount: number }) => {
    return (
        <div style={{ position: "relative" }}>
            <CircularRectTextButton
                {...badgeProps}
                onClick={onClick}
                sx={{ width: "18.4rem", height: "5.2rem", fontSize: "1.5rem", gap: "1rem", ...btnProps?.sx }}>
                {children}
            </CircularRectTextButton>

            {!!badgeCount && (
                <CircularButton
                    disabled
                    radius="2.8rem"
                    {...badgeProps}
                    style={{
                        position: "absolute",
                        top: "-0.8rem",
                        right: "-0.8rem",
                        backgroundColor: themeColors.primary,
                        color: themeColors.primaryDark,
                        fontSize: "1.2rem",
                        fontWeight: "600",
                        ...badgeProps?.style,
                    }}>
                    {badgeCount}
                </CircularButton>
            )}
        </div>
    );
};

export default BadgedButton;
