import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import { QRCode } from "@/components/QRCode";
import { BASE_URL } from "@/config/deployConstants";
import ResponsiveDialog, { DialogCloseBtn } from "@/features/ResponsiveDialog";
import { borderRadius } from "@/utils/borderRadius";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { Class } from "@knowt/syncing/graphql/schema";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { Link } from "lucide-react";
import { strokeWidth, iconSizes } from "@/utils/iconProps";
import br from "@/styles/breakpoints.module.css";
import { isDarkColor } from "@knowt/syncing/utils/genericUtils";
import { useTheme } from "@/utils/theme";

export const CopyClassLinkBtn = ({ onClick }: { onClick: (e: any) => void }) => {
    return (
        <CircularRectTextButton
            onClick={onClick}
            sx={{
                marginLeft: "2rem",
                border: `1px solid ${themeColors.neutralBlack}`,
                fontWeight: "400",
                height: "3.2rem",
                width: "13.8rem",
                fontSize: "1.2rem",
            }}>
            copy class link
            <Link strokeWidth={strokeWidth.normal} size={iconSizes.SM_S} style={{ marginLeft: "0.8rem" }} />
        </CircularRectTextButton>
    );
};

const ClassCodePopup = ({
    course,
    isOpen,
    onClose,
    onCopy,
}: {
    course: Class;
    isOpen: boolean;
    onClose: () => void;
    onCopy: (e: any) => void;
}) => {
    const { isDarkMode } = useTheme();
    return (
        <ResponsiveDialog
            isOpen={isOpen}
            onClose={onClose}
            DialogStyle={{
                borderRadius: borderRadius.card,
                padding: "2rem 2rem 4rem 2rem",
            }}
            SlideMenuStyle={{
                padding: "2rem",
            }}>
            <DialogCloseBtn onClick={onClose} style={{ marginLeft: "auto" }} />
            <FlexRowAlignCenter style={{ padding: "04rem", gap: "1.95rem" }} className={br.mdDownColumnReverse}>
                <FlexColumn>
                    <p
                        style={{
                            color: course.color ?? themeColors.primary,
                            fontWeight: 700,
                            fontSize: "13rem",
                            borderBottom: `3px solid ${course.color ?? themeColors.primary}`,
                            width: "100%",
                            textAlign: "center",
                            margin: "auto",
                        }}>
                        {course.classId}
                    </p>
                    <FlexRowAlignCenter style={{ justifyContent: "space-between", marginTop: spacing.LG }}>
                        <p className="bodyBold2">{course.name}</p>
                        <CopyClassLinkBtn onClick={onCopy} />
                    </FlexRowAlignCenter>
                </FlexColumn>
                <div
                    style={{ width: "1px", height: "20rem", backgroundColor: themeColors.neutral1 }}
                    className={br.mdDownDisplayNone}
                />
                <QRCode
                    value={`${BASE_URL}/join/${course.classId}`}
                    // TODO: logo centering isn't really working
                    // logoImage="/images/knowt-logo-mobile.svg"
                    // logoPadding={10}
                    // logoHeight={40}
                    // logoWidth={30}
                    // cant use theme colors here, it needs a hex value
                    // we can use the course color if it's a dark color, otherwise use a dark color
                    fgColor={course.color}
                    bgColor={
                        isDarkColor(course.color)
                            ? isDarkMode
                                ? "#ffffff"
                                : "transparent"
                            : isDarkMode
                            ? "transparent"
                            : "#282929"
                    }
                    style={{
                        width: "25rem",
                        height: "25rem",
                    }}
                    size={300}
                />
            </FlexRowAlignCenter>
        </ResponsiveDialog>
    );
};

export default ClassCodePopup;
