"use client";

import AddRemoveImageButton from "@/components/AddRemoveImageButton";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { Flex, FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import HtmlText from "@/components/HtmlText";
import ResponsiveDialog, { DialogCloseBtn } from "@/features/ResponsiveDialog";
import { iconSizes, strokeWidth } from "@/utils/iconProps";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { ArrowLeft, ArrowRight } from "lucide-react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import { TableCell, TableHead, TableRow } from "@mui/material";
import CircularProgress from "@/components/CircularProgress";
import StudentInformationCell from "../../TeacherView/components/StudentInformationCell";
import { getColor, getBackgroundColor, getProgressValue, getProgressBg } from "@/utils/progressPill";
import Circular from "@/components/Circular";
import { FlashcardsStudyAnalytics } from "@knowt/syncing/hooks/study/statsUtils";
import { formatTimeStudying } from "@knowt/syncing/utils/dateTimeUtils";
import { ClassMemberWithDetails, Flashcard } from "@knowt/syncing/graphql/schema";
import { capitalize } from "@knowt/syncing/utils/stringUtils";
import { useSyncedState } from "@knowt/syncing/utils/hooks/useSyncedState";

const TableHeader = () => {
    const columnHeaders = [
        {
            id: "student",
            label: "Student",
        },
        {
            id: "current-flashcards-progress",
            label: "Current flashcards progress",
        },
        {
            id: "time-studying",
            label: "Time studying",
        },
    ];

    return (
        <TableHead>
            <TableRow style={{}}>
                {columnHeaders.map(({ id, label }) => (
                    <TableCell key={id} sx={{ fontWeight: "600" }}>
                        <FlexRowAlignCenter style={{ gap: "0.8rem", cursor: "pointer" }}>{label}</FlexRowAlignCenter>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

const TableEntry = ({
    member,
    flashcardAnalytics,
}: {
    member: ClassMemberWithDetails | null | undefined;
    flashcardAnalytics: FlashcardsStudyAnalytics["id"]["id"] | null | undefined;
}) => {
    if (!member || !flashcardAnalytics) return null;

    const studyProgress = flashcardAnalytics.progress;

    return (
        <TableRow>
            <TableCell>
                <StudentInformationCell member={member} />
            </TableCell>
            <TableCell>
                <Circular
                    radius="auto"
                    style={{
                        width: "16rem",
                        minWidth: "16rem",
                        height: "4rem",
                        color: getColor(studyProgress),
                        backgroundColor: getBackgroundColor(studyProgress),
                        gap: "0.8rem",
                        justifyContent: "flex-start",
                        padding: "0 0 0 1.5rem",
                        fontSize: "1.5rem",
                        transition: "all 0.2s ease-in-out",
                    }}>
                    <CircularProgress
                        value={getProgressValue(studyProgress)}
                        bgColor={getProgressBg(studyProgress)}
                        color={getColor(studyProgress)}
                        size={"2rem"}
                        thickness={8}
                    />
                    {capitalize(studyProgress)}
                </Circular>
            </TableCell>
            <TableCell style={{ width: "20rem" }}>
                <p className="secondaryTextBold1">{formatTimeStudying(flashcardAnalytics.timeSpent)}</p>
            </TableCell>
        </TableRow>
    );
};

const MasteryPerFlashcardDetailsPopup = ({
    isOpen,
    onClose,
    currentFlashcardIndex,
    allFlashcards,
    members,
    flashcardsStudyAnalytics,
}: {
    isOpen: boolean;
    onClose: () => void;
    currentFlashcardIndex: number | undefined;
    allFlashcards: Flashcard[];
    members: ClassMemberWithDetails[];
    flashcardsStudyAnalytics: FlashcardsStudyAnalytics | null | undefined;
}) => {
    const [flashcardIndex, setFlashcardIndex] = useSyncedState(currentFlashcardIndex);

    if (!flashcardIndex || !allFlashcards[flashcardIndex]) return null;

    const { term, definition, image, secondaryImage, flashcardId } = allFlashcards[flashcardIndex];

    const flashcardAnalytics = flashcardsStudyAnalytics?.[flashcardId];
    const flashcardsCount = allFlashcards.length;

    const membersById = members.reduce((acc, member) => {
        acc[member.userId] = member;
        return acc;
    }, {});

    const renderTable = () => {
        return (
            <TableContainer
                sx={{
                    ".MuiTableCell-root": {
                        fontFamily: "var(--knowt-font-name)",
                        fontSize: "1.4rem",
                        padding: "1.6rem 2.4rem",
                        height: "100%",
                        color: themeColors.neutralBlack,
                    },
                }}>
                <Table sx={{ borderColor: themeColors.background }}>
                    <TableHeader />
                    {Object.entries(flashcardAnalytics || {}).map(([userId, analytics]) => {
                        return <TableEntry key={userId} member={membersById[userId]} flashcardAnalytics={analytics} />;
                    })}
                </Table>
            </TableContainer>
        );
    };

    const renderContent = () => {
        return (
            <div style={{ overflowY: "scroll", paddingRight: "1rem" }} className="scrollbar">
                <DialogCloseBtn style={{ margin: "0 0 0 auto" }} onClick={onClose} />
                <FlexColumn style={{ gap: "1.5rem" }}>
                    <div style={{ fontWeight: 700, fontSize: "2.1rem", marginBottom: "1rem" }}>
                        {"Students that haven’t mastered this flashcard"}
                    </div>

                    {/* Flashcard content */}
                    <Flex
                        style={{
                            width: "100%",
                            gap: spacing.MD,
                            justifyContent: "space-between",
                            fontSize: "1.8rem",
                            flexDirection: "column",
                            border: `1px solid ${themeColors.neutral1}`,
                            padding: "2rem",
                            borderRadius: "2rem",
                        }}>
                        <Flex style={{ gap: spacing.SM, width: "35%" }}>
                            {secondaryImage && (
                                <div>
                                    <AddRemoveImageButton
                                        editable={false}
                                        alt={term || "term image"}
                                        image={image}
                                        size="9rem"
                                    />
                                </div>
                            )}
                            <HtmlText
                                text={term}
                                style={{
                                    width: "100%",
                                    fontFamily: "var(--knowt-font-name)",
                                    background: "transparent",
                                }}
                            />
                        </Flex>
                        <div
                            style={{
                                backgroundColor: themeColors.neutral1,
                                height: "1px",
                            }}
                        />
                        <Flex style={{ gap: spacing.SM, width: "65%" }}>
                            {secondaryImage && (
                                <div>
                                    <AddRemoveImageButton
                                        editable={false}
                                        alt={term ?? "term image"}
                                        image={image}
                                        size="9rem"
                                        style={{ visibility: image ? "visible" : "hidden" }}
                                    />
                                </div>
                            )}
                            <HtmlText
                                text={definition}
                                style={{
                                    width: "100%",
                                    fontFamily: "var(--knowt-font-name)",
                                    background: "transparent",
                                }}
                            />
                        </Flex>
                    </Flex>

                    {/* Flashcards navigation buttons */}
                    <Flex style={{ justifyContent: "space-between" }}>
                        <CircularRectTextButton
                            onClick={() => setFlashcardIndex(index => (index - 1 + flashcardsCount) % flashcardsCount)}
                            sx={{
                                border: `1px solid ${themeColors.neutral1}`,
                                height: "4.5rem",
                                width: "14rem",
                                fontSize: "1.2rem",
                                backgroundColor: "transparent",
                                color: themeColors.neutralBlack,
                            }}>
                            <ArrowLeft
                                strokeWidth={strokeWidth.normal}
                                size={iconSizes.SM_S}
                                style={{ marginRight: "0.5rem" }}
                            />
                            Last flashcard
                        </CircularRectTextButton>
                        <CircularRectTextButton
                            onClick={() => setFlashcardIndex(index => (index + 1 + flashcardsCount) % flashcardsCount)}
                            sx={{
                                border: `1px solid ${themeColors.neutral1}`,
                                height: "4.5rem",
                                width: "14rem",
                                fontSize: "1.2rem",
                                backgroundColor: "transparent",
                                color: themeColors.neutralBlack,
                            }}>
                            Next flashcard
                            <ArrowRight
                                strokeWidth={strokeWidth.normal}
                                size={iconSizes.SM_S}
                                style={{ marginLeft: "0.5rem" }}
                            />
                        </CircularRectTextButton>
                    </Flex>
                    {renderTable()}
                </FlexColumn>
            </div>
        );
    };

    return (
        <ResponsiveDialog
            isOpen={isOpen}
            onClose={onClose}
            style={{ backgroundColor: themeColors.background, overflow: "hidden" }}
            DialogStyle={{
                padding: "3.1rem 3.6rem",
                width: "80rem",
                borderRadius: "4rem",
            }}
            SlideMenuStyle={{
                padding: "2.5rem 2rem 1rem",
            }}>
            {renderContent()}
        </ResponsiveDialog>
    );
};

export default MasteryPerFlashcardDetailsPopup;
