import { useFolderAncestors } from "@knowt/syncing/hooks/folders/useFolders";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { useRef, useState } from "react";
import Link from "@/components/wrappers/Link";
import { ChevronDown } from "lucide-react";
import { iconSizes } from "@/utils/iconProps";
import FolderOptionsMenu from "@/components/cards/FolderCard/components/FolderOptionsMenu";
import noop from "@knowt/syncing/utils/noop";
import { MoveToPopupProvider, SlugPillFilled, SlugSeparatorChevron } from "features/MoveToPopup";
import FolderIcon from "@/components/cards/FolderCard/components/FolderIcon";
import { useClass } from "@knowt/syncing/hooks/classes/useClass";
import LinkWrapper from "@/components/wrappers/Link/Link";
import Image from "next/image";
import FolderIconBtn from "@/components/cards/FolderCard/components/FolderIconBtn";

const TitleItem = ({ folder, update }) => {
    const { userId } = useCurrentUser();

    const isOwner = userId && userId === folder?.userId;

    const titleItemRef = useRef(null);
    const menuBtnRef = useRef(null);

    const openMenuRef = useRef<(e: React.MouseEvent) => void>(noop);

    const canOpenMenu = isOwner;

    const [showColorPicker, setShowColorPicker] = useState(false);
    const [loadColorPicker, setLoadColorPicker] = useState(false);

    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [loadEmojiPicker, setLoadEmojiPicker] = useState(false);

    if (!folder) return null;

    return (
        <MoveToPopupProvider>
            <SlugPillFilled
                component="span"
                ref={titleItemRef}
                onMouseEnter={() => {
                    if (canOpenMenu) {
                        setLoadColorPicker(true);
                        setLoadEmojiPicker(true);
                    }
                }}
                onClick={event => {
                    if (canOpenMenu) openMenuRef.current(event);
                }}>
                <FolderIcon
                    {...{
                        folder,
                        showColorPicker,
                        setShowColorPicker,
                        loadColorPicker,
                        showEmojiPicker,
                        setShowEmojiPicker,
                        loadEmojiPicker,
                    }}
                />
                <span>{folder.name}</span>
                {canOpenMenu && (
                    <div ref={menuBtnRef} style={{ marginTop: 3 }}>
                        <ChevronDown size={iconSizes.SM} />
                    </div>
                )}
            </SlugPillFilled>
            <FolderOptionsMenu
                folder={folder}
                parentRef={titleItemRef}
                renderBtn={openMenu => {
                    openMenuRef.current = openMenu;
                    return null;
                }}
                openIconPicker={type => {
                    if (type === "color") {
                        setShowColorPicker(true);
                        update({ icon: { emoji: null, skinTone: null } });
                    } else {
                        setShowEmojiPicker(true);
                    }
                }}
            />
        </MoveToPopupProvider>
    );
};

const DroppableFolderTitle = ({ folder, update }) => {
    const { userId } = useCurrentUser();

    const otherUserId = userId !== folder.userId ? folder.userId : null;

    const { ancestors } = useFolderAncestors({ folderId: folder.folderId, otherUserId });
    const { course } = useClass({ classId: folder.classId });

    return (
        <>
            <LinkWrapper href={folder.trash ? "/?filterBy=trashed" : "/"}>
                <SlugPillFilled component="span">
                    <Image src={`/images/home-icon.svg`} width={iconSizes.SM} height={iconSizes.SM} alt={"home"} />
                    Home
                </SlugPillFilled>
            </LinkWrapper>
            {course && (
                <>
                    <SlugSeparatorChevron />
                    <Link href={`/class/${course.classId}/dashboard`} key={course.classId}>
                        <SlugPillFilled component="span">
                            <Image
                                src={`/images/class-icon.svg`}
                                width={iconSizes.SM}
                                height={iconSizes.SM}
                                alt="class icon"
                            />
                            {course.name}
                        </SlugPillFilled>
                    </Link>
                </>
            )}
            {ancestors?.slice(0, ancestors.length - 1)?.map(ancestorFolder => (
                <>
                    <SlugSeparatorChevron />
                    <Link href={`/folder/${ancestorFolder.folderId}`} key={ancestorFolder.folderId}>
                        <SlugPillFilled component="span">
                            <FolderIconBtn folder={ancestorFolder} />
                            {ancestorFolder.name}
                        </SlugPillFilled>
                    </Link>
                </>
            ))}
            <SlugSeparatorChevron />
            <TitleItem folder={folder} update={update} />
        </>
    );
};

export default DroppableFolderTitle;
