"use client";

import { FlexRowAlignCenter } from "@/components/Flex";
import { MoveToPopupProvider } from "features/MoveToPopup";
import { AnimatedTrashButton } from "@/components/TrashButton/TrashButton";
import ClickableText from "@/components/styled/button/ClickableText";
import { iconSizes, strokeWidth } from "@/utils/iconProps";
import { useTopLevelStandaloneFlashcardSets } from "@knowt/syncing/hooks/flashcards/useStandaloneFlashcards";
import { deleteFlashcardSets, moveFlashcardSets, restoreFlashcardSets } from "@knowt/syncing/hooks/flashcards/utils";
import { callCreateFolder } from "@knowt/syncing/hooks/folders/graphqlUtils";
import { useFolders } from "@knowt/syncing/hooks/folders/useFolders";
import { deleteFolders, moveFolder, resolveFolderSWRKey, restoreFolders } from "@knowt/syncing/hooks/folders/utils";
import { useAllMedias } from "@knowt/syncing/hooks/media/useMedia";
import { deleteMedias, moveMedias, restoreMedias } from "@knowt/syncing/hooks/media/utils";
import { useNotebook } from "@knowt/syncing/hooks/notes/useNotes";
import { deleteNotes, moveNotes, restoreNotes } from "@knowt/syncing/hooks/notes/utils";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { FolderInput } from "lucide-react";
import React from "react";
import { toast } from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";
import { CreateNewContentButtonWithHandler } from "@/components/CreateNewContent/CreateNewContentButtonWithHandler";
import { useHomeManagementContextSelector } from "../hooks/useHomeManagementContext";
import { HomeFilesTab, HomeTab } from "../types";
import { ALL_ITEM_TYPES } from "../../types";
import styles from "../homepage.module.css";
import br from "@/styles/breakpoints.module.css";
import {
    CreateFolderButton,
    FilterButton,
    MergeSetButton,
    MoveToFolderButton,
    SelectMultipleButton,
    SortButton,
    UserContentTrashButton,
} from "../../components/ControlSections";
import Select from "@/components/Select";
import { themeColors } from "@/utils/themeColors";
import BadgedButton from "../../components/styled/BadgedButton";
import { spacing } from "@/utils/spacing";
import { mutate } from "swr";
import { isFlashcardSet, isFolder, isMedia, isNote } from "@knowt/syncing/utils/dataCleaning";
import { useUserContentManagementContextSelector } from "@/features/UserContentManagement";

const TAB_OPTIONS = {
    [HomeFilesTab.HOME]: { value: HomeFilesTab.HOME, label: "home" },
    [HomeFilesTab.SAVED]: { value: HomeFilesTab.SAVED, label: "saved" },
    [HomeFilesTab.TRASH]: { value: HomeFilesTab.TRASH, label: "trash" },
};

const EmptyTrashButtonWithHandler = () => {
    const { userId } = useCurrentUser();
    const { folders: rootFolders } = useFolders({ inTrash: true });
    const { notes } = useNotebook({ inTrash: true, showFoldersNotes: true });
    const { flashcardSetsObj: flashcardSets } = useTopLevelStandaloneFlashcardSets({ inTrash: true });
    const { medias } = useAllMedias({ inTrash: true });

    const setIsSelectMode = useUserContentManagementContextSelector(state => state.setIsSelectMode);
    const setSelectedItems = useUserContentManagementContextSelector(state => state.setSelectedItems);

    const emptyTrash = async () => {
        try {
            await Promise.all([
                deleteFolders({ folderIds: Object.keys(rootFolders), userId }),
                deleteNotes({ noteIds: Object.keys(notes), userId }),
                deleteFlashcardSets({ flashcardSetIds: Object.keys(flashcardSets), userId }),
                deleteMedias({ mediaIds: Object.keys(medias), userId }),
            ]);

            // we have animation during the deletion, so wait for it to finish
            setTimeout(() => {
                setSelectedItems({});
                setIsSelectMode(false);
            }, 1000);
        } catch (e) {
            toast.error("Error emptying trash");
            toast.error(e);
        }
    };

    return (
        <AnimatedTrashButton
            width="18.4rem"
            text="empty trash"
            confirmDialogProps={{
                title: "Are you sure you want to empty trash?",
                subtitle: "These items will not be able to be recovered if you continue to delete them.",
                buttonText: "Confirm",
            }}
            onDelete={emptyTrash}
            onAnimationFinished={() => null}
        />
    );
};

const RestoreItemsButtonWithHandler = () => {
    const { userId } = useCurrentUser();

    const selectedItems = useUserContentManagementContextSelector(state => state.selectedItems);
    const setSelectedItems = useUserContentManagementContextSelector(state => state.setSelectedItems);
    const setIsSelectMode = useUserContentManagementContextSelector(state => state.setIsSelectMode);

    const selectedCount = Object.keys(selectedItems).length;

    const restoreSelectedItems = async () => {
        const selectedItemArr = Object.values(selectedItems);

        const noteIds: string[] = [];
        const folderIds: string[] = [];
        const flashcardSetIds: string[] = [];
        const mediaIds: string[] = [];

        selectedItemArr.forEach(item => {
            if (isNote(item)) {
                return noteIds.push(item.noteId);
            } else if (isFolder(item)) {
                return folderIds.push(item.folderId);
            } else if (isFlashcardSet(item)) {
                return flashcardSetIds.push(item.flashcardSetId);
            } else if (isMedia(item)) {
                return mediaIds.push(item.mediaId);
            }

            throw new Error("Unknown item type");
        });

        // toast vocab
        const tv = {
            verb: "restored",
            flashcardSet: (() => {
                const len = flashcardSetIds.length;
                const flashcardSet = len === 1 ? "flashcard set" : "flashcard sets";
                return len > 0 ? len + " " + flashcardSet : null;
            })(),
            note: (() => {
                const len = noteIds.length;
                const note = len === 1 ? "note" : "notes";
                return len > 0 ? len + " " + note : null;
            })(),
            folder: (() => {
                const len = folderIds.length;
                const folder = len === 1 ? "folder" : "folders";
                return len > 0 ? len + " " + folder : null;
            })(),
            media: (() => {
                const len = mediaIds.length;
                const media = len === 1 ? "media" : "medias";
                return len > 0 ? len + " " + media : null;
            })(),
        };

        try {
            await Promise.all([
                restoreNotes({ noteIds, userId, sourceFolderId: null }),
                restoreFlashcardSets({ flashcardSetIds, userId, sourceFolderId: null }),
                restoreFolders({ folderIds, userId }),
                restoreMedias({ mediaIds, userId, sourceFolderId: null }),
            ]);

            setTimeout(() => {
                toast.success(
                    [tv.note, tv.flashcardSet, tv.folder, tv.media].filter(Boolean).join(", ") + " " + tv.verb
                );
            }, 1000);

            setSelectedItems({});
            setIsSelectMode(false);
        } catch (e) {
            toast.error(
                [tv.note, tv.flashcardSet, tv.folder, tv.media].filter(Boolean).join(", ") + " failed to restore"
            );
            toast.error(e);
        }
    };

    return (
        <BadgedButton badgeCount={selectedCount} onClick={restoreSelectedItems}>
            <FolderInput size={iconSizes.MD} strokeWidth={strokeWidth.normal} /> Restore Item
        </BadgedButton>
    );
};

const HomeControlSection = () => {
    const { userId } = useCurrentUser();

    const sort = useUserContentManagementContextSelector(state => state.sort);
    const setSort = useUserContentManagementContextSelector(state => state.setSort);
    const itemTypesToShow = useUserContentManagementContextSelector(state => state.itemTypesToShow);
    const setItemTypesToShow = useUserContentManagementContextSelector(state => state.setItemTypesToShow);
    const isSelectMode = useUserContentManagementContextSelector(state => state.isSelectMode);
    const setSelectedItems = useUserContentManagementContextSelector(state => state.setSelectedItems);
    const setIsSelectMode = useUserContentManagementContextSelector(state => state.setIsSelectMode);

    const currentTab = useHomeManagementContextSelector(state => state.currentTab);
    const currentFilesTab = useHomeManagementContextSelector(state => state.currentFilesTab);
    const changeCurrentFilesTab = useHomeManagementContextSelector(state => state.changeCurrentFilesTab);

    const inTrashTab = currentFilesTab === HomeFilesTab.TRASH;
    const inSavedTab = currentFilesTab === HomeFilesTab.SAVED;
    const inHomeTab = currentFilesTab === HomeFilesTab.HOME;

    const inClassesTab = currentTab === HomeTab.CLASSES;

    if (isSelectMode) {
        return (
            <FlexRowAlignCenter style={{ flexWrap: "wrap", gap: "1.7rem" }}>
                {inTrashTab && <RestoreItemsButtonWithHandler />}
                {!inTrashTab && (
                    <MoveToPopupProvider>
                        <MoveToFolderButton
                            onMove={async (
                                { classId: destinationClassId, folderId: destinationFolderId },
                                { noteIds, folderIds, flashcardSetIds, mediaIds }
                            ) => {
                                await Promise.all([
                                    moveNotes({
                                        noteIds,
                                        userId,
                                        sourceFolderId: null,
                                        destinationFolderId,
                                        sourceClassId: null,
                                        destinationClassId,
                                    }),
                                    moveFlashcardSets({
                                        flashcardSetIds,
                                        userId,
                                        sourceFolderId: null,
                                        destinationFolderId,
                                        sourceClassId: null,
                                        destinationClassId,
                                    }),
                                    folderIds.map(folderId =>
                                        moveFolder({
                                            folderId,
                                            userId,
                                            sourceFolderId: null,
                                            parentId: destinationFolderId,
                                            sourceClassId: null,
                                            destinationClassId,
                                        })
                                    ),
                                    moveMedias({
                                        mediaIds,
                                        userId,
                                        sourceFolderId: null,
                                        destinationFolderId,
                                        sourceClassId: null,
                                        destinationClassId,
                                    }),
                                ]);
                            }}
                        />
                    </MoveToPopupProvider>
                )}
                <UserContentTrashButton inTrashTab={inTrashTab} />
                <MergeSetButton />
                <ClickableText
                    onClick={() => {
                        setIsSelectMode(false);
                        setSelectedItems({});
                    }}>
                    cancel
                </ClickableText>
            </FlexRowAlignCenter>
        );
    }

    return (
        <FlexRowAlignCenter style={{ gap: "1.8rem" }} className={styles.controlSectionGap}>
            <FlexRowAlignCenter className={styles.hideableControlButtons} style={{ gap: "inherit" }}>
                {inHomeTab && <CreateNewContentButtonWithHandler classMode={inClassesTab} />}
                {currentTab === HomeTab.FILES && (
                    <>
                        {inHomeTab && (
                            <CreateFolderButton
                                onCreate={async ({ name }) => {
                                    const newFolder = callCreateFolder({
                                        userId,
                                        folderId: uuidv4(),
                                        name,
                                        trash: false,
                                    });

                                    await mutate(resolveFolderSWRKey({ userId }));
                                    return newFolder;
                                }}
                            />
                        )}
                        {(inHomeTab || inTrashTab) && <SelectMultipleButton />}
                        {inTrashTab && <EmptyTrashButtonWithHandler />}
                    </>
                )}
            </FlexRowAlignCenter>
            <div className={br.smDownDisplayNone} style={{ marginLeft: "auto" }} />
            <FlexRowAlignCenter>
                <SortButton
                    onChange={({ by, direction }) => setSort({ by, direction })}
                    by={sort.by}
                    direction={sort.direction}
                />
                {!inClassesTab && (
                    <>
                        <Select
                            selected={TAB_OPTIONS[currentFilesTab]}
                            onChange={({ value }) => changeCurrentFilesTab(value)}
                            transitionOrigin={{
                                anchorOrigin: { vertical: "bottom", horizontal: "left" },
                                transformOrigin: { vertical: "top", horizontal: "left" },
                            }}
                            btnSx={{
                                height: "4.8rem",
                                borderRadius: 999,
                                justifyContent: "space-between",
                                backgroundColor: themeColors.neutralWhite,
                                margin: `0 ${spacing.SM}`,
                                gap: spacing.SM,
                                "&:hover": {
                                    backgroundColor: themeColors.neutralBlack,
                                    color: themeColors.neutralWhite,
                                },
                            }}
                            menuSx={{ width: "15rem" }}
                            options={Object.values(TAB_OPTIONS)}
                        />
                        <FilterButton
                            allItemTypes={ALL_ITEM_TYPES}
                            selectedItemTypes={itemTypesToShow}
                            onChange={({ selectedItemTypes: newSelected }) => setItemTypesToShow(newSelected)}
                            inSavedTab={inSavedTab}
                        />
                    </>
                )}
            </FlexRowAlignCenter>
        </FlexRowAlignCenter>
    );
};

export default HomeControlSection;
