import { Folder } from "@knowt/syncing/graphql/schema";
import React from "react";
import { useUserContentManagementContextSelector } from "../hooks/useUserContentManagementContext";
import { UserContentItem } from "../types";
import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import FolderCard from "@/components/cards/FolderCard";
import UserContentCard from "@/components/cards/UserContentCard";
import RepeatAutoFillGrid from "@/components/styled/div/RepeatAutoFillGrid";
import { Rem } from "@/types/common";
import { isFlashcardSet, isNote } from "@knowt/syncing/utils/dataCleaning";

export const ContentsGrid = ({
    label,
    style,
    children,
    itemMinWidth,
    id,
}: {
    label: string;
    children: React.ReactNode;
    style?: React.CSSProperties;
    itemMinWidth: Rem;
    id?: string;
}) => {
    return (
        <FlexColumn id={id} style={{ scrollMargin: "10rem" }}>
            <FlexRowAlignCenter style={{ justifyContent: "space-between", marginBottom: "1.2rem", height: "2.6rem" }}>
                <span className="bodyBold2">{label}</span>
            </FlexRowAlignCenter>
            <RepeatAutoFillGrid itemMinWidth={itemMinWidth} style={{ gap: "3.5rem 3.3rem", ...style }}>
                {children}
            </RepeatAutoFillGrid>
        </FlexColumn>
    );
};

// just a renderer for the items (note and flashcard set)
export const ItemList = ({ items }: { items: UserContentItem[] }) => {
    const isSelectMode = useUserContentManagementContextSelector(state => state.isSelectMode);
    const selectedItems = useUserContentManagementContextSelector(state => state.selectedItems);
    const toggleSelectedItem = useUserContentManagementContextSelector(state => state.toggleSelectedItem);

    return (
        <React.Fragment>
            {items.map((item, idx) => {
                const itemId = isNote(item) ? item.noteId : isFlashcardSet(item) ? item.flashcardSetId : item.mediaId;

                return (
                    <UserContentCard
                        key={"homeItem" + itemId + idx}
                        {...(isNote(item)
                            ? { note: item }
                            : isFlashcardSet(item)
                            ? { flashcardSet: item }
                            : { media: item })}
                        modeProps={{
                            mode: isSelectMode ? "SELECT" : null,
                            isSelected: itemId in selectedItems,
                            toggleSelect: toggleSelectedItem,
                        }}
                    />
                );
            })}
        </React.Fragment>
    );
};

export const FolderList = ({ folders }: { folders: Folder[] }) => {
    const isSelectMode = useUserContentManagementContextSelector(state => state.isSelectMode);
    const selectedItems = useUserContentManagementContextSelector(state => state.selectedItems);
    const toggleSelectedItem = useUserContentManagementContextSelector(state => state.toggleSelectedItem);

    return (
        <React.Fragment>
            {folders.map(folder => {
                const folderId = folder.folderId;
                return (
                    <FolderCard
                        key={folderId}
                        folder={folder}
                        modeProps={{
                            mode: isSelectMode ? "SELECT" : null,
                            isSelected: folderId in selectedItems,
                            toggleSelect: () => toggleSelectedItem(folderId, folder),
                        }}
                    />
                );
            })}
        </React.Fragment>
    );
};
