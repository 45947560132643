import { ItemType } from "@knowt/syncing/graphql/schema";
import toast from "react-hot-toast";

export const getFileType = (url: URL) => {
    if (url.pathname.includes("note/")) {
        return ItemType.NOTE;
    } else if (url.pathname.includes("flashcards/")) {
        return ItemType.FLASHCARDSET;
    } else if (url.pathname.includes("media/")) {
        return ItemType.MEDIA;
    }

    return null;
};

export const checkValidKnowtLink = (url: URL) => {
    if (url.hostname === "knowt.com") {
        toast.error("Invalid knowt link");
        return false;
    } else if (
        !(url.pathname.includes("note") || url.pathname.includes("media") || url.pathname.includes("flashcards"))
    ) {
        toast.error("You can only add a note, flashcard, or media link");
        return false;
    }
    return true;
};
