"use client";

import React, { useEffect, useRef } from "react";
import { createContext, useContextSelector } from "@knowt/syncing/utils/use-context-selector";
import { Class, FlashcardSet, Folder, Media, Note, UserDetails } from "@knowt/syncing/graphql/schema";
import { SetState } from "@knowt/syncing/types/common";
import { STORAGE_KEYS } from "@/config/constants";
import { HomeFilesTab, HomeTab } from "../types";
import { UserContentSortSettings } from "../../types";
import { UserContentManagementContextProvider } from "../../hooks/useUserContentManagementContext";
import useCookieWithFallback from "@/hooks/useCookieWithFallback";
import { useSafeQueryNavigation } from "@/hooks/navigation/useSafeQueryNavigation";
import { useSearchParams } from "next/navigation";
import { mutate } from "swr";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { LocalUser } from "@knowt/syncing/hooks/user/types";
import dynamic from "next/dynamic";
import { ComplianceBanner } from "@/features/UserContentManagement";

const Lottie = dynamic(() => import("lottie-react"), { ssr: true });

export type HomeManagementContextProps = {
    currentTab: HomeTab;
    changeCurrentTab: SetState<HomeTab>;
    currentFilesTab: HomeFilesTab;
    changeCurrentFilesTab: (newTab: HomeFilesTab) => void;
    isClassesTab: boolean;
    //
    isTopSectionCollapsed: boolean;
    toggleTopSectionCollapse: () => void;
    //
    isStudentWithNoClasses: boolean;
};

const HomeManagementContext = createContext<HomeManagementContextProps | null>(null);

export const HomeManagementContextProvider = ({
    children,
    serverFolders,
    serverNotes,
    serverFlashcardSets,
    serverMedias,
    serverUser,
    serverClasses,
    sortValue,
    isTopSectionCollapsed: isTopSectionCollapsedInitialValue,
    homeTab,
    homeFilesTab,
    loadingAnimation,
}: {
    children: React.ReactNode;
    serverFolders?: Record<string, Folder>;
    serverNotes?: Record<string, Note>;
    serverFlashcardSets?: Record<string, FlashcardSet>;
    serverUser: UserDetails | null;
    serverMedias?: Record<string, Media>;
    serverClasses?: Record<string, Class>;
    sortValue: UserContentSortSettings;
    isTopSectionCollapsed: boolean;
    homeTab: HomeTab;
    homeFilesTab: HomeFilesTab;
    loadingAnimation?: string;
}) => {
    const searchParams = useSearchParams();
    const { user: swrUser } = useCurrentUser();
    const code = searchParams.get("code");
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        // make sure social sign in users gets logged in
        if (code && !swrUser) {
            intervalRef.current = setInterval(async () => {
                (await mutate("getCurrentUser")) as LocalUser;

                // if (localUser.user) {
                //     const url = await getInitialSetupUrl({ user: localUser.user });

                //     if (url) {
                //         router.replace(url);
                //     }
                // }
            }, 1_000);
        } else if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [code, serverUser, swrUser]);

    const [isTopSectionCollapsed, setIsTopSectionCollapsed] = useCookieWithFallback<boolean>({
        key: STORAGE_KEYS.HOME_PAGE_IS_TOP_SECTION_COLLAPSABLE,
        fallback: isTopSectionCollapsedInitialValue,
    });

    const tabFromParams = searchParams.get("tab") as HomeTab | null;
    const filesTabFromParams = searchParams.get("filterBy") as HomeFilesTab | null;

    const [currentTab, setCurrentTab] = useCookieWithFallback<HomeTab>({
        key: STORAGE_KEYS.HOME_PAGE_TAB,
        fallback: tabFromParams ?? homeTab,
    });

    const [currentFilesTab, setCurrentFilesTab] = useCookieWithFallback<HomeFilesTab>({
        key: STORAGE_KEYS.HOME_PAGE_FILES_TAB,
        fallback: filesTabFromParams ?? homeFilesTab,
    });

    const { addParamsReplace } = useSafeQueryNavigation();

    const isStudentWithNoClasses = serverUser?.accountType === "Student" && !serverUser.classes?.enrolled?.length;

    const toggleTopSectionCollapse = () => setIsTopSectionCollapsed(!isTopSectionCollapsed);

    const isClassesTab = currentTab === HomeTab.CLASSES;

    const changeCurrentFilesTab = (newTab: HomeFilesTab) => {
        setCurrentFilesTab(newTab);
        addParamsReplace({ tab: currentTab, filterBy: newTab });
    };

    const changeCurrentTab = (newTab: HomeTab) => {
        setCurrentTab(newTab);
        setCurrentFilesTab(HomeFilesTab.HOME);
        addParamsReplace({ tab: newTab, filterBy: currentFilesTab });
    };

    if (code && !swrUser) {
        // LOGIN for social sign in users.
        return (
            <div
                style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                <Lottie animationData={loadingAnimation} className="flex justify-center items-center" loop={true} />
            </div>
        );
    }

    return (
        <HomeManagementContext.Provider
            value={{
                currentTab,
                changeCurrentTab,
                currentFilesTab,
                changeCurrentFilesTab,
                isClassesTab,
                //
                isTopSectionCollapsed,
                toggleTopSectionCollapse,
                //
                isStudentWithNoClasses,
            }}>
            <UserContentManagementContextProvider
                serverFolders={serverFolders}
                serverNotes={serverNotes}
                serverFlashcardSets={serverFlashcardSets}
                serverUser={serverUser}
                serverMedias={serverMedias}
                serverClasses={serverClasses}
                sortValue={sortValue}>
                {currentTab === HomeTab.CLASSES && <ComplianceBanner />}
                {children}
            </UserContentManagementContextProvider>
        </HomeManagementContext.Provider>
    );
};

export const useHomeManagementContextSelector = <T,>(selector: (context: HomeManagementContextProps) => T) =>
    useContextSelector(HomeManagementContext, selector);
