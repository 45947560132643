import { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import Dialog from "@/components/Dialog";
import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import LoadingAnimation from "@/components/LoadingAnimation";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import ClickableText from "@/components/styled/button/ClickableText";
import BorderColoredInput from "@/components/styled/input/BorderColoredInput";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";
import { themeColors } from "@/utils/themeColors";

export type NamingPopupProps = {
    title: string;
    isOpen: boolean;
    onClose: () => void;
    onConfirm: (name: string) => Promise<void>;
    placeholder: string;
    defaultName?: string;
};

export const NamingPopupBody = ({
    isOpen,
    onClose,
    title,
    onConfirm,
    placeholder,
    defaultName = "",
}: NamingPopupProps) => {
    const [name, setName] = useState(defaultName);
    const [isLoading, setIsLoading] = useState(false);

    // when popup close, update name to defaultName
    if (!isOpen && name !== defaultName) {
        setName(defaultName);
    }

    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (isOpen) inputRef.current?.focus();
    }, [isOpen]);

    const confirmAndClose = async e => {
        e?.stopPropagation();
        try {
            setIsLoading(true);
            await onConfirm(name);
            onClose();
        } catch (e) {
            toast.error(e.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <FlexColumn data-testid={"naming-popup"}>
            <label style={{ marginTop: "1rem", fontSize: "2.4rem", fontWeight: "600" }} htmlFor={"name"}>
                {title}
            </label>
            <BorderColoredInput
                ref={inputRef}
                id={"name"}
                value={name}
                onChange={event => setName(event.target.value)}
                onKeyDown={async e => {
                    if (!isLoading && e.key === "Enter" && isOpen && name && name.trim() !== "") {
                        await confirmAndClose(null);
                    }
                }}
                placeholder={placeholder}
                style={{
                    height: "5rem",
                    fontSize: "1.4rem",
                    borderRadius: "2rem",
                    margin: "3.3rem 0",
                    fontFamily: "var(--knowt-font-name)",
                    fontWeight: "600",
                }}
            />
            <FlexRowAlignCenter style={{ justifyContent: "flex-end", gap: "2rem" }}>
                <ClickableText onClick={onClose}>cancel</ClickableText>
                <CircularRectTextButton
                    onClick={confirmAndClose}
                    sx={{
                        backgroundColor: themeColors.primary,
                        width: "10rem",
                        height: "4.2rem",
                        color: themeColors.pureWhite,
                        fontWeight: "600",
                        fontSize: "1.6rem",
                    }}>
                    {isLoading ? <LoadingAnimation color="white" size={22} /> : defaultName ? "save" : "create"}
                </CircularRectTextButton>
            </FlexRowAlignCenter>
        </FlexColumn>
    );
};

const NamingPopup = ({
    title,
    isOpen,
    onClose,
    onConfirm: onConfirmClick,
    placeholder,
    defaultName,
}: NamingPopupProps) => {
    const { smDown } = useBreakPoints();
    return (
        <Dialog
            PaperProps={{
                style: {
                    position: "relative",
                    borderRadius: "2rem",
                    color: "var(--color-neutral-black)",
                    backgroundColor: "var(--color-background)",
                    width: "65rem",
                    maxWidth: "200rem",
                    height: "auto",
                    flexDirection: "column",
                    padding: !smDown ? "4.1rem 3.6rem" : "4.2rem 2rem",
                },
            }}
            open={isOpen}
            onClose={onClose}
            onMouseDown={e => {
                e.stopPropagation();
            }}>
            <NamingPopupBody
                {...{ title, isOpen, onClose: onClose, onConfirm: onConfirmClick, placeholder, defaultName }}
            />
        </Dialog>
    );
};

export default NamingPopup;
