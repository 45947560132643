import { Folder, ItemType, UpgradeEvent } from "@knowt/syncing/graphql/schema";
import FolderIconBtn from "./FolderIconBtn";
import LazyLoaded from "@/components/LazyLoaded";
import { preventStopPropogation } from "@/utils/domUtils";
import { HexColorPicker } from "react-colorful";
import { useTheme } from "@/utils/theme";
import dynamic from "next/dynamic";
import { Theme } from "emoji-picker-react";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { useUpgradePopupContextSelector } from "@/components/Popup/Subscription/UpgradePopupContext";
import { useRef, useState } from "react";
import { themeColors } from "@/utils/themeColors";
import FloatingMenu from "@/components/menus/FloatingMenu";
import { useFolder } from "@knowt/syncing/hooks/folders/useFolders";
import { mutate } from "swr";
import { resolveFoldersSWRKey } from "@knowt/syncing/hooks/folders/utils";

const Picker = dynamic(() => import("emoji-picker-react"), { ssr: false });

// TODO(types): add types for props
const FolderIcon = ({
    folder,
    menuBtnRef = undefined,
    showColorPicker,
    setShowColorPicker,
    loadColorPicker,
    showEmojiPicker,
    setShowEmojiPicker,
    loadEmojiPicker,
}) => {
    const { theme } = useTheme();

    const { update: _update } = useFolder({
        folderId: folder?.folderId,
        isEnabled: showColorPicker || showEmojiPicker,
    });

    const update = async (newFolderData: Partial<Folder>) => {
        await _update(newFolderData);
        mutate(
            resolveFoldersSWRKey({ userId: folder.userId }),
            oldFolders => ({
                ...oldFolders,
                [folder.folderId]: {
                    ...oldFolders[folder.folderId],
                    ...newFolderData,
                },
            }),
            {
                revalidate: false,
            }
        );
    };

    const { isSubscriptionActive } = useCurrentUser();
    const openUpgradePopup = useUpgradePopupContextSelector(state => state.openUpgradePopup);

    const [selectedColor, setSelectedColor] = useState(folder?.color);

    const iconContainerRef = useRef<HTMLDivElement>(null);

    return (
        <>
            <span ref={iconContainerRef}>
                <FolderIconBtn
                    folder={folder}
                    selectedColor={selectedColor}
                    onClick={
                        menuBtnRef
                            ? () => {
                                  if (!isSubscriptionActive) {
                                      openUpgradePopup({
                                          event: folder?.icon?.emoji
                                              ? UpgradeEvent.FOLDER_EMOJI
                                              : UpgradeEvent.FOLDER_COLOR,
                                          context: {
                                              itemId: folder?.folderId,
                                              itemType: ItemType.FOLDER,
                                          },
                                      });
                                  } else {
                                      menuBtnRef?.current?.click?.();
                                  }
                              }
                            : undefined
                    }
                />
            </span>
            <LazyLoaded load={showColorPicker || loadColorPicker}>
                <FloatingMenu
                    isOpen={showColorPicker}
                    onClose={e => {
                        preventStopPropogation(e);
                        setShowColorPicker(false);

                        if (selectedColor !== folder?.color) {
                            update({ color: selectedColor, icon: null });
                        }
                    }}
                    minWidth="5rem"
                    darkenOverlay={false}
                    padding="0"
                    listStyle={{ padding: "0" }}
                    paperStyle={{ overflow: "hidden" }}
                    top={(iconContainerRef.current?.getBoundingClientRect()?.bottom ?? 0) + 12}
                    left={iconContainerRef.current?.getBoundingClientRect()?.left}
                    items={
                        <div onClick={preventStopPropogation}>
                            <HexColorPicker
                                color={selectedColor || themeColors.neutralBlack}
                                onChange={color => setSelectedColor(color)}
                            />
                        </div>
                    }
                />
            </LazyLoaded>
            <LazyLoaded load={showEmojiPicker || loadEmojiPicker}>
                <FloatingMenu
                    isOpen={showEmojiPicker}
                    onClose={event => {
                        event.stopPropagation();
                        setShowEmojiPicker(false);
                    }}
                    minWidth="5rem"
                    darkenOverlay={false}
                    padding="0"
                    listStyle={{ padding: "0" }}
                    top={(iconContainerRef.current?.getBoundingClientRect()?.bottom ?? 0) + 12}
                    left={iconContainerRef.current?.getBoundingClientRect()?.left}
                    items={
                        showEmojiPicker && (
                            <div onClick={event => event.stopPropagation()}>
                                {folder?.icon?.emoji && (
                                    <div
                                        style={{
                                            textAlign: "center",
                                            padding: "1rem",
                                            fontWeight: "600",
                                            fontSize: "1.4rem",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            setShowEmojiPicker(false);
                                            update({ icon: { emoji: null, skinTone: null } });
                                        }}>
                                        {"Remove Icon"}
                                    </div>
                                )}

                                <Picker
                                    theme={theme as Theme}
                                    searchPlaceHolder={"Search with descriptions!"}
                                    onEmojiClick={emojiData => {
                                        setShowEmojiPicker(false);

                                        update({
                                            icon: {
                                                emoji: emojiData.emoji,
                                                skinTone: emojiData.activeSkinTone,
                                            },
                                        });
                                    }}
                                />
                            </div>
                        )
                    }
                />
            </LazyLoaded>
        </>
    );
};

export default FolderIcon;
