import { isFlashcardSet } from "@knowt/syncing/utils/dataCleaning";
import { iconSizes, strokeWidth } from "@/utils/iconProps";
import { Merge } from "lucide-react";
import { useRouter } from "next13-progressbar";
import { useUserContentManagementContextSelector } from "../../hooks/useUserContentManagementContext";
import BadgedButton from "../styled/BadgedButton";

const MergeSetButton = ({ folderId }: { folderId?: string }) => {
    const router = useRouter();

    const selectedItems = useUserContentManagementContextSelector(state => state.selectedItems);
    const selectedCount = Object.keys(selectedItems).length;

    const isAllSelectedFlashcardSet = () => {
        const items = Object.values(selectedItems);
        if (items.length === 0) {
            return false;
        }
        return items.every(item => isFlashcardSet(item));
    };

    if (!isAllSelectedFlashcardSet()) {
        return null;
    }

    // at this point, we are sure that all selected items are flashcard sets
    const routeToMergeSetPage = () => {
        const flashcardSetIds = Object.keys(selectedItems);
        const folderQuery = folderId ? `&folderId=${folderId}` : "";

        const setIdsSearchParams = new URLSearchParams(flashcardSetIds.map(s => ["flashcardSetId", s]));
        router.push(`/flashcards/merge?${setIdsSearchParams.toString()}${folderQuery}`);
    };

    return (
        <BadgedButton badgeCount={selectedCount} onClick={routeToMergeSetPage}>
            <Merge size={iconSizes.MD} strokeWidth={strokeWidth.normal} /> Merge set
        </BadgedButton>
    );
};

export default MergeSetButton;
