import { useFlashcardSets } from "@/hooks/flashcards/useFlashcards";
import { useMedias } from "@/hooks/media/useMedia";
import { useNotesMetadata } from "@/hooks/notes/useNotes";
import { useCurrentUser } from "@/hooks/user/useCurrentUser";
import { ItemType, View } from "@knowt/syncing/graphql/schema";
import { populateRecentlyViewedItemsWithMetadata } from "./utils";
import { SuggestedHomeSearchItems } from "../localSearchSuggestions/useLocalSearchSuggestions";
import { UNTITLED } from "@/utils/dataCleaning";
import { timeDeltaFromNow } from "@/utils/dateTimeUtils";

const typeIs = (targetType: ItemType) => (view: View) => view.type === targetType;
const getId = (view: View) => view.ID;

const formatRecentlyViewed = (item: any): SuggestedHomeSearchItems => {
    return {
        ...item,
        id: item.ID,
        title: item?.title ?? UNTITLED,
        description: `Viewed ${item?.time ? timeDeltaFromNow(item?.time) : "a while"} ago`,
    };
};

export const useRecentlyViewed = (
    { isEnabled = true, length }: { isEnabled?: boolean; length?: number } = {
        isEnabled: true,
    }
) => {
    const { user, loginInProgress } = useCurrentUser();
    const _recentlyViewed = !user?.recentlyViewed ? [] : user?.recentlyViewed;
    const recentlyViewed = length ? _recentlyViewed?.slice(0, length) : _recentlyViewed;

    const recentlyViewedNoteIds = recentlyViewed?.filter(typeIs(ItemType.NOTE)).map(getId);
    const recentlyViewedFlashcardSetIds = recentlyViewed?.filter(typeIs(ItemType.FLASHCARDSET)).map(getId);
    const recentlyViewedMediaIds = recentlyViewed?.filter(typeIs(ItemType.MEDIA)).map(getId);

    const { notesMetadata: notes } = useNotesMetadata(recentlyViewedNoteIds, isEnabled);
    const { flashcardSets } = useFlashcardSets(recentlyViewedFlashcardSetIds, isEnabled);
    const { medias } = useMedias(recentlyViewedMediaIds, isEnabled);

    const recentlyViewItemWithMetaData = populateRecentlyViewedItemsWithMetadata(user, notes, flashcardSets, medias);

    const allDataFetched = !!notes && !!flashcardSets && !!medias;
    const loading = loginInProgress || (!!user && !allDataFetched);

    return { loading, data: recentlyViewItemWithMetaData?.map(formatRecentlyViewed) };
};
