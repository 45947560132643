import GUIDED_EVENTS, { AllEventNames } from "@knowt/syncing/hooks/guidedEvents/guidedEvents";
import useGuidedEvent from "@knowt/syncing/hooks/guidedEvents/useGuidedEvent";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import dynamic from "next/dynamic";
import React, { useEffect, useState } from "react";
import { useDetectAdBlock } from "@/hooks/useDetectAdBlocker";
import UpdatesPopup from "@/components/Popup/WalkthroughAndUpdateCards/UpdatesPopup";
import PaymentExpiredCard from "@/components/Popup/Subscription/PaymentExpiredCard";
import LazyLoaded from "@/components/LazyLoaded";
import { useInitialAccountSetupContextSelector } from "@/components/Popup/InitialAccountSetupPopup/InitialAccountSetupContext";
import { FeedbackEvents } from "@/components/Feedback/feedbackPopupContent";
import { FeedbackSubmitHandler } from "@/components/Feedback/FeedbackFlowContext";
import Mixpanel from "@/utils/analytics/Mixpanel";

const AdBlockPopup = dynamic(() => import("@/components/Popup/AdBlockPopup"));
const FloatingFeedbackSelector = dynamic(() => import("@/components/Feedback/FloatingFeedbackSelector"));
const PrivacyPopup = dynamic(() => import("./PrivacyPopup"));

const HomePopups = () => {
    const { userId, isSubscriptionPastDue } = useCurrentUser();
    const [isPaymentExpiredCardPopupOpen, setIsPaymentExpiredCardPopupOpen] = useState(false);

    useEffect(() => {
        if (isSubscriptionPastDue) {
            setIsPaymentExpiredCardPopupOpen(true);
        }
    }, [isSubscriptionPastDue]);

    const isExamSelectionOpen = useInitialAccountSetupContextSelector(state => state.isExamSelectionOpen);

    const { latestEvent, closeLatestEvent, allStagedEvents } = useGuidedEvent(GUIDED_EVENTS.ON_HOME_PAGE);

    const guidedEventsBlocked =
        allStagedEvents?.includes(AllEventNames.GOAL_FEEDBACK) ||
        allStagedEvents?.includes(AllEventNames.REFERRAL_FEEDBACK);

    const isPrivacyPopupOpen =
        !guidedEventsBlocked && latestEvent?.eventName === AllEventNames.PRIVACY_AGREEMENT_MARCH_2024;

    const isFeedbackSelectorOpen = !guidedEventsBlocked && latestEvent?.eventName === AllEventNames.EXPERIENCE;

    const isUpdatesPopupOpen =
        !guidedEventsBlocked &&
        (latestEvent?.eventName === AllEventNames.CHX || latestEvent?.eventName === AllEventNames.SEPT2023) &&
        !isExamSelectionOpen;

    const canShowAdblockerPopup =
        !guidedEventsBlocked && !isExamSelectionOpen && latestEvent?.eventName === AllEventNames.ADBLOCKER;

    const showAdBlockerPopup = useDetectAdBlock() && canShowAdblockerPopup;

    const onFeedbackSubmitHandler: FeedbackSubmitHandler = ({ rating, category, followUp, reason }) => {
        Mixpanel.track("Home Feedback Submitted", {
            rating,
            category,
            followUp,
            reason,
        });
        closeLatestEvent();
    };

    if (!userId) return null;

    return (
        <React.Fragment>
            <PaymentExpiredCard
                isOpen={isPaymentExpiredCardPopupOpen}
                onClose={() => setIsPaymentExpiredCardPopupOpen(false)}
            />
            <LazyLoaded load={isPrivacyPopupOpen}>
                <PrivacyPopup isOpen={isPrivacyPopupOpen} onClose={closeLatestEvent} />
            </LazyLoaded>

            <LazyLoaded load={!!showAdBlockerPopup}>
                <AdBlockPopup isOpen={showAdBlockerPopup} onClose={closeLatestEvent} />
            </LazyLoaded>
            <LazyLoaded load={isUpdatesPopupOpen}>
                <UpdatesPopup
                    isOpen={isUpdatesPopupOpen}
                    onClose={closeLatestEvent}
                    eventName={latestEvent?.eventName}
                />
            </LazyLoaded>
            <LazyLoaded load={isFeedbackSelectorOpen}>
                <FloatingFeedbackSelector
                    title="How do you feel about Knowt?"
                    autoCloseAfter={15000}
                    feedbackFor={FeedbackEvents.HOME_EXPERIENCE}
                    isOpen={isFeedbackSelectorOpen}
                    onSubmitHandler={onFeedbackSubmitHandler}
                    onClose={closeLatestEvent}
                />
            </LazyLoaded>
        </React.Fragment>
    );
};

export default HomePopups;
