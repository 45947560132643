import React from "react";
import { createContext, useContextSelector } from "@knowt/syncing/utils/use-context-selector";

import { useFolder } from "@knowt/syncing/hooks/folders/useFolders";
import { FlashcardSet, Folder, Media, Note, UserDetails } from "@knowt/syncing/graphql/schema";
import { useParams } from "next/navigation";
import {
    UserContentManagementContextProps,
    UserContentManagementContextProvider,
} from "../../hooks/useUserContentManagementContext";
import { UserContentSortSettings } from "../../types";

type FolderManagementContextProps = {
    folder: Folder;
    folderId: string;
    readOnly: boolean;
    mutate: () => void;
    update: (folder: Partial<Folder>) => void;
};

const FolderManagementContext = createContext<FolderManagementContextProps | null>(null);

export const FolderManagementContextProvider = ({
    children,
    serverFolder,
    serverFolders,
    serverNotes,
    serverFlashcardSets,
    serverMedias,
    serverUser,
    sortValue,
    readOnly,
}: {
    children: React.ReactNode;
    serverFolder: Folder;
    serverFolders: Record<string, Folder>;
    serverNotes: Record<string, Note>;
    serverFlashcardSets: Record<string, FlashcardSet>;
    serverMedias: Record<string, Media>;
    sortValue: UserContentSortSettings;
    readOnly: boolean;
    serverUser: UserDetails | null;
}) => {
    const { folderId } = useParams() as { folderId: string };
    const { folder, mutate, update } = useFolder({ folderId: serverFolder.folderId, fallbackData: serverFolder });

    return (
        <FolderManagementContext.Provider value={{ folder, folderId, readOnly, mutate, update }}>
            <UserContentManagementContextProvider
                serverFolders={serverFolders}
                serverNotes={serverNotes}
                serverFlashcardSets={serverFlashcardSets}
                serverMedias={serverMedias}
                serverUser={serverUser}
                sortValue={sortValue}>
                {children}
            </UserContentManagementContextProvider>
        </FolderManagementContext.Provider>
    );
};

export const useFolderManagementContextSelector = <T,>(
    selector: (context: FolderManagementContextProps & UserContentManagementContextProps) => T
): T => useContextSelector(FolderManagementContext, selector);
