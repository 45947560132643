import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { FlexColumn, FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import FolderIconBtn from "@/components/cards/FolderCard/components/FolderIconBtn";
import { ASSETS_URL } from "@/config/deployConstants";
import { getImgFromItemType } from "@/features/UserContentManagement";
import { themeColors } from "@/utils/themeColors";
import { ItemType } from "@knowt/syncing/graphql/schema";
import {
    getLinkPerItemType,
    SuggestedHomeSearchItems,
} from "@knowt/syncing/hooks/localSearchSuggestions/useLocalSearchSuggestions";
import Image from "next/image";
import { useRouter } from "next13-progressbar";

export const SearchEmptyState = ({
    containerStyle,
    isSearch = true,
}: {
    containerStyle?: React.CSSProperties;
    isSearch?: boolean;
}) => {
    return (
        <FlexColumnAlignJustifyCenter style={{ textAlign: "center", marginTop: "6rem", ...containerStyle }}>
            <Image
                src={`${ASSETS_URL}/images/kai-confused.svg`}
                alt="no content"
                width="190"
                height={200}
                style={{ objectFit: "contain", marginBottom: "3rem" }}
            />
            <span className="bodyBold2">{isSearch ? "No results found!" : "It's looking a little empty..."}</span>
            <span className="secondaryText1">
                {isSearch ? "Try searching for a different filename." : "Add or move some files here"}
            </span>
        </FlexColumnAlignJustifyCenter>
    );
};

const MyFilesSearchCard = ({
    item,
    onMouseMoveCapture,
    onClick,
    isWithViewButton = false,
    sx,
}: {
    item: SuggestedHomeSearchItems;
    onMouseMoveCapture?: () => void;
    onClick?: () => void;
    isWithViewButton?: boolean;
    sx?: React.CSSProperties;
}) => {
    const router = useRouter();

    return (
        <CircularRectTextButton
            component={"div"}
            role="button"
            onClick={() => {
                if (onClick) onClick();
                else {
                    router.push(getLinkPerItemType(item.type, item.id));
                }
            }}
            onMouseMoveCapture={onMouseMoveCapture}
            sx={{
                padding: "1rem 1.5rem",
                backgroundColor: themeColors.neutral1,
                justifyContent: "space-between",
                borderRadius: "2rem",
                color: themeColors.neutralBlack,
                gap: "0.8rem",
                ...sx,
            }}>
            <FlexRowAlignCenter style={{ gap: "1.5rem", textAlign: "start" }}>
                {item.type === ItemType.FOLDER ? (
                    <FolderIconBtn folder={item} />
                ) : (
                    <Image
                        src={getImgFromItemType(item.type)}
                        width="24"
                        height="24"
                        style={{ objectFit: "contain" }}
                        alt={"resume study"}
                    />
                )}
                <FlexColumn>
                    <span
                        className="bodyBold2"
                        style={{
                            overflowWrap: "anywhere",
                            display: "-webkit-box",
                            WebkitLineClamp: "1",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}>
                        {item.title}
                    </span>
                    {/* recently viewed data contains the time  */}
                    <span className="secondaryText2" style={{ overflowWrap: "anywhere" }}>
                        {item.description}
                    </span>
                </FlexColumn>
            </FlexRowAlignCenter>
            {isWithViewButton && (
                <CircularRectTextButton
                    onClick={e => {
                        e.stopPropagation();
                        router.push(getLinkPerItemType(item.type, item.id));
                    }}
                    sx={{
                        backgroundColor: themeColors.neutralWhite,
                        color: themeColors.neutralBlack,
                        height: "3.6rem",
                        padding: "0 1.2rem",
                        gap: "0.5rem",
                        width: "auto",
                        justifyContent: "space-between",
                        ":hover": { backgroundColor: themeColors.neutralBlack, color: themeColors.neutralWhite },
                        transition: "all 0.2s ease-in-out",
                    }}>
                    view
                </CircularRectTextButton>
            )}
        </CircularRectTextButton>
    );
};

export default MyFilesSearchCard;
