import { useEffect, useMemo, useState } from "react";
import { fetchNoteMetadata } from "../notes/graphqlUtils";
import { useAllBookmarks } from "@/hooks/bookmarks/useBookmarks";
import { callGetRawFlashcardSet } from "@/hooks/flashcards/graphqlUtils";
import { callGetMedia } from "@/hooks/media/graphqlUtils";
import { useCurrentUser } from "@/hooks/user/useCurrentUser";
import { FlashcardSet, ItemType, Media, Note } from "@knowt/syncing/graphql/schema";
import { wait } from "@/utils/genericUtils";
import { useNotebook } from "../notes/useNotes";
import { useAllStandaloneFlashcardSets } from "../flashcards/useStandaloneFlashcards";
import { useAllMedias } from "../media/useMedia";

export const useSavedUserContents = (props: { delay?: number; types?: ItemType[] }) => {
    const { delay, types: propTypes } = props;
    const types = useMemo(() => propTypes ?? [ItemType.NOTE, ItemType.FLASHCARDSET, ItemType.MEDIA], [propTypes]);

    const { userId } = useCurrentUser();
    const { rawBookmarks } = useAllBookmarks();

    const bookmarks = useMemo(() => rawBookmarks?.filter(({ type }) => types.includes(type)), [rawBookmarks, types]);

    const { notes: notesObj } = useNotebook({ inTrash: false, isEnabled: types.includes(ItemType.NOTE) });

    const { standaloneFlashcards: flashcardSetsObj } = useAllStandaloneFlashcardSets({
        inTrash: false,
        isEnabled: types.includes(ItemType.FLASHCARDSET),
    });

    const { medias: mediasObj } = useAllMedias({ inTrash: false, isEnabled: types.includes(ItemType.MEDIA) });

    const [savedNotes, setSavedNotes] = useState<Note[]>(null);
    const [savedFlashcardSets, setSavedFlashcardSets] = useState<FlashcardSet[]>(null);
    const [savedMedias, setSavedMedias] = useState<Media[]>(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!userId || !bookmarks) {
            setIsLoading(false);
            return;
        }

        const asyncFunction = async () => {
            const savedNotesIds = bookmarks
                .filter(({ type }) => type === ItemType.NOTE)
                .filter(({ ID }) => !notesObj?.[ID])
                .map(({ ID }) => ID);

            const savedFlashcardSetsIds = bookmarks
                .filter(({ type }) => type === ItemType.FLASHCARDSET)
                .filter(({ ID }) => !flashcardSetsObj?.[ID])
                .map(({ ID }) => ID);

            const savedMediasIds = bookmarks
                .filter(({ type }) => type === ItemType.MEDIA)
                .filter(({ ID }) => !mediasObj?.[ID])
                .map(({ ID }) => ID);

            await wait(delay);

            const [notes, flashcardSets, medias] = await Promise.all([
                Promise.all(savedNotesIds.map(noteId => fetchNoteMetadata({ noteId }))),
                Promise.all(
                    savedFlashcardSetsIds.map(flashcardSetId =>
                        callGetRawFlashcardSet({ flashcardSetId }).catch(() => null)
                    )
                ),
                Promise.all(savedMediasIds.map(mediaId => callGetMedia({ mediaId }).catch(() => null))),
            ]);

            setSavedNotes(notes.filter(Boolean).filter(({ classId, trash }) => classId === null && !trash));
            setSavedFlashcardSets(
                flashcardSets.filter(Boolean).filter(({ classId, trash }) => classId === null && !trash)
            );
            setSavedMedias(medias.filter(Boolean).filter(({ trash }) => !trash));
        };

        asyncFunction().finally(() => setIsLoading(false));
    }, [notesObj, flashcardSetsObj, mediasObj, bookmarks, delay, userId]);

    return { savedNotes, savedFlashcardSets, savedMedias, isLoading };
};
