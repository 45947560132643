import { getUpdateContent } from "@/components/Popup/WalkthroughAndUpdateCards/constants";
import SimpleSlideCard from "@/components/Popup/PopupCards/SimpleSlideCard";
import { themeColors } from "@/utils/themeColors";
import { maybeOpenChromeExtensionInstallPage } from "@/utils/chromeExtensionUtils";
import { useRouter } from "next13-progressbar";
import { AllEventNames } from "@knowt/syncing/hooks/guidedEvents/guidedEvents";

const UpdatesPopup = ({
    isOpen,
    onClose,
    eventName = "",
}: {
    isOpen: boolean;
    onClose: () => void;
    eventName?: string;
}) => {
    const router = useRouter();

    const slides =
        // add onSelectes as onClose for last slid in sept2023
        eventName === AllEventNames.SEPT2023
            ? getUpdateContent(eventName)?.content?.map((slide, index) => {
                  const slideLength = getUpdateContent(eventName)?.content?.length ?? 0;
                  if (index === slideLength - 1) {
                      return {
                          ...slide,
                          onSelected: onClose,
                      };
                  }
                  return slide;
              })
            : eventName === AllEventNames.CHX
            ? // add onNext to last slide
              getUpdateContent("sept2023")
                  ?.content.slice(0, (getUpdateContent("sept2023")?.content?.length ?? 0) - 1)
                  .concat(
                      getUpdateContent(eventName)?.content?.map((slide, index) => {
                          const slideLength = getUpdateContent(eventName)?.content?.length ?? 0;
                          if (index === slideLength - 1) {
                              return {
                                  ...slide,
                                  onNextButtonProps: {
                                      text: "try it out!",
                                      sx: {
                                          padding: "1.2rem 2.4rem",
                                          color: themeColors.primaryDark,
                                          backgroundColor: themeColors.primary,
                                          borderColor: themeColors.primaryDark,
                                      },
                                      onClick: () => {
                                          maybeOpenChromeExtensionInstallPage(router);
                                      },
                                  },
                              };
                          }
                          return slide;
                      }) ?? []
                  )
            : getUpdateContent(eventName)?.content || [];

    return (
        <SimpleSlideCard
            isOpen={isOpen}
            onClose={onClose}
            slides={slides ?? []}
            slideIndex={eventName === "chx" ? (slides?.length ?? 0) - 1 : 0}
        />
    );
};

export default UpdatesPopup;
