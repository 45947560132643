import React from "react";
import { Class, ClassSection, FlashcardSet } from "@knowt/syncing/graphql/schema";
import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import { spacing } from "@/utils/spacing";
import InputWithMenu from "@/components/InputWithMenu";
import { themeColors } from "@/utils/themeColors";
import SectionPicker from "../../../components/SectionPicker";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { ArrowRight } from "lucide-react";
import { iconSizes } from "@/utils/iconProps";
import { UNTITLED } from "@knowt/syncing/utils/dataCleaning";
import br from "@/styles/breakpoints.module.css";

type FlashcardSetPickerProps = {
    course: Class;
    classFlashcardSets: Record<string, FlashcardSet>;
    selectedFlashcardSet: FlashcardSet | null | undefined;
    onChange: (event: React.SyntheticEvent, newValue: { value: string } | null) => void; // Update these types as per your requirements
    selectedSection: ClassSection | null;
    onSectionChange: (value: ClassSection | null) => void;
    onViewFlashcardSetClick: () => void | Promise<boolean>;
};

const FlashcardSetPicker = ({
    course,
    classFlashcardSets,
    selectedFlashcardSet,
    onChange,
    selectedSection,
    onSectionChange,
    onViewFlashcardSetClick,
}: FlashcardSetPickerProps) => {
    return (
        <FlexRowAlignCenter
            style={{ justifyContent: "space-between", marginBottom: spacing.MD, gap: spacing.MD }}
            className={br.smDownColumn}>
            <FlexRowAlignCenter style={{ gap: spacing.MD, width: "100%" }} className={br.smDownColumn}>
                <FlexColumn style={{ rowGap: spacing.XS }} className={br.smDownWidth100}>
                    <p className="bodyBold2"> Flashcards</p>
                    <InputWithMenu
                        useAutocompleteProps={{
                            options: Object.values(classFlashcardSets)
                                .filter(set => !set.draft)
                                .map(flashcardSet => ({
                                    label: flashcardSet.title ?? UNTITLED,
                                    value: flashcardSet.flashcardSetId,
                                })),
                            value: {
                                label: selectedFlashcardSet?.title ?? UNTITLED,
                                value: selectedFlashcardSet?.flashcardSetId ?? "",
                            },
                            onChange: onChange,
                        }}
                        inputProps={{
                            placeholder: "Search flashcard sets",
                            sx: {
                                borderRadius: "999px",
                                height: "4.8rem",
                                backgroundColor: themeColors.neutralWhite,
                                padding: "0 1.5rem",
                                minWidth: "30rem",
                            },
                        }}
                        ulProps={{
                            sx: {
                                backgroundColor: "var(--color-neutral-white)",
                                borderRadius: "1.2rem",
                                top: "5.6rem",
                                maxHeight: "18.5rem",
                                boxShadow: "0 6px 20px 0 rgba(0,0,0,.08)",
                            },
                        }}
                    />
                </FlexColumn>
                {course.sections.length > 1 && (
                    <FlexColumn style={{ rowGap: spacing.XS }} className={br.smDownWidth100}>
                        <p className="bodyBold2">Sections</p>
                        <SectionPicker selectedSection={selectedSection} onSectionChange={onSectionChange} />
                    </FlexColumn>
                )}
            </FlexRowAlignCenter>
            <CircularRectTextButton
                disabled={!selectedFlashcardSet}
                onClick={onViewFlashcardSetClick}
                sx={{
                    alignSelf: "flex-end",
                    paddingInline: spacing.SM,
                    paddingBlock: spacing.XS_2,
                    fontSize: "1.5rem",
                    gap: "1rem",
                    opacity: selectedFlashcardSet ? 1 : 0.8,
                }}>
                view flashcard set
                <ArrowRight size={iconSizes.MD} color={themeColors.neutralBlack} />
            </CircularRectTextButton>
        </FlexRowAlignCenter>
    );
};

export default FlashcardSetPicker;
