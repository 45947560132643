import { moveFlashcardSets } from "@knowt/syncing/hooks/flashcards/utils";
import { callCreateFolder } from "@knowt/syncing/hooks/folders/graphqlUtils";
import {
    deleteFolder,
    moveFolder,
    resolveNestedFoldersSWRKey,
    restoreFolder,
} from "@knowt/syncing/hooks/folders/utils";
import { moveNotes } from "@knowt/syncing/hooks/notes/utils";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { mutate } from "swr";
import { v4 as uuidv4 } from "uuid";
import { useFolderManagementContextSelector } from "../hooks/useFolderManagementContext";
import FolderTrashButtonWithHandler from "./FolderTrashButton";
import ClickableText from "@/components/styled/button/ClickableText";
import { ClassSharingPillWithHandler, SharingPillWithHandler } from "@/features/ShareSettings";
import { MoveToPopupProvider } from "features/MoveToPopup";
import { FlexRowAlignCenter } from "@/components/Flex";
import { CreateNewContentButtonWithHandler } from "@/components/CreateNewContent/CreateNewContentButtonWithHandler";
import { ALL_ITEM_TYPES } from "../../types";
import FilledTextButton from "@/components/CircularButton/styled/FilledTextButton";
import { useRouter } from "next13-progressbar";
import toast from "react-hot-toast";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";
import useConfirmDialog from "@/hooks/useConfirmDialog";
import UserContentOwnerCard from "@/components/UserContentOwnerCard";
import { usePublicProfile } from "@knowt/syncing/hooks/user/useUserData";
import { useMounted } from "@/hooks/useMounted";
import { LastSavedTime } from "@/components/LastSavedTime";
import { spacing } from "@/utils/spacing";
import { moveMedias } from "@knowt/syncing/hooks/media/utils";
import {
    CreateFolderButton,
    FilterButton,
    MergeSetButton,
    MoveToFolderButton,
    SelectMultipleButton,
    SortButton,
} from "../../components/ControlSections";
import { useUserContentManagementContextSelector } from "@/features/UserContentManagement";

const FolderControlSection = () => {
    const { smDown } = useBreakPoints();
    const router = useRouter();
    const mounted = useMounted();
    const { userId } = useCurrentUser();

    const sort = useUserContentManagementContextSelector(state => state.sort);
    const itemTypesToShow = useUserContentManagementContextSelector(state => state.itemTypesToShow);
    const isSelectMode = useUserContentManagementContextSelector(state => state.isSelectMode);
    const setSelectedItems = useUserContentManagementContextSelector(state => state.setSelectedItems);
    const setIsSelectMode = useUserContentManagementContextSelector(state => state.setIsSelectMode);
    const setSort = useUserContentManagementContextSelector(state => state.setSort);
    const setItemTypesToShow = useUserContentManagementContextSelector(state => state.setItemTypesToShow);

    const folderId = useFolderManagementContextSelector(state => state.folderId);
    const folder = useFolderManagementContextSelector(state => state.folder);
    const mutateCurrentFolder = useFolderManagementContextSelector(state => state.mutate);
    const readOnly = useFolderManagementContextSelector(state => state.readOnly);

    const { userData: ownerData } = usePublicProfile(folder?.userId);

    const { openConfirmDialog } = useConfirmDialog();

    const renderMoveButton = () => {
        return (
            <MoveToFolderButton
                onMove={async (
                    { classId: destinationClassId, folderId: destinationFolderId },
                    { folderIds, noteIds, flashcardSetIds, mediaIds }
                ) => {
                    await Promise.all([
                        moveNotes({
                            noteIds,
                            userId,
                            sourceFolderId: folderId,
                            destinationFolderId,
                            sourceClassId: folder.classId,
                            destinationClassId,
                        }),
                        moveFlashcardSets({
                            flashcardSetIds,
                            userId,
                            sourceFolderId: folderId,
                            destinationFolderId,
                            sourceClassId: folder.classId,
                            destinationClassId,
                        }),
                        folderIds.map(folderId =>
                            moveFolder({
                                folderId,
                                userId,
                                sourceFolderId: folderId,
                                parentId: destinationFolderId,
                                sourceClassId: folder.classId,
                                destinationClassId,
                            })
                        ),
                        moveMedias({
                            mediaIds,
                            userId,
                            sourceFolderId: folderId,
                            destinationFolderId,
                            sourceClassId: folder.classId,
                            destinationClassId,
                        }),
                    ]);
                }}
            />
        );
    };

    if (readOnly) {
        return (
            <FlexRowAlignCenter style={{ flexWrap: "wrap", gap: "1.5rem" }}>
                <UserContentOwnerCard owner={ownerData} style={{ padding: "1.7rem 2.2rem", minWidth: "35rem" }} />
                <div style={{ marginLeft: "auto" }} />
                {mounted && (
                    <LastSavedTime
                        timestamp={folder.updated ? +folder.updated * 1000 : 0}
                        style={{ fontWeight: "600", marginRight: spacing.SM }}
                        renderText={time => `Last updated ${time} ago`}
                    />
                )}
                <SortButton
                    onChange={({ by, direction }) => setSort({ by, direction })}
                    by={sort.by}
                    direction={sort.direction}
                />
                <FilterButton
                    allItemTypes={ALL_ITEM_TYPES}
                    selectedItemTypes={itemTypesToShow}
                    onChange={({ selectedItemTypes: newSelected }) => setItemTypesToShow(newSelected)}
                />
            </FlexRowAlignCenter>
        );
    }

    if (isSelectMode) {
        return (
            <FlexRowAlignCenter style={{ gap: "1rem" }}>
                <MoveToPopupProvider>{renderMoveButton()}</MoveToPopupProvider>
                <FolderTrashButtonWithHandler />
                <MergeSetButton folderId={folderId} />
                <ClickableText
                    onClick={() => {
                        setIsSelectMode(false);
                        setSelectedItems({});
                    }}>
                    cancel
                </ClickableText>
            </FlexRowAlignCenter>
        );
    }

    return (
        <FlexRowAlignCenter style={{ flexWrap: "wrap", gap: "1rem" }}>
            {folder.trash ? (
                <>
                    <FilledTextButton
                        onClick={() => restoreFolder({ folderId, userId })}
                        text={"Restore folder"}
                        sx={{ height: "5rem", padding: "1rem 1.5rem" }}
                    />
                    <ClickableText
                        onClick={() => {
                            openConfirmDialog({
                                title: "Are you sure you want to delete?",
                                subtitle: "This folder will not be able to be recovered if you continue to delete it.",
                                style: { borderRadius: "4rem" },
                                rounded: true,
                                cardSize: !smDown ? "58rem" : "31rem",
                                spacing: 2.2,
                                subtitleFontSize: "1.9rem",
                                subtitleFontWeight: "500",
                                buttonColor: "black",
                                simpleCancelBtn: true,
                                onConfirm: async () => {
                                    await toast.promise(deleteFolder({ folderId, userId }), {
                                        loading: "Deleting folder permanently...",
                                        success: "Folder deleted permanently",
                                        error: "Something went wrong! Please try again later",
                                    });
                                    router.push(folder.parentId ? `/folder/${folder.parentId}` : "/");
                                },
                            });
                        }}
                        style={{
                            height: "5rem",
                            padding: "1rem 1.5rem",
                            color: "#FD7A7A",
                        }}>
                        Delete permanently
                    </ClickableText>
                </>
            ) : (
                <>
                    <CreateNewContentButtonWithHandler folderId={folderId} classId={folder.classId || undefined} />
                    <CreateFolderButton
                        onCreate={async ({ name }) => {
                            const newFolder = await callCreateFolder({
                                userId,
                                folderId: uuidv4(),
                                parentId: folderId,
                                name,
                                trash: false,
                            });

                            await mutate(resolveNestedFoldersSWRKey({ userId, parentId: folderId }));
                            return newFolder;
                        }}
                    />
                    <SelectMultipleButton />
                    {folder.classId ? (
                        <ClassSharingPillWithHandler
                            folder={folder}
                            sx={{ height: "5.2rem" }}
                            mutate={mutateCurrentFolder}
                        />
                    ) : (
                        <SharingPillWithHandler
                            folder={folder}
                            sx={{ height: "5.2rem" }}
                            mutate={mutateCurrentFolder}
                        />
                    )}
                </>
            )}
            <div style={{ marginLeft: "auto" }} />
            <SortButton
                onChange={({ by, direction }) => setSort({ by, direction })}
                by={sort.by}
                direction={sort.direction}
            />
            <FilterButton
                allItemTypes={ALL_ITEM_TYPES}
                selectedItemTypes={itemTypesToShow}
                onChange={({ selectedItemTypes: newSelected }) => setItemTypesToShow(newSelected)}
            />
        </FlexRowAlignCenter>
    );
};

export default FolderControlSection;
