import { EventFunction } from "@/types/common";
import noop from "@knowt/syncing/utils/noop";
import Menu from "@mui/material/Menu";
import { Flex } from "@/components/Flex";
import { useTheme } from "@/utils/theme";
import { themeColors } from "@/utils/themeColors";

type FloatingMenuProps = {
    isOpen: boolean;
    onClose?: EventFunction | typeof noop;
    title?: string;
    top?: number;
    left?: number;
    minWidth?: string | number;
    maxHeight?: string | number;
    darkenOverlay?: boolean;
    padding?: string | number;
    items: React.ReactNode;
    titleStyle?: React.CSSProperties;
    listStyle?: React.CSSProperties;
    paperStyle?: React.CSSProperties;
    hideScrollOnOpen?: boolean;
};

const FloatingMenu = ({
    isOpen,
    onClose = noop,
    title,
    top = 0,
    left = 0,
    minWidth,
    maxHeight,
    darkenOverlay = true,
    padding = "0.8rem 2rem 0.2rem 1.4rem",
    items,
    titleStyle,
    listStyle,
    paperStyle,
    hideScrollOnOpen = false,
}: FloatingMenuProps) => {
    const { isDarkMode } = useTheme();

    return (
        <div
            style={{
                visibility: isOpen ? "visible" : "hidden",
                position: "fixed",
                inset: 0,
                backgroundColor: darkenOverlay ? "rgba(0, 0, 0, 0.25)" : "transparent",
                zIndex: 1001,
            }}>
            <Menu
                disableScrollLock={hideScrollOnOpen}
                keepMounted
                open={isOpen}
                onClose={onClose}
                anchorReference="anchorPosition"
                anchorPosition={{ top, left }}
                MenuListProps={{
                    style: {
                        backgroundColor: themeColors.neutralWhite,
                        color: themeColors.neutralBlack,
                        fontFamily: '"roboto", sans-serif',
                        ...listStyle,
                    },
                }}
                PaperProps={{
                    style: {
                        backgroundColor: isDarkMode ? "transparent" : "#000000",
                        padding,
                        boxShadow: darkenOverlay ? "0 0 20px 0 rgba(0, 0, 0, 0.5)" : "0 0 20px 0 rgba(0, 0, 0, 0.15)",
                        borderRadius: 12,
                        minWidth,
                        maxHeight,
                        ...paperStyle,
                    },
                }}>
                <Flex style={{ justifyContent: "space-between" }}>
                    {title && <div style={{ fontSize: 18, marginBottom: 10, ...titleStyle }}>{title}</div>}
                </Flex>

                <div>{items}</div>
            </Menu>
        </div>
    );
};

export default FloatingMenu;
