import { EventFunction } from "@/types/common";
import { wait } from "@knowt/syncing/utils/genericUtils";
import clsx from "clsx";
import { Trash2 } from "lucide-react";
import { useState } from "react";
import styles from "./trashbtn.module.css";
import Circular from "@/components/Circular";
import CircularButton from "@/components/CircularButton";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import useConfirmDialog from "@/hooks/useConfirmDialog";
import { iconSizes, strokeWidth } from "@/utils/iconProps";
import { themeColors } from "@/utils/themeColors";
import toast from "react-hot-toast";

const TrashButton = ({
    onDelete,
    selectedCount,
    width = "12rem",
    text = "Trash",
    badgeStyle,
}: {
    onDelete: EventFunction;
    selectedCount?: number;
    imageSize?: string;
    width?: string;
    text?: string;
    badgeStyle?: React.CSSProperties;
}) => {
    return (
        <div style={{ position: "relative" }}>
            <CircularRectTextButton
                onClick={onDelete}
                sx={{
                    width,
                    height: "4.5rem",
                    borderRadius: "4.5rem",
                }}>
                <Circular radius="3.9rem" style={{ backgroundColor: "#FD7A7A" }}>
                    <Trash2 size={iconSizes.MD} />
                </Circular>
                {text}
            </CircularRectTextButton>
            {selectedCount ? (
                <CircularButton
                    disabled
                    radius="2.2rem"
                    style={{
                        position: "absolute",
                        top: "-0.8rem",
                        right: "-0.8rem",
                        backgroundColor: "rgb(241, 136, 136)",
                        color: "rgb(255, 255, 255)",
                        fontSize: "1.2rem",
                        fontWeight: "600",
                        ...badgeStyle,
                    }}>
                    {selectedCount}
                </CircularButton>
            ) : null}
        </div>
    );
};

export const AnimatedTrashButton = ({
    onDelete,
    selectedCount = 0,
    width = "13rem",
    text = "Delete",
    badgeStyle = {},
    confirmDialogProps,
    onAnimationFinished,
}) => {
    const { openConfirmDialog } = useConfirmDialog();

    const [animationRunning, setAnimationRunning] = useState(false);

    const clickDelete = () => {
        if (typeof onDelete !== "function") {
            return toast.error("No items selected, please select some items to delete.");
        }

        openConfirmDialog({
            title: confirmDialogProps.title,
            subtitle: confirmDialogProps.subtitle,
            style: { borderRadius: "4rem" },
            buttonText: confirmDialogProps.buttonText,
            rounded: true,
            cardSize: "58rem",
            spacing: 2.2,
            subtitleFontSize: "1.9rem",
            subtitleFontWeight: "500",
            buttonColor: "black",
            simpleCancelBtn: true,
            onConfirm: async () => {
                const animationDuration = 2_250;
                setAnimationRunning(true);
                await Promise.all([onDelete(), wait(animationDuration)]);
                setAnimationRunning(false);
                if (typeof onAnimationFinished === "function") {
                    onAnimationFinished();
                }
            },
        });
    };

    return (
        <div style={{ position: "relative" }}>
            <button
                style={
                    {
                        backgroundColor: themeColors.card,
                        borderRadius: "4.5rem",
                        gap: "0.5rem",
                        margin: "0",
                        width,
                        height: "5.2rem",
                        alignItems: "center",
                        "--trash-x": animationRunning ? `calc((${width} - 20px - 30px) / 2)` : null, // width - padding - iconWidth,
                    } as React.CSSProperties
                }
                className={clsx(styles.button, animationRunning && styles.delete)}
                onClick={clickDelete}>
                {animationRunning ? (
                    <div className={styles.trash}>
                        <div className={styles.top}>
                            <div className={styles.paper} />
                        </div>
                        <div className={styles.box} />
                        <div className={styles.check}>
                            <svg viewBox="0 0 8 6">
                                <polyline points="1 3.4 2.71428571 5 7 1"></polyline>
                            </svg>
                        </div>
                    </div>
                ) : (
                    <Circular
                        radius="3.9rem"
                        style={{ backgroundColor: "#FD7A7A", display: "flex", marginRight: "0.5rem" }}>
                        <Trash2
                            size={iconSizes.SM + 2}
                            strokeWidth={strokeWidth.normal}
                            color={themeColors.pureWhite}
                        />
                    </Circular>
                )}
                <span
                    className="bold"
                    style={{
                        color: themeColors.neutralBlack,
                        fontSize: "1.5rem",
                        fontFamily: "var(--knowt-font-name)",
                    }}>
                    {text}
                </span>
            </button>
            {selectedCount ? (
                <CircularButton
                    disabled
                    radius="2.8rem"
                    style={{
                        position: "absolute",
                        top: "-0.8rem",
                        right: "-0.8rem",
                        backgroundColor: "rgb(241, 136, 136)",
                        color: "rgb(255, 255, 255)",
                        fontSize: "1.2rem",
                        fontWeight: "600",
                        ...badgeStyle,
                    }}>
                    {selectedCount}
                </CircularButton>
            ) : null}
        </div>
    );
};

export default TrashButton;
