"use state";

import { useCurrentUser } from "@/hooks/user/useCurrentUser";
import { examNameToUrlComponent, getImageLink } from "@knowt/syncing/hooks/exams/utils";
import { Edit2, Search } from "lucide-react";
import { useRouter } from "next13-progressbar";
import dynamic from "next/dynamic";
import Image from "next/image";
import React, { useMemo, useState } from "react";
import { useLocalStorage } from "react-use";
import { FlexColumn, FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import LazyLoaded from "@/components/LazyLoaded";
import CircularOutlineIcon from "@/components/CircularButton/styled/CircularOutlineIcon";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { iconSizes, strokeWidth } from "@/utils/iconProps";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { useMounted } from "@/hooks/useMounted";
import OutlineTextButton from "@/components/CircularButton/styled/OutlineTextButton";
import Mixpanel from "@/utils/analytics/Mixpanel";

const ExamSelectionPopup = dynamic(() => import("@/components/Popup/InitialAccountSetupPopup/ExamSelectionPopup"), {
    ssr: false,
});

const RecommendedCard = () => {
    const router = useRouter();
    const searches = ["Silk Road", "Cellular Respiration", "AB Calc", "Civil Rights Movement"];
    const [recentSearches] = useLocalStorage<string[]>("recentlySearched", []);
    const mounted = useMounted();

    const { user } = useCurrentUser();

    const [isExamSelectionOpen, setIsExamSelectionOpen] = useState(false);

    // TODO: fix this `?.` things
    const userExams = useMemo(() => {
        const parsedExamHistory = user?.examHistory ? JSON.parse(user?.examHistory) : null;
        const latestPicks = Object.keys(parsedExamHistory || {})?.sort((a, b) => eval(b) - eval(a));
        return parsedExamHistory?.[latestPicks?.[0]]?.map?.(examName => ({
            text: examName,
            icon: getImageLink(examName),
        }));
    }, [user?.examHistory]);

    const renderRecommendPill = (text: string) => {
        return (
            <OutlineTextButton
                onClick={() => {
                    Mixpanel.track("Recommended Card - Clicked", {
                        tab: "Recent Searches",
                        exam: text,
                    });
                    router.push(`/search?q=${text}`);
                }}
                sx={{
                    width: "auto",
                    height: "3.3rem",
                    padding: "0 1.4rem",
                }}
                text={
                    <>
                        <Search
                            size={iconSizes.XS}
                            style={{ marginRight: "0.5rem" }}
                            strokeWidth={strokeWidth.normal}
                        />
                        {text}
                    </>
                }
            />
        );
    };

    const renderExamPill = (text: string, icon: string) => {
        return (
            <OutlineTextButton
                onClick={() => {
                    Mixpanel.track("Recommended Card - Clicked", {
                        tab: "Exams",
                        exam: text,
                    });
                    router.push(`/exams/${text.split(" ")[0]}/${examNameToUrlComponent(text)}`);
                }}
                sx={{
                    width: "auto",
                    height: "3.3rem",
                    padding: "0 1.4rem",
                    maxWidth: "98%",
                    textAlign: "left",
                }}
                text={
                    <>
                        <Image
                            src={icon}
                            alt={"exam icon"}
                            width={"16"}
                            height={"16"}
                            style={{ marginRight: "0.5rem" }}
                        />
                        {text}
                    </>
                }
            />
        );
    };

    const renderEmptyState = () => (
        <FlexColumnAlignJustifyCenter
            style={{
                gap: spacing.MD,
                height: "100%",
            }}>
            <FlexColumn
                style={{
                    gap: spacing.XXS,
                    maxWidth: "80%",
                    textAlign: "center",
                }}>
                <p className="bodyBold2">You don’t have any exams added!</p>
                <p className="secondaryText1">Add the exams that you’re studying for to quickly access them here.</p>
            </FlexColumn>
            <CircularRectTextButton
                onClick={() => setIsExamSelectionOpen(true)}
                style={{
                    width: "12.6rem",
                    height: "4rem",
                    backgroundColor: themeColors.primary,
                    color: themeColors.pureWhite,
                }}>
                add exams
            </CircularRectTextButton>
        </FlexColumnAlignJustifyCenter>
    );

    const renderContent = () => {
        if (!userExams?.length) {
            return renderEmptyState();
        }

        return (
            <FlexColumn
                className="scrollbar-thin"
                style={{
                    marginBottom: "-1.9rem",
                    paddingBottom: "1rem",
                    marginRight: "-1.5rem",
                    overflowY: "auto",
                    overflowX: "hidden",
                }}>
                <FlexRowAlignCenter style={{ marginBottom: "1rem" }}>
                    {/* TODO: Temporary, until we do our ES refactor */}
                    {mounted && (
                        <span className="bold" style={{ fontSize: "1.2rem" }}>
                            {recentSearches?.length ? "Your Searches" : "Relevant Searches"}
                        </span>
                    )}
                </FlexRowAlignCenter>

                <FlexRowAlignCenter style={{ flexWrap: "wrap", gap: "0.8rem", marginBottom: "2.1rem" }}>
                    {mounted &&
                        (recentSearches?.length ? recentSearches : searches).map((r, i) => (
                            <React.Fragment key={r + i}>{renderRecommendPill(r)}</React.Fragment>
                        ))}
                </FlexRowAlignCenter>

                <FlexRowAlignCenter style={{ marginBottom: "1rem" }}>
                    <span className="bold" style={{ fontSize: "1.2rem" }}>
                        {userExams?.length ? "Your Exams" : "Relevant Exams"}
                    </span>
                </FlexRowAlignCenter>

                <FlexRowAlignCenter style={{ flexWrap: "wrap", gap: "0.8rem" }}>
                    {userExams.map((r, i: number) => (
                        <React.Fragment key={r.text + i}>{renderExamPill(r.text, r.icon)}</React.Fragment>
                    ))}
                </FlexRowAlignCenter>
            </FlexColumn>
        );
    };

    return (
        <FlexColumn
            style={{
                padding: "1.9rem 1.5rem",
                backgroundColor: themeColors.card,
                borderRadius: "2rem",
                width: "100%",
            }}>
            <FlexRowAlignCenter style={{ justifyContent: "space-between", marginBottom: "2.2rem" }}>
                <span className="600 bodyBold2">Recommended</span>
                <CircularOutlineIcon
                    Icon={Edit2}
                    size={iconSizes.SM}
                    onClick={() => setIsExamSelectionOpen(true)}
                    radius="3rem"
                    tooltip={"customize recommended"}
                />
            </FlexRowAlignCenter>

            {renderContent()}
            <LazyLoaded load={isExamSelectionOpen}>
                <ExamSelectionPopup isOpen={isExamSelectionOpen} onClose={() => setIsExamSelectionOpen(false)} />
            </LazyLoaded>
        </FlexColumn>
    );
};

export default RecommendedCard;
