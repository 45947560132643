import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import Select from "@/components/Select";
import { ASSETS_URL } from "@/config/deployConstants";
import br from "@/styles/breakpoints.module.css";
import { formatUnixSecondsTimestamp } from "@/utils/dateTimeUtils";
import { iconSizes } from "@/utils/iconProps";
import noop from "@/utils/noop";
import { themeColors } from "@/utils/themeColors";
import { isDarkColor } from "@knowt/syncing/utils/genericUtils";
import { Skeleton, TableCell, TableRow } from "@mui/material";
import { ArrowRight, ChevronDown } from "lucide-react";
import Image from "next/image";
import SectionPill from "../../../components/styled/SectionPill";
import { useClassManagementContextSelector } from "../../../hooks/useClassManagementContext";
import { ClassDashboardTab } from "../../../types";
import StudentInformationCell from "../StudentInformationCell";
import { ClassMemberWithDetails } from "@knowt/syncing/graphql/schema";
import CheckBox from "@/components/styled/input/CheckBox";

const SkeletonLoading = () => (
    <TableRow>
        <TableCell className={br.smDownDisplayNone}>
            <CheckBox isChecked={false} onCheck={noop} />
        </TableCell>
        <TableCell
            style={{
                width: "23rem",
            }}>
            <FlexRowAlignCenter style={{ gap: "1.1rem" }}>
                <Skeleton variant="circular" width={44} height={44} />
                <FlexColumn
                    style={{
                        flex: 1,
                    }}>
                    <Skeleton
                        style={{
                            width: "80%",
                            height: "20px",
                        }}
                    />
                    <Skeleton
                        style={{
                            width: "60%",
                            height: "20px",
                        }}
                    />
                </FlexColumn>
            </FlexRowAlignCenter>
        </TableCell>
        <TableCell>
            <Skeleton width={100} height={20} style={{ borderRadius: "999px" }} />
        </TableCell>
        <TableCell className={br.mdDownDisplayNone}>
            <Skeleton width={100} height={20} />
        </TableCell>
        <TableCell className={br.mdDownDisplayNone}>
            <Skeleton width={190} height={20} style={{ borderRadius: "999px" }} />
        </TableCell>
        <TableCell className={br.smDownDisplayNone}>
            <Skeleton width={130} height={20} style={{ borderRadius: "999px" }} />
        </TableCell>
    </TableRow>
);

export const TableEntry = ({
    classMember,
    isChecked,
    loading,
    onCheck,
    onChangeSection,
    onRemoveStudent,
}: {
    classMember: ClassMemberWithDetails;
    loading: boolean;
    isChecked: boolean;
    onCheck: () => void;
    onChangeSection: () => void;
    onRemoveStudent: () => void;
}) => {
    const course = useClassManagementContextSelector(state => state.course);
    const changeCurrentTab = useClassManagementContextSelector(state => state.changeCurrentTab);

    const section = classMember && course?.sections.find(section => section.id === classMember.sections[0]);

    if (loading) return <SkeletonLoading />;

    const actionOptions = [
        {
            label: "Change Section",
            value: onChangeSection,
        },
        {
            label: "Remove Student",
            value: onRemoveStudent,
        },
    ];

    return (
        <TableRow>
            <TableCell className={br.smDownDisplayNone}>
                <CheckBox isChecked={isChecked} onCheck={onCheck} />
            </TableCell>
            <TableCell>
                <StudentInformationCell member={classMember} />
            </TableCell>
            <TableCell>
                <SectionPill
                    sx={{
                        backgroundColor: section?.color || themeColors.neutralWhite,
                        color: isDarkColor(section?.color || themeColors.neutralWhite)
                            ? themeColors.pureWhite
                            : themeColors.pureBlack,
                    }}
                    onClick={onChangeSection}
                    className="ellipsisText">
                    {section?.name}
                </SectionPill>
            </TableCell>
            <TableCell style={{ width: "20rem" }} className={br.mdDownDisplayNone}>
                <p className="secondaryTextBold1">{formatUnixSecondsTimestamp(classMember.lastLogIn || 0).date}</p>
                <p className="secondaryText1">{formatUnixSecondsTimestamp(classMember.lastLogIn || 0).time}</p>
            </TableCell>
            <TableCell className={br.mdDownDisplayNone}>
                <CircularRectTextButton
                    onClick={() => changeCurrentTab(ClassDashboardTab.PROGRESS)}
                    tooltip="view progress"
                    style={{
                        padding: "0.8rem 1.2rem",
                        border: `2px solid ${themeColors.neutral1}`,
                        gap: "0.8rem",
                    }}>
                    <Image
                        src={`${ASSETS_URL}/images/views-icon.svg`}
                        alt={"studied by"}
                        width={18}
                        height={18}
                        style={{ objectFit: "contain" }}
                    />
                    <p className={br.mdDownDisplayNone}>view progress</p>
                    <ArrowRight size={iconSizes.SM_S} />
                </CircularRectTextButton>
            </TableCell>
            <TableCell className={br.smDownDisplayNone}>
                <Select
                    options={actionOptions}
                    onChange={({ value }) => {
                        value();
                    }}
                    menuSx={{ width: "20rem", padding: "1rem" }}
                    btnSx={{
                        width: "fit-content",
                        gap: "1rem",
                        borderRadius: "999px",
                        padding: "0.8rem 1.6rem",
                        border: `2px solid ${themeColors.neutral1}`,
                        backgroundColor: themeColors.neutralWhite,
                    }}
                    adornment={<ChevronDown size={iconSizes.SM} />}
                    selected={{ label: "Actions", value: noop }}
                    transitionOrigin={{
                        anchorOrigin: { vertical: "bottom", horizontal: "left" },
                        transformOrigin: { vertical: "top", horizontal: "left" },
                    }}
                />
            </TableCell>
        </TableRow>
    );
};
