import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FlexColumn, FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { borderRadius } from "@/utils/borderRadius";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import { ClassMemberWithDetails, StudySessionProgressEnum, StudySessionType } from "@/graphql/schema";
import { getProgressBucket, UserStudyAnalytics } from "@/hooks/study/statsUtils";
import { ControlsSection, MemberSortSettings, TableHeader } from "./ProgressTableSections";
import TableEntry from "./ProgressTableEntry";
import { ArrowDown } from "lucide-react";
import { TableBody } from "@mui/material";
import { InviteStudentsButtonWithPopup } from "../../../components/ClassCodeLinkCard";

const EmptyState = () => (
    <FlexColumnAlignJustifyCenter
        style={{
            flex: 1,
            width: "100%",
            gap: spacing.MD,
        }}>
        <FlexColumnAlignJustifyCenter
            style={{
                gap: spacing.XXS,
            }}>
            <p className="bodyBold1">You have no students in your class!</p>
            <p className="secondaryText1">Invite some to see progress metrics</p>
        </FlexColumnAlignJustifyCenter>
        <InviteStudentsButtonWithPopup />
    </FlexColumnAlignJustifyCenter>
);

const LoadingEntries = () => (
    <React.Fragment>
        {Array.from({ length: 4 }).map((_, index) => (
            <TableEntry key={index} isLoading />
        ))}
    </React.Fragment>
);

type ProgressTableProps = {
    studentsStudyAnalytics: Record<string, UserStudyAnalytics> | null | undefined;
    studentDetails: ClassMemberWithDetails[] | null | undefined;
    studyProgressFilter: StudySessionProgressEnum | null;
    onRemoveStudyProgressFilter: () => void;
};

const ProgressTable = ({
    studentsStudyAnalytics,
    studentDetails,
    studyProgressFilter,
    onRemoveStudyProgressFilter,
}: ProgressTableProps) => {
    const isLoading = useMemo(
        () => studentsStudyAnalytics === undefined || studentDetails === undefined,
        [studentDetails, studentsStudyAnalytics]
    );

    const isEmpty = useMemo(() => {
        return (studentsStudyAnalytics === null || !studentDetails?.length) && !isLoading;
    }, [isLoading, studentDetails?.length, studentsStudyAnalytics]);

    const [selectedStudyMode, setSelectedStudyMode] = useState<StudySessionType | null>(null);
    const [sortSettings, setSortSettings] = useState<MemberSortSettings | null>(null);
    const [studentNameQuery, setStudentNameQuery] = useState("");
    const [isExpanded, setIsExpanded] = useState(false);

    // for animated expansion
    const dynamicContainerRef = useRef<HTMLDivElement | null>(null);
    const [tableContainerHeight, setTableContainerHeight] = useState<number>();

    const filteredMembers = studentDetails?.filter(({ userId, Name, username }) => {
        if (studyProgressFilter) {
            const analytics = studentsStudyAnalytics?.[userId];
            if (!analytics) return false;

            return getProgressBucket(analytics.progressPercentage) === studyProgressFilter;
        }

        const query = studentNameQuery.toLowerCase();

        return !studentNameQuery || Name.toLowerCase().includes(query) || username?.toLowerCase().includes(query);
    });

    const slicedFilteredMembers = isExpanded ? filteredMembers : filteredMembers?.slice(0, 4);

    const heightUpdater = useCallback(() => {
        if (isEmpty) return;

        const currentHeight = dynamicContainerRef?.current?.getBoundingClientRect().height || 0;

        if (isExpanded && currentHeight !== (tableContainerHeight || 0)) {
            setTableContainerHeight(currentHeight);
        }

        if (!isExpanded && currentHeight !== (tableContainerHeight || 0)) {
            setTableContainerHeight(currentHeight);
        }
    }, [isEmpty, isExpanded, tableContainerHeight]);

    useEffect(() => {
        heightUpdater();
        window.addEventListener("resize", heightUpdater);

        return () => {
            window.removeEventListener("resize", heightUpdater);
        };
    }, [heightUpdater]);

    return (
        <FlexColumn
            style={{
                padding: spacing.MD,
                flex: 1,
                backgroundColor: themeColors.neutralWhite,
                borderRadius: borderRadius.card,
                rowGap: spacing.MD,
                height: isLoading
                    ? "100%"
                    : isEmpty || (filteredMembers?.length || 0) < 5
                    ? "62rem"
                    : tableContainerHeight
                    ? tableContainerHeight + 150
                    : "auto", // 150 is the height of the controls section and the "view more" button found with trial and error
                transition: "height 0.5s ease-in-out",
                overflow: "hidden",
                maxHeight: "fit-content",
                minHeight: "63rem",
            }}>
            <ControlsSection
                studentNameQuery={studentNameQuery}
                setStudentNameQuery={setStudentNameQuery}
                selectedStudyMode={selectedStudyMode}
                onStudyModeChange={setSelectedStudyMode}
                studyProgressFilter={studyProgressFilter}
                onClearStudyProgressCategoryFilter={onRemoveStudyProgressFilter}
            />
            <TableContainer
                className="hide-scrollbar"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: isEmpty ? 1 : "unset",
                    ".MuiTableCell-root": {
                        fontFamily: "var(--knowt-font-name)",
                        fontSize: "1.4rem",
                        padding: "1.6rem 2.4rem",
                        color: themeColors.neutralBlack,
                        height: "8.3rem",
                    },
                }}>
                <Table sx={{ borderColor: themeColors.background }}>
                    <TableHeader
                        studyMode={selectedStudyMode}
                        sortSettings={sortSettings}
                        onSortSettingsChange={setSortSettings}
                    />
                    {!isEmpty && (
                        <TableBody>
                            {studentsStudyAnalytics === undefined ? (
                                <LoadingEntries />
                            ) : (
                                slicedFilteredMembers?.map(studentDetails => {
                                    const analytics = studentsStudyAnalytics?.[studentDetails.userId];

                                    return (
                                        <TableEntry
                                            key={studentDetails.userId}
                                            studentData={studentDetails}
                                            analytics={analytics}
                                            studyMode={selectedStudyMode}
                                        />
                                    );
                                })
                            )}
                        </TableBody>
                    )}
                </Table>
                {isEmpty && <EmptyState />}
            </TableContainer>
            {(filteredMembers?.length || 0) > 5 ? (
                <FlexRowAlignCenter
                    onClick={() => setIsExpanded(!isExpanded)}
                    className="secondaryTextBold1"
                    style={{
                        gap: "1rem",
                        height: "3.2rem",
                        cursor: "pointer",
                        color: themeColors.primary4,
                        width: "100%",
                        justifyContent: "center",
                    }}>
                    <p>{isExpanded ? "View less students" : "View more students"}</p>
                    <ArrowDown
                        style={{
                            transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
                            transition: "transform 0.2s",
                        }}
                    />
                </FlexRowAlignCenter>
            ) : (
                // for consistent height
                <div style={{ height: "3.2rem", width: "100%" }} />
            )}
        </FlexColumn>
    );
};

export default ProgressTable;
