import { filterUserContentItems, sortHomeFolder, sortPinnedItemsToTop, sortUserContentItems } from "../../utils";
import UserContentCard from "@/components/cards/UserContentCard";
import Image from "next/image";
import { ContentsGrid, ItemList } from "../../components/UserContentManagementGrid";
import { FlexColumnAlignJustifyCenter } from "@/components/Flex";
import React, { useMemo } from "react";
import Circular from "@/components/Circular";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import FolderCard from "@/components/cards/FolderCard";
import LoadingAnimation from "@/components/LoadingAnimation";
import { useClassNotes } from "@knowt/syncing/hooks/notes/useNotes";
import { useClassFlashcardSets } from "@knowt/syncing/hooks/flashcards/useFlashcards";
import { useClassMedias } from "@knowt/syncing/hooks/media/useMedia";
import { useClassFolders } from "@knowt/syncing/hooks/folders/useFolders";
import { UserContentItem } from "../../types";
import {
    DraggableCardHandler,
    DroppableCardHandler,
    UserContentManagementMoveToHandler,
    useUserContentManagementContextSelector,
} from "@/features/UserContentManagement";
import { MoveToPopupProvider } from "features/MoveToPopup";
import { isFlashcardSet, isNote } from "@knowt/syncing/utils/dataCleaning";
import { useClassManagementContextSelector } from "../hooks/useClassManagementContext";
import { useAllBookmarks } from "@knowt/syncing/hooks/bookmarks/useBookmarks";

const ClassContentEmptyState = ({ title, desc }: { title: string; desc: string }) => (
    <FlexColumnAlignJustifyCenter style={{ maxWidth: "80rem", textAlign: "center", margin: "0 auto 5rem" }}>
        <Circular
            radius="20rem"
            style={{
                backgroundColor: themeColors.neutralWhite,
                position: "relative",
                overflow: "hidden",
                marginBottom: spacing.MD,
            }}>
            <Image
                src="/images/kai-waving.svg"
                width="133"
                height="170"
                alt="no content"
                style={{ position: "absolute", right: "2rem", bottom: "-2.5rem" }}
            />
        </Circular>
        <p className="heading5" style={{ marginBottom: spacing.SM }}>
            {" "}
            {title}{" "}
        </p>
        <p className="body2" style={{ maxWidth: "61rem" }}>
            {" "}
            {desc}{" "}
        </p>
    </FlexColumnAlignJustifyCenter>
);

export const CLASS_FILES_GRID_ID = "class-files-grid";

export const ClassHomeTabContents = () => {
    const sort = useUserContentManagementContextSelector(state => state.sort);
    const itemTypesToShow = useUserContentManagementContextSelector(state => state.itemTypesToShow);
    const serverNotes = useUserContentManagementContextSelector(state => state.serverNotes);
    const serverMedias = useUserContentManagementContextSelector(state => state.serverMedias);
    const serverFlashcardSets = useUserContentManagementContextSelector(state => state.serverFlashcardSets);
    const serverFolders = useUserContentManagementContextSelector(state => state.serverFolders);
    const isSelectMode = useUserContentManagementContextSelector(state => state.isSelectMode);
    const selectedItems = useUserContentManagementContextSelector(state => state.selectedItems);
    const toggleSelectedItem = useUserContentManagementContextSelector(state => state.toggleSelectedItem);

    const classId = useClassManagementContextSelector(state => state.classId);
    const selectedSection = useClassManagementContextSelector(state => state.selectedSection);
    const isTeacher = useClassManagementContextSelector(state => state.isTeacher);
    const isStudent = useClassManagementContextSelector(state => state.isStudent);
    const course = useClassManagementContextSelector(state => state.course);

    const { bookmarks } = useAllBookmarks();

    const { classNotes, isLoading: isLoadingNotes } = useClassNotes({
        classId,
        fallbackData: serverNotes,
        sectionId: selectedSection,
    });

    const { classFlashcardSets, isLoading: isLoadingFlashcardSets } = useClassFlashcardSets({
        classId,
        fallbackData: serverFlashcardSets,
        sectionId: selectedSection,
    });

    const { classMedias, isLoading: isLoadingMedia } = useClassMedias({
        classId,
        fallbackData: serverMedias,
        sectionId: selectedSection,
    });

    const { classFolders } = useClassFolders({ classId, fallbackData: serverFolders, sectionId: selectedSection });

    const isLoading = isLoadingNotes || isLoadingFlashcardSets || isLoadingMedia;

    const sortedAndFilteredItems = useMemo(() => {
        if (isLoading) return [];

        const notes = classNotes ? Object.values(classNotes).filter(({ mediaId }) => !mediaId) : [];

        const flashcards = classFlashcardSets
            ? Object.values(classFlashcardSets).filter(({ mediaId, noteId }) => !mediaId && !noteId)
            : [];

        const medias = classMedias ? Object.values(classMedias) : [];

        const pinnedItems = new Set(course.pinned);

        const filteredClassItems = filterUserContentItems(
            [...notes, ...flashcards, ...medias].map(item => ({
                ...item,
                isBookmarked: pinnedItems.has(
                    isNote(item) ? item.noteId : isFlashcardSet(item) ? item.flashcardSetId : item.mediaId
                ),
            })),
            itemTypesToShow
        );

        const sortedClassItems = sortUserContentItems(filteredClassItems, sort) as UserContentItem[];

        return sortPinnedItemsToTop(sortedClassItems, Object.keys(bookmarks || {}));
    }, [isLoading, classNotes, classFlashcardSets, classMedias, course.pinned, itemTypesToShow, bookmarks, sort]);

    const sortedFolders = useMemo(() => {
        const foldersArr = Object.values(classFolders || {});
        return sortHomeFolder(foldersArr, sort);
    }, [classFolders, sort]);

    const isNoContent = sortedAndFilteredItems.length === 0 && sortedFolders.length === 0;

    if (isLoading) {
        return (
            <FlexColumnAlignJustifyCenter style={{ margin: "6rem 0 12rem" }}>
                <LoadingAnimation style={{ width: "100%" }} />
            </FlexColumnAlignJustifyCenter>
        );
    }

    if (isNoContent) {
        return (
            <ClassContentEmptyState
                title={'Click "add files" to create flashcards, notes, and more!'}
                desc={
                    "You’ll find this button in the top left, and above that you’ll be able to switch to view this class's members or flashcard progress."
                }
            />
        );
    }

    const renderFolderGrid = () => {
        return (
            <ContentsGrid label={"Folders"} itemMinWidth={"28rem"} style={{ marginBottom: "3.2rem" }}>
                {sortedFolders.map(folder => {
                    const folderId = folder.folderId;

                    return (
                        <DroppableCardHandler key={folderId} id={folderId}>
                            {({ isOver }) => (
                                <DraggableCardHandler id={folderId} item={folder}>
                                    <FolderCard
                                        key={folderId}
                                        folder={folder}
                                        modeProps={{
                                            mode: isSelectMode ? "SELECT" : null,
                                            isSelected: folderId in selectedItems,
                                            toggleSelect: () => toggleSelectedItem(folderId, folder),
                                        }}
                                        style={{ ...(isOver ? { backgroundColor: "#50d2c2" } : {}) }}
                                    />
                                </DraggableCardHandler>
                            )}
                        </DroppableCardHandler>
                    );
                })}
            </ContentsGrid>
        );
    };

    const renderUserContentGrid = () => {
        return (
            <ContentsGrid
                label={"Files"}
                id={CLASS_FILES_GRID_ID}
                itemMinWidth={"28rem"}
                style={{ display: sortedAndFilteredItems?.length ? "grid" : "none" }}>
                {sortedAndFilteredItems.map((item, i) => {
                    const itemId = isNote(item)
                        ? item.noteId
                        : isFlashcardSet(item)
                        ? item.flashcardSetId
                        : item.mediaId;

                    return (
                        <DraggableCardHandler key={"homeItem" + itemId + i} id={itemId} item={item}>
                            <UserContentCard
                                key={itemId}
                                {...(isNote(item)
                                    ? { note: item }
                                    : isFlashcardSet(item)
                                    ? { flashcardSet: item }
                                    : { media: item })}
                                modeProps={{
                                    mode: isSelectMode ? "SELECT" : null,
                                    isSelected: itemId in selectedItems,
                                    toggleSelect: toggleSelectedItem,
                                }}
                                classProps={{ isStudentClass: isStudent, isTeacherClass: isTeacher }}
                            />
                        </DraggableCardHandler>
                    );
                })}
            </ContentsGrid>
        );
    };

    return (
        <UserContentManagementMoveToHandler disabled={isSelectMode}>
            <MoveToPopupProvider>
                {sortedFolders.length > 0 && renderFolderGrid()}
                {sortedAndFilteredItems.length > 0 && renderUserContentGrid()}

                <div style={{ paddingBottom: "13rem" }} />
            </MoveToPopupProvider>
        </UserContentManagementMoveToHandler>
    );
};

export const ClassSavedTabContents = () => {
    const sort = useUserContentManagementContextSelector(state => state.sort);
    const itemTypesToShow = useUserContentManagementContextSelector(state => state.itemTypesToShow);
    const serverNotes = useUserContentManagementContextSelector(state => state.serverNotes);
    const serverMedias = useUserContentManagementContextSelector(state => state.serverMedias);
    const serverFlashcardSets = useUserContentManagementContextSelector(state => state.serverFlashcardSets);

    const classId = useClassManagementContextSelector(state => state.classId);
    const selectedSection = useClassManagementContextSelector(state => state.selectedSection);

    const { classNotes } = useClassNotes({ classId, fallbackData: serverNotes, sectionId: selectedSection });
    const notesArr = Object.values(classNotes || {});
    const { classMedias } = useClassMedias({ classId, fallbackData: serverMedias, sectionId: selectedSection });
    const mediasArr = Object.values(classMedias || {});

    const { classFlashcardSets } = useClassFlashcardSets({
        classId,
        fallbackData: serverFlashcardSets,
        sectionId: selectedSection,
    });

    const flashcardSetsArr = Object.values(classFlashcardSets || {});

    const { bookmarks } = useAllBookmarks();

    const savedItems = useMemo(() => {
        return [...notesArr, ...mediasArr, ...flashcardSetsArr].filter(item => {
            const id = isNote(item) ? item.noteId : isFlashcardSet(item) ? item.flashcardSetId : item.mediaId;
            return !!bookmarks[id];
        });
    }, [flashcardSetsArr, mediasArr, notesArr, bookmarks]);

    const sortedItems = useMemo(() => {
        const filteredItems = filterUserContentItems(savedItems, itemTypesToShow);

        return sortUserContentItems(filteredItems, sort) as UserContentItem[];
    }, [savedItems, itemTypesToShow, sort]);

    if (!sortedItems.length) {
        return (
            <FlexColumnAlignJustifyCenter style={{ flex: 1 }}>
                <ClassContentEmptyState title={"No saved items"} desc={""} />
            </FlexColumnAlignJustifyCenter>
        );
    }

    if (!bookmarks) {
        return (
            <FlexColumnAlignJustifyCenter style={{ margin: "6rem 0 12rem" }}>
                <LoadingAnimation style={{ width: "100%" }} />
            </FlexColumnAlignJustifyCenter>
        );
    }

    return (
        <ContentsGrid label={"Class Saved"} itemMinWidth={"28rem"} style={{ paddingBottom: "15rem" }}>
            <ItemList items={sortedItems} />
        </ContentsGrid>
    );
};
