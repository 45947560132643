import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import { borderRadius } from "@/utils/borderRadius";
import { iconSizes, strokeWidth } from "@/utils/iconProps";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { Paperclip, Settings, User } from "lucide-react";
import { useClassManagementContextSelector } from "../hooks/useClassManagementContext";
import dynamic from "next/dynamic";
import LazyLoaded from "@/components/LazyLoaded";
import { useState } from "react";
import Image from "next/image";
import { updateClass } from "@knowt/syncing/hooks/classes/utils";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { uploadPictureToS3 } from "@knowt/syncing/utils/s3";
import { useRouter } from "next13-progressbar";
import { CircularButtonProps } from "@/components/CircularButton";
import { ClassDashboardTab } from "../types";
import br from "@/styles/breakpoints.module.css";
import { UNTITLED } from "@knowt/syncing/utils/dataCleaning";
import { CLASS_FILES_GRID_ID } from "./ClassTabContents";

const CustomizeAppearancePopup = dynamic(() => import("@/features/CustomizeAppearancePopup"));

const DisplayPill = ({ children, sx, ...btnProps }: CircularButtonProps) => {
    return (
        <CircularRectTextButton
            className="bodySecondary2"
            sx={{
                "&:hover": {
                    backgroundColor: themeColors.neutralBlack,
                    color: themeColors.neutralWhite,
                    transition: "0.4s",
                },
                "&:active": {},
                backgroundColor: "transparent",
                height: "4rem",
                padding: "0 1.7rem",
                gap: "0.7rem",
                color: themeColors.neutralBlack,
                fontWeight: "400",
                fontSize: "1.2rem",
                border: `1px solid ${themeColors.neutral1}`,
                ...sx,
            }}
            {...btnProps}>
            {children}
        </CircularRectTextButton>
    );
};

const BottomClassInfo = () => {
    const course = useClassManagementContextSelector(state => state.course);
    const readOnly = useClassManagementContextSelector(state => state.readOnly);
    const changeCurrentTab = useClassManagementContextSelector(state => state.changeCurrentTab);

    const router = useRouter();

    const { user } = useCurrentUser();

    const { sections }: { sections: string[] } = user?.classes?.enrolled.find(
        classInfo => classInfo.classId === course?.classId
    ) ?? { sections: [] };

    const sectionName = course?.sections?.find(section => sections.includes(section?.id))?.name || "...";

    const members = course?.members?.length || 0;

    return (
        <FlexColumn style={{ flex: 0.5, backgroundColor: themeColors.neutralWhite, padding: "2.4rem" }}>
            {/* BOTTOM CLASS INFO */}
            <span className="bodyBold2 ellipsisText" style={{ paddingBottom: "0.8rem" }}>
                {course?.name || UNTITLED}
            </span>
            <span
                className="secondaryText1 clampText"
                style={{ color: themeColors.neutralBlack, WebkitLineClamp: 2, paddingBottom: spacing.SM }}>
                {course?.description}
            </span>
            <FlexRowAlignCenter
                className={br.mdDownWrap}
                style={
                    course?.description
                        ? { gap: "0.8rem 1.5rem" }
                        : { gap: "0.8rem 1.5rem", flex: 1, alignItems: "flex-end" }
                }>
                {!readOnly && (
                    <DisplayPill onClick={() => router.push(`/class/${course.classId}/settings`)}>
                        <Settings strokeWidth={strokeWidth.normal} size={iconSizes.MD - 4} />
                        Class Settings
                    </DisplayPill>
                )}
                {readOnly && <DisplayPill>{sectionName}</DisplayPill>}
                {!readOnly && (
                    <DisplayPill onClick={() => changeCurrentTab(ClassDashboardTab.PEOPLE)}>
                        <User strokeWidth={strokeWidth.normal} size={iconSizes.SM} />
                        {members} student{members === 1 ? "" : "s"}
                    </DisplayPill>
                )}
                <DisplayPill
                    onClick={() => {
                        const filesGrid = document.getElementById(CLASS_FILES_GRID_ID);
                        if (filesGrid) {
                            filesGrid.scrollIntoView({ behavior: "smooth", block: "start" });
                        } else {
                            throw new Error("element with `CLASS_FILES_GRID_ID` not found");
                        }
                    }}>
                    <Paperclip strokeWidth={strokeWidth.normal} size={iconSizes.SM} />
                    {course?.fileCount || 0} files
                </DisplayPill>
            </FlexRowAlignCenter>
        </FlexColumn>
    );
};

const TopCover = () => {
    const { user } = useCurrentUser();

    const course = useClassManagementContextSelector(state => state.course);
    const readOnly = useClassManagementContextSelector(state => state.readOnly);

    const [isCustomizePopupOpen, setIsCustomizePopupOpen] = useState(false);

    return (
        <FlexColumn
            style={{ backgroundColor: course?.color || "#e2ecf6", flex: 1, position: "relative", overflow: "hidden" }}>
            {!readOnly && (
                <CircularRectTextButton
                    onClick={() => setIsCustomizePopupOpen(true)}
                    sx={{
                        width: "10rem",
                        height: "3.2rem",
                        position: "absolute",
                        right: "2rem",
                        top: "2rem",
                        fontSize: "1.3rem",
                    }}>
                    Customize
                </CircularRectTextButton>
            )}

            {course?.cover && (
                <Image
                    alt="class cover"
                    src={course.cover}
                    width="500"
                    height="500"
                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
            )}

            <LazyLoaded load={isCustomizePopupOpen}>
                <CustomizeAppearancePopup
                    onSave={async ({ newCoverImg, newSelectedColor }) => {
                        let imgURL: string | null = null;
                        if (newCoverImg) {
                            imgURL = await uploadPictureToS3(newCoverImg, "knowt-profile-pictures", newCoverImg.type);
                        }

                        await updateClass(
                            { color: newSelectedColor, cover: imgURL, classId: course?.classId, name: course?.name },
                            user
                        );
                    }}
                    isOpen={isCustomizePopupOpen}
                    onClose={() => setIsCustomizePopupOpen(false)}
                    coverImg={course?.cover || undefined}
                    selectedColor={course?.color || undefined}
                />
            </LazyLoaded>
        </FlexColumn>
    );
};

const ClassCard = () => {
    return (
        <FlexColumn style={{ flex: 1, borderRadius: borderRadius.card, overflow: "hidden" }}>
            <TopCover />
            <BottomClassInfo />
        </FlexColumn>
    );
};

export default ClassCard;
