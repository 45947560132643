"use client";

import clsx from "clsx";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import { ItemType, UserDetails } from "@knowt/syncing/graphql/schema";
import { duplicateFlashcardSet, genericUpdateFlashcardSets } from "@knowt/syncing/hooks/flashcards/utils";
import { useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { now } from "@/utils/SyncUtils";
import { duplicateNote, genericUpdateNotes } from "@knowt/syncing/hooks/notes/utils";
import { duplicateMedia, genericUpdateMedias } from "@knowt/syncing/hooks/media/utils";
import { useRouter } from "next13-progressbar";
import { useClass } from "@knowt/syncing/hooks/classes/useClass";
import ResponsiveDialog, { DialogCloseBtn } from "@/features/ResponsiveDialog";
import InputWithSearchbarIcon from "@/components/styled/input/InputWithSearchbarIcon";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { checkValidKnowtLink, getFileType } from "./utils";
import { useLocalSearchSuggestions } from "@knowt/syncing/hooks/localSearchSuggestions/useLocalSearchSuggestions";
import { MoveToPopupBreadCrumb } from "features/MoveToPopup";
import { MyFilesSearchCard, SearchEmptyState } from "@/components/Popup/SearchPopup";
import { UNTITLED } from "@knowt/syncing/utils/dataCleaning";
import { updateClass } from "@/hooks/classes/utils";
import { toggleArrayElements } from "@/utils/arrayUtils";
import { pluralize } from "@/utils/stringUtils";

type MoveFileToClassPopupProps = {
    isOpen: boolean;
    onClose: () => void;
    classId?: string;
    folderId?: string;
};

const MoveFileToClassPopup = ({ isOpen, onClose, classId, folderId }: MoveFileToClassPopupProps) => {
    const { user, userId } = useCurrentUser();
    const { course } = useClass({ classId });

    const { classes, notes, flashcardSets, folders, medias, suggestedItems, searchText, setSearchText } =
        useLocalSearchSuggestions({
            isEnabled: true,
        });

    const [currentFolderId, setCurrentFolderId] = useState<string | null>(null);
    const [currentClassId, setCurrentClassId] = useState<string | null>(null);

    const [selectedItems, setSelectedItems] = useState<{ type: ItemType; id: string }[]>([]);

    const itemsToDisplay = useMemo(() => {
        return [
            ...classes.filter(c => !currentClassId && !currentFolderId && c.classId !== classId),
            ...folders.filter(folder => folder.parentId == currentFolderId && folder.classId == currentClassId),
            ...notes.filter(note => note.parentId == currentFolderId && note.classId == currentClassId),
            ...flashcardSets.filter(
                flashcardSet => flashcardSet.parentId == currentFolderId && flashcardSet.classId == currentClassId
            ),
            ...medias.filter(media => media.parentId == currentFolderId && media.classId == currentClassId),
        ];
    }, [currentFolderId, currentClassId, classes, notes, flashcardSets, folders, medias, classId]);

    const router = useRouter();

    const [knowtLink, setKnowtLink] = useState("");

    const courseName = course?.name || "this class";

    const handleClose = () => {
        setSelectedItems([]);
        setCurrentClassId(null);
        setCurrentFolderId(null);

        onClose();
    };

    const addFile = async (type: ItemType | null, id: string) => {
        const overrides = { classId, folderId, addedAt: now().toString() };

        if (type === ItemType.NOTE) {
            const newNote = await toast.promise(duplicateNote(id, user as UserDetails, overrides), {
                loading: "Adding note...",
                success: "Note added successfully!",
                error: "Failed to add the note",
            });

            localStorage.setItem("addedFileId", newNote.noteId);
            router.push(`/note/${newNote.noteId}/${newNote.title || UNTITLED}`);
        } else if (type === ItemType.FLASHCARDSET) {
            const newFlashcardSet = await toast.promise(duplicateFlashcardSet(id, user, overrides), {
                loading: "Adding Flashcard set...",
                success: "Flashcard set added successfully!",
                error: "Failed to add the Flashcard set",
            });

            localStorage.setItem("addedFileId", newFlashcardSet.flashcardSetId);
            router.push(`/flashcards/${newFlashcardSet.flashcardSetId}/edit`);
        } else if (type === ItemType.MEDIA) {
            const newMedia = await toast.promise(
                duplicateMedia({ baseMediaId: id, userId: userId || "", overrides: overrides }),
                {
                    loading: "Adding Media...",
                    success: "Media added successfully!",
                    error: "Failed to add the Media",
                }
            );

            localStorage.setItem("addedFileId", newMedia.mediaId);
            router.push(`/media/${newMedia.mediaId}`);
        } else {
            toast.error("Yoo Invalid file type");
        }

        await updateClass({ classId, fileCount: (course?.fileCount ?? 0) + 1 }, user);
        handleClose();
    };

    const handleMoveFile = async () => {
        if (!userId) return toast.error("User not logged in");

        const selectedNotesIds = notes
            .filter(({ id }) => selectedItems.some(item => item.id === id))
            .map(({ id }) => id);

        const selectedFlashcardSetsIds = flashcardSets
            .filter(({ id }) => selectedItems.some(item => item.id === id))
            .map(({ id }) => id);

        const selectedMediasIds = medias
            .filter(({ id }) => selectedItems.some(item => item.id === id))
            .map(({ id }) => id);

        const updates = { classId, folderId, addedAt: now().toString(), updated: now().toString() };

        const genericUpdateParams = {
            userId,
            sourceClassId: currentClassId,
            destinationClassId: classId,
            sourceFolderId: currentFolderId,
            destinationFolderId: folderId,
            updatedFields: updates,
        };

        await Promise.all([
            genericUpdateNotes({ noteIds: selectedNotesIds, ...genericUpdateParams }),
            genericUpdateFlashcardSets({
                flashcardSetIds: selectedFlashcardSetsIds,
                ...genericUpdateParams,
            }),
            genericUpdateMedias({ mediaIds: selectedMediasIds, ...genericUpdateParams }),
        ]);

        toast.success("File moved successfully!");

        handleClose();
    };

    const addKnowtLink = () => {
        if (!knowtLink) {
            return toast.error("Please enter a knowt link first");
        }
        const url = new URL(knowtLink);
        const isValid = checkValidKnowtLink(url);

        if (isValid) {
            const fileType = getFileType(url);
            const fileId = url.pathname.split("/")[2] || "";

            addFile(fileType, fileId);
        }
    };

    const renderFilesContent = () => {
        if (!searchText) {
            return (
                <FlexColumn style={{ gap: spacing.XS_2, flex: 1 }}>
                    <MoveToPopupBreadCrumb
                        folderId={currentFolderId}
                        classId={currentClassId}
                        onHomeClick={() => {
                            setCurrentFolderId(null);
                            setCurrentClassId(null);
                            setSelectedItems([]);
                        }}
                        onFolderClick={folder => setCurrentFolderId(folder.folderId)}
                        onClassClick={course => {
                            setCurrentFolderId(null);
                            setCurrentClassId(course.classId);
                        }}
                    />

                    {itemsToDisplay.length === 0 ? (
                        <SearchEmptyState containerStyle={{ marginTop: spacing.MD }} isSearch={false} />
                    ) : (
                        <FlexColumn
                            style={{
                                minHeight: "30rem",
                                overflowX: "hidden",
                                overflowY: "auto",
                                height: "100%",
                                gap: "1rem",
                                flexBasis: 0,
                                flexGrow: 1,
                                paddingBottom: "7rem",
                            }}>
                            {itemsToDisplay.map(item => (
                                <MyFilesSearchCard
                                    item={item}
                                    key={item.id}
                                    sx={{
                                        border: selectedItems.some(({ id }) => id === item.id)
                                            ? `1px solid ${themeColors.neutralBlack}`
                                            : undefined,
                                    }}
                                    onClick={() => {
                                        if (item.type === ItemType.FOLDER) {
                                            setCurrentFolderId(item.id);
                                        } else if (item.type === ItemType.CLASS) {
                                            setCurrentClassId(item.id);
                                        } else {
                                            setSelectedItems(current =>
                                                toggleArrayElements(
                                                    current,
                                                    [{ id: item.id, type: item.type }],
                                                    (a, b) => a.id === b.id
                                                )
                                            );
                                        }
                                    }}
                                />
                            ))}
                        </FlexColumn>
                    )}
                </FlexColumn>
            );
        }

        if (!suggestedItems.length) {
            return <SearchEmptyState />;
        }

        return (
            <div>
                <div style={{ fontWeight: "bold", fontSize: "1.8rem", marginBottom: "1.5rem" }}>Results</div>
                <FlexColumn style={{ gap: "1rem", paddingBottom: "2rem", height: "30rem", overflow: "scroll" }}>
                    {suggestedItems.map(item => (
                        <MyFilesSearchCard
                            item={item}
                            key={item.id}
                            sx={{
                                border: selectedItems.some(({ id }) => id === item.id)
                                    ? `1px solid ${themeColors.neutralBlack}`
                                    : undefined,
                            }}
                            onClick={() => {
                                if (item.type === ItemType.FOLDER) {
                                    setCurrentFolderId(item.id);
                                } else if (item.type === ItemType.CLASS) {
                                    setCurrentClassId(item.id);
                                } else {
                                    setSelectedItems(current =>
                                        toggleArrayElements(
                                            current,
                                            [{ id: item.id, type: item.type }],
                                            (a, b) => a.id === b.id
                                        )
                                    );
                                }
                            }}
                        />
                    ))}
                </FlexColumn>
            </div>
        );
    };

    const renderContent = () => {
        return (
            <FlexColumn style={{ gap: spacing.MD, flex: 1 }}>
                <DialogCloseBtn onClick={handleClose} style={{ marginLeft: "auto", marginBottom: "2rem" }} />
                <FlexColumn style={{ gap: spacing.XS }}>
                    <span className="heading4">Move existing files to class</span>
                    <span className="body1">
                        It will be moved from its original location and only be accessible here.
                    </span>
                </FlexColumn>
                <FlexColumn style={{ gap: spacing.XS }}>
                    <span className="bodyBold1">Duplicate and add a file from Knowt</span>
                    <FlexRowAlignCenter style={{ gap: spacing.SM }}>
                        <InputWithSearchbarIcon
                            placeholder="paste a knowt link"
                            value={knowtLink}
                            onChange={e => setKnowtLink(e.target.value)}
                            containerStyle={{ width: "100%" }}
                            sx={{
                                width: "100%",
                                height: "5rem",
                                fontSize: "1.5rem",
                                border: `1px solid ${themeColors.neutralBlack}`,
                                color: themeColors.neutralBlack,
                            }}
                            noIcon={true}
                        />
                        <CircularRectTextButton
                            onClick={addKnowtLink}
                            sx={{
                                backgroundColor: themeColors.primary,
                                padding: `${spacing.XS_2} ${spacing.SM}`,
                            }}>
                            <span className="bodyBold2">add</span>
                        </CircularRectTextButton>
                    </FlexRowAlignCenter>
                </FlexColumn>
                <FlexColumn style={{ gap: spacing.XS }}>
                    <span className="bodyBold1">Add from your library</span>
                    <InputWithSearchbarIcon
                        placeholder="search for anything"
                        value={searchText}
                        onChange={e => setSearchText(e.target.value)}
                        sx={{
                            width: "100%",
                            height: "5rem",
                            fontSize: "1.5rem",
                            padding: "0 1.5rem 0 5rem",
                            border: `1px solid ${themeColors.neutralBlack}`,
                            color: themeColors.neutralBlack,
                        }}
                    />
                </FlexColumn>
                {renderFilesContent()}
                <CircularRectTextButton
                    sx={{
                        display: itemsToDisplay.length === 0 ? "none" : undefined,
                        position: "absolute",
                        bottom: "2rem",
                        width: "fit-content",
                        alignSelf: "center",
                        maxWidth: "22rem",
                        backgroundColor: themeColors.primary,
                        padding: `${spacing.XS_2} ${spacing.SM}`,
                    }}
                    onClick={() => {
                        if (!selectedItems.length) {
                            return toast.error("Please select a file to move");
                        }

                        handleMoveFile();
                    }}>
                    <span className={clsx("bodyBold2", "ellipsisText")}>
                        move {selectedItems.length} {pluralize("item", selectedItems.length)} to {courseName}
                    </span>
                </CircularRectTextButton>
            </FlexColumn>
        );
    };

    return (
        <ResponsiveDialog
            isOpen={isOpen}
            onClose={handleClose}
            style={{ backgroundColor: themeColors.neutralWhite, overflow: "hidden" }}
            DialogStyle={{
                padding: "4rem 4rem 8rem",
                width: "81.7rem",
                height: "auto",
                borderRadius: "4rem",
            }}
            SlideMenuStyle={{
                padding: "2.5rem 2rem 1rem",
                height: "100%",
            }}>
            {renderContent()}
        </ResponsiveDialog>
    );
};

export default MoveFileToClassPopup;
