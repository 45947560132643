import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import ClickableText from "@/components/styled/button/ClickableText";
import { borderRadius } from "@/utils/borderRadius";
import { iconSizes, strokeWidth } from "@/utils/iconProps";
import { themeColors } from "@/utils/themeColors";
import { Scan } from "lucide-react";
import { useState } from "react";
import { useClassManagementContextSelector } from "../hooks/useClassManagementContext";
import dynamic from "next/dynamic";
import { CopyClassLinkBtn } from "./ClassCodePopup";
import LazyLoaded from "@/components/LazyLoaded";
import { copyToClipboard } from "@/utils/clipboard";
import { BASE_URL } from "@/config/deployConstants";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { isDarkColor } from "@knowt/syncing/utils/genericUtils";
import { spacing } from "@/utils/spacing";
import { useRouter } from "next13-progressbar";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { WALKTHROUGH_INVITE_BTN } from "./Walkthroughs";

const ClassCodePopup = dynamic(() => import("./ClassCodePopup"));

const handleCopyToClipboard = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, course) => {
    e.stopPropagation();
    copyToClipboard(`${BASE_URL}/class/join?classId=${course.classId}`);
};

export const ClassCodeTextWithPopup = () => {
    const course = useClassManagementContextSelector(state => state.course);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <FlexRowAlignCenter>
                <FlexRowAlignCenter
                    as={ClickableText}
                    style={{ color: course?.color, fontWeight: 700, fontSize: "2.2rem" }}
                    onClick={e => {
                        handleCopyToClipboard(e, course);
                        setIsOpen(true);
                    }}>
                    {course?.classId}
                    <Scan
                        strokeWidth={strokeWidth.normal}
                        size={iconSizes.MD}
                        color={course?.color}
                        style={{ margin: "auto 0 0 1rem" }}
                    />
                </FlexRowAlignCenter>
                <CopyClassLinkBtn
                    onClick={e => {
                        handleCopyToClipboard(e, course);
                    }}
                />
            </FlexRowAlignCenter>
            <LazyLoaded load={isOpen}>
                <ClassCodePopup
                    course={course}
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    onCopy={e => handleCopyToClipboard(e, course)}
                />
            </LazyLoaded>
        </>
    );
};

export const InviteStudentsButtonWithPopup = () => {
    const course = useClassManagementContextSelector(state => state.course);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <CircularRectTextButton
                onClick={e => {
                    handleCopyToClipboard(e, course);
                    setIsOpen(true);
                }}
                className="bodyBold2"
                style={{
                    backgroundColor: course?.color || themeColors.neutralBlack,
                    color: course?.color
                        ? isDarkColor(course.color)
                            ? themeColors.pureWhite
                            : themeColors.pureBlack
                        : themeColors.neutralWhite,
                    padding: "0.8rem 1.6rem",
                }}>
                invite students
            </CircularRectTextButton>
            <LazyLoaded load={isOpen}>
                <ClassCodePopup
                    course={course}
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    onCopy={e => handleCopyToClipboard(e, course)}
                />
            </LazyLoaded>
        </>
    );
};

const ClassCodeLinkCard = ({ course }) => {
    const { organization } = useCurrentUser();
    const router = useRouter();

    const isDPAMissing = organization && !organization.dpa;

    return (
        <FlexColumn
            style={{
                borderRadius: borderRadius.card,
                padding: "1.5rem",
                backgroundColor: themeColors.neutralWhite,
                height: "11.2rem",
            }}>
            <h2 className="bodyBold2" style={{ marginBottom: "1.5rem" }}>
                Class Code & Link
            </h2>
            <FlexRowAlignCenter>
                {isDPAMissing ? (
                    <CircularRectTextButton
                        className={WALKTHROUGH_INVITE_BTN}
                        sx={{
                            backgroundColor: course.color,
                            color: isDarkColor(course.color) ? themeColors.pureWhite : themeColors.pureBlack,
                            padding: `${spacing.XS} ${spacing.SM}`,
                        }}
                        onClick={() => router.push(`/class/${course?.classId}/school-referral`)}>
                        {"invite your students"}
                    </CircularRectTextButton>
                ) : (
                    <ClassCodeTextWithPopup />
                )}
            </FlexRowAlignCenter>
        </FlexColumn>
    );
};

export default ClassCodeLinkCard;
