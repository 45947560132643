import CircularFilledIcon from "@/components/CircularButton/styled/CircularFilledIcon";
import FilledTextButton from "@/components/CircularButton/styled/FilledTextButton";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";
import { iconSizes } from "@/utils/iconProps";
import { Layers } from "lucide-react";
import { useUserContentManagementContextSelector } from "../../hooks/useUserContentManagementContext";

const SelectMultipleButton = () => {
    const { mdDown } = useBreakPoints({ md: 850 });

    const isSelectMode = useUserContentManagementContextSelector(state => state.isSelectMode);
    const setIsSelectMode = useUserContentManagementContextSelector(state => state.setIsSelectMode);

    const toggleSelectMode = () => {
        setIsSelectMode(!isSelectMode);
    };

    return mdDown ? (
        <CircularFilledIcon
            radius="5.2rem"
            size={iconSizes.MD}
            Icon={Layers}
            tooltip="Select multiple"
            onClick={toggleSelectMode}
        />
    ) : (
        <FilledTextButton
            onClick={toggleSelectMode}
            text={"select multiple"}
            sx={{ height: "5rem", padding: "1rem 1.5rem" }}
        />
    );
};

export default SelectMultipleButton;
