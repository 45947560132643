import { useEffect, useState } from "react";
import { SetState } from "@/types/common";

export const useSyncedState = <ItemType>(
    syncedValue: ItemType,
    initialState = syncedValue
): [ItemType, SetState<ItemType>] => {
    const [state, setState] = useState<ItemType>(initialState);

    useEffect(() => {
        if (state !== syncedValue && syncedValue) {
            setState(syncedValue);
        }
    }, [syncedValue]);

    return [state, setState];
};
