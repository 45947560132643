import { useSWRImmutable } from "@knowt/syncing/hooks/swr";
import { callListStandaloneFlashcardSetByUserNoContent } from "@/hooks/flashcards/graphqlUtils";
import { resolveFlashcardSetsSWRKey } from "@/hooks/flashcards/utils";
import { useCurrentUser } from "@/hooks/user/useCurrentUser";
import { FlashcardSet } from "@knowt/syncing/graphql/schema";
import { fromEntries } from "@/utils/genericUtils";
import { useMemo } from "react";
import { populateCacheWithFallbackData } from "@/hooks/swr";

export const useTopLevelStandaloneFlashcardSets = ({
    inTrash = false,
    isEnabled = true,
    fallbackData,
}: {
    inTrash?: boolean;
    isEnabled?: boolean;
    fallbackData?: Record<string, FlashcardSet> | undefined;
}) => {
    const { standaloneFlashcards, mutate } = useAllStandaloneFlashcardSets({ isEnabled, fallbackData });

    const flashcardSetsObj = useMemo(() => {
        if (!standaloneFlashcards) return null;

        return fromEntries(
            Object.entries(standaloneFlashcards)
                .filter(([, { classId }]) => classId === null)
                .filter(([, { folderId }]) => folderId === null)
                .filter(([, { trash }]) => trash === inTrash)
        );
    }, [standaloneFlashcards, inTrash]);

    return {
        flashcardSetsObj,
        mutate,
    };
};

export const useAllStandaloneFlashcardSets = ({
    isEnabled = true,
    inTrash = false,
    fallbackData,
    showClassSets = false,
    showFolderSets = false,
}: {
    isEnabled?: boolean;
    inTrash?: boolean;
    fallbackData?: Record<string, FlashcardSet> | undefined;
    showClassSets?: boolean;
    showFolderSets?: boolean;
}) => {
    const { userId } = useCurrentUser();

    const {
        data: _allStandaloneFlashcards,
        error,
        mutate,
    } = useSWRImmutable(
        resolveFlashcardSetsSWRKey({ userId, isEnabled }),
        ([_, userId]) => callListStandaloneFlashcardSetByUserNoContent({ userId }),
        {
            fallbackData,
            use: [populateCacheWithFallbackData],
        }
    );

    const standaloneFlashcards = useMemo(() => {
        if (!_allStandaloneFlashcards) return null;

        return fromEntries(
            Object.entries(_allStandaloneFlashcards)
                .filter(([, { classId }]) => !classId || showClassSets)
                .filter(([, { folderId }]) => !folderId || showFolderSets)
                .filter(([, { trash }]) => trash === inTrash)
        );
    }, [_allStandaloneFlashcards, inTrash, showClassSets, showFolderSets]);

    return {
        standaloneFlashcards,
        isLoading: !standaloneFlashcards && !error,
        mutate,
    };
};
