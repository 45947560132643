"use client";

import { LocalUser } from "@knowt/syncing/hooks/user/types";
import { Class, FlashcardSet, Folder, Media, Note, Bookmark } from "@knowt/syncing/graphql/schema";
import clsx from "clsx";
import { SWRConfig, unstable_serialize } from "swr";
import HomeControlSection from "./components/HomeControlSection";
import { HomeFilesTab, HomeTab } from "./types";
import { UserContentSortSettings } from "../types";
import { HomeManagementContextProvider, useHomeManagementContextSelector } from "./hooks/useHomeManagementContext";
import UserContentManagementPlusButton from "../components/UserContentManagementPlusButton";
import HomePopups from "./components/HomePopups";
import {
    HomeClassesTabContent,
    HomeHomeTabContent,
    HomeSavedTabContent,
    HomeTrashTabContent,
} from "./components/HomeTabContents";
import HomeProfileCard from "./components/HomeProfileCard";
import RecommendedCard from "./components/RecommendedCard";
import StudyEncouragementCard from "./components/StudyEncouragementCard";
import styles from "./homepage.module.css";
import { spacing } from "@/utils/spacing";
import { Flex, FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import { InnerContainer, MainContainer } from "@/components/Containers";
import Collapse from "@mui/material/Collapse";
import br from "@/styles/breakpoints.module.css";
import { MainContainerAdSlot } from "@/features/Ads";
import TabSwitcher from "@/components/TabSwitcher";
import CircularFilledIcon from "@/components/CircularButton/styled/CircularFilledIcon";
import { iconSizes } from "@/utils/iconProps";
import { ChevronsDown, ChevronsUp } from "lucide-react";
import React from "react";
import { WALKTHROUGH_HOME_TAB_SWITCHER } from "./components/Walkthroughs";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";

const HomeTopSection = () => {
    const isTopSectionCollapsed = useHomeManagementContextSelector(state => state.isTopSectionCollapsed);
    const toggleTopSectionCollapse = useHomeManagementContextSelector(state => state.toggleTopSectionCollapse);
    const isClassesTab = useHomeManagementContextSelector(state => state.isClassesTab);

    if (isClassesTab) return null;

    return (
        <FlexColumn>
            <FlexRowAlignCenter style={{ justifyContent: "space-between", marginBottom: spacing.XS_2 }}>
                <span className={"bodyBold2"}>Widgets</span>
                <CircularFilledIcon
                    radius="3.2rem"
                    size={iconSizes.MD_S}
                    Icon={isTopSectionCollapsed ? ChevronsDown : ChevronsUp}
                    tooltip="Collapse top section"
                    onClick={toggleTopSectionCollapse}
                />
            </FlexRowAlignCenter>
            <Collapse orientation="vertical" in={!isTopSectionCollapsed}>
                <FlexRowAlignCenter
                    style={{
                        justifyContent: "space-between",
                        gap: "1.6rem",
                        height: "33.3rem",
                    }}>
                    <Flex className={styles.topCardLeft} style={{ height: "100%", overflow: "hidden" }}>
                        <StudyEncouragementCard />
                    </Flex>
                    <Flex className={clsx(br.smDownDisplayNone, styles.topCardMiddle)} style={{ height: "100%" }}>
                        <RecommendedCard />
                    </Flex>
                    <Flex className={clsx(br.mdDownDisplayNone, styles.topCardRight)} style={{ height: "100%" }}>
                        <HomeProfileCard />
                    </Flex>
                </FlexRowAlignCenter>
                <div style={{ paddingBottom: spacing.LG }} />
            </Collapse>
        </FlexColumn>
    );
};

const HomeTabsSwitcher = () => {
    const { isTeacher } = useCurrentUser();
    const currentTab = useHomeManagementContextSelector(state => state.currentTab);
    const changeCurrentTab = useHomeManagementContextSelector(state => state.changeCurrentTab);

    const tabs = [
        { label: "My Files", value: HomeTab.FILES },
        { label: "Classes", value: HomeTab.CLASSES },
    ];

    return (
        <div>
            <TabSwitcher
                className={WALKTHROUGH_HOME_TAB_SWITCHER}
                tabs={isTeacher ? tabs.reverse() : tabs}
                selectedTab={tabs.findIndex(({ value }) => value === currentTab)}
                setSelectedTab={index => changeCurrentTab(tabs[index].value)}
                width="12rem"
                height="5.5rem"
                fontSize="1.6rem"
            />
        </div>
    );
};

const HomeFilesTabsContent = () => {
    const currentFilesTab = useHomeManagementContextSelector(state => state.currentFilesTab);

    if (currentFilesTab === HomeFilesTab.HOME) {
        return <HomeHomeTabContent />;
    }

    if (currentFilesTab === HomeFilesTab.SAVED) {
        return <HomeSavedTabContent />;
    }

    if (currentFilesTab === HomeFilesTab.TRASH) {
        return <HomeTrashTabContent />;
    }

    throw new Error("Unknown tab: Make sure to only set the tab from the `HomeFilesTab` enum");
};

const HomeTabsContent = () => {
    const currentTab = useHomeManagementContextSelector(state => state.currentTab);
    return currentTab === HomeTab.CLASSES ? <HomeClassesTabContent /> : <HomeFilesTabsContent />;
};

const HomePage = ({
    serverUser,
    serverFolders,
    serverNotes,
    serverFlashcardSets,
    serverMedias,
    serverClasses,
    serverBookmarks,
    sortValue,
    isTopSectionCollapsed,
    homeTab,
    homeFilesTab,
    loadingAnimation,
}: {
    serverUser: LocalUser;
    serverFolders?: Record<string, Folder>;
    serverNotes?: Record<string, Note>;
    serverFlashcardSets?: Record<string, FlashcardSet>;
    serverMedias?: Record<string, Media>;
    serverClasses?: Record<string, Class>;
    serverBookmarks?: Bookmark[] | null | undefined;
    sortValue: UserContentSortSettings;
    isTopSectionCollapsed: boolean;
    homeTab: HomeTab;
    homeFilesTab: HomeFilesTab;
    loadingAnimation?: string;
}) => {
    return (
        <SWRConfig
            value={{
                fallback: {
                    getCurrentUser: serverUser,
                    [unstable_serialize(["bookmarks", serverUser?.user?.ID])]: serverBookmarks,
                },
            }}>
            <HomeManagementContextProvider
                serverUser={serverUser.user ?? null}
                serverFolders={serverFolders}
                serverNotes={serverNotes}
                serverFlashcardSets={serverFlashcardSets}
                serverMedias={serverMedias}
                serverClasses={serverClasses}
                sortValue={sortValue}
                isTopSectionCollapsed={isTopSectionCollapsed}
                homeTab={homeTab}
                homeFilesTab={homeFilesTab}
                loadingAnimation={loadingAnimation}>
                <MainContainer style={{ paddingBlock: "0rem 0rem", minHeight: "calc(99vh - var(--navbar-height))" }}>
                    <InnerContainer data-testid="home-page">
                        <div style={{ paddingBottom: spacing.LG }} />
                        <HomeTabsSwitcher />
                        <div style={{ paddingBottom: spacing.LG }} />
                        {serverUser.user?.accountType !== "Teacher" && <HomeTopSection />}
                        <HomeControlSection />
                        <div style={{ paddingBottom: spacing.LG }} />
                        <HomeTabsContent />
                        <HomePopups />
                        <span className={br.smUpDisplayNone}>
                            <UserContentManagementPlusButton />
                        </span>
                    </InnerContainer>
                    <MainContainerAdSlot user={serverUser.user} organization={serverUser.organization} />
                </MainContainer>
            </HomeManagementContextProvider>
        </SWRConfig>
    );
};

export default HomePage;
