"use client";

import React from "react";
import { InnerContainer, MainContainer } from "@/components/Containers";
import { Flex, FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import TabSwitcher from "@/components/TabSwitcher";
import { MainContainerAdSlot } from "@/features/Ads";
import { spacing } from "@/utils/spacing";
import { LocalAuthUser } from "@knowt/syncing/hooks/user/types";
import { Class, FlashcardSet, Folder, Media, Note } from "@knowt/syncing/graphql/schema";
import ClassCard from "../components/ClassCard";
import ClassCodeLinkCard from "../components/ClassCodeLinkCard";
import SectionPills from "../components/SectionPills";
import { ClassManagementContextProvider, useClassManagementContextSelector } from "../hooks/useClassManagementContext";
import ClassDashboardProgressTab from "./components/ClassDashboardProgressTab";
import CompletionProgress from "../components/CompletionProgress";
import ClassControlSection from "./components/ClassControlSection";
import ClassDashboardStudentsTab from "./components/ClassDashboardStudentsTab";
import { ClassHomeTabContents, ClassSavedTabContents } from "../components/ClassTabContents";
import { UserContentSortSettings } from "../../types";
import { ClassDashboardTab, ClassFilesTab } from "../types";
import ClassBreadcrumb from "./components/ClassBreadcrumb";
import br from "@/styles/breakpoints.module.css";
import { SWRConfig } from "swr";
import ReminderBadge from "./components/ReminderBadge";
import ComplianceBanner from "./components/ComplianceBanner";
import { ClassDashboardWalkthrough, WALKTHROUGH_CLASS_TAB } from "../components/Walkthroughs";
import clsx from "clsx";
import dynamic from "next/dynamic";
import LazyLoaded from "@/components/LazyLoaded";
import useGuidedEvent from "@knowt/syncing/hooks/guidedEvents/useGuidedEvent";
import GUIDED_EVENTS, { AllEventNames } from "@knowt/syncing/hooks/guidedEvents/guidedEvents";

const DashboardProgressTrackingPopup = dynamic(() => import("../components/popups/DashboardProgressTrackingPopup"));

const ClassTabSwitcher = () => {
    const course = useClassManagementContextSelector(state => state.course);
    const currentTab = useClassManagementContextSelector(state => state.currentTab);
    const changeCurrentTab = useClassManagementContextSelector(state => state.changeCurrentTab);

    const tabs = [
        { label: "Dashboard", value: ClassDashboardTab.DASHBOARD },
        { label: "People", value: ClassDashboardTab.PEOPLE },
        { label: "Progress", value: ClassDashboardTab.PROGRESS },
    ];

    return (
        <FlexRowAlignCenter style={{ width: "100%", justifyContent: "space-between" }}>
            <TabSwitcher
                className={clsx(br.smDownDisplayNone, WALKTHROUGH_CLASS_TAB)}
                tabs={tabs}
                selectedTab={tabs.findIndex(tab => tab.value === currentTab)}
                setSelectedTab={index => changeCurrentTab(tabs[index].value)}
                tabColor={course?.color}
                width="13.3rem"
                height="5.5rem"
                fontSize="1.6rem"
                containerStyle={{ width: "fit-content" }}
            />
            <TabSwitcher
                className={br.smUpDisplayNone}
                tabs={tabs}
                selectedTab={tabs.findIndex(tab => tab.value === currentTab)}
                setSelectedTab={index => changeCurrentTab(tabs[index].value)}
                tabColor={course?.color}
                width="11rem"
                height="5.5rem"
                fontSize="1.6rem"
                containerStyle={{ width: "fit-content" }}
            />
            <ReminderBadge classId={course.classId} />
        </FlexRowAlignCenter>
    );
};

const ClassTopCards = () => {
    const course = useClassManagementContextSelector(state => state.course);
    const selectedFlashcardSet = useClassManagementContextSelector(state => state.selectedFlashcardSet);
    const setStudyProgressFilter = useClassManagementContextSelector(state => state.setStudyProgressFilter);
    const changeCurrentTab = useClassManagementContextSelector(state => state.changeCurrentTab);

    return (
        <Flex style={{ gap: "2rem", height: "auto" }} className={br.smDownColumn}>
            <ClassCard />
            <FlexColumn style={{ flex: 1, gap: "2rem" }}>
                <CompletionProgress
                    classId={course.classId}
                    flashcardSet={selectedFlashcardSet}
                    onSeeMoreClick={() => {
                        changeCurrentTab(ClassDashboardTab.PROGRESS);
                    }}
                    onProgressClick={progress => {
                        setStudyProgressFilter(progress);
                        changeCurrentTab(ClassDashboardTab.PROGRESS);
                    }}
                />
                <ClassCodeLinkCard course={course} />
            </FlexColumn>
        </Flex>
    );
};

const DashboardClassBreadcrumb = () => {
    const course = useClassManagementContextSelector(state => state.course);
    return <ClassBreadcrumb course={course} />;
};

const DashboardTab = () => {
    const currentFilesTab = useClassManagementContextSelector(state => state.currentFilesTab);

    return (
        <FlexColumn style={{ gap: spacing.MD }}>
            <ClassTopCards />
            <ClassControlSection />
            <SectionPills />
            <div style={{ paddingBottom: spacing.XS }} />
            {(() => {
                if (currentFilesTab === ClassFilesTab.SAVED) {
                    return <ClassSavedTabContents />;
                }
                return <ClassHomeTabContents />;
            })()}
        </FlexColumn>
    );
};

const ClassDashboardTabsContent = () => {
    const currentTab = useClassManagementContextSelector(state => state.currentTab);

    const { latestEvent, closeLatestEvent } = useGuidedEvent(GUIDED_EVENTS.CLASS_DASHBOARD);
    const isDashboardIntroOpen = latestEvent?.eventName === AllEventNames.CLASS_INTRO;

    if (currentTab === ClassDashboardTab.DASHBOARD) {
        return (
            <>
                <DashboardTab />
                {(() => {
                    if (isDashboardIntroOpen) {
                        return (
                            <LazyLoaded load={isDashboardIntroOpen}>
                                <DashboardProgressTrackingPopup
                                    isOpen={isDashboardIntroOpen}
                                    onClose={closeLatestEvent}
                                />
                            </LazyLoaded>
                        );
                    }
                    // only run waltkhrough once the intro popup has been closed
                    return <ClassDashboardWalkthrough />;
                })()}
            </>
        );
    }

    if (currentTab === ClassDashboardTab.PEOPLE) {
        return <ClassDashboardStudentsTab />;
    }

    if (currentTab === ClassDashboardTab.PROGRESS) {
        return <ClassDashboardProgressTab />;
    }

    return null;
};

type ClassDashboardProps = {
    serverUser: LocalAuthUser;
    serverClass: Class;
    serverFolders: Record<string, Folder>;
    serverNotes: Record<string, Note>;
    serverFlashcardSets: Record<string, FlashcardSet>;
    serverMedias: Record<string, Media>;
    classTab: ClassDashboardTab;
    sortValue: UserContentSortSettings;
};

const ClassDashboard = ({
    serverUser,
    serverClass,
    serverNotes,
    serverFlashcardSets,
    serverFolders,
    serverMedias,
    classTab,
    sortValue,
}: ClassDashboardProps) => {
    return (
        <SWRConfig value={{ fallback: { getCurrentUser: serverUser } }}>
            <ComplianceBanner />
            <MainContainer style={{ paddingBlock: "0rem 0rem", minHeight: "calc(99vh - var(--navbar-height))" }}>
                <InnerContainer>
                    <ClassManagementContextProvider
                        serverUser={serverUser.user}
                        serverClass={serverClass}
                        serverNotes={serverNotes}
                        serverFolders={serverFolders}
                        serverFlashcardSets={serverFlashcardSets}
                        serverMedias={serverMedias}
                        classTab={classTab}
                        sortValue={sortValue}>
                        <div style={{ paddingBottom: spacing.LG_2 }} />
                        <DashboardClassBreadcrumb />
                        <div style={{ paddingBottom: spacing.MD }} />
                        <ClassTabSwitcher />
                        <div style={{ paddingBottom: spacing.MD }} />
                        <ClassDashboardTabsContent />
                    </ClassManagementContextProvider>
                </InnerContainer>
                <MainContainerAdSlot user={serverUser.user} organization={serverUser.organization} />
            </MainContainer>
        </SWRConfig>
    );
};

export default ClassDashboard;
