import React from "react";
import Select from "@/components/Select";
import { useClassManagementContextSelector } from "../hooks/useClassManagementContext";
import { themeColors } from "@/utils/themeColors";
import { ClassSection } from "@knowt/syncing/graphql/schema";
import { SxProps } from "@mui/material";

const SectionPicker = ({
    selectedSection,
    onSectionChange,
    hideAllSections,
    btnSx,
    adornment,
}: {
    selectedSection?: ClassSection | null;
    onSectionChange: (section: ClassSection | null) => void;
    hideAllSections?: boolean;
    btnSx?: SxProps;
    adornment?: React.ReactNode;
}) => {
    const course = useClassManagementContextSelector(state => state.course);

    if (course.sections.length === 1) return null;

    return (
        <Select
            options={[
                ...(hideAllSections ? [] : [{ label: "All sections", value: null }]),
                ...course.sections.map(section => ({
                    label: section.name,
                    value: section,
                })),
            ]}
            onChange={({ value }) => onSectionChange(value)}
            menuSx={{ width: "25rem", padding: "1rem" }}
            btnSx={{
                width: "fit-content",
                gap: "1rem",
                height: "4.8rem",
                borderRadius: 999,
                backgroundColor: themeColors.neutralWhite,
                ...btnSx,
            }}
            selected={
                selectedSection
                    ? { label: selectedSection.name, value: selectedSection }
                    : { label: "All sections", value: null }
            }
            transitionOrigin={{
                anchorOrigin: { vertical: "bottom", horizontal: "left" },
                transformOrigin: { vertical: "top", horizontal: "left" },
            }}
            adornment={adornment}
        />
    );
};

export default SectionPicker;
