import { trashFlashcardSets } from "@knowt/syncing/hooks/flashcards/utils";
import { trashFolders } from "@knowt/syncing/hooks/folders/utils";
import { trashNotes } from "@knowt/syncing/hooks/notes/utils";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { toast } from "react-hot-toast";
import { AnimatedTrashButton } from "@/components/TrashButton/TrashButton";
import { useFolderManagementContextSelector } from "../hooks/useFolderManagementContext";
import { trashMedias } from "@knowt/syncing/hooks/media/utils";
import { isFlashcardSet, isFolder, isMedia, isNote } from "@knowt/syncing/utils/dataCleaning";
import { useUserContentManagementContextSelector } from "@/features/UserContentManagement";

const FolderTrashButtonWithHandler = () => {
    const { userId } = useCurrentUser();

    const selectedItems = useUserContentManagementContextSelector(state => state.selectedItems);
    const setSelectedItems = useUserContentManagementContextSelector(state => state.setSelectedItems);
    const setIsSelectMode = useUserContentManagementContextSelector(state => state.setIsSelectMode);

    const folderId = useFolderManagementContextSelector(state => state.folderId);

    const selectedItemCount = Object.keys(selectedItems).length;

    const deleteSelectedItems = async () => {
        if (!userId) {
            toast.error("Please login to delete items");
            return;
        }

        const selectedItemArr = Object.values(selectedItems);

        const noteIds: string[] = [];
        const folderIds: string[] = [];
        const flashcardSetIds: string[] = [];
        const mediaIds: string[] = [];

        selectedItemArr.forEach(item => {
            if (isNote(item)) {
                noteIds.push(item.noteId);
            } else if (isFolder(item)) {
                folderIds.push(item.folderId);
            } else if (isFlashcardSet(item)) {
                flashcardSetIds.push(item.flashcardSetId);
            } else if (isMedia(item)) {
                mediaIds.push(item.mediaId);
            } else {
                throw new Error("Unknown item type");
            }
        });

        try {
            await Promise.all([
                trashNotes({ noteIds, userId, sourceFolderId: folderId }),
                trashFlashcardSets({ flashcardSetIds, userId, sourceFolderId: folderId }),
                trashMedias({ mediaIds, userId, sourceFolderId: folderId }),
                trashFolders({ folderIds, userId, parentId: folderId }),
            ]);

            // we have animation during the deletion, so wait for it to finish
            setTimeout(() => {
                setSelectedItems({});
                setIsSelectMode(false);
            }, 1000);
        } catch {
            const noun = selectedItemCount > 1 ? "items" : "item";
            toast.error(`Failed to trash ${selectedItemCount} ${noun}`);
        }
    };

    return (
        <AnimatedTrashButton
            text={"Trash"}
            confirmDialogProps={{
                title: `Are you sure you want to delete ${selectedItemCount > 1 ? "these" : "this"}?`,
                subtitle:
                    "You'll need to visit your trash to recover these items, open them, or if you want to delete them forever.",
                buttonText: "Trash",
            }}
            selectedCount={selectedItemCount}
            onDelete={selectedItemCount >= 1 ? deleteSelectedItems : null}
            onAnimationFinished={() => null}
        />
    );
};

export default FolderTrashButtonWithHandler;
