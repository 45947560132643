import { RawFlashcardSet } from "@/graphql/customSchema";
import { NoteMetadata } from "@/types/common";
import { ItemType, Media, UserDetails } from "@knowt/syncing/graphql/schema";

export const populateRecentlyViewedItemsWithMetadata = (
    user: UserDetails,
    notes: Record<string, NoteMetadata> | null,
    flashcardSets: Record<string, RawFlashcardSet>,
    medias: Record<string, Media>
) => {
    if (!user?.recentlyViewed) return null;

    return user.recentlyViewed
        .filter(item => item.type !== ItemType.CHAPTER)
        .map(item => {
            if (item.type === ItemType.NOTE) {
                return { ...notes?.[item.ID], ...item };
            }

            if (item.type === ItemType.FLASHCARDSET) {
                return { ...flashcardSets?.[item.ID], ...item };
            }

            if (item.type === ItemType.MEDIA) {
                return { ...medias?.[item.ID], ...item };
            }
        });
};
