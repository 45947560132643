import { Flex, FlexColumnAlignJustifyCenter } from "@/components/Flex";
import Image from "next/image";
import { themeColors } from "@/utils/themeColors";
import { spacing } from "@/utils/spacing";
import { ASSETS_URL } from "@/config/deployConstants";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { ShareIcon } from "lucide-react";
import { useState } from "react";
import dynamic from "next/dynamic";
import LazyLoaded from "@/components/LazyLoaded";

const kaiWaving = "/images/kai-waving.svg";

const StudentToTeacherReferralPopup = dynamic(() => import("./StudentToTeacherReferralPopup"), {
    ssr: false,
});

export const ClassesTabEmptyState = ({ isTeacher }: { isTeacher: boolean }) => {
    const [isReferralPopupOpen, setIsReferralPopupOpen] = useState(false);

    return (
        <FlexColumnAlignJustifyCenter style={{ height: "100%", gap: "4rem" }}>
            {isTeacher ? (
                <Flex
                    style={{
                        marginBottom: spacing.LG,
                        width: "20rem",
                        height: "20rem",
                        borderRadius: "50%",
                        backgroundColor: themeColors.neutralWhite,
                        position: "relative",
                        overflow: "hidden",
                    }}>
                    <Image
                        src={kaiWaving}
                        alt="empty home page"
                        height={150}
                        width={150}
                        style={{ position: "absolute", right: "0%", bottom: "-10%" }}
                    />
                </Flex>
            ) : (
                <Image src={`${ASSETS_URL}/images/wrappedGift.svg`} alt="empty home page" height={160} width={160} />
            )}
            <FlexColumnAlignJustifyCenter style={{ textAlign: "center", maxWidth: "55rem", gap: "0.8rem" }}>
                <p className="heading4">
                    {isTeacher
                        ? 'Click "create new" to create your first class'
                        : "Get Knowt completely ad-free when your teacher joins"}
                </p>
                <p
                    className="body2"
                    style={{
                        maxWidth: "42rem",
                    }}>
                    {isTeacher
                        ? " You'll find this button in the top left, and above that you'll be able to switch to your personal files as well."
                        : "You get Knowt completely ad free (inside and outside of your class) for the rest of the year if your"}
                    teacher joins!
                </p>
            </FlexColumnAlignJustifyCenter>
            {!isTeacher && (
                <CircularRectTextButton
                    onClick={() => setIsReferralPopupOpen(true)}
                    className="bodyBold1"
                    style={{
                        padding: "1.6rem 2.4rem",
                        gap: "0.8rem",
                        backgroundColor: themeColors.neutralBlack,
                        color: themeColors.neutralWhite,
                    }}>
                    <ShareIcon size={24} strokeWidth={2.5} />
                    Refer your teacher
                </CircularRectTextButton>
            )}
            <LazyLoaded load={isReferralPopupOpen}>
                <StudentToTeacherReferralPopup
                    isOpen={isReferralPopupOpen}
                    onClose={() => setIsReferralPopupOpen(false)}
                />
            </LazyLoaded>
        </FlexColumnAlignJustifyCenter>
    );
};

export const MyFilesTabEmptyState = () => {
    return (
        <FlexColumnAlignJustifyCenter style={{ height: "100%" }}>
            <Flex
                style={{
                    marginBottom: spacing.LG,
                    width: "20rem",
                    height: "20rem",
                    borderRadius: "50%",
                    backgroundColor: themeColors.neutralWhite,
                    position: "relative",
                    overflow: "hidden",
                }}>
                <Image
                    src={kaiWaving}
                    alt="empty home page"
                    height={150}
                    width={150}
                    style={{ position: "absolute", right: "0%", bottom: "-10%" }}
                />
            </Flex>
            <div style={{ textAlign: "center", maxWidth: "55rem" }}>
                <span className={"bodyBold1"}>
                    <b>Click "create new" to create flashcards, notes, and more</b>
                </span>
                <br />
                You'll find this button in the top left, and above that you'll be able to switch to the class tab as
                well.
            </div>
        </FlexColumnAlignJustifyCenter>
    );
};
