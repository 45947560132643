import { CreateNewContentButtonWithHandler } from "@/components/CreateNewContent/CreateNewContentButtonWithHandler";
import { useSearchParams } from "next/navigation";

/** on mobile, this will be the button to create a new content. This should work for both home and folder page */
const UserContentManagementPlusButton = () => {
    const searchParams = useSearchParams();
    const folderId = searchParams?.get("folderId");

    return (
        <div
            style={{
                fontSize: "4.5rem",
                transform: "scale(2)",
                color: "white",
                position: "fixed",
                right: "5rem",
                bottom: "6rem",
                zIndex: 2,
            }}>
            <CreateNewContentButtonWithHandler onlyShowIcon folderId={folderId || undefined} />
        </div>
    );
};

export default UserContentManagementPlusButton;
