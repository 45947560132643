export enum HomeTab {
    CLASSES = "classes",
    FILES = "files",
}

export enum HomeFilesTab {
    HOME = "home",
    SAVED = "saved",
    TRASH = "trashed",
}
