import { ClassMemberWithDetails } from "@/graphql/schema";
import { FlexRowAlignCenter } from "@/components/Flex";
import UserAvatar from "@/components/userCardComponents/UserAvatar";
import LinkWrapper from "@/components/wrappers/Link";

const StudentInformationCell = ({ member }: { member: ClassMemberWithDetails | null | undefined }) => {
    if (!member) return null;

    return (
        <FlexRowAlignCenter style={{ gap: "1.1rem", width: "15rem" }}>
            <UserAvatar radius={"4.4rem"} src={member.pictureUrl} />
            <LinkWrapper href={`/u/${member.username}`} style={{ maxWidth: "90%" }}>
                <p className="bodyBold2 ellipsisText">{member.Name}</p>
                <p className="ellipsisText">@{member.username}</p>
            </LinkWrapper>
        </FlexRowAlignCenter>
    );
};

export default StudentInformationCell;
