import Popper from "@mui/material/Popper";
import { useState } from "react";

/**
 * Works where `children` is the element to trigger the `PopperChildren` when mouse is hovered
 */
const HoverPopper = ({
    children,
    PopperChildren,
}: {
    children: ({ ref, onPointerEnter, onPointerLeave }) => React.ReactNode;
    PopperChildren: React.ReactNode | (() => React.ReactNode);
}) => {
    const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null);

    const onPointerEnter = (event: React.MouseEvent) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const onPointerLeave = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            {children({ ref: anchorEl, onPointerEnter, onPointerLeave })}
            <Popper open={open} anchorEl={anchorEl}>
                {typeof PopperChildren === "function" ? PopperChildren() : PopperChildren}
            </Popper>
        </>
    );
};

export default HoverPopper;
